import { Component, SimpleChanges, OnChanges, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, Directive, HostListener } from '@angular/core';
import { NestedTreeControl, CdkTreeModule } from '@angular/cdk/tree';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NestableModule } from 'ngx-nestable';
import * as FileSaver from 'file-saver';
import { SystemService } from '../../../services/system.service'
import { InventoryService } from '../../../services/inventory.service'
import { UntypedFormBuilder, Validators, ValidationErrors, UntypedFormControl, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { CustomerService } from '../../../services/customer.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { DispatchService } from '../../../services/dispatch.service';
import { UsersService } from '../../../services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-customer-approval',
	templateUrl: './customer-approval.component.html',
	styleUrls: ['./customer-approval.component.scss']
})
export class CustomerApprovalComponent {
	@ViewChild('detailsModal') detailsRef: ElementRef;
	@ViewChild('approveModal') approveRef: ElementRef;
	@ViewChild('stepper', { static: true }) stepper: MatStepper;
	@ViewChild('detailsModal2') detailsModalEle: ElementRef;

	@Output() customersawaiting = new EventEmitter < any > ();

	debtorFromGroup: UntypedFormGroup;
	branchFormGroup: UntypedFormGroup;
	contactFormGroup: UntypedFormGroup;
	denySearchRef = new UntypedFormControl('');
	searchVal = new UntypedFormControl('');
	isEditable = false;
	terms: any = [];
	customertypes: any = [];
	holdreasons: any = [];
	currencies: any = [];
	salestypes: any = [];
	zones: any = [];
	taxes: any = [];
	alltaxes: any = [];
	locations: any = [];
	areas: any = [];
	salesman: any = [];
	shipvia: any = [];
	tirefee = true;
	disabledbranch = false;
	customer_created: any = [];
	paymentmethods: any = [];
	phone: any = '';
	place: any = false;
	routes: any = [];
	custresults: any = [];
	//todo make this part of configurations or define company variables on login
	defaults = {
		country: 'United States',
		region: 'NY',
		holdreason: '1',
		currency: 'USD',
		terms: 'COD',
		salestype: 'A',
		customertype: '1',
		discount: 0,
		credit: 0,
		email_invoice: false,
		requirepo: false,
		email_statement: false,
		interest: 3,
		warehouse: '00',
		shipvia: '1',
		area: '01',
		territory: '01',
		salesman: '',
		disabled: false,
		tirefee: true,
		taxauth: '119',
		branchcode: '00',
		default_paymentmehod: '2',
		cosm_license: '',
		licExpDate: '',
		signed_mor: false,
	};

	color = '';
	s: any = [];
	config: any = false;
	index = 1;
	duplicatewarning: any;
	modalAction: boolean;
	contacttypes: any = false;
	contacts: any = [];
	customers: any = false;
	detail: any = [];
	approvingNew: any = '';
	assignExisting: any = '';
	allcustomers: any = false;
	userData: any;

	constructor(public systemService: SystemService, private userService: UsersService, public dispatchService: DispatchService, public router: Router, private fb: UntypedFormBuilder, public globalsService: GlobalsService, public customerService: CustomerService, public location: Location, private globalSearchService: GlobalSearchService, private modalService: NgbModal) {}


	ngOnInit(): void {

		this.userData = this.userService.getLocalUser();

		this.systemService.getWebCustomers().subscribe(v => {

			this.customers = v;
			this.allcustomers = v;
			this.approvingNew = false;

			this.customersawaiting.emit(v);
		});
		this.globalSearchService.configsubscription.subscribe(c => {
			this.config = c;
		});

		this.color = this.globalSearchService.getColor();

		this.dispatchService.getRoutes().subscribe((r: any) => {
			this.routes = r
		});

		this.globalSearchService.taxgroups.subscribe(async (results: any) => {
			this.taxes = results;
			this.alltaxes = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salesman = results;
		});

		this.globalSearchService.shipvia.subscribe(async (results: any) => {
			this.shipvia = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.zones.subscribe((results: any) => {
			this.zones = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});

		this.globalSearchService.customertypes.subscribe(details => {
			this.customertypes = details;
		});

		this.globalSearchService.currencies.subscribe(details => {
			this.currencies = details;
		});

		this.globalSearchService.salestypes.subscribe(details => {
			this.salestypes = details;
		});

		this.globalSearchService.locations.subscribe(details => {
			this.locations = details;
		});

		this.globalSearchService.areas.subscribe(details => {
			this.areas = details;
		});

		this.globalsService.getPaymentMethods().subscribe(details => {
			this.paymentmethods = details;
		});

		this.globalsService.getTypes().subscribe(async (results: any) => {
			this.globalSearchService.all_system_types.next(results);
			this.contacttypes = results.contacttypes
			this.contactFormGroup = this.fb.group({
				contid: [0],
				name: ['', Validators.required],
				email: ['', [Validators.email]],
				phone: [''],
				role: [
					[], Validators.required
				],
			});

			//this.denySearchRef.valueChanges.subscribe(newValue => {
			//this.searchDeny(newValue);
			//});

		});

		this.setForms()


		this.debtorFromGroup.get('paymentterms').valueChanges.subscribe(newValue => {
			const termcode = this.terms.filter(t => {
				return t.termsindicator == newValue
			})[0];
			if (termcode.collect_payment && termcode.collect_payment == '1') {
				this.debtorFromGroup.get('creditlimit').setValue('0.00')
			}
		});

		this.debtorFromGroup.get('emailstatement').valueChanges.subscribe((newValue: any) => {
			if (newValue) {
				this.debtorFromGroup.get('statementemailaddress').setValidators([Validators.required])
				this.debtorFromGroup.get('statementemailaddress').updateValueAndValidity();
			} else {
				this.debtorFromGroup.get('statementemailaddress').clearValidators()
				this.debtorFromGroup.get('statementemailaddress').updateValueAndValidity();
			}
		})

		this.debtorFromGroup.get('ediinvoices').valueChanges.subscribe((newValue: any) => {
			if (newValue) {
				this.debtorFromGroup.get('invoiceemailaddress').setValidators([Validators.required])
				this.debtorFromGroup.get('invoiceemailaddress').updateValueAndValidity();
			} else {
				this.debtorFromGroup.get('invoiceemailaddress').clearValidators()
				this.debtorFromGroup.get('invoiceemailaddress').updateValueAndValidity();
			}
		})


		const data = { loccode: this.userData.user.defaultlocation.loccode };
		this.globalsService.getLocationDefaults(data).subscribe((results: any) => {
			this.defaults = results;
			this.updateBranch();
		});

	}
	setForms() {
		// this.contactFormGroup = this.fb.group({
		// 		contid: [0],
		// 		name: ['', Validators.required],
		// 		email: ['', [Validators.email]],
		// 		phone: [''],
		// 		role: [
		// 			[], Validators.required
		// 		],
		// 	});


		this.debtorFromGroup = this.fb.group({
			name: ['', [Validators.required]],
			address1: ['', [Validators.required]],
			address2: ['', []],
			address3: ['', [Validators.required]],
			address4: [this.defaults.region, [Validators.required]],
			address5: ['', [Validators.required]],
			address6: [this.defaults.country, [Validators.required]],
			creditlimit: [this.defaults.credit, [Validators.required]],
			discount: [this.defaults.discount, [Validators.pattern('[0-9]*')]],
			pymtdiscount: [this.defaults.discount, [Validators.pattern('[0-9]*')]],
			arintrate: [this.defaults.interest],
			paymentterms: [this.defaults.terms, [Validators.required]],
			salestype: [this.defaults.salestype, [Validators.required]],
			typeid: [this.defaults.customertype, [Validators.required]],
			holdreason: [this.defaults.holdreason, [Validators.required]],
			currcode: [this.defaults.currency, [Validators.required]],
			emailstatement: [this.defaults.email_statement, [Validators.required]],
			statementemailaddress: ['', [Validators.email]],
			ediinvoices: [this.defaults.email_invoice, [Validators.required]],
			invoiceemailaddress: ['', [Validators.email]],
			customerpoline: [this.defaults.requirepo, [Validators.required]],
			cosm_license: [this.defaults.cosm_license, []],
			licExpDate: [this.defaults.licExpDate, []],
			signed_mor: [this.defaults.signed_mor, [Validators.required]],
		});

	}

	updateBranch() {


		this.branchFormGroup = this.fb.group({
			branchcode: [this.defaults.branchcode, [Validators.required]],
			//debtorno: [''],
			brname: [this.debtorFromGroup.controls.name.value, [Validators.required]],
			contactname: [' ', [Validators.required]],
			braddress1: [this.debtorFromGroup.controls.address1.value, [Validators.required]],
			braddress2: [this.debtorFromGroup.controls.address2.value],
			braddress3: [this.debtorFromGroup.controls.address3.value, [Validators.required]],
			braddress4: [this.debtorFromGroup.controls.address4.value, [Validators.required]],
			braddress5: [this.debtorFromGroup.controls.address5.value, [Validators.required]],
			braddress6: [this.debtorFromGroup.controls.address6.value, [Validators.required]],
			//estdeliverydays: [''],
			phoneno: [this.phone, [Validators.required]],
			email: ['', [Validators.email, Validators.required]],
			faxno: [''],
			disabletrans: [this.defaults.disabled],
			tirefee: [this.defaults.tirefee],
			defaultlocation: [this.defaults.warehouse, [Validators.required]],
			defaultshipvia: [this.defaults.shipvia, [Validators.required]],
			area: [this.defaults.area, [Validators.required]],
			salesman: [this.defaults.salesman, [Validators.required]],
			tax_ref: [''],
			taxgroupid: [this.defaults.taxauth, [Validators.required]],
			specialinstructions: [''],
			username: [''],
			password: [''],
			lat: [],
			lng: [],
			bypass: false,
			route: ['', [Validators.required]],
			default_paymentmehod: [this.defaults.default_paymentmehod, [Validators.required]],
			//territory_id: [this.defaults.area, [Validators.required]],
			//pricelevel: [''],

		});
		const s = this.s;

		if (s && s.custom_field) {

			this.debtorFromGroup.get('name').setValue(s.custom_field.company)
			this.debtorFromGroup.get('address1').setValue(s.custom_field.address1)
			this.debtorFromGroup.get('address2').setValue(s.custom_field.address2)
			this.debtorFromGroup.get('address3').setValue(s.custom_field.city)
			this.debtorFromGroup.get('address4').setValue(s.custom_field.address4)
			this.debtorFromGroup.get('address5').setValue(s.custom_field.postcode)
			this.debtorFromGroup.get('address6').setValue('United States')

			this.branchFormGroup.get('email').setValue(s.custom_field.email)
			this.branchFormGroup.get('username').setValue(s.custom_field.email)
			this.branchFormGroup.get('password').setValue(s.custom_field.password)
			this.branchFormGroup.get('contactname').setValue(s.firstname + ' ' + s.lastname);
			this.branchFormGroup.get('phoneno').setValue(s.custom_field.telephone)

			this.branchFormGroup.get('brname').setValue(s.custom_field.company)
			this.branchFormGroup.get('braddress1').setValue(s.custom_field.address1)
			this.branchFormGroup.get('braddress2').setValue(s.custom_field.address2)
			this.branchFormGroup.get('braddress3').setValue(s.custom_field.city)
			this.branchFormGroup.get('braddress4').setValue(s.custom_field.address4)
			this.branchFormGroup.get('braddress5').setValue(s.custom_field.postcode)
			this.branchFormGroup.get('braddress6').setValue('United States')

			this.branchFormGroup.get('email').setValue(s.custom_field.email);
			this.branchFormGroup.get('username').setValue(s.custom_field.email);
			this.branchFormGroup.get('password').setValue(s.custom_field.password);
			this.branchFormGroup.get('contactname').setValue(s.firstname + ' ' + s.lastname);
			this.branchFormGroup.get('phoneno').setValue(s.custom_field.telephone);
			this.branchFormGroup.get('tax_ref').setValue(s.custom_field.taxref);

			const data_search = {
				zipcode: s.custom_field.postcode
			};

			this.customerService.zipCodeCheck(data_search).subscribe(async (results: any) => {
				if (results && results.length) {
					this.taxes = results;
					this.branchFormGroup.get('taxgroupid').setValue(results[0].taxgroupid)
				} else {
					this.taxes = this.alltaxes;
				}
			});



		}

		if (this.place) {
			this.branchFormGroup.get('lat').setValue(this.place.geometry.location.lat())
			this.branchFormGroup.get('lng').setValue(this.place.geometry.location.lng())
		}

		this.branchFormGroup.get('email').valueChanges.subscribe(newValue => {
			const valuetest = this.branchFormGroup.get('username').value;
			if (valuetest != newValue || valuetest == '') {
				this.branchFormGroup.get('username').setValue(newValue);
			}

		})

		this.branchFormGroup.get('braddress5').valueChanges.subscribe(newValue => {

			const data_search  = {
				zipcode: newValue,
				tax_ref: this.branchFormGroup.get('taxgroupid').value
			}
			this.customerService.zipCodeCheck(data_search).subscribe(async (results: any) => {
				if(results && results.length) {
					this.taxes = results;
					this.branchFormGroup.get('taxgroupid').setValue(results[0].taxgroupid)
				} else {
					this.taxes = this.alltaxes;
				}
			});
		});

		if (this.config && this.config.env.package === 'beauty') {
			this.branchFormGroup.get('route').setValidators([])
			this.branchFormGroup.get('route').setValue(1); // default for beauty
			this.branchFormGroup.get('email').setValidators([])
			this.branchFormGroup.updateValueAndValidity();


		}

		this.branchFormGroup.get('tax_ref').valueChanges.subscribe(newvalue => {
			if (newvalue != '') {
				let currentValue = this.alltaxes.filter(v => {
					return v.taxgroupid == this.branchFormGroup.get('taxgroupid').value
				})[0];

				if (currentValue) {
					currentValue = currentValue.taxgroupdescription
				} else {
					currentValue = '';
				}

				this.filterTaxAuths(true, currentValue);
			} else {
				this.filterTaxAuths(false, '');
			}
		});

		this.branchFormGroup.markAllAsTouched();
	}

	detailCustomer(s: any) {

		this.detail = s;
		this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {});

	}

	approveCustomer(s: any) {

		const data = { loccode: this.userData.user.defaultlocation.loccode };

		this.globalsService.getLocationDefaults(data).subscribe((results: any) => {
			this.contacts = [];
			this.defaults = results;
			this.setForms();
			this.s = s;

			this.debtorFromGroup.get('name').setValue(s.custom_field.company)
			this.debtorFromGroup.get('address1').setValue(s.custom_field.address1)
			this.debtorFromGroup.get('address2').setValue(s.custom_field.address2)
			this.debtorFromGroup.get('address3').setValue(s.custom_field.city)
			this.debtorFromGroup.get('address4').setValue(s.custom_field.address4)
			this.debtorFromGroup.get('address5').setValue(s.custom_field.postcode)
			this.debtorFromGroup.get('address6').setValue('United States')

			this.branchFormGroup.get('email').setValue(s.custom_field.email)
			this.branchFormGroup.get('username').setValue(s.custom_field.email)
			this.branchFormGroup.get('password').setValue(s.custom_field.password)
			this.branchFormGroup.get('contactname').setValue(s.firstname + ' ' + s.lastname);
			this.branchFormGroup.get('phoneno').setValue(s.custom_field.telephone)

			this.branchFormGroup.get('brname').setValue(s.custom_field.company)
			this.branchFormGroup.get('braddress1').setValue(s.custom_field.address1)
			this.branchFormGroup.get('braddress2').setValue(s.custom_field.address2)
			this.branchFormGroup.get('braddress3').setValue(s.custom_field.city)
			this.branchFormGroup.get('braddress4').setValue(s.custom_field.address4)
			this.branchFormGroup.get('braddress5').setValue(s.custom_field.postcode)
			this.branchFormGroup.get('braddress6').setValue('United States')

			const data_search = {
				zipcode: s.custom_field.postcode
			};

			if (s && s.custom_field) {
				this.branchFormGroup.get('email').setValue(s.custom_field.email);
				this.branchFormGroup.get('username').setValue(s.custom_field.email);
				this.branchFormGroup.get('password').setValue(s.custom_field.password);
				this.branchFormGroup.get('contactname').setValue(s.firstname + ' ' + s.lastname);
				this.branchFormGroup.get('phoneno').setValue(s.custom_field.telephone);
				this.branchFormGroup.get('tax_ref').setValue(s.custom_field.taxref);
				this.prefillContact()
			}

			this.customerService.zipCodeCheck(data_search).subscribe(async (results: any) => {
				if (results && results.length) {
					this.taxes = results;
					this.branchFormGroup.get('taxgroupid').setValue(results[0].taxgroupid)
				} else {
					this.taxes = this.alltaxes;
				}
			});
			this.approvingNew = true;
			this.updateBranch();

		});
	}

	getAddress(place: any) {
		this.place = place


		const componenets = place['address_components'];
		const name = place['name'];
		const phone = place['formatted_phone_number'];
		const website = place['website'];

		const address1 = this.parseComponentShortName(componenets, ['street_number', 'route']);
		const address2 = this.parseComponentShortName(componenets, ['subpremise']);
		const address3 = this.parseComponentShortName(componenets, ['locality']);
		const address4 = this.parseComponentShortName(componenets, ['administrative_area_level_1']);
		const address5 = this.parseComponentShortName(componenets, ['postal_code']);
		const address6 = this.parseComponentLongName(componenets, ['country']);

		this.debtorFromGroup.get('address1').setValue(address1)
		this.debtorFromGroup.get('address2').setValue(address2)
		this.debtorFromGroup.get('address3').setValue(address3)
		this.debtorFromGroup.get('address4').setValue(address4)
		this.debtorFromGroup.get('address5').setValue(address5)
		this.debtorFromGroup.get('address6').setValue(address6)

		this.branchFormGroup.get('braddress1').setValue(address1)
		this.branchFormGroup.get('braddress2').setValue(address2)
		this.branchFormGroup.get('braddress3').setValue(address3)
		this.branchFormGroup.get('braddress4').setValue(address4)
		this.branchFormGroup.get('braddress5').setValue(address5)
		this.branchFormGroup.get('braddress6').setValue(address6)

		this.branchFormGroup.get('lat').setValue(this.place.geometry.location.lat())
		this.branchFormGroup.get('lng').setValue(this.place.geometry.location.lng())


		if (name) {
			this.debtorFromGroup.get('name').setValue(name)
			this.branchFormGroup.get('brname').setValue(name)
		}

		if (phone) {
			this.phone = phone;
			this.branchFormGroup.get('phoneno').setValue(phone)
		}

	}

	addContact() {
		this.contacts.push(this.contactFormGroup.value);
		this.contactFormGroup.reset();
	}

	private parseComponentShortName(componenets: any, types: any) {

		const result = [];
		componenets.forEach((comp: any) => {
			const compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					const adda = (comp.short_name) ? comp.short_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}

	private parseComponentLongName(componenets: any, types: any) {
		const result = [];
		componenets.forEach((comp: any) => {
			const compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					const adda = (comp.long_name) ? comp.long_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}

	prefillContact() {

		const email = this.branchFormGroup.get('email').value;
		const phone = this.branchFormGroup.get('phoneno').value;
		const name = this.branchFormGroup.get('contactname').value;
		this.contactFormGroup.get('phone').patchValue(phone)
		this.contactFormGroup.get('email').patchValue(email);
		this.contactFormGroup.get('name').patchValue(name);
		this.contactFormGroup.get('role').patchValue(['OWNER']);

		this.addContact()

	}

	createCustomer() {

		this.getFormValidationErrors(this.branchFormGroup);
		this.getFormValidationErrors(this.debtorFromGroup);
		if (this.branchFormGroup.valid && this.debtorFromGroup.valid) {

			let debtorfrom = this.debtorFromGroup.value;

			debtorfrom.webcustomer = true;

			this.customerService.createCustomer(debtorfrom, this.branchFormGroup.value, this.contacts).subscribe((results: any) => {
				if (results.success) {
					this.customer_created = results.customer;
					this.approvingNew = false;
					this.globalSearchService.showNotification('Customer Approved and new Customer created', 'success', 'bottom', 'right');
					const data = { customer: this.s.customer_id }

					this.systemService.approveCustomer(data).subscribe(v => {
						this.customers = v;
						this.allcustomers = v;
						this.customersawaiting.emit(v);
						this.contactFormGroup.reset();
						this.debtorFromGroup.reset();
						this.branchFormGroup.reset();

					});

				} else {
					const bypass = confirm(results.message);
					if (bypass) {
						this.branchFormGroup.get('bypass').setValue(true);
						this.customerService.createCustomer(this.debtorFromGroup.value, this.branchFormGroup.value).subscribe((v: any) => {
							this.customer_created = v.customer;
							this.approvingNew = false;
							this.globalSearchService.showNotification('Customer Approved and new Customer created', 'success', 'bottom', 'right');
							const data = { customer: this.s.customer_id }
							this.systemService.approveCustomer(data).subscribe(v => {
								this.customers = v;
								this.allcustomers = v;
								this.customersawaiting.emit(v);
							});
							//this.router.navigate(['./customers/view/' + results.customer.debtorno]);
						});

					}

				}
			});
		}
	}

	denyCustomer(s: any) {

		const data = { customer: s.customer_id }
		this.systemService.denyCustomer(data).subscribe(v => {

			this.customers = v;
			this.allcustomers = v;
			this.customersawaiting.emit(v);
			this.approvingNew = false;
		});
	}
	undenyCustomer(s: any) {

		const data = { customer: s.customer_id }
		this.systemService.undenyCustomer(data).subscribe(v => {

			this.customers = v;
			this.allcustomers = v;
			this.customersawaiting.emit(v);
			this.approvingNew = false;
		});
	}
	searchAwait(searchValue: any) {

		if (searchValue == '') {
			this.customers = this.allcustomers;

		} else {
			this.customers.awaiting = this.globalSearchService.filterItem(this.customers.awaiting, searchValue, 'firstname,lastname')
		}
	}
	searchDeny(searchValue: any) {

		if (searchValue == '') {
			this.customers = this.allcustomers;
		} else {
			this.customers.denied = this.globalSearchService.filterItem(this.customers.denied, searchValue, 'firstname,lastname')
		}
	}

	isAssigning: boolean = false;

	assignCustomer(custRecord: any, existingCust: any) {

		const newAssign = { custdata: custRecord, oldcust: existingCust };

		this.isAssigning = true;

		this.systemService.assignCustomer(newAssign).subscribe(r => {
			this.isAssigning = false;
			this.globalSearchService.showNotification('Customer Approved and login assigned to ' + existingCust.debtorno + '.' + existingCust.branchcode, 'success', 'bottom', 'right');
			const data = { customer: custRecord.customer_id }
			this.systemService.approveCustomer(data).subscribe(v => {

				this.customers = v;
				this.allcustomers = v;
			});

		});
		this.modalService.dismissAll();
	}
	assignCustomerDialog(custRecord: any) {

		this.detail = custRecord;
		this.custresults = [];
		this.searchVal.setValue('');
		this.modalService.open(this.approveRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {});
	}
	searching: boolean = false;
	custSearch() {
		const data = { keywords: this.searchVal.value };
		this.searching = true;
		this.customerService.getCustomerSearch(data).subscribe(r => {
			this.custresults = r;
			this.searching = false;
		});
	}

	getFormValidationErrors(form: any) {
		Object.keys(form.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = form.get(key).errors;

			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					form.markAllAsTouched();
					form.markAsUntouched()

					if (key == 'reference') {
						key = 'PO Number';
					}

					this.globalSearchService.showNotification(key + ' ' + keyError, 'danger', 'bottom', 'right')
				});
			}
		});
	}

	compareAddress() {
		if (!this.debtorFromGroup.valid) { return; }
		const a1 = this.debtorFromGroup.get('address1').value;
		const a2 = this.debtorFromGroup.get('address2').value;
		const a3 = this.debtorFromGroup.get('address3').value;
		const cont = false;
		if (a1 != '' || a2 != '' || a3 != '') {
			const data = { add1: a1, add2: a2, add3: a3 }
			this.customerService.addressCompare(data).subscribe((result) => {
				if (result.length) {
					this.duplicatewarning = result;
					this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', keyboard: false }).result.then((result) => {}, (reason) => {

						if (this.modalAction) {
							this.updateBranch();
							this.step();
							this.modalAction = undefined;
						} else {
							this.modalAction = undefined;
						}

					});
				} else {
					this.updateBranch();
					this.step();
				}
			});

		}

	}

	isMatch(entered, found) {
		if (entered.toLowerCase() == found.toLowerCase()) {
			return true;
		}
		return false;
	}

	userSelection(input: boolean) {
		this.modalAction = input;
		this.modalService.dismissAll();
	}


	removeContact(contact: any) {
		const index = this.contacts.indexOf(contact);
		this.contacts.splice(index, 1);
	}
	step() {
		document.getElementById('stepperNext').click();
	}

	isEmail(search: string): boolean {
		let serchfind: boolean;
		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		serchfind = regexp.test(search);
		return serchfind
	}

	cancel() {
		this.debtorFromGroup.reset();
		this.branchFormGroup.reset();
		this.contactFormGroup.reset();
		this.approvingNew = false;
	}

	filterTaxAuths(filter: any, current: any) {

		if (filter) {

			const results = this.taxes.filter((r: any) => {
				let newvalue = '';

				if (current != '') {
					newvalue = current.replace('1', '2');
				}

				if (r.taxgroupdescription.startsWith('2') ) {
					if (newvalue != '' && newvalue == r.taxgroupdescription) {
						this.branchFormGroup.get('taxgroupid').setValue(r.taxgroupid);
						//this.globalSearchService.showNotification('Tax Group Updated to Non Taxable', 'warn', 'bottom', 'right');
					}
					return r;
				}
			})
			this.taxes = results;
		} else {
			this.taxes = this.alltaxes;
		}
	}
}