<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card pr-0 pl-0">
				<div class="card-header card-header-{{color}}-3">
					<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Purchase Receiving</h4>
					<p class="card-category"></p>
					<div class="messages row">
					</div>
				</div>
				<div class="card-body">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" [selectedIndex]="pageindex" class="no-overflow" (selectedTabChange)="resetData($event)">
						<mat-tab label="Item Scan">
							<ng-template matTabContent class="no-overflow">
								<div class="col-12 m-0 p-0 ">
									<mat-form-field appearance="outline" class="ml-2 mr-2" *ngIf="purchoderdetails.length > 0">
										<mat-label>Vendor</mat-label>
										<mat-select [formControl]="vendor" (selectionChange)="filterItems()">
											<mat-option *ngFor="let v of vendors" [value]="v.supplierid">
												{{v.supplierid}} - {{v.suppname}}
											</mat-option>
										</mat-select>
									</mat-form-field>
									<div class="table table-responsive text-center" *ngIf="!purchoderdetails.length && loadingdetails">
										<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
											</circle>
										</svg>
										<div>
											<p>Loading Order Details</p>
										</div>
									</div>
								</div>
								<div class="col-12 m-0 p-0 " *ngIf="!loadingdetails">
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>Scan An Item</mat-label>
										<input #itemCodeRef matInput class="text-right" placeholder="Scan an Item" [formControl]="itemCode" (input)="itemScan()">
									</mat-form-field>
								</div>
							</ng-template>
						</mat-tab>
						<mat-tab label="P.O. Receiving" class="no-overflow">
							<ng-container>
								<form [formGroup]="itemScanForm" class="no-overflow">
									<div class="row ">
										<!--<div class="col-12 col-md-3 ml-0 mr-0">
											<app-vendor-auto-complete (vendor_selected)="filterPoByVendor($event)"></app-vendor-auto-complete>
										</div>
										-->
										<div class="col-12 col-md-4 ml-0 mr-0">
											<mat-form-field appearance="outline" class="ml-2 mr-2">
												<mat-label>Journal</mat-label>
												<mat-select formControlName="journal">
													<mat-option *ngFor="let j of journals" [value]="j">
														#{{j.journal_no }}: {{j.journal_date | date }} {{j.desc1 }}</mat-option>
												</mat-select>
												<span matPrefix (click)="closeOpenNewJournal()">
													<small><i class="material-icons">remove</i></small>
												</span>
											</mat-form-field>
										</div>
										<div class="col-12 col-md-4 ml-0 mr-0">

											<mat-form-field appearance="outline" class="ml-2 mr-2">
												<mat-label>Select A Purchase Order</mat-label>
												<input type="text" placeholder="Select Purchase Order" aria-label="Select Purchase Order" matInput [formControl]="poSearch" [matAutocomplete]="pos">
												<mat-autocomplete #pos="matAutocomplete">
													<mat-option (onSelectionChange)="loadSelectedOrder(o.orderno)" *ngFor="let o of purchaseorders" [value]="o.orderno">
														{{ o.orderno }} {{ o.statusname }} {{ o.suppname }}
													</mat-option>
												</mat-autocomplete>
												<button matSuffix mat-icon-button aria-label="Clear" (click)="loadPos()">
													<mat-icon>close</mat-icon>
												</button>
											</mat-form-field>
										</div>
										<div class="col-12 col-md-4 ml-0 mr-0">
											<mat-form-field appearance="outline" class="ml-2 mr-2">
												<mat-label>Scan An Item</mat-label>
												<input type="text" placeholder="Scan an Item" aria-label="Scan an Item" matInput formControlName="keywords" (input)="itemSearch()">
											</mat-form-field>
										</div>
									</div>
								</form>
							</ng-container>
						</mat-tab>
					</mat-tab-group>
					<div class="row mt-2 mb-2" *ngIf="!mustpick.length && !dataSource.length && !loadingPO && !loadingdetails">
						<div class="col-12 text-center">
							<span class="alert alert-succes">No Items To Receive, Select a Purchase Order or Scan an Item</span>
						</div>
					</div>
					<div class="table table-responsive text-center" *ngIf="receviginorder && loadingPO">
						<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
							</circle>
						</svg>
						<div>
							<p>Loading #{{receviginorder}}</p>
						</div>
					</div>
					<div class="row border-bottom mt-2" *ngIf="mustpick.length">
						<div class="col-6 text-left">
							<p *ngIf="receviginorder">PO #{{receviginorder}}</p>
						</div>
						<div class="col-6 text-right">
							<button mat-raised-button color="accent" aria-label="complete receviing" (click)="completeReceiving()">
								<mat-icon>save</mat-icon> Complete Receiving
							</button>
						</div>
						<div class="col-6 text-left mr-0 pr-0"><b>ITEM/PO</b></div>
						<div class="col-6 text-right ml-0 text-nowrap"><b>DESC/PICK/BIN</b></div>

					</div>
					<div class="row">
						<div class="ml-auto col-md-3">
						</div>
						<div class="col-md-3">
							<mat-form-field appearance="outline">
								<mat-label>Items Per Page</mat-label>
								<mat-select id="pagePicker" [value]="pageSize" (selectionChange)="updatePageSize($event);">
									<mat-option *ngFor="let i of items_per_page" [value]="i">{{ i }}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="ml-auto mr-0 col-md-6 mt-3">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
					</div>
					<ul class="list-unstyled" *ngIf="mustpick.length > 0">
						<li *ngFor="let item of mustpick | paginate: { itemsPerPage: pageSize, currentPage: p }">
							<div class="row border-bottom pickitem mt-2">
								<div class="col-12 col-md-6 mt-2 mr-auto ml-0">
									<span class="spec-label-large">ITEM</span>
									&nbsp;&nbsp;{{ item.stockid }}
									<br />
									<span class="spec-label-large">DESC:</span>&nbsp;&nbsp;
									{{ item.description }}
									<br />
									<span class="spec-label-large">QOH</span>&nbsp;&nbsp;
									({{ item.totalqoh }})
										&nbsp;&nbsp;
									<span class="spec-label-large">PO#</span>&nbsp;&nbsp;
									{{ item.orderno }}
									<ng-container *ngIf="item.commited">
										<span class="spec-label-large">COMMITED:</span>&nbsp;&nbsp;
										{{ item.commited.sales_orderno }} x {{ item.commited.quantiy_order }}
									</ng-container>
								</div>
								<div class="col-12 col-md-6 text-right ml-auto mr-0 ">
									<div class="row m-0 p-0 ">
										<div class="col-md-1 " [ngClass]="{'alert-success': item.current_movement > 0, 'alert-danger': item.current_movement < 0 }">
											<mat-icon class="mt-4" *ngIf="item.current_movement > 0">add</mat-icon>
											<mat-icon class="mt-4" *ngIf="item.current_movement < 0">remove</mat-icon>
										</div>
										<div class="col-md-1 mt-3 mr-2">
											<button mat-icon-button aria-label="split bin" (click)="addBin(item)">
												<mat-icon>call_split</mat-icon>
											</button>
										</div>
										<div class="col-md-9">
											TOTAL: <b>{{ item.quantityrecd }}</b> / <b>{{ item.quantityord }}</b>
											<div class="row m-0 p-0" *ngFor="let b of item.bins">
												<div class="col-md-4 mt-2">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Bin</mat-label>
														<input matInput type="text" placeholder="Bin" [(value)]="b.bin" (input)="setBin($event.target.value,b)">
														<mat-error></mat-error>
														<span matSuffix *ngIf="item.bins.length > 1">
															<button mat-icon-button aria-label="remove bin" (click)="removeBin(b, item)" *ngIf="item.bins.length > 1">
																<mat-icon>remove</mat-icon>
															</button>
														</span>

													</mat-form-field>
												</div>
												<div class="col-md-8 mt-auto mb-auto">
													<span class="" [ngClass]="item.quantityrecd < item.quantityord ? 'text-danger': 'text-success'">
														<mat-form-field class="full-width" appearance="outline">
															<span matPrefix>
																<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(b, item)">
																	<small>
																		<i class="material-icons">remove</i>
																	</small>
																</button>
															</span>
															<input matInput type="text" class="text-right" [(ngModel)]="b.qty" [ngClass]="item.quantityrecd < item.quantityord ? 'text-danger': 'text-success'" (blur)="setQty($event, item, b)" (focus)="selectText($event)">
															<span matSuffix> / <b>{{ item.quantityord }}</b></span>
															<span matSuffix>
																<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(b, item)">
																	<small><i class="material-icons">add</i></small>
																</button>
															</span>
															<span matSuffix>
																<mat-checkbox [checked]='item.quantityrecd == item.quantityord' (change)="setCheckValue($event, item)" class="mat-checkbox mat-accent" data-item="{{ item.podetailitem }}"></mat-checkbox>
															</span>
															<mat-error *ngIf="showError">{{errorMessage}}</mat-error>
														</mat-form-field>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>