import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';

@Injectable({
  providedIn: 'root'
})
export class ClaudeService {

  private apiUrl: string;
  private apiKey: string;

  constructor(
	private globalSearchService: GlobalSearchService,
	private http: HttpClient,
	private _configSvc: RuntimeConfigLoaderService
  ) {
	this.globalSearchService.configsubscription.subscribe(r => {
	  this.apiUrl = r.apiServer.endpoint;  // Get API URL from config
	  this.apiKey = r.apiCredentials.clientId; // API Key from config
	});
  }

  private httpOptions = {
	headers: new HttpHeaders({
	  'Content-Type': 'application/json',
	  'x-api-key' : '0597078',
	  'customer-id':'0597078',
	})
  };

  /**
   * Sends a report to the backend PHP API for Claude processing.
   * @param report The report text to be analyzed.
   */
  public analyzeReport(report: string): Observable<any> {
	const method = 'upload/analyze';  // Assuming backend endpoint is `claude/analyze`
	return this.http.post(this.setEndPoint(method), { report }, this.httpOptions).pipe(
	  catchError(this.handleError('analyzeReport', report))
	);
  }

  private setEndPoint(method: string) {
	return 'https://cdn.beautyserv.com/upload/analyze' ;
  }

  private getApiKey() {
	return '&key=' + this.apiKey;
  }

  private handleError<T>(operation = 'operation', result?: T) {
	return (error: any): Observable<T> => {
	  if (error.status === 401) {
		window.location.href = '/#/auth/logout';
	  }
	  return throwError(() => new Error(`${operation} failed: ${error.message}`));
	};
  }
}
