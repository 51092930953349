import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, Input, Output, EventEmitter, SimpleChanges, ChangeDetectionStrategy, OnChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { PaymentsService } from '../../services/payments.service';
import { PrintService } from '../../services/print.service';

@Component({
	selector: 'app-journal-report',
	templateUrl: './journal-report.component.html',
	styleUrls: ['./journal-report.component.scss']
})
export class JournalReportComponent implements OnInit {
	@ViewChild('journal_details') journal_detailsRef: ElementRef;
	journal_payments: any = [];
	journal_review = new UntypedFormControl('');
	color: any = 'blue';
	sending: any = false;
	today = new Date();
	repdate = new UntypedFormControl(this.today);
	filteredresult: any = [];
	compdate: any = '';
	closedjournals: any = [];
	selectedJournal: any = false;
	loadingjournal: any = false;

	constructor(private printService: PrintService, private globalSearchService: GlobalSearchService, private paymentsService: PaymentsService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.getJournals();
	}

	pdfJournal() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.journal_detailsRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'Journal '+this.journal_payments.journal_no+' '+this.journal_payments.userid+' '+this.journal_payments.journal_date,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	xlsJournal() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.journal_detailsRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'journal',
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	getJournals() {
		const data = {
			startdate: this.today
		};
		this.paymentsService.getClosedJournals(data).subscribe((result: any) => {
			this.filteredresult = [];
			this.compdate = this.repdate.value.toISOString().split('T')[0];

			for (let x = 0; x < result.length; x++) {

				if (result[x].journal_date == this.compdate) {
					this.filteredresult.push(result[x]);
				}
			}

			this.closedjournals = this.filteredresult;
			if (this.filteredresult.length) {
				this.journal_review.setValue(this.filteredresult[0]);
				this.selectedJournal = this.filteredresult[0];
				this.loadSelectedJournal();
			} else {
				this.journal_review.setValue('');
			}
		});
	}

	getSelectedJournal(event: any) {
		this.selectedJournal = event.value;
		this.loadSelectedJournal();
	}

	loadSelectedJournal() {
		this.loadingjournal = true;
		this.paymentsService.getJournal(this.selectedJournal).subscribe(r => {
			this.journal_payments = r;
			this.loadingjournal = false;
		});
	}
	back() {
		//this.location.back()
	}
	updateDayPayMents(event: any) {
		this.today = event;
		this.getJournals();
	}
}
