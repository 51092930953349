import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehiclesService } from '../../services/vehicles.service';

@Component({
	selector: 'app-parts-used',
	templateUrl: './parts-used.component.html',
	styleUrls: ['./parts-used.component.scss']
})
export class PartsUsedComponent implements OnInit {
	@Input() vehicle: any | null = null;

	partsUsedForm: FormGroup;
	partsUsed: any[] = [];
	filteredPartsUsed: any[] = [];
	vehicles: any[] = [];
	selectedPart: any | null = null;
	isFormVisible = false;
	isLoading = false;
	searchTerm = '';
	vehicleFilter = '';

	constructor(
		private fb: FormBuilder,
		private vehiclesService: VehiclesService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.initializeForm();
		this.loadVehicles();
		this.getPartsUsed();
	}

	initializeForm(): void {
		this.partsUsedForm = this.fb.group({
			vehicle_id: [this.vehicle ? this.vehicle.id : '', Validators.required],
			part_name: ['', Validators.required],
			part_number: ['', Validators.required],
			quantity: [1, [Validators.required, Validators.min(1)]],
			cost: [0, [Validators.required, Validators.min(0)]],
			supplier: ['']
		});
	}

	getPartsUsed(): void {
		const filters = {
			vehicle_id: this.vehicle?.id
		};
		this.vehiclesService.getPartsUsed(filters).subscribe((data: any) => {
			this.partsUsed = data.parts_used;
			this.filteredPartsUsed = data.parts_used;
		});
	}

	loadVehicles(): void {
		this.vehiclesService.getVehicles({}).subscribe((vehicles) => {
			this.vehicles = vehicles.vehicles;
		});
	}

	applyFilter(event: any): void {
		this.searchTerm = event.target.value.toLowerCase();
		this.filterPartsUsed();
	}

	applyVehicleFilter(vehicleId: string): void {
		this.vehicleFilter = vehicleId;
		this.filterPartsUsed();
	}

	filterPartsUsed(): void {
		this.filteredPartsUsed = this.partsUsed.filter(part =>
			(!this.searchTerm || part.part_name.toLowerCase().includes(this.searchTerm)) &&
			(!this.vehicleFilter || part.vehicle_id.toString() === this.vehicleFilter)
		);
	}

	savePartUsed(): void {
		if (this.partsUsedForm.valid) {
			const partData = this.partsUsedForm.value;
			if (this.selectedPart) {
				this.vehiclesService.updatePartUsed(partData).subscribe(() => {
					this.snackBar.open('Part updated successfully.', 'Close', { duration: 3000 });
					this.getPartsUsed();
					this.toggleForm();
				});
			} else {
				this.vehiclesService.addPartUsed(partData).subscribe(() => {
					this.snackBar.open('Part added successfully.', 'Close', { duration: 3000 });
					this.getPartsUsed();
					this.toggleForm();
				});
			}
		} else {
			this.showValidationErrors();
		}
	}

	showValidationErrors(): void {
		for (const control in this.partsUsedForm.controls) {
			if (this.partsUsedForm.controls.hasOwnProperty(control)) {
				this.partsUsedForm.controls[control].markAsTouched();
			}
		}
	}

	toggleForm(): void {
		this.isFormVisible = !this.isFormVisible;
		if (this.isFormVisible) this.partsUsedForm.reset({ vehicle_id: this.vehicle ? this.vehicle.vehicle_id : '' });
	}

	deletePartUsed(partId: number): void {
		if (!confirm('Are you sure you want to delete this part?')) return;

		this.vehiclesService.deletePartUsed(partId).subscribe(
			(result) => {
				if (result.success) {
					this.snackBar.open('Part deleted successfully.', 'Close', { duration: 3000 });
					this.getPartsUsed();
				} else {
					this.snackBar.open(result.error, 'Close', { duration: 3000 });
				}
			},
			() => {
				this.snackBar.open('Error deleting part.', 'Close', { duration: 3000 });
			}
		);
	}
}