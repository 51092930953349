<div class="row mb-3">
	<div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Search Fuel Logs</mat-label>
			<input matInput (keyup)="applyFilter($event)" placeholder="Search by Fuel Type, Cost, or Amount" />
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
	</div>
	<div class="col-md-6 text-end">
		<button mat-raised-button color="accent" class="mt-2" (click)="toggleForm()">
			{{ isFormVisible ? 'Hide Form' : 'Add Fuel Log' }}
		</button>
	</div>
</div>

<!-- Add/Edit Fuel Log Form -->
<div *ngIf="isFormVisible" class="p-3 border rounded bg-light">
	<form [formGroup]="fuelLogForm" (ngSubmit)="saveFuelLog()">
		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100" *ngIf="vehicles">
					<mat-label>Vehicle</mat-label>
					<mat-select formControlName="vehicle_id" required>
						<mat-option *ngIf="vehicle" [value]="vehicle.id">
							{{ vehicle.plate }} - {{ vehicle.make }} {{ vehicle.model }}
						</mat-option>
						<mat-option *ngFor="let v of vehicles" [value]="v.id">
							{{ v.plate }} - {{ v.make }} {{ v.model }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="fuelLogForm.controls['vehicle_id'].invalid && fuelLogForm.controls['vehicle_id'].touched">
						Vehicle selection is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Fuel Date</mat-label>
					<input matInput [matDatepicker]="picker" formControlName="fuel_date" required />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-error *ngIf="fuelLogForm.controls['fuel_date'].invalid && fuelLogForm.controls['fuel_date'].touched">
						A valid fuel date is required.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Fuel Type</mat-label>
					<mat-select formControlName="fuel_type" required>
						<mat-option value="Gasoline">Gasoline</mat-option>
						<mat-option value="Diesel">Diesel</mat-option>
						<mat-option value="Electric">Electric</mat-option>
						<mat-option value="Hybrid">Hybrid</mat-option>
					</mat-select>
					<mat-error *ngIf="fuelLogForm.controls['fuel_type'].invalid && fuelLogForm.controls['fuel_type'].touched">
						Fuel type selection is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Amount</mat-label>
					<input matInput formControlName="amount" type="number" required />
					<mat-error *ngIf="fuelLogForm.controls['amount'].invalid && fuelLogForm.controls['amount'].touched">
						<span *ngIf="fuelLogForm.controls['amount'].errors?.['required']">Amount is required.</span>
						<span *ngIf="fuelLogForm.controls['amount'].errors?.['min']">Must be greater than 0.</span>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Cost ($)</mat-label>
					<input matInput formControlName="cost" type="number" required />
					<mat-error *ngIf="fuelLogForm.controls['cost'].invalid && fuelLogForm.controls['cost'].touched">
						<span *ngIf="fuelLogForm.controls['cost'].errors?.['required']">Cost is required.</span>
						<span *ngIf="fuelLogForm.controls['cost'].errors?.['min']">Must be a positive value.</span>
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Odometer</mat-label>
					<input matInput formControlName="odometer" type="number" required />
					<mat-error *ngIf="fuelLogForm.controls['odometer'].invalid && fuelLogForm.controls['odometer'].touched">
						<span *ngIf="fuelLogForm.controls['odometer'].errors?.['required']">Odometer is required.</span>
						<span *ngIf="fuelLogForm.controls['odometer'].errors?.['min']">Must be a positive value.</span>
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="text-end">
			<button mat-raised-button color="accent" type="submit" [disabled]="fuelLogForm.invalid">
				Save Fuel Log
			</button>
			&nbsp;&nbsp;
			<button mat-raised-button color="warn" type="button" (click)="toggleForm()">
				Cancel
			</button>
		</div>
	</form>

</div>

<!-- Table -->
<div class="table-responsive mt-4">
	<table class="table table-striped table-hover table-bordered">
		<thead class="thead-dark">
			<tr>
				<th class="text-center">Fuel Date</th>
				<th class="text-center">Fuel Type</th>
				<th class="text-center">Amount</th>
				<th class="text-center">Cost ($)</th>
				<th class="text-center">Odometer</th>
				<th class="text-center"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let log of filteredFuelLogs">

				<td class="text-center">{{ log.fuel_date }}</td>
				<td class="text-center">{{ log.fuel_type }}</td>
				<td class="text-center">{{ log.amount }}</td>
				<td class="text-center">{{ log.cost }}</td>
				<td class="text-center">{{ log.odometer }}</td>
				<td class="text-center">
					<button mat-icon-button color="primary" (click)="editFuelLog(log)">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="warn" (click)="deleteFuelLog(log.log_id)">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>
