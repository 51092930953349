<!-- Filters -->
<div class="row mb-3">
	<div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Search Driver Logs</mat-label>
			<input matInput (keyup)="applyFilter($event)" placeholder="Search by Driver Name" />
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
	</div>
	<div class="col-md-6 text-end">
		<button mat-raised-button color="accent" class="mt-2" (click)="toggleForm()">
			{{ isFormVisible ? 'Hide Form' : 'Add Driver Log' }}
		</button>
	</div>
</div>

<!-- Add/Edit Driver Log Form -->
<div *ngIf="isFormVisible" class="p-3 border rounded bg-light">
	<form [formGroup]="driverLogForm" (ngSubmit)="saveDriverLog()">
		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Driver Name</mat-label>
					<input matInput formControlName="driver_name" required />
					<mat-error *ngIf="driverLogForm.controls['driver_name'].invalid && driverLogForm.controls['driver_name'].touched">
						Driver Name is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Driver License</mat-label>
					<input matInput formControlName="driver_license" required />
					<mat-error *ngIf="driverLogForm.controls['driver_license'].invalid && driverLogForm.controls['driver_license'].touched">
						Driver License is required.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Start Time</mat-label>
					<input matInput [matDatepicker]="pickerStart" formControlName="start_time" required />
					<mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
					<mat-datepicker #pickerStart></mat-datepicker>
					<mat-error *ngIf="driverLogForm.controls['start_time'].invalid && driverLogForm.controls['start_time'].touched">
						Start Time is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>End Time</mat-label>
					<input matInput [matDatepicker]="pickerEnd" formControlName="end_time" />
					<mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
					<mat-datepicker #pickerEnd></mat-datepicker>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Purpose</mat-label>
					<input matInput formControlName="purpose" required />
					<mat-error *ngIf="driverLogForm.controls['purpose'].invalid && driverLogForm.controls['purpose'].touched">
						Purpose is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-3">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Starting Mileage</mat-label>
					<input matInput formControlName="mileage_start" type="number" required />
					<mat-error *ngIf="driverLogForm.controls['mileage_start'].invalid && driverLogForm.controls['mileage_start'].touched">
						Must be a positive value.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-3">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Ending Mileage</mat-label>
					<input matInput formControlName="mileage_end" type="number" />
				</mat-form-field>
			</div>
		</div>

		<div class="text-end">
			<button mat-raised-button color="accent" type="submit" [disabled]="driverLogForm.invalid">
				Save Driver Log
			</button>
			<button mat-raised-button color="warn" type="button" (click)="toggleForm()">
				Cancel
			</button>
		</div>
	</form>
</div>

<!-- Table -->
<div class="table-responsive mt-4">
	<table class="table table-striped table-hover table-bordered">
		<thead class="thead-dark">
			<tr>
				<th class="text-center">Driver</th>
				<th class="text-center">Vehicle</th>
				<th class="text-center">Start Time</th>
				<th class="text-center">End Time</th>
				<th class="text-center">Purpose</th>
				<th class="text-center">Mileage Start</th>
				<th class="text-center">Mileage End</th>
				<th class="text-center"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let log of filteredDriverLogs">
				<td class="text-center">{{ log.driver_name }}</td>
				<td class="text-center">{{ log.vehicle_id }}</td>
				<td class="text-center">{{ log.start_time }}</td>
				<td class="text-center">{{ log.end_time || '-' }}</td>
				<td class="text-center">{{ log.purpose }}</td>
				<td class="text-center">{{ log.mileage_start }}</td>
				<td class="text-center">{{ log.mileage_end || '-' }}</td>
				<td class="text-center">
					<button mat-icon-button color="primary" (click)="editDriverLog(log)">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="warn" (click)="deleteDriverLog(log.log_id)">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>