<ng-template #userGuideModal>
  <div class="modal-header">
	<h5 class="modal-title">
	  <mat-icon class="title-icon">help_outline</mat-icon>
	  User Guide: Managing Brand Priorities & Remote Orders
	</h5>
	<button type="button" class="close" (click)="closeModal()" aria-label="Close">
	  <span aria-hidden="true">&times;</span>
	</button>
  </div>

  <div class="modal-body">
	<mat-accordion class="user-guide-accordion">
	  <!-- Viewing Brand Priorities -->
	  <mat-expansion-panel>
		<mat-expansion-panel-header>
		  <mat-panel-title>📌 Viewing Brand Priorities</mat-panel-title>
		</mat-expansion-panel-header>
		<p>Navigate to the <strong>Brand Management Section</strong> and select a brand to view its vendor priorities.</p>
		<ul>
		  <li>Sortable list of assigned vendors.</li>
		  <li><strong>Remote Order Toggle:</strong> Enable/Disable remote ordering.</li>
		  <li><strong>Drag Handle:</strong> <span class="badge badge-secondary">⋮</span> for reordering vendors.</li>
		  <li><strong>Delete Button:</strong> <span class="badge badge-danger">🗑️</span> to remove a vendor.</li>
		</ul>
	  </mat-expansion-panel>

	  <!-- Updating Brand Priorities -->
	  <mat-expansion-panel>
		<mat-expansion-panel-header>
		  <mat-panel-title>🔄 Updating Brand Priorities</mat-panel-title>
		</mat-expansion-panel-header>
		<h5>📍 Change Vendor Order</h5>
		<ol>
		  <li>Click & hold the <span class="badge badge-secondary">⋮</span> drag handle.</li>
		  <li>Drag the supplier to the desired position.</li>
		  <li>The system automatically updates the sort order.</li>
		</ol>
		<p class="text-muted">✅ Only the first two remote suppliers are assigned as <strong>a2</strong> and <strong>a3</strong>.</p>

		<h5>📍 Enable/Disable Remote Orders</h5>
		<ol>
		  <li>Locate the <strong>Remote Order Toggle</strong> next to a supplier.</li>
		  <li>Click to toggle <span class="badge badge-success">ON</span> or <span class="badge badge-danger">OFF</span>.</li>
		  <li>The system updates:
			<ul>
			  <li>A maximum of <strong>two remote suppliers</strong> can be set.</li>
			  <li>If one is disabled, the next available remote supplier is assigned.</li>
			</ul>
		  </li>
		</ol>
	  </mat-expansion-panel>

	  <!-- Removing a Vendor -->
	  <mat-expansion-panel>
		<mat-expansion-panel-header>
		  <mat-panel-title>❌ Removing a Vendor</mat-panel-title>
		</mat-expansion-panel-header>
		<ol>
		  <li>Click the <span class="badge badge-danger">🗑️</span> delete button next to a vendor.</li>
		  <li>The system will:
			<ul>
			  <li>Remove the vendor from <strong>purchasingpriorites</strong>.</li>
			  <li>Unassign them from <strong>a2/a3</strong> if applicable.</li>
			  <li>Set their <strong>purchdata</strong> record to inactive.</li>
			</ul>
		  </li>
		</ol>
		<p class="text-muted">🚨 If a vendor assigned to <strong>a2/a3</strong> is removed, the system will automatically adjust assignments.</p>
	  </mat-expansion-panel>

	  <!-- Searching for Vendors -->
	  <mat-expansion-panel>
		<mat-expansion-panel-header>
		  <mat-panel-title>🔎 Searching for Vendors</mat-panel-title>
		</mat-expansion-panel-header>
		<ul>
		  <li>Use the <strong>search bar</strong> to find vendors by:
			<ul>
			  <li>Supplier ID</li>
			  <li>Supplier Name</li>
			  <li>Phone Number</li>
			</ul>
		  </li>
		  <li>Vendors marked as <strong>Remote Order Allowed</strong> have an active toggle.</li>
		</ul>
	  </mat-expansion-panel>

	  <!-- Quick Notes -->
	  <mat-expansion-panel>
		<mat-expansion-panel-header>
		  <mat-panel-title>🎯 Quick Notes</mat-panel-title>
		</mat-expansion-panel-header>
		<ul class="list-group">
		  <li class="list-group-item">✅ <strong>Drag & Drop</strong> to reorder priorities.</li>
		  <li class="list-group-item">✅ <strong>Only two remote suppliers</strong> (<strong>a2</strong> & <strong>a3</strong>) are allowed.</li>
		  <li class="list-group-item">✅ <strong>Removing a vendor updates manufacturer records.</strong></li>
		  <li class="list-group-item">✅ <strong>Search filters</strong> help find vendors quickly.</li>
		</ul>
	  </mat-expansion-panel>

	  <!-- Troubleshooting -->
	  <mat-expansion-panel>
		<mat-expansion-panel-header>
		  <mat-panel-title>🔧 Troubleshooting</mat-panel-title>
		</mat-expansion-panel-header>
		<ul class="list-group">
		  <li class="list-group-item">❌ <strong>Remote order toggles don’t update?</strong> → Try refreshing the page.</li>
		  <li class="list-group-item">❌ <strong>Vendor not saving?</strong> → Ensure they are allowed for remote orders.</li>
		  <li class="list-group-item">❌ <strong>Drag-and-drop not working?</strong> → Check for browser extensions interfering.</li>
		</ul>
	  </mat-expansion-panel>
	</mat-accordion>
  </div>

  <div class="modal-footer">
	<button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
  </div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<!-- Card Wrapper -->
				<div class="card tp-box">
					<!-- Dynamic Card Header -->
					<div class="card-header card-header-{{ color }}-6">
						<h4 class="card-title">
							<i class="material-icons me-2 cursor-pointer" (click)="back()">arrow_left</i>
							Brand Priorities
						</h4>
						<p class="card-category text-right mb-0">
							Manage and organize your brand priorities effectively
							<a href="#" (click)="openUserGuide($event)">Click to view instructions</a>
						</p>


					</div>

					<!-- Search Box -->
					<div class="card-body pb-0">
						<div class="row mb-4">
							<div class="col-md-2">

								<button mat-raised-button (click)="exportXls()" *ngIf="filteredBrands" class="mt-2 btn btn-success btn-sm"  [disabled]="exportingXls">
									<mat-spinner *ngIf="exportingXls" diameter="20"></mat-spinner>
									<i class="fa fa-file-excel-o" *ngIf="!exportingXls"></i>&nbsp;&nbsp;XLS
								</button>

								&nbsp;&nbsp;

								<button mat-raised-button (click)="exportPdf()" *ngIf="filteredBrands" class="btn btn-danger btn-sm mt-2" [disabled]="exportingPdf">
									<mat-spinner *ngIf="exportingPdf" diameter="20"></mat-spinner>
									<i class="fa fa-file-pdf-o" *ngIf="!exportingPdf"></i>&nbsp;&nbsp;PDF
								</button>

							</div>
							<div class="col-md-6 offset-md-4">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Search Brands</mat-label>
									<input matInput type="text" placeholder="Type to search..." [(ngModel)]="searchText" (input)="filterBrands()" />
									<button mat-icon-button matSuffix aria-label="Clear" *ngIf="searchText" (click)="clearSearch()">
										<mat-icon>close</mat-icon>
									</button>
								</mat-form-field>
							</div>
						</div>
					</div>

					<!-- Card Content -->
					<div class="card-body">
						<div class="row" *ngFor="let brand of filteredBrands">
							<div class="col-12">
								<!-- Brand Section -->
								<div class="brand-section border rounded p-3 mb-4 shadow-sm">
									<!-- Brand Header -->
									<div class="d-flex justify-content-between align-items-center">
										<h5 class="brand-title">{{ brand.name }}</h5>
										<div class="d-flex align-items-center">
											<!-- Custom Select -->
											<div class="custom-select-wrapper me-3" *ngIf="!viewOnly">
												<select class="custom-select" [(ngModel)]="selectedVendor[brand.name]">
													<option value="" disabled selected>Select Vendor</option>
													<option *ngFor="let v of vendors" [value]="v.suppname">
														{{ v.suppname }}
													</option>
												</select>
											</div>

											<!-- Add Vendor Button -->
											<button class="btn btn-primary btn-sm" (click)="addVendor(brand)" *ngIf="!viewOnly">
												Add Vendor
											</button>
										</div>
									</div>

									<!-- Priorities List -->
									<div *ngIf="brand.priorities && brand.priorities.length > 0" class="priority-list mt-3">
										<div cdkDropList class="vendor-list" [cdkDropListData]="brand.priorities" (cdkDropListDropped)="drop($event, brand)" [cdkDropListDisabled]="viewOnly">
											<div class="vendor-box" *ngFor="let p of brand.priorities; let i = index;" cdkDrag [cdkDragDisabled]="viewOnly">

												<!-- Drag Handle -->
												<span class="drag-handle" cdkDragHandle *ngIf="!viewOnly">
													<mat-icon>drag_handle</mat-icon>
												</span>

												<span class="vendor-item">
													<span class="priority-badge">{{ i + 1 }}</span>
													{{ p.suppname }}
												</span>

												<!-- Remote Order Toggle -->
												<div class="remote-order-wrapper">

													<ng-container *ngIf="p.remoteOrderAllowed">
														<mat-slide-toggle [(ngModel)]="p.remoteOrder" color="primary" (click)="$event.stopPropagation()" (change)="updateRemoteOrder(brand, p)" [disabled]="viewOnly">
															Remote Order {{ i + 1 }}
														</mat-slide-toggle>
													</ng-container>
												</div>

												<button mat-icon-button color="warn" (click)="removeVendor(p, brand)" matTooltip="Remove Vendor" *ngIf="!viewOnly">
													<mat-icon>remove</mat-icon>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>