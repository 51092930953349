import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PrintService } from '../../services/print.service';
import { PurchasingService } from '../../services/purchasing.service';
import { InventoryService } from '../../services/inventory.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-purchasing-brand-priority',
	templateUrl: './purchasing-brand-priority.component.html',
	styleUrls: ['./purchasing-brand-priority.component.scss']
})
export class PurchasingBrandPriorityComponent implements OnInit {
	@ViewChild('userGuideModal') userGuideModal!: TemplateRef < any > ;
	color = 'blue';
	brands: any = [];
	filteredBrands = [...this.brands];
	vendors: any = [];
	priorities: any = [];
	searchText: string = '';
	selectedVendor: {
		[key: string]: string
	} = {};
	vendor = new UntypedFormControl('');
	viewOnly = true;
	exportingXls = false;
	exportingPdf = false;
	user: any = [];
	constructor(private printService: PrintService, private cdr: ChangeDetectorRef, private inventoryService: InventoryService, private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe(results => {
			this.user = results
			this.viewOnly = parseInt(results.user.fullaccess ?? '0') < 7;
		});
	}

	ngOnInit(): void {
		this.inventoryService.getBrands().subscribe(results => {
			this.brands = results;
			this.filteredBrands = [...this.brands];
		})

		this.purchasingService.getSuppliers('').subscribe((results: any) => {
			this.vendors = results;
		});
	}

	loadPriorities(id: any) {
		this.inventoryService.getBrandPriorities(id).subscribe(results => {
			this.priorities = results;
		})
	}


	filterBrands(): void {
		const search = this.searchText.trim().toLowerCase();
		this.filteredBrands = this.brands.filter((brand) =>
			brand.name.toLowerCase().includes(search)
		);
	}

	// Clear the search box
	clearSearch(): void {
		this.searchText = '';
		this.filterBrands();
	}

	removeVendor(vendor: any, brand: any) {

		const index = brand.priorities.indexOf(vendor)
		brand.priorities.splice(index, 1)
		if (!brand.priorities.length) {
			brand.priorities = false
		}

		const data = { brand: brand, remove: vendor }
		this.inventoryService.removeBrandPriorities(data).subscribe(result => {

		});

	}

	openUserGuide(event: Event) {
		event.preventDefault();
		if (this.userGuideModal) {
			this.modalService.open(this.userGuideModal, { size: 'lg', centered: true });
		}
	}

	closeModal() {
		this.modalService.dismissAll();
	}


	addVendor(brand: any) {
		let proceed = true;
		const selectedVendorName = this.selectedVendor[brand.name]; // Get the selected vendor name from ngModel

		if (selectedVendorName) {
			const selectedVendor = this.vendors.find((v: any) => v.suppname === selectedVendorName);

			if (!brand.priorities) {
				brand.priorities = [];
			} else {
				// Check if the vendor already exists in the priorities
				const existingVendor = brand.priorities.find(
					(priority: any) => priority.supplierid === selectedVendor.supplierid
				);

				if (existingVendor) {
					proceed = false; // Prevent duplicates
				}
			}

			if (proceed) {
				// Add the selected vendor to priorities
				brand.priorities.push(selectedVendor);

				// Prepare data to send to the backend
				const data = { brand: brand, priorities: brand.priorities };

				// Call API to update priorities
				this.inventoryService.updateBrandPriorities(data).subscribe(() => {
					console.log('Vendor added successfully:', selectedVendor);
				});

				// Clear the selected vendor for this brand
				this.selectedVendor[brand.name] = '';
			}
		}
	}

	generateExportHTML(): string {
		// Determine the max number of vendors assigned to any brand
		const maxVendors = Math.max(...this.filteredBrands.map(brand => brand.priorities?.length || 0), 0);

		let html = `
			<style>
				table {
					width: 100%;
					border-collapse: collapse;
					font-family: Arial, sans-serif;
					font-size: 14px;
				}
				th, td {
					padding: 10px;
					text-align: left;
					border: 1px solid #ddd;
				}
				th {
					background-color: #000;
					color: white;
					font-weight: bold;
				}
				td {
					background-color: #f8f9fa;
				}
				.vendor-cell {
					text-align: center;
					font-weight: bold;
				}
				.remote {
					color: green;
					font-weight: bold;
					padding-top:5px;
				}
				.local {
					color: red;
					font-weight: bold;
					padding-top:5px;
				}
			</style>

			<table>
				<tr>
					<th>Brand</th>`;

		// Dynamically generate vendor column headers
		for (let i = 1; i <= maxVendors; i++) {
			html += `<th>Vendor ${i}</th>`;
		}

		html += `</tr>`;

		// Table Rows: Iterate through brands and dynamically generate vendor columns
		this.filteredBrands.forEach((brand: any) => {
			let vendorColumns = new Array(maxVendors).fill('<td class="vendor-cell">-</td>');

			if (brand.priorities && brand.priorities.length > 0) {
				brand.priorities.forEach((vendor: any, index: number) => {
					vendorColumns[index] = `
						<td class="vendor-cell">
							${vendor.suppname}
							<div class="${vendor.remoteOrder ? 'remote' : 'local'}" style="margin-top: 5px;">
								${vendor.remoteOrder ? 'Remote' : 'Local'}
							</div>
						</td>
					`;
				});
			}

			html += `
				<tr>
					<td>${brand.name}</td>
					${vendorColumns.join('')}
				</tr>
			`;
		});

		html += `</table>`;

		return html;
	}




	exportPdf() {
		const d = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateExportHTML());
		const data = {
			content: encoded,
			filename: `Brand_Priorities_${d.getMonth() + 1}${d.getDate()}${d.getFullYear()}`,
			title: `Brand Priorities`,
			subtitle: 'Vendor Assignment and Remote Ordering Overview'
		};
		this.exportingPdf = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.exportingPdf = false;
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}


	exportXls() {
		const alldata = [];

		// Define necessary brand-level fields
		const fieldMapping = {
			name: 'Brand Name',
			mfg_code: 'MFG Code',
			a2: 'Remote Vendor 1',
			a3: 'Remote Vendor 2',
			sort_order: 'Sort Order',
		};

		// Necessary priority fields
		const priorityFields = ['supplierno', 'suppname', 'sort_order', 'remoteOrder'];

		// Iterate through brands
		this.brands.forEach((brand: any) => {
			// Format brand-level data
			const formattedBrand = {};
			Object.keys(fieldMapping).forEach((key) => {
				if (brand[key] !== undefined) {
					formattedBrand[fieldMapping[key]] = brand[key];
				}
			});

			// Check if the brand has priorities
			if (brand.priorities && brand.priorities.length > 0) {
				brand.priorities.forEach((priority: any) => {
					const formattedPriority = { ...formattedBrand }; // Clone brand-level data

					// Add only the required priority fields
					priorityFields.forEach((key) => {
						if (priority[key] !== undefined) {
							formattedPriority[key.replace(/_/g, ' ')] = priority[key]; // Convert keys
						}
					});

					// Push the combined data to export array
					alldata.push(formattedPriority);
				});
			} else {
				// Push the brand data even if no priorities exist
				alldata.push(formattedBrand);
			}
		});
		this.exportingXls = true;
		// Export the cleaned-up data
		this.globalSearchService.exportJsonToExcel(alldata, 'Brand_Priorities');
		this.exportingXls = false;
	}



	updateRemoteOrder(brand: any, priority: any) {
		const data = {
			brand: brand,
			priorities: brand.priorities.map(p => ({
				supplierno: p.supplierno,
				brandid: brand.manufacturer_id,
				sort_order: p.sort_order,
				remoteOrder: p.remoteOrder // Ensure updated value is sent
			}))
		};

		this.inventoryService.updateBrandPriorities(data).subscribe(result => {
			if (result && Array.isArray(result)) {
				const updatedBrand = result.find(b => b.manufacturer_id === brand.manufacturer_id);
				if (updatedBrand) {
					brand.priorities = [...updatedBrand.priorities];
					this.cdr.detectChanges();
				}
			}

		});
	}


	drop(event: CdkDragDrop < string[] > , brand: any) {

		moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		const data = { brand: brand, priorities: event.container.data }


		this.inventoryService.updateBrandPriorities(data).subscribe(result => {
			this.priorities = result;
		});

	}

	back(): void {
		this.location.back()
	}

}