import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable, ElementRef, Output, EventEmitter } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DataService } from '../../../data.service';
import { ChatService } from '../../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';

import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { Message } from '../../../classes/message';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl, UntypedFormControl } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { InventoryService } from '../../../services/inventory.service';
import { PrintService } from '../../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
	selector: 'app-transfers-receive',
	templateUrl: './transfers-receive.component.html',
	styleUrls: ['./transfers-receive.component.scss']
})
export class TransfersReceiveComponent implements OnInit {
	@ViewChild('binSelect') releaseRef: ElementRef;
	@ViewChild('binadd') addbinRef: ElementRef;
	@Output('refreshTransfers') refreshTransfers = new EventEmitter < any > ();
	bininput: FormControl;
	transferlist: any = [];
	color = 'blue';
	locations: any = [];
	user: any = [];
	transferData: any = {};
	items: any = [];
	toaddress: any = '';
	fromaddress: any = '';

	statuses: any = [{ 'code': '0', 'description': ' Entered ' },
		{ 'code': '1', 'description': ' Released ' },
		{ 'code': '2', 'description': ' Received ' },
		{ 'code': '3', 'description': ' Canceled ' }
	]
	searchForm: UntypedFormGroup;
	bincreation: UntypedFormGroup;
	lineediting: any;
	constructor(private fb: UntypedFormBuilder, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.globalsService.getUserLocations().subscribe((results: any) => {
			if (results) {
				this.locations = results;
			}
		});
		this.globalSearchService.user.subscribe(result => {
			this.user = result;
		});
		this.searchForm = this.fb.group({
			'fromloc': '',
			'toloc': this.user.user.defaultlocation.loccode,
			'startdate': '',
			'enddate': '',
			'status': '1'
		});
		this.bincreation = this.fb.group({
			bininput: ''
		})

		this.getTransfers();
	}

	updateBinQuantity(event: any, item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;
	}

	getTotalQtyTimesPrice(): number {
		return this.items.reduce((total, item) => total + (item.quantity * item.price), 0);
	}

	getTotalQtyRecv(): number {
		return this.items.reduce((total, item) => total + (parseFloat(item.quantity) || 0), 0);
	}
	updateBinSelection(event: any, item: any) {

		const index = this.items.indexOf(item);
		this.items[index].bincode = event.value;
	}
	getTransfers() {

		this.inventoryService.getTransfers(this.searchForm.value).subscribe((results: any) => {
			if (results) {
				this.transferlist = results;
			}
		});
	}

	getTransferData(transno) {
		this.inventoryService.getTransferData({ 'transno': transno, 'type': 'REC' }).subscribe((results: any) => {


			if (results) {


				this.transferData = results;
				this.items = results.lines;
				console.log(this.items);
				this.toaddress = results.transferheader.locationname + '\n' + results.transferheader.deladd1 + '\n' + results.transferheader.deladd3 + ' ' + results.transferheader.deladd4 + ', ' + results.transferheader.deladd5;
				this.fromaddress = results.transferheader.shipname + '\n' + results.transferheader.shipadd1 + '\n' + results.transferheader.shipcity + ' ' + results.transferheader.shipstate + ', ' + results.transferheader.shipzip;

				this.modalService.open(this.releaseRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {

				});
			}
		});
	}
	receiveItems(items) {

		const data = { 'transno': this.transferData.transferheader.transno, 'tostkloc': this.transferData.transferheader.tostkloc, lines: items };
		//Quick check to make sure over/under receive is ok.
		let oktogo = true;
		this.items.forEach((item) => {
			if (item.quantity != item.transferqty) {
				oktogo = false;
			}
		});
		if (!oktogo) {
			oktogo = confirm('This represents an under or over receipt of items on this transfer. Is this ok?');
		}
		if (oktogo) {
			this.inventoryService.receiveTransfer(data).subscribe((results: any) => {
				if (results.success == true) {
					this.globalSearchService.showNotification('Transfer Received', 'success', 'bottom', 'left');
					this.modalService.dismissAll();
					this.getTransfers();
					this.refreshTransfers.emit();
				}
			});
		}
	}

	openBinAdd(event: any) {
		this.lineediting = event;
		this.modalService.open(this.addbinRef, { ariaLabelledBy: 'modal-title', size: 'med' }).result.then((result) => {

		}, (reason) => {



		});
	}

	parseF(val: any) {

		return parseFloat(val);
	}
	createBin(event: any) {
		const data = this.lineediting;
		data['newbin'] = this.bincreation.get('bininput').value;
		this.inventoryService.addBin(data).subscribe((res) => {
			const index = this.items.indexOf(this.lineediting)
			this.items[index].bins = res;
		})

	}

	setBin(item) {

		const bincode = item.bincode;
		const out = item.bins.filter((bin) => {
			return bin.bin.toUpperCase() === bincode.toUpperCase();
		});

		return out[0] != undefined ? out[0].bin : (item.bins.length > 0) ? item.bins[0].bin : '';
	}

	toggleAll(event: any) {
		const isChecked = event.checked;
		this.items.forEach(item => {
			item.selected = isChecked;
			if (isChecked) {
				item.savedqty = item.quantity;
				item.quantity = item.transferqty;

			} else {
				item.quantity = (item.savedqty) ? item.savedqty : 0;
			}
		});
	}


}
