<div class="main-content">
  <div class="container-fluid">
	<div class="row">
	  <div class="col-md-12">
		<div class="card">
		  <div class="card-header card-header-primary">
			<h4 class="card-title d-flex align-items-center">
			  <mat-icon class="mr-2">directions_car</mat-icon>
			  Fleet Management
			</h4>
		  </div>

		  <div class="card-body">
			<mat-tab-group animationDuration="500ms">
			  <mat-tab label="Vehicles">
				<ng-template matTabContent>
				  <app-vehicles></app-vehicles>
				</ng-template>
			  </mat-tab>

			  <mat-tab label="Fuel Logs">
				<ng-template matTabContent>
				  <app-fuel-logs></app-fuel-logs>
				</ng-template>
			  </mat-tab>

			  <mat-tab label="Maintenance">
				<ng-template matTabContent>
				  <app-maintenance-logs></app-maintenance-logs>
				</ng-template>
			  </mat-tab>

			  <mat-tab label="Driver Logs">
				<ng-template matTabContent>
				  <app-driver-logs></app-driver-logs>
				</ng-template>
			  </mat-tab>

			  <mat-tab label="Accidents">
				<ng-template matTabContent>
				  <app-accident-logs></app-accident-logs>
				</ng-template>
			  </mat-tab>

			  <mat-tab label="Parts Used">
				<ng-template matTabContent>
				  <app-parts-used></app-parts-used>
				</ng-template>
			  </mat-tab>
			</mat-tab-group>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</div>
