<ng-template #movementdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Bin Movements</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<button mat-icon-button color="warn" aria-label="Reverse Bin Movements"
				(click)="reverseMovements(bindetails.header.binmoveid)">
				<mat-icon>restore</mat-icon>
			</button>
			<table class="table table-sm">
				<thead>
					<tr>
						<th>Date</th>
						<th>Item</th>
						<th>From</th>
						<th>To</th>
						<th>Type</th>
						<th>User</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let h of bindetails.details">
						<td>{{ h.date_created }}</td>
						<td>{{ h.stockid }}</td>
						<td>{{ h.from }}</td>
						<td>{{ h.to }}</td>
						<td>{{ h.type }}</td>
						<td>{{ h.user }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="table-container">
	<div class="row p-1 m-1">
		<div class="col-sm-6 form-inline" *ngIf="!stockid">
			<mat-form-field appearance="outline">
				<mat-label class="text-right">Search By Items/Order</mat-label>
				<input tabindex="0" matInput value="" [formControl]="stockIdCtrl" class="text-right"
					(input)="getHistory()">
				<mat-hint>Search for an Item/Invoice</mat-hint>
			</mat-form-field>
			<form class="form-inline my-2 my-lg-0">
				<mat-form-field appearance="outline">
					<mat-label>Movement Dates</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
						<input matEndDate [formControl]="todateCtrl" placeholder="End date">
					</mat-date-range-input>
					<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</form>
		</div>

		<div class="col-sm-6 form-inline" *ngIf="stockid">
			<form class="form-inline my-2 my-lg-0">
				<mat-form-field appearance="outline">
					<mat-label>Movement Dates</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
						<input matEndDate [formControl]="todateCtrl" placeholder="End date">
					</mat-date-range-input>
					<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</form>
		</div>
		<div class="col-sm-6 form-inline justify-content-end d-flex">			
			<button mat-raised-button color="accent" class="ml-2" (click)="getHistory()">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div>
	<mat-paginator #paginator [pageSizeOptions]="[25,50,100]" showFirstLastButtons *ngIf="!stockid"></mat-paginator>
	<table mat-table [dataSource]="datasource" class="table table-sm table-fixed table-sticky" *ngIf="datasource && history">
		<ng-container matColumnDef="View">
			<th mat-header-cell *matHeaderCellDef>View</th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button color="normal" aria-label="view" (click)="openModal(movementdetails , element.binmoveid)">
					<mat-icon>visibility</mat-icon>
				</button>
			</td>
		  </ng-container>
		  <ng-container matColumnDef="Date">
			<th mat-header-cell *matHeaderCellDef>Date</th>
			<td mat-cell *matCellDef="let element">{{element.date_created}}</td>
		  </ng-container>
		  <ng-container matColumnDef="Description">
			<th mat-header-cell *matHeaderCellDef>Description</th>
			<td mat-cell *matCellDef="let element">{{element.description}}</td>
		  </ng-container>
		  <ng-container matColumnDef="To">
			<th mat-header-cell *matHeaderCellDef>To</th>
			<td mat-cell *matCellDef="let element">{{getToBin(element.variables)}}</td>
		  </ng-container>
		  <ng-container matColumnDef="User">
			<th mat-header-cell *matHeaderCellDef>User</th>
			<td mat-cell *matCellDef="let element">{{element.user}}</td>
		  </ng-container>
		  <ng-container matColumnDef="Type">
			<th mat-header-cell *matHeaderCellDef>Type</th>
			<td mat-cell *matCellDef="let element">{{element.type | uppercase}}</td>
		  </ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
	<mat-paginator #paginator [pageSizeOptions]="[25,50,100]" showFirstLastButtons *ngIf="stockid && history"></mat-paginator>
	<ng-container *ngIf="!history && !gettingHistory">
		<table class="table table-sm table-fixed w-100">
			<tr>
				<td colspan="5" class="text-center">
					<h4>No movements for selected date range.</h4>
				</td>
			</tr>
		</table>
	</ng-container>


</div>