import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { CardLists, Card } from '../../classes/orders';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/services/customer.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { InventoryService } from 'app/services/inventory.service';



@Component({
	selector: 'app-customer-cpdet',
	templateUrl: './customer-cpdet.component.html',
	styleUrls: ['./customer-cpdet.component.scss']
})
export class CustomerCpdetComponent implements OnInit {
	@Input() customer: any = false;
	@Input() menuapp = true;
	@ViewChild('orderView') orderStatusViewRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('printTable') printtable: ElementRef;
	@ViewChild('brandFilter') brandsRef: MatSelect;
	@ViewChild('branchFilter') branchesRef: MatSelect;

	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	conBrRef = new UntypedFormControl('C');
	placedFromRef = new UntypedFormControl('');
	branchRef = new UntypedFormControl('');
	salespersonRef = new UntypedFormControl('');
	filterresults: any = [];
	filteredItems: any = [];
	orderData: CardLists;
	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];
	orderResults: any = false;
	ordersFound: any = [];
	todate = new Date();
	fromdate = new Date();
	reportCriteria = {
		fromdate: '',
		debtorno: '',
		todate: '',
		conbr: '',
		prodline: ''
	};

	@Input() name: string;

	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	filename: any = 'Customer_productline_sales_';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	// prodlineRef = new UntypedFormControl('');
	sending: any = false;
	branches: any = [];
	salespeople: any = [];
	properties: any = [];

	reportData: any = false;

	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
	displayLookup = true;
	ref_location: any = false;
	user: any = false;
	prodlines: any = false;
	running = false;
	modal_ref: any;
	lineForm: UntypedFormGroup;
	brands: any = false;
	brandnames: any[];

	constructor(private customerService: CustomerService, private activatedRoute: ActivatedRoute, private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal, private inventoryService: InventoryService, private fb: UntypedFormBuilder) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			if (this.user) {
				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.branchRef.setValue([this.user.user.defaultlocation.loccode]);
					this.ref_location = false;
				}
			}

			this.user = results

			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
				this.branchRef.setValue([this.user.user.defaultlocation.loccode]);
			}
		});

		this.globalSearchService.mesagesallowed.next(false);

		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});

		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.properties = results
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});

		this.inventoryService.getItemProductlines().subscribe((results: any) => {
			this.prodlines = results;
		})

		this.inventoryService.getBrands().subscribe((results: any) => {
			this.brands = results;
		})



		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate);
		this.todateCtrl.setValue(this.todate);

		if (!this.customer) {
			this.activatedRoute.params.subscribe(({ id }) => {
				if (!isNaN(id)) {
					this.orderResults = false;
					this.customerService.getDebtor(id).subscribe((data: any) => {
						this.customer = data.customer;
						this.displayLookup = false;
					})
				}
			});
		} else {
			this.getCPDET();
		}
	}

	ngOnInit(): void {

		this.globalSearchService.aiDataAvailable$.next(null);

		this.lineForm = this.fb.group({
			prodlineRef: ['', [Validators.required]],
		});
	}

	updateBrand(event: any){
	}

	getCPDET() {
		this.running = true;
		this.reportCriteria = {
			fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
			debtorno: this.customer.debtorno,
			todate: this.todateCtrl.value.toISOString().split('T')[0],
			conbr: this.conBrRef.value,
			prodline: this.lineForm.get('prodlineRef').value,
		}



		this.reportsService.getCPDET(this.reportCriteria).subscribe((results: any) => {
			this.brandnames = [];

			this.brands.filter((i) => {
				if(this.reportCriteria.prodline.includes(i.manufacturer_id)){
					this.brandnames.push(i.name)
				}
			})
			if(this.brandnames.length > 1){
				this.reportCriteria.prodline = `Multiple (${this.brandnames.length}) `;
			}else{
				this.reportCriteria.prodline = this.brandnames[0];
			}
			this.running = false;
			this.reportData = results;

		})
		// this.reportData = {"reportdata":[{"lineid":"TBC","stockid":"TBC-DSL62","description":"185\/65R14 DORAL SDL-SPORT 3.0","Times Ordered":"1","QTY Ordered":"4","price":"53.54","EXT":"214.16"},{"lineid":"TBC","stockid":"TBC-RSL13","description":"235\/50R18 DORAL SDL-SPORT+ 3.0","Times Ordered":"1","QTY Ordered":"4","price":"75.02","EXT":"300.08"},{"lineid":"TBC","stockid":"TBC-SLR76","description":"225\/65R17 102H SOLAR 4XS PLUS 45K","Times Ordered":"1","QTY Ordered":"4","price":"71.50","EXT":"286.00"}],"total_sales":800.24,"total_qty":12};
	}

	selectCustomer(event: any) {
		this.customer = {};
		this.customer = event;
		this.displayLookup = false;
		this.router.navigate(['/customers/customer-cpdet', event.debtorno]);
		this.getCPDET();
	}

	showCustomerLookup() {
		this.displayLookup == true ? this.displayLookup = false : this.displayLookup = true;
	}

	openDrilldown(row){
		this.modal_ref = row;
		const drilldown = {
			stockid: row.stockid,
			debtorno: this.reportCriteria.debtorno,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			conbr: this.reportCriteria.conbr
		};
		this.reportsService.getCPDETDrill(drilldown).subscribe((orders: any) => {
			this.orderdetails = orders
			// this.orderdetails = [{"orderno":"788418","debtorno":"3900.00","brname":"TIRE EMPIRE","salesperson":"98","salesmanname":"MICHELLE - MP","id":"270741","trandate":"12\/27\/2023","DocType":"Invoice","loccode":"01","fromstkloc":"01","qty":"4","price":"71.50","EXT":"286.00"}]
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
				this.modal_ref = ''; //used for modal header
			}, (reason) => {});
		});
	}

	openDocument(transaction: any, content: any, display: string) {
		switch (transaction.DocType) {
		case 'Credit':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		case 'Invoice':
			this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		}
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate()+today.getFullYear(),
			title: this.customer.debtorno+'.'+this.customer.branchcode+' ' +this.customer.brname+' Customer Brand Sales ',
			subtitle: this.getTitle()+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created @ '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	getTitle() {
		const from = new Date(this.reportCriteria.fromdate);
		const to = new Date(this.reportCriteria.todate);
		return `${this.reportCriteria.prodline} Brand Sales for `;
	}

	exportXls() {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename+ today.getMonth() + today.getDate()+today.getFullYear(),
			title: this.customer.debtorno+'.'+this.customer.branchcode+' ' +this.customer.brname+' Customer Brand Sales ',
			subtitle: this.getTitle()+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	back(){

	}

}
