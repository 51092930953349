<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							General Ledger Journal Inquiry
						</h4>
						<p class="card-category text-right">General Ledger Journal Inquiry</p>
					</div>
					<div class="card-body">

						<form [formGroup]="journalForm" (ngSubmit)="searchJournals()">
							<div class="row align-items-center g-2">
								<div class="col-md-2">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Journal Number From</mat-label>
										<input matInput formControlName="numberFrom" type="number" />
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Journal Number To</mat-label>
										<input matInput formControlName="numberTo" type="number" />
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>From Date</mat-label>
										<input matInput [matDatepicker]="fromDate" formControlName="fromDate" />
										<mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
										<mat-datepicker #fromDate></mat-datepicker>
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>To Date</mat-label>
										<input matInput [matDatepicker]="toDate" formControlName="toDate" />
										<mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
										<mat-datepicker #toDate></mat-datepicker>
									</mat-form-field>
								</div>
								<div class="col-md-2 text-end">
									<button mat-raised-button color="accent" type="submit" class="w-100">Search</button>
								</div>
							</div>
						</form>



						<div class="col-md-12 text-center" *ngIf="isrunning">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
								</circle>
							</svg>
						</div>

						<div *ngIf="journalData?.data?.length > 0">
							<!-- Search Bar -->
							<div class="mb-3">
								<input type="text" class="form-control" placeholder="Search Journals..." [(ngModel)]="searchQuery" (ngModelChange)="filterJournals()" />
							</div>

							<!-- Filtered Journals -->
							<div *ngFor="let journal of filteredJournals" class="card mb-3">
								<div class="card-header">
									<h5 class="mb-0">Journal Number: {{ journal.journalNumber }}</h5>
									<small class="text-muted">Date: {{ journal.date | date }}</small>
									<div class="export-buttons float-right">
										<button mat-raised-button class="btn btn-danger" (click)="exportPdf(journal.journalNumber)">
											<i class="fa fa-file-pdf-o"></i>
										</button>
										<button mat-raised-button class="btn btn-success" (click)="exportXls(journal.journalNumber)">
											<i class="fa fa-file-excel-o"></i>
										</button>
									</div>
								</div>
								<div class="card-body">
									<div class="table-responsive" #journalTable [id]="'journalTable' + journal.journalNumber">
										<table class="table table-striped table-hover table-bordered">
											<thead class="thead-dark">
												<tr>
													<th>Account Code</th>
													<th>Account Name</th>
													<th>Description</th>
													<th>Amount</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let transaction of journal.transactions">
													<td>{{ transaction.accountCode }}</td>
													<td>{{ transaction.accountName }}</td>
													<td>{{ transaction.description }}</td>
													<td [class.text-danger]="transaction.amount < 0" [class.text-success]="transaction.amount >= 0">
														{{ transaction.amount | currency }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="journalData?.data?.length === 0">
							<h4 class="text-center"> No Results </h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>