import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { GlobalsService } from 'app/services/globals.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';
import { ReportsService } from 'app/services/reports.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { UsersService } from 'app/services/users.service';

@Component({
	selector: 'app-idet-report',
	templateUrl: './idet-report.component.html',
	styleUrls: ['./idet-report.component.scss']
})

//app-igr-report
export class IdetReportComponent implements OnInit, AfterViewInit {
	@Input('stockid') stockid;
	content = false;
	itemForm: UntypedFormGroup;
	locationList: any = false;
	results: any = false;
	sending = false;
	salesmanList: any = false;
	rollupQtys: any = {};
	rollupPrices: any = {};
	rollupExts: any = {};
	prodDescriptions: any = {};
	prodsReady: boolean;

	prodSearch: { datasource: any; displayedColumns: string[]; };
	filtersReady = false;

	color = 'blue';

	//chips
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	prodCtrl = new FormControl();
	filteredProds: Observable<string[]>;
	prods: string[] = [];
	allProds: string[] = [];
	@ViewChild('prodInput', { static: false }) prodInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
	selectedFromDate: string;
	selectedToDate: string;
	showdiscontinue = false;
	issalesman: boolean;
	user: any;

	constructor(private usersService:UsersService,  private location: Location, private reportsService: ReportsService, private globalsService: GlobalsService,private inventoryService: InventoryService, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private printService: PrintService) {
		this.filteredProds = this.prodCtrl.valueChanges.pipe(
			startWith(''),
			debounceTime(400),
			distinctUntilChanged(),
			switchMap(val => {
				if (val != '') {
					return this.filter(val || '')
				} else {
					return [];
				}
			})
		)
		this.color = this.globalsService.getColor()
	}

	ngOnInit(): void {
		this.globalSearchService.user.subscribe((result) => {
			this.user = result.user;
			this.globalSearchService.locations.subscribe( (r:any) => {
				this.locationList = r;

				this.reportsService.getSalesPeople().subscribe((results: any) => {
					this.usersService.isPosUser().subscribe((isPOS) => {
						if (isPOS) {
							this.salesmanList = results.filter((s)=>{return s.username?.toLowerCase() == this.user.userid.toLowerCase()});
						}else{
							this.salesmanList = results
						}
						this.setForm();
					});
			
				});
			});
		});


	}

	ngAfterViewInit(): void {
	}

	ngOnChanges(): void {
		// this.setForm();
	}

	back(): void {
		this.location.back()
	}

	startListeners(){
		this.itemForm.get('todate').valueChanges.subscribe((res) => {
			this.updateReportDates();
			this.itemForm.updateValueAndValidity();
		})
		this.itemForm.get('fromdate').valueChanges.subscribe((res) => {
			this.updateReportDates();
			this.itemForm.updateValueAndValidity();
		})
	}

	filter(val: string): Observable<any> {
		return this.inventoryService.itemSearch({ search: val, type: ['igr'], discontinued: this.showdiscontinue })
			.pipe(
				map(response => response.filter(option => {
					return (
						(option.stockid.toLowerCase().indexOf(val.toLowerCase()) === 0 || option.description.toLowerCase().includes(val.toLowerCase())) && !this.prods.includes(option.stockid)
					)
				}))
			)
	}

	add(event: MatChipInputEvent): void {
		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;
			if ((value || '').trim()) {
				this.prods.push(value.trim());
			}
			if (input) {
				input.value = '';
			}
			this.prodCtrl.setValue(null);
		}
	}

	remove(prod: string): void {
		const index = this.prods.indexOf(prod);
		if (index >= 0) {
			this.prods.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const input = (event.option.viewValue).toString().split(':');
		const stockid = input[0].trim();
		const description = input[1].trim();

		this.prodDescriptions[stockid] = description;

		this.prods.push(stockid);
		this.prodInput.nativeElement.value = '';
		this.prodCtrl.setValue(null);
	}

	setForm() {
		const afterdate = new Date();
		const todate = new Date(new Date().setDate(afterdate.getDate() - 30));
		this.itemForm = this.fb.group({
			locs: [this.locationList.map(i => i.loccode), Validators.required],
			sales: [this.salesmanList.map(i => i.salesmancode), Validators.required],
			fromdate: [todate, Validators.required],
			todate: [afterdate, Validators.required]
		});
		this.updateReportDates()
		this.startListeners();
	}


	updateReportDates() {
		let date = new Date(this.itemForm.get('fromdate').value);
		this.selectedFromDate = (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
		// this.itemForm.get('fromdate').setValue(this.selectedFromDate);

		date = new Date(this.itemForm.get('todate').value);
		this.selectedToDate = (date.getMonth() +1) + '/' + date.getDate() + '/' + date.getFullYear();

		this.filtersReady = true;

	}

	getIDET() {
		const data = this.itemForm.value;
		data['prods'] = this.prods;
		this.sending = true;
		this.inventoryService.getIdet(data).subscribe((result) => {
			this.results = result;
			this.calculateRollup(result);
			this.sending = false;
		});
	}

	calculateRollup(data: any) {
		Object.entries(data).forEach((key, value) => {
			const stockid = key[0];
			const entries: any = key[1];
			if (entries.length) {
				this.rollupQtys[stockid] = entries.map((i) => Number(i.QTY)).reduce((sum, current) => sum + current);
				this.rollupPrices[stockid] = entries.map((i) => Number(i.Price)).reduce((sum, current) => sum + current);
				this.rollupExts[stockid] = entries.map((i) => Number(i.Extension)).reduce((sum, current) => sum + current);
			} else {
				this.rollupQtys[stockid] = '';
				this.rollupPrices[stockid] = '';
				this.rollupExts[stockid] = '';
			}
		});
	}

	selectAllToggle(ref: MatSelect) {
		const selected = ref.options.find(opt => opt.selected);
		if (selected) {
			ref.options.forEach((item: MatOption) => item.deselect());
		} else {
			ref.options.forEach((item: MatOption) => item.select());
		}
	}

	exportPdf(ref: any) {
		const now = new Date();
		const filename = 'ITEM_DETAIL_REPORT_';
		const encoded: string = this.globalSearchService.base64encode(ref.innerHTML);
		const data = {
			content: encoded,
			filename: filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls(ref: any) {
		const filename = 'ITEM_DETAIL_REPORT';
		const encoded: string = this.globalSearchService.base64encode(ref.innerHTML);
		const data = {
			content: encoded,
			filename: filename,
			title: this.exportHeaderGen('title'),
			subtitle: this.exportHeaderGen('locations') + '  ' + this.exportHeaderGen('salesmen')
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	sortedProds() {
		return this.prods.sort((a, b) => a > b ? 1 : 0);
	}
	sortedLocs() {
		return this.itemForm.get('locs').value.sort((a, b) => a > b ? 1 : 0);
	}

	sortedSales() {
		return this.itemForm.get('sales').value.sort((a, b) => a > b ? 1 : 0);
	}

	exportHeaderGen(type: any) {
		let output = '';

		const today = new Date();

		if (type == 'title') {

			output = `Item Detail Report ${this.selectedFromDate} - ${this.selectedToDate} Created @ ${today.toLocaleString()}`;
		}

		if (type == 'locations') {
			output = 'Locations: [ ';
			if (this.sortedLocs().length == Object.entries(this.locationList).length) {
				output += 'ALL'
			} else {
				this.sortedLocs().forEach(loc => {
					output += (loc + ', ');
				});
				output = output.slice(0, output.length - 2);
			}
			output += ' ]';
		}

		if(type == 'salesmen'){
			output += ' Salesmen: [ ';
			if (this.sortedSales().length == Object.entries(this.salesmanList).length) {
				output += 'ALL'
			} else {
				this.sortedSales().forEach(person => {
					output += (person + ', ');
				});
				output = output.slice(0, output.length - 2);
			}
			output += ' ]';
		}
		return output;
	}

	allowDiscontinue(event: Event){
		this.showdiscontinue = event['checked'];
		this.prodCtrl.setValue(null);
		this.prodInput.nativeElement.value = '';

	}




}
