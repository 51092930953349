
<!-- Vehicle Selection -->
<div *ngIf="!selectedVehicle">
	<div class="row mb-3">
		<div class="col-md-6">
			<mat-form-field appearance="outline" class="w-100">
				<mat-label>Search Vehicles</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Search by VIN, Plate, Make, Model" />
				<mat-icon matSuffix>search</mat-icon>
			</mat-form-field>
		</div>
		<div class="col-md-6 text-end">
			<button mat-raised-button class="mt-3" color="accent" (click)="toggleForm()">
				{{ isFormVisible ? 'Cancel' : 'Add Vehicle' }}
			</button>
		</div>
	</div>

	<!-- Add Vehicle Form -->
	<!-- Add Vehicle Form -->
	<div *ngIf="isFormVisible" class="p-4 border rounded bg-light">
		<form [formGroup]="vehicleForm" (ngSubmit)="saveVehicle()">
			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Customer ID</mat-label>
						<input matInput formControlName="debtorno" required />
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Branch</mat-label>
						<input matInput formControlName="branch" required />
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>VIN</mat-label>
						<input matInput formControlName="vin" required />
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>License Plate</mat-label>
						<input matInput formControlName="plate" required />
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Make</mat-label>
						<input matInput formControlName="make" required />
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Model</mat-label>
						<input matInput formControlName="model" required />
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Year</mat-label>
						<input matInput formControlName="year" type="number" required />
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Mileage</mat-label>
						<input matInput formControlName="last_mileage" type="number" required />
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Engine Size</mat-label>
						<input matInput formControlName="engine_size" />
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Transmission</mat-label>
						<input matInput formControlName="transmission" />
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Next Inspection Date</mat-label>
						<input matInput formControlName="next_inspection_date" type="date" />
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Next Maintenance Date</mat-label>
						<input matInput formControlName="next_maint_date" type="date" />
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Next Oil Change</mat-label>
						<input matInput formControlName="next_oil_change" type="date" />
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Status</mat-label>
						<mat-select formControlName="status" required>
							<mat-option value="Active">Active</mat-option>
							<mat-option value="In Service">In Service</mat-option>
							<mat-option value="Maintenance">Maintenance</mat-option>
							<mat-option value="Retired">Retired</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<mat-form-field appearance="outline" class="w-100">
				<mat-label>Notes</mat-label>
				<textarea matInput formControlName="notes" rows="3"></textarea>
			</mat-form-field>

			<div class="text-end mt-3">
				<button mat-raised-button color="accent" type="submit" [disabled]="vehicleForm.invalid">
					Save Vehicle
				</button>
				<button mat-raised-button color="warn" type="button" (click)="toggleForm()">
					Cancel
				</button>
			</div>
		</form>
	</div>

	<!-- Vehicle List -->
	<div class="table-responsive mt-4">
		<table class="table table-striped table-hover table-bordered">
			<thead class="thead-dark">
				<tr>
					<th class="text-center">VIN</th>
					<th class="text-center">License Plate</th>
					<th class="text-center">Make</th>
					<th class="text-center">Model</th>
					<th class="text-center">Year</th>
					<th class="text-center">Mileage</th>
					<th class="text-center">Status</th>
					<th class="text-center">Engine</th>
					<th class="text-center">Next Maintenance</th>
					<th class="text-center"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let vehicle of filteredVehicles">
					<td class="text-center">{{ vehicle.vin }}</td>
					<td class="text-center">{{ vehicle.plate }}</td>
					<td class="text-center">{{ vehicle.make }}</td>
					<td class="text-center">{{ vehicle.model }}</td>
					<td class="text-center">{{ vehicle.year }}</td>
					<td class="text-center">{{ vehicle.last_mileage | number }}</td>
					<td class="text-center">
						<span class="badge" [ngClass]="{
							'badge-success': vehicle.status === 'Active',
							'badge-warning': vehicle.status === 'In Service',
							'badge-danger': vehicle.status === 'Maintenance',
							'badge-secondary': vehicle.status === 'Retired'
						}">
							{{ vehicle.status }}
						</span>
					</td>
					<td class="text-center">{{ vehicle.engine_size || 'N/A' }}</td>
					<td class="text-center">{{ vehicle.next_maint_date || 'Not Scheduled' }}</td>
					<td class="text-center">
						<button mat-raised-button color="primary" (click)="selectVehicle(vehicle)">
							Select
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- Selected Vehicle View with Tabs -->
<div *ngIf="selectedVehicle" class="vehicle-details-container">
	<!-- VEHICLE HEADER -->
	<div class="vehicle-header card p-4 mb-4">
		<div class="d-flex justify-content-between align-items-center">
			<h3 class="mb-0">
				<mat-icon class="mr-2 cursor-pointer" (click)="selectedVehicle = null">arrow_back</mat-icon>
				{{ selectedVehicle.make }} {{ selectedVehicle.model }} ({{ selectedVehicle.year }})
			</h3>
			<span class="badge" [ngClass]="{
				'badge-success': selectedVehicle.status === 'Active',
				'badge-warning': selectedVehicle.status === 'In Service',
				'badge-danger': selectedVehicle.status === 'Maintenance',
				'badge-secondary': selectedVehicle.status === 'Retired'
			}">
				{{ selectedVehicle.status }}
			</span>
		</div>
	</div>

	<!-- VEHICLE INFO CARDS -->
	<div class="row">
		<div class="col-md-4">
			<div class="info-card">
				<mat-icon>directions_car</mat-icon>
				<span>VIN: {{ selectedVehicle.vin }}</span>
			</div>
		</div>
		<div class="col-md-4">
			<div class="info-card">
				<mat-icon>local_gas_station</mat-icon>
				<span>Engine: {{ selectedVehicle.engine_size || 'N/A' }}</span>
			</div>
		</div>
		<div class="col-md-4">
			<div class="info-card">
				<mat-icon>speed</mat-icon>
				<span>Mileage: {{ selectedVehicle.last_mileage | number }} miles</span>
			</div>
		</div>
	</div>

	<div class="row mt-3">
		<div class="col-md-4">
			<div class="info-card">
				<mat-icon>date_range</mat-icon>
				<span>Next Service: {{ selectedVehicle.next_maint_date || 'Not Scheduled' }}</span>
			</div>
		</div>
		<div class="col-md-4">
			<div class="info-card">
				<mat-icon>build</mat-icon>
				<span>Next Oil Change: {{ selectedVehicle.next_oil_change || 'Not Scheduled' }}</span>
			</div>
		</div>
		<div class="col-md-4">
			<div class="info-card">
				<mat-icon>assignment</mat-icon>
				<span>Branch: {{ selectedVehicle.branch }}</span>
			</div>
		</div>
	</div>

	<!-- VEHICLE TABS -->
	<mat-tab-group class="mt-4">
		<mat-tab label="Fuel Logs">
			<app-fuel-logs [vehicle]="selectedVehicle"></app-fuel-logs>
		</mat-tab>
		<mat-tab label="Driver Logs">
			<app-driver-logs [vehicle]="selectedVehicle"></app-driver-logs>
		</mat-tab>
		<mat-tab label="Accident Logs">
			<app-accident-logs [vehicle]="selectedVehicle"></app-accident-logs>
		</mat-tab>
		<mat-tab label="Maintenance Logs">
			<app-maintenance-logs [vehicle]="selectedVehicle"></app-maintenance-logs>
		</mat-tab>
	</mat-tab-group>
</div>
