import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gcload',
  templateUrl: './gcload.component.html',
  styleUrls: ['./gcload.component.scss']
})
export class GcloadComponent implements OnInit {
	@Output() gcadded = new EventEmitter < any > ();
	@ViewChild('cardModal') giftCardRef: ElementRef;

	config: any = [];
	gcForm: UntypedFormGroup;
	newcard: any = true;
	updateValue: any = 0;
	user: any = [];
	newBalance: any = 0;
	
	constructor(public fb: UntypedFormBuilder, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private systemService: SystemService) {
		
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});
	}
	
	ngOnInit(): void {
		this.setForm();
		
	}
	
	setForm() {
		this.newBalance = 0;
		this.gcForm = this.fb.group({
			cardnumber: ['', [Validators.required]],
			amount: [0, [Validators.required]],
			comment: ['',],
		});

		this.gcForm.get('cardnumber').valueChanges.subscribe( newValue => {
			if(newValue != '') {
				const data = {'cardnumber': newValue};
				this.systemService.checkCardNumber(data).subscribe( r => {
					if(r.error == '') {
						this.newcard = false;
						this.updateValue = r.balance;
						this.newBalance = parseFloat(this.updateValue) + parseFloat(this.gcForm.get('amount').value);
						this.newBalance = this.newBalance.toFixed(2);
					} else {
						this.newcard = true;
						this.updateValue = 0;
						this.newBalance = 0;
					}
				});
			}
		})
		
		this.gcForm.get('amount').valueChanges.subscribe( newValue => {
			if(!this.newcard){
				this.newBalance = parseFloat(this.updateValue) + parseFloat(this.gcForm.get('amount').value);
				this.newBalance = this.newBalance.toFixed(2);
			}
		})
		this.gcForm.markAllAsTouched();

	}
	
	gcModal(){
	
		this.setForm();
		this.modalService.open(this.giftCardRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {

		}, (reason) => {
			
		});
	}
	
	addGC(){
	
		if(this.gcForm.valid) {
			this.gcadded.emit(this.gcForm.value);
			this.setForm();
			this.modalService.dismissAll();
		} else {
			this.getFormValidationErrors();
		}
	}
	
	getFormValidationErrors() {
		Object.keys(this.gcForm.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = this.gcForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					this.gcForm.markAllAsTouched();
					this.globalSearchService.showNotification(key + ' ' + keyError , 'danger', 'bottom', 'right')
				});
			}
		});
	}
}
