import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormControl, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common'
import { interval, Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { PaymentsService } from '../../services/payments.service';
import { OrdersService } from '../../services/orders.service';
import { PrintService } from '../../services/print.service'
import { GeneralLedgerService } from '../../services/general-ledger.service';
import { UsersService } from '../../services/users.service'
import * as FileSaver from 'file-saver';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-bank-rec',
	templateUrl: './bank-rec.component.html',
	styleUrls: ['./bank-rec.component.scss']
})
export class BankRecComponent implements OnInit {
	config: any = false;
	color = 'blue';
	payment_methods: any = [];
	today = new Date();
	dateto = new UntypedFormControl(this.today);
	datefrom = new UntypedFormControl(this.getFirstDayOfLastMonth());
	ref_location: any;
	user: any = [];
	isPosUser: boolean = false;
	locations: any = [];
	transactions: any = [];
	salespeople: any = [];
	title: string = 'Bank Reconciliation';
	defaultlocation = new UntypedFormControl('');
	searchFilter = new FormControl('');
	salesperson = new UntypedFormControl('');
	paymenttypefilter = new UntypedFormControl();
	isARMode = true; // Default to AR mode
	showCleared = new UntypedFormControl(true);
	orignal_results: any = [];
	running = false;
	payments: any = [];
	total_pay: any = 0;
	total_sub: any = 0;
	cctotal: any = 0;
	total_discount: any = 0;
	filteredTransactions: any = [];
	temporaryAmount = new UntypedFormControl('');


	constructor(
		private generalLedgerService: GeneralLedgerService,
		private ordersService: OrdersService,
		private usersService: UsersService,
		private _changeDetectorRef: ChangeDetectorRef,
		private location: Location,
		private reportsService: ReportsService,
		private printService: PrintService,
		private globalSearchService: GlobalSearchService,
		private globalsService: GlobalsService,
		private paymentsService: PaymentsService,
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		public router: Router,
		private modalService: NgbModal
	) {
		this.searchFilter.valueChanges.subscribe(() => {
			this.applyFilters();
		});
		this.subscribeToGlobalUpdates();
	}

	applyFilters() {
		if (this.payments && this.payments.transactions) {
			const searchValue = this.searchFilter.value?.toLowerCase() || '';

			this.filteredTransactions = this.payments.transactions.filter((transaction) => {
				// Check cleared filter
				if (!this.showCleared.value && transaction.isCleared) {
					return false;
				}

				// Optimize by searching specific fields only
				if (searchValue) {
					const fieldsToSearch = [
						'transno',
						'debtorno',
						'reference',
						'name',
						'ovamount',
						'reference',
						'checknum'
					];

					const matchesSearch = fieldsToSearch.some((field) => {
						const value = transaction[field];
						return (
							value !== null &&
							value !== undefined &&
							value.toString().toLowerCase().includes(searchValue)
						);
					});

					return matchesSearch;
				}

				return true; // Include transaction if no search value provided
			});
		}
	}

	ngOnInit(): void {
		this.globalSearchService.payment_methods.subscribe((methods) => {
			this.payment_methods = methods;
			this.paymenttypefilter.setValue(this.payment_methods);
			this.loadTransactions();
		});
	}

	getFirstDayOfLastMonth(): Date {
		const now = new Date();
		const firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		return firstDayLastMonth;
	}

	subscribeToGlobalUpdates(): void {
		// Subscribe to config updates
		this.globalSearchService.configsubscription.subscribe((config) => {
			this.config = config;
			if (this.config.env.package === 'beauty') {
			}
		});

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe((userResults) => {
			if (this.user) {
				if (userResults.user.defaultlocation.loccode !== this.ref_location) {
					this.defaultlocation.setValue([userResults.user.defaultlocation.loccode]);
					this.ref_location = false;
				}
			}

			this.user = userResults;

			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}
		});

		// Check if the user is a POS user and fetch locations
		this.usersService.isPosUser().subscribe((isPOS) => {
			this.isPosUser = !!isPOS;

			this.globalsService.getLocations().subscribe((locations: any[]) => {
				if (locations) {
					this.locations = (this.isPosUser || this.user.user.issalesman) ?
						locations.filter((location) => location.loccode === this.user.user.defaultlocation.loccode) :
						locations;

					const defaultLocation = [this.user.user.defaultlocation.loccode];
					this.defaultlocation.setValue(defaultLocation);
				}
			});
		});

		// Fetch salespeople data
		this.reportsService.getSalesPeople().subscribe((salespeople: any) => {
			this.salespeople = salespeople;
		});
	}

	toggleClearView() {
		this.applyFilters();
	}

	toggleMode(): void {
		this.isARMode = !this.isARMode;
		this.loadTransactions();
	}

	onAmountInputChange(event: Event): void {
		const value = (event.target as HTMLInputElement).value;

	}


	loadTransactions(): void {

		const data = {
			from: this.datefrom.value,
			to: this.dateto.value,
			locations: this.defaultlocation.value,
			arap: this.isARMode,
			showCleared: this.showCleared.value,
			payment_type: (this.paymenttypefilter && this.paymenttypefilter.value) ? this.paymenttypefilter.value.map((method: any) => method.paymentname) : [], // Extract only the names .. should prob be ids
		};

		this.running = true;
		this.paymentsService.getBankRec(data).subscribe((results: any) => {
			const copyofresults = results;
			this.orignal_results.transactions = copyofresults.transactions;
			this.running = false;
			this.payments = results;

			this.applyFilters();


			this.total_pay = results.summary.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.amount);
			}, 0);

			const parent = this;
			this.cctotal = results.summary.reduce(function(accumulator: number, items: any) {

				const ttype = parent.payment_methods.filter((t: any) => {
					return t.paymentname == items.type;
				})[0];
				let value = 0;

				if (ttype && ttype.cc_process == '1') {
					value = parseFloat(items.amount);
				}

				return accumulator + value;
			}, 0);

			this.total_sub = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovamount);
			}, 0);

			this.total_discount = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovdiscount);
			}, 0);


		})
	}

	toggleEdit(transaction: any): void {
		// Toggle selection state
		transaction.isSelected = !transaction.isSelected;

		// Initialize default values if needed
		if (transaction.isSelected) {
			this.temporaryAmount.setValue(transaction.amountCleared);
			if (!transaction.amountCleared) {
				transaction.amountCleared = Math.abs(transaction.alloc || 0);
			}
			this.temporaryAmount.setValue(transaction.amountCleared);
			transaction.isCleared = false; // Default to not cleared
		}
	}

	togglePopover(transaction: any): void {

		this.temporaryAmount.setValue(transaction.amountCleared);

		transaction.isSelected = !transaction.isSelected;

		// Initialize default values when selected
		// if (transaction.isSelected) {
		//   transaction.amountCleared = transaction.alloc || 0; // Default to allocated amount
		//   transaction.isCleared = false; // Default to not cleared
		// }
	}

	saveTransaction(transaction: any): void {
		// Optionally close the popover after saving
		transaction.isSelected = false;
		this.togglePopover(transaction)
	}
	onClearChange(transaction: any): void {
		// Update partially cleared state
		if (transaction.isCleared) {
			transaction.isPartiallyCleared = false;
		}
	}

	savePartial(transaction: any): void {

		transaction.amountCleared = this.temporaryAmount.value;

		this.paymentsService.updateTransaction(transaction).subscribe({
			next: (response) => {
				transaction.isCleared = response.isCleared;
				transaction.amountCleared = response.amountCleared;
				transaction.isSelected = false;
			},
			error: (error) => console.error('Error updating transaction:', error),
		});

	}

	toggleClear(transaction: any): void {
		transaction.isCleared = !transaction.isCleared;

		// Optional: Additional logic when toggling the clear state
		if (transaction.isCleared) {
			transaction.amountCleared = Math.abs(transaction.ovamount); // Mark full amount cleared
		} else {
			transaction.amountCleared = 0; // Reset cleared amount
		}

		this.paymentsService.updateTransaction(transaction).subscribe({
			next: (response) => {
				// setTimeout(() => {
				//   console.log('Transaction updated:', response);
				this.applyFilters(); // Apply filters after 1-second delay
				//}, 100);
			},
			error: (error) => console.error('Error updating transaction:', error),
		});

	}


	populateLocations(): void {
		this.locations = this.transactions.map(transaction => ({
			loccode: transaction.locationCode,
			locationname: transaction.locationName,
		}));

		// Prepopulate default locations
		const defaultLocCodes = this.locations.map(loc => loc.loccode);
		this.defaultlocation.setValue(defaultLocCodes);
	}

	trackByTransaction(index: number, transaction: any): number {
		return transaction.transno; // Unique identifier for the transaction
	}


	back(): void {
		this.location.back();
	}
}