import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { interval, Subscription, Observable, BehaviorSubject } from 'rxjs';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { UsersService } from '../../services/users.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PrintService } from '../../services/print.service';
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-crpt',
	templateUrl: './crpt.component.html',
	styleUrls: ['./crpt.component.scss']
})
export class CrptComponent implements OnInit {
	@ViewChild('orderView') orderStatusViewRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('print_tablexlsx') printtablexlsx: ElementRef;
	@ViewChild('print_tablepdf') printtablepdf: ElementRef;
	@ViewChild('salesFilter') salesmanRef: MatSelect;
	@ViewChild('branchFilter') branchesRef: MatSelect;
	@ViewChildren(MatPaginator) paginators: QueryList < MatPaginator > ;
	@ViewChildren(MatSort) sorts: QueryList < MatSort > ;

	orderResults: any[] = [];

	private sort: MatSort;

	dataSource: MatTableDataSource < any > ;
	dataSources: {
		[key: string]: MatTableDataSource < any >
	} = {};
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20, 100, 500, 1000];
	pagesizedefault = 20;

	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	conBrRef = new UntypedFormControl('C');
	placedFromRef = new UntypedFormControl('');
	branchRef = new UntypedFormControl('');
	salespersonRef = new UntypedFormControl('');

	filterresults: any = [];
	filteredItems: any = [];

	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];

	ordersFound: any = [];
	todate = new Date();
	fromdate = new Date();
	reportCriteria = {
		fromdate: '',
		todate: '',
		locs: [],
		salesperson: [],
		conbr: '',
		placedfrom: ''
	};

	@Input() name: string;
	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	filename: any = 'Customer_sales_by_branch_';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	sending: any = false;
	branches: any = [];
	salespeople: any = [];
	user: any = false;
	isPosUser: any = false;
	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
	ref_location: any = false;
	reportsub: any = false;
	ot: any = false;
	sortColumn: string = '';
	sortDirection: string = 'asc';
	sortedDetails: any[] = [];

	constructor(private _changeDetectorRef: ChangeDetectorRef, private usersService: UsersService, private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.mesagesallowed.next(false);
		this.globalSearchService.aiDataAvailable$.next(null);
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});
		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isPosUser = true;
			}
		})

		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;
		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			if (this.user) {
				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.branchRef.setValue(this.user.user.defaultlocation.loccode);
					this.ref_location = false;
				}
			}

			this.user = results

			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}
		});
	}

	ngOnInit(): void {
		// this.orderResults$.subscribe(results => {
		// 	this.orderResults = results;
		// 	this.initializePagination();
		// });
	}

	getLocationNameByLoccode(loccode) {
		if (loccode === '99') {
			return 'Consolidated';
		}

		const location = this.branches.find(location => location.loccode === loccode);
		return location ? location.locationname : null;
	}

	getCRPT() {
		this.orderResults = [];
		this.ot = false;
		this.reportCriteria = {
			fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
			todate: this.todateCtrl.value.toISOString().split('T')[0],
			locs: this.branchRef.value,
			conbr: this.conBrRef.value,
			placedfrom: this.placedFromRef.value,
			salesperson: this.salespersonRef.value
		};

		this.sending = true;
		if (this.reportsub) {
			this.reportsub.unsubscribe();
		}

		this.reportsub = this.reportsService.getCRPT(this.reportCriteria).subscribe((r: any) => {
			this.ot = r;
			this.sending = false;
			this.globalSearchService.aiDataAvailable$.next(r);
			this.orderResults = r.lines;
			this.sortedDetails = r.lines.details;
			this.initializePagination();
			this._changeDetectorRef.detectChanges();
			
		});
	}

	viewCustomer(debtorno: any, loccode: any) {
		const drilldown = {
			cust: debtorno,
			loc: loccode,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			locs: this.reportCriteria.locs,
			conbr: this.reportCriteria.conbr,
			placedfrom: this.reportCriteria.placedfrom,
			salesperson: this.reportCriteria.salesperson
		};
		this.reportsService.getCRPTDrill(drilldown).subscribe((order: any) => {
			this.orderdetails = order;
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}
	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.DocType) {
			case 'Credit':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				});
				break;
			case 'Invoice':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				});
				break;
		}
	}
	exportPdf() {
		this.sending = true;
		const now = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateHTML());
		const data = {
			content: encoded,
			filename: this.filename + (now.getMonth() + 1) + now.getDate() + now.getFullYear(),
			title: `Customer Sales by Branch ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate} Created @ ${now.toLocaleString()}`,
			subtitle: this.getReportSubtitle()
		};

		this.printService.pdf(data).subscribe((result: any) => {
			this.sending = false;
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		this.sending = true;
		const now = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateHTML());
		const data = {
			content: encoded,
			filename: this.filename + (now.getMonth() + 1) + now.getDate() + now.getFullYear(),
			title: `Customer Sales by Branch ${this.reportCriteria.fromdate} ${this.reportCriteria.todate} Created @ ${now.toLocaleString()}`,
			subtitle: this.getReportSubtitle()
		};

		this.printService.xls(data).subscribe((result: any) => {
			this.sending = false;
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	back(): void {
		this.location.back();
	}

	getBranchNameByLoccode(loc) {
		if (loc == 99) { return 'Consolidated'; }
		const branch = this.branches.filter((i) => {
			return i.loccode == loc;
		});
		if (branch.length == 0) {
			return '';
		} else {
			return branch[0].locationname;
		}
	}

	getReportSubtitle() {
		let out = '';

		this.reportCriteria.conbr == 'C' ? out += 'Consolidated Report ' : out += 'Detail Report ';

		if ((this.reportCriteria.locs.length != this.branches.length && this.reportCriteria.locs.length > 0)) {
			out += 'For Location(s): ';
			this.reportCriteria.locs.length ? (this.reportCriteria.locs).map((loc) => out += (loc + ' ')) : '';
		} else if (this.isPosUser && this.reportCriteria.locs.length <= 1) {
			out += 'For ' + this.getBranchNameByLoccode(this.ref_location) + ' ';
		} else {
			out += 'For All Locations ';
		}

		if (this.reportCriteria.salesperson.length != this.salespeople.length && this.reportCriteria.salesperson.length > 0) {
			out += 'and Salesperson(s): ';
			this.reportCriteria.salesperson.length ? (this.reportCriteria.salesperson).map((person) => out += (person + ' ')) : '';
		} else {
			out += 'and All Salespeople';
		}

		if (!this.isPosUser) {
			if (this.reportCriteria.placedfrom == ' ') {
				out += ' Including Web Orders';
			}
			if (this.reportCriteria.placedfrom == '1') {
				out += ' Excluding Web Orders';
			}
			if (this.reportCriteria.placedfrom == '2') {
				out += ' Only Web Orders';
			}
		}
		return out;
	}

	selectAllToggle(filter_name: string) {
		let filter;
		switch (filter_name) {
			case 'salesman':
				filter = this.salesmanRef;
				break;
			case 'branch':
				filter = this.branchesRef;
				break;
			default:
				return;
		}
		const selected = filter.options.find(opt => opt.selected);
		if (selected) {
			filter.options.forEach((item: MatOption) => item.deselect());
		} else {
			filter.options.forEach((item: MatOption) => item.select());
		}
	}

	initializePagination() {
		if (this.orderResults && Array.isArray(this.orderResults)) {
			this.orderResults.forEach((line, index) => {
				const branchName = line.branchname;
				this.dataSources[branchName] = new MatTableDataSource(line.details);

				if (this.paginators.toArray()[index]) {
					this.dataSources[branchName].paginator = this.paginators.toArray()[index];
				}
				if (this.sorts.toArray()[index]) {
					this.dataSources[branchName].sort = this.sorts.toArray()[index];
				}
			});
		}
	}

	generateHTML(): string {
		const orderResults = this.orderResults; // Get the current value of the orderResults observable
		if (!orderResults || !orderResults.length) return '';

		let html = `
			<table class="table">
				<tr>
					<th>BR</th>
					<th>Customer</th>
					<th>Name</th>
					<th>Salesman</th>
					<th style="text-align: left;">Salesman Name</th>
					<th style="text-align: right;">Sales</th>
				</tr>
		`;

		orderResults.forEach(o => {
			html += `
				<tr>
					<th colspan="6" style="text-align: left;">${this.getBranchNameByLoccode(o.branchname)}</th>
				</tr>
			`;
			o.details.forEach(line => {
				html += `
					<tr>
						<td>${line.br}</td>
						<td>${line.customer}</td>
						<td>${line.name}</td>
						<td style="text-align: center;">${line.salesman}</td>
						<td>${line.salesman_name}</td>
						<td style="text-align: right;">${line.sales.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
					</tr>
				`;
			});
			html += `
				<tr>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th style="text-align: right;">${this.getBranchNameByLoccode(o.branchname)} Total: ${o.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</th>
				</tr>
			`;
		});

		html += `
			<tr>
				<th></th>
				<th></th>
				<th></th>
				<th>Count: ${this.ot.totalrecords}</th>
				<th>Avg: ${this.ot.avg.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</th>
				<th style="text-align: right;">Total: ${this.ot.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</th>
			</tr>
			</table>
		`;

		return html;
	}

	ngAfterViewInit() {
		this.paginators.changes.subscribe(() => {
			this.paginators.forEach((paginator, index) => {
				const branchName = this.orderResults[index]?.branchname;
				if (branchName && this.dataSources[branchName]) {
					this.dataSources[branchName].paginator = paginator;
				}
			});
		});

		this.sorts.changes.subscribe(() => {
			this.sorts.forEach((sort, index) => {
				const branchName = this.orderResults[index]?.branchname;
				if (branchName && this.dataSources[branchName]) {
					this.dataSources[branchName].sort = sort;
				}
			});
		});
	}



	sortTable(order: any, column: string) {
		const direction = order.sortDirection === 'asc' ? 'desc' : 'asc';
		order.sortColumn = column;
		order.sortDirection = direction;

		order.details.sort((a: any, b: any) => {
			let aValue = a[column];
			let bValue = b[column];

			// If the column is 'sales', remove '$' and ',' and convert to number
			if (column === 'sales') {
				aValue = parseFloat(aValue.replace(/[$,]/g, ''));
				bValue = parseFloat(bValue.replace(/[$,]/g, ''));
			}

			let comparison = 0;

			if (aValue > bValue) {
				comparison = 1;
			} else if (aValue < bValue) {
				comparison = -1;
			}

			return direction === 'asc' ? comparison : -comparison;
		});
	}

	// Method to get the appropriate Material icon for sorting
	getSortIcon(order: any, column: string): string {
		if (order.sortColumn === column) {
			return order.sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward';
		}
		return 'sort';
	}


	trackByBranchName(index: number, item: any): string {
		return item.branchname;
	}
}