<div class="logo">
	<ng-container *ngIf="this.user && this.user.user.showdashboard==='0'">
		<a href="#" class="simple-text">
			<div class="logo-img">
				<img src="/assets/img/ring.png" *ngIf="config.env.package == 'shadow'"/>
				<img src="/assets/img/report-icon-16.png" *ngIf="config.env.package == 'tires'"/>
				<img src="/assets/img/circle.png" *ngIf="config.env.package == 'beauty'"/>
			</div>
			<b>V<i class="material-icons">all_inclusive</i> </b>
			<span *ngIf="!getNavState()">v8</span>
		</a>
	</ng-container>
	<ng-container *ngIf="this.user && this.user.user.showdashboard!=='0'">
		<a href="/#/orders/entry" class="simple-text">
		<div class="logo-img">
			<img src="/assets/img/ring.png" *ngIf="config.env.package == 'shadow'"/>
			<img src="/assets/img/report-icon-16.png" *ngIf="config.env.package == 'tires'"/>
			<img src="/assets/img/circle.png" *ngIf="config.env.package == 'beauty'"/>
		</div>
		<b>V<i class="material-icons">all_inclusive</i> </b>
		<span *ngIf="!getNavState()">v8</span>
		</a>

	</ng-container>
</div>
<div class="sidebar-wrapper">
	<div *ngIf="isMobileMenu()" class="msearch forced">
		<search class="forced"></search>
	</div>
	<ul class="nav">
		<div class="user">
			<div class="user-info">
				<a data-toggle="collapse" href="#profile" class="" aria-expanded="false" *ngIf="user.user">
					<span>{{ user.user.realname }} <b class="caret caret-top"></b></span></a>
				<div id="profile" class="collapse " style="">
					<ul class="nav">
						<li class="nav-item">
							<a [routerLink]="'/users/user-profile'" class="nav-link ">
								<span class="sidebar-mini">EP</span>
								<span class="sidebar-normal">Edit Profile</span>
							</a>
						</li>
						<li class="nav-item d-none">
							<a [routerLink]="'/users/user-settings'" class="nav-link">
								<span class="sidebar-mini">S</span><span class="sidebar-normal">Settings</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<li routerLinkActive="active" class="nav-item" id="parent-favorites" >
			<ng-container>
			<a data-toggle="collapse" href="#collapse-favorites" class="nav-link linkback parents"  aria-expanded="false"
				(click)="selectParentMarker('#parent-favorites','#collapse-favorites')">
				<i class="material-icons linkback">star</i>
				<p>Favorites</p>
			</a>
			</ng-container>
			<div id="collapse-favorites" class="children collapse" style="" >
				<ul class="nav" >
					<ng-container *ngIf="favorites && favorites.length">
						<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let item of favorites;">
							<ng-container>
								<a [routerLink]="[item.path]" class="nav-link linkback" *ngIf="!item.children && memoizedPathAllowed(item.path)" (click)="selectMarker('#parent-favorites', '#collapse-favorites')">
									<span class="sidebar-mini linkback f-10">{{ item.icon }}</span>
									<span [title]="item.longtitle ? item.longtitle : item.title" class="sidebar-normal">{{ item.title }} </span>
								</a>
							</ng-container>
						</li>
					</ng-container>
					<li class="nav-item">
						<ng-container >
							<a class="nav-link linkback" (click)="addFavorites()" *ngIf="!isFavorite()">
								<span class="sidebar-mini linkback f-10"><i class="material-icons linkback">add</i></span>
								<span title="Favorites" class="sidebar-normal">Add to Favorites</span>
							</a>
							<a class="nav-link linkback" (click)="removeFavorites()" *ngIf="isFavorite()">
								<span class="sidebar-mini linkback f-10"><i class="material-icons linkback">remove</i></span>
								<span title="Favorites" class="sidebar-normal">Remove Favorite</span>
							</a>
						</ng-container>
					</li>
				</ul>
			</div>
		</li>
		<li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item" id="parent-{{ menuItem.anchor }}">
			<!-- holdover for counter pos need to make dynamic -->
			<ng-container *ngIf="(menuItem.package && menuItem.package == config.env.package) || menuItem.package=='all'">
			<a class="nav-link linkback" [routerLink]="[menuItem.path]" *ngIf="!menuItem.children && memoizedPathAllowed(menuItem.path)" (click)="selectMarker('#parent-'+menuItem.anchor,'#parent-'+menuItem.anchor)">
				<i class="material-icons linkback">{{menuItem.icon}}</i>
				<p>{{menuItem.title}}</p>
			</a>
			</ng-container>
			<ng-container *ngIf="(menuItem.package && menuItem.package == config.env.package) || menuItem.package=='all'">
			<a data-toggle="collapse" href="#collapse-{{menuItem.anchor}}" class="nav-link linkback parents"  aria-expanded="false" *ngIf="menuItem.children && memoizedPathAllowed(menuItem.path)"
				(click)="selectParentMarker('#parent-'+menuItem.anchor,'#collapse-'+menuItem.anchor)">
				<i class="material-icons linkback">{{menuItem.icon}}</i>
				<p>{{menuItem.title}}</p>
			</a>
			</ng-container>
			<!-- currently false are general package todo move this to config -->
			<ng-container *ngIf="!menuItem.package">
				<a data-toggle="collapse" href="#collapse-{{menuItem.anchor}}" class="nav-link linkback parents"  aria-expanded="false" *ngIf="menuItem.children && memoizedPathAllowed(menuItem.path)"
					(click)="selectParentMarker('#parent-'+menuItem.anchor,'#collapse-'+menuItem.anchor)">
					<i class="material-icons linkback">{{menuItem.icon}}</i>
					<p>{{menuItem.title}}</p>
				</a>
			</ng-container>

			<div id="collapse-{{menuItem.anchor}}" class="children collapse" style="" *ngIf="menuItem.children">
				<ul class="nav">
					<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let item of menuItem.children;">

						<ng-container *ngIf="item.package && item.package == config.env.package">
							<a [routerLink]="[item.path]" class="nav-link linkback" *ngIf="!item.children && memoizedPathAllowed(item.path)" (click)="selectMarker('#parent-'+menuItem.anchor, '#collapse-'+menuItem.anchor)">
								<span class="sidebar-mini linkback f-10">{{ item.icon }}</span>
								<span [title]="item.longtitle ? item.longtitle : item.title" class="sidebar-normal">{{ item.title }} </span>
							</a>
							<a data-toggle="collapse" href="#collapse-{{item.anchor}}" class="nav-link linkback " aria-expanded="false" *ngIf="item.children && memoizedPathAllowed(item.path)"
							(click)="selectMarker('#parent-'+menuItem.anchor,'#collapse-'+menuItem.anchor )">
								<!-- (click)="navTo(menuItem.path)" -->
								<i class="material-icons linkback thirdlevel ">{{item.icon}}</i>
								<p>{{item.title}}</p>
							</a>
						</ng-container>
						<ng-container *ngIf="!item.package">
							<a [routerLink]="[item.path]" class="nav-link linkback" *ngIf="!item.children && memoizedPathAllowed(item.path)" (click)="selectMarker('#parent-'+menuItem.anchor, '#collapse-'+menuItem.anchor)">
								<span class="sidebar-mini linkback f-10">{{ item.icon }}</span>
								<span [title]="item.longtitle ? item.longtitle : item.title" class="sidebar-normal">{{ item.title }} </span>
							</a>
							<a data-toggle="collapse" href="#collapse-{{item.anchor}}" class="nav-link linkback " aria-expanded="false" *ngIf="item.children && memoizedPathAllowed(item.path)"
							(click)="selectMarker('#parent-'+menuItem.anchor,'#collapse-'+menuItem.anchor )">
								<!-- (click)="navTo(menuItem.path)" -->
								<i class="material-icons linkback thirdlevel ">{{item.icon}}</i>
								<p>{{item.title}}</p>
							</a>
						</ng-container>
						<ng-container>
							<div id="collapse-{{item.anchor}}" class="collapse " style="" *ngIf="item.children  && memoizedPathAllowed(item.path)">
								<ul class="nav">
									<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item" *ngFor="let ti of item.children;">
										<a [routerLink]="[ti.path]" class="nav-link linkback" *ngIf="memoizedPathAllowed(ti.path)" (click)="selectMarker('#parent-'+menuItem.anchor,'#collapse-'+menuItem.anchor)">
											<span class="sidebar-mini linkback f-10">{{ ti.icon }}</span>
											<span [title]="ti.longtitle ? ti.longtitle : ti.title" class="sidebar-normal">{{ ti.title }} </span>
										</a>
									</li>
								</ul>
							</div>
						</ng-container>
					</li>
					<li class="nav-item" *ngIf="anchorReports(menuItem.anchor).length || menuItem.reports && menuItem.reports.length">
							<a class="nav-link linkback" data-toggle="collapse" href="#collapse-{{menuItem.anchor}}-reports" aria-expanded="false">
								<span class="sidebar-mini linkback"><i class="material-icons linkback thirdlevel">stacked_bar_chart</i></span>
								<span class="sidebar-normal">Reports </span>
							</a>
							<ng-container>
								<div id="collapse-{{menuItem.anchor}}-reports" class="collapse report-section" style="" >
									<ul class="nav">
										<ng-container *ngFor="let ti of menuItem.reports;">
											<ng-container *ngIf="menuItem.reports">
												<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item"  *ngIf="isTitleInReports(ti.title )">
													<a [routerLink]="[ti.path]" class="nav-link linkback"  (click)="selectMarker('#parent-'+menuItem.anchor,'#collapse-'+menuItem.anchor)">
														<span class="sidebar-mini linkback f-10">{{ ti.icon }}</span>
														<span class="sidebar-normal">{{ ti.title }} </span>
													</a>
												</li>
											</ng-container>
										</ng-container>
										<ng-container *ngIf="anchorReports(menuItem.anchor).length">
											<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item" *ngFor="let ti of anchorReports(menuItem.anchor);">
												<a [routerLink]="[ti.nglink]" class="nav-link linkback"  (click)="selectMarker('#parent-'+menuItem.anchor,'#collapse-'+menuItem.anchor)">
													<span class="sidebar-mini linkback f-10">-</span>
													<span class="sidebar-normal">{{ ti.title }} </span>
												</a>
											</li>
										</ng-container>
									</ul>
								</div>
							</ng-container>

						</li>
				</ul>
			</div>
		</li>
	</ul>
</div>
