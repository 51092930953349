import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehiclesService } from '../../services/vehicles.service';

@Component({
	selector: 'app-driver-logs',
	templateUrl: './driver-logs.component.html',
	styleUrls: ['./driver-logs.component.scss']
})
export class DriverLogsComponent implements OnInit {
	@Input() vehicle: any | null = null;

	driverLogForm: FormGroup;
	driverLogs: any[] = [];
	filteredDriverLogs: any[] = [];
	isFormVisible = false;
	isLoading = false;
	searchTerm = '';
	selectedDriverLog: any = null;
	constructor(
		private fb: FormBuilder,
		private vehiclesService: VehiclesService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.initializeForm();
		this.loadDriverLogs();
	}

	initializeForm(): void {
		this.driverLogForm = this.fb.group({
			vehicle_id: [this.vehicle ? this.vehicle.id : '', Validators.required],
			driver_name: ['', Validators.required],
			driver_license: ['', Validators.required],
			start_time: ['', Validators.required],
			end_time: [''],
			purpose: ['', Validators.required],
			mileage_start: [0, [Validators.required, Validators.min(0)]],
			mileage_end: [0, [Validators.min(0)]]
		});
	}

	loadDriverLogs(): void {
		let filters = { vehicle_id: this.vehicle?.id };
		this.vehiclesService.getDriverLogs(filters).subscribe((logs: any) => {
			this.driverLogs = logs.driverLogs;
			this.filteredDriverLogs = logs.driverLogs;
		});
	}

	applyFilter(event: any): void {
		this.searchTerm = event.target.value.toLowerCase();
		this.filteredDriverLogs = this.driverLogs.filter(log =>
			log.driver_name.toLowerCase().includes(this.searchTerm)
		);
	}

	editDriverLog(log: any): void {
	  this.selectedDriverLog = log;
	  this.driverLogForm.patchValue(log);
	  this.isFormVisible = true;
	}


	saveDriverLog(): void {
	  if (this.driverLogForm.valid) {
		const driverLogData = this.driverLogForm.value;

		if (this.selectedDriverLog) {
		  // Update existing driver log
		  driverLogData.log_id = this.selectedDriverLog.log_id;
		  this.vehiclesService.updateDriverLog(driverLogData).subscribe(() => {
			this.snackBar.open('Driver log updated successfully.', 'Close', { duration: 3000 });
			this.loadDriverLogs();
			this.toggleForm();
		  });
		} else {
		  // Add new driver log
		  this.vehiclesService.addDriverLog(driverLogData).subscribe(() => {
			this.snackBar.open('Driver log added successfully.', 'Close', { duration: 3000 });
			this.loadDriverLogs();
			this.toggleForm();
		  });
		}
	  } else {
		this.showValidationErrors();
	  }
	}


	deleteDriverLog(logId: number): void {
		if (!confirm('Are you sure you want to delete this driver log?')) return;

		this.vehiclesService.deleteDriverLog(logId).subscribe(() => {
			this.snackBar.open('Driver log deleted successfully.', 'Close', { duration: 3000 });
			this.loadDriverLogs();
		});
	}

	toggleForm(): void {
		this.isFormVisible = !this.isFormVisible;
		if (this.isFormVisible) this.driverLogForm.reset({ vehicle_id: this.vehicle ? this.vehicle.id : '' });
	}

	showValidationErrors(): void {
		for (const control in this.driverLogForm.controls) {
			if (this.driverLogForm.controls.hasOwnProperty(control)) {
				this.driverLogForm.controls[control].markAsTouched();
			}
		}
		this.snackBar.open('Please correct the errors before submitting.', 'Close', { duration: 3000 });
	}
}
