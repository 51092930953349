<div class="auth-wrapper">
	<div class="auth-box">
		<div class="card">
			<div class="card-body text-center">
				<!-- Larger Logo -->
				<img class="auth-logo img img-fluid mb-3" src="{{ logo }}" alt="System Logo">

				<!-- Dynamic Heading with Better Typography -->
				<h3 class="auth-title">
					<i class="icon-user mr-2"></i> {{ systemType || 'Login' }}
				</h3>

				<form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="auth-form" novalidate>
					<!-- Username Input -->
					<div class="form-group">
						<label for="username">Username</label>
						<input type="email" id="username" formControlName="userName"
							class="form-control" placeholder="Enter your username" required>
					</div>

					<!-- Password Input -->
					<div class="form-group">
						<label for="password">Password</label>
						<input type="password" id="password" formControlName="password"
							class="form-control" placeholder="Enter your password" required>
					</div>

					<!-- Error Message -->
					<div *ngIf="loginForm.value.txterror" class="alert alert-danger">
						{{ loginForm.value.txterror }}
					</div>

					<!-- Remember Me & Forgot Password -->
					<!-- <div class="d-flex justify-content-between align-items-center">
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" id="rememberMe">
							<label class="custom-control-label" for="rememberMe">Remember me</label>
						</div>
						<a href="javascript:void(0)" class="forgot-pass">Forgot password?</a>
					</div> -->

					<!-- Submit Button -->
					<div class="form-button mt-4">
						<button class="btn btn-primary btn-block" type="submit">
							{{ systemType ? 'Access ' + systemType : 'Login' }}
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>