import { Component } from '@angular/core';

@Component({
  selector: 'app-free-gift',
  templateUrl: './free-gift.component.html',
  styleUrls: ['./free-gift.component.scss']
})
export class FreeGiftComponent {

}
