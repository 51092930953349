import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { EventEmitter } from '@angular/core';
import { GlobalsService } from 'app/services/globals.service';

@Component({
	selector: 'app-order-tracking',
	templateUrl: './order-tracking.component.html',
	styleUrls: ['./order-tracking.component.scss']
})



export class OrderTrackingComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() forced_orderno: any = false;
	@Input() type: any = false;
	@Output() updated = new EventEmitter<any>
	
	orderSearchRef = new UntypedFormControl('');
	trackingRef = new UntypedFormControl('');
	trackingTypeRef = new UntypedFormControl('');

	
	errorMessage: any = false;
	orderInfo: any = false;
	items: any = [];
	branchInfo: any = false;
	total = 0;
	totals: any;
	searchingord: any;
	color: string = 'blue';
	orderno: any;
	submitting: any = false;
	forced: boolean = false;
	currentTrack: any = false;
	shippers: any = [];
	forcedType: any;

	constructor(private cd: ChangeDetectorRef, private globalsService: GlobalsService,  private ordersService: OrdersService, private globalSearchService: GlobalSearchService) { }


	ngAfterViewInit(): void {
		// if(this.forced && this.forcedType){
			// console.log(this.forcedType, this.shippers)
		// }
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.forced_orderno){
			this.orderSearchRef.setValue(changes.forced_orderno.currentValue)
			this.orderSearch()
			this.forced = true;
		}
		if(changes.type){
			// this.trackingTypeRef.setValue(this.getShipperId(changes.type.currentValue))
			this.forcedType = changes.type.currentValue;
			this.forced = true;

		}
	}


	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.globalsService.getShipVia().subscribe((res)=>{
			this.shippers = res.filter((via)=>{
				if(['fedex', 'ups'].includes(via.shippername.toLowerCase())){
					return via;
				}
			})
		});	
	}


	setCurrentTracking(input){
		this.currentTrack = input;
		this.trackingRef.setValue(this.currentTrack.trackingnumber);
		this.trackingTypeRef.setValue(this.currentTrack.type_id)
	}


	orderSearch(){
		if(this.searchingord){
			this.searchingord = false;
		}

		const search = this.orderSearchRef.value.trim()
		if(!search.length){return}

		const data = {
			orderno: search
		}

		this.resetData();

		this.searchingord = this.ordersService.trackingservice(data).subscribe((res) => {
			this.searchingord = false;
			if (res.success) {
				this.orderInfo = res.orderInfo;
				this.items = res.items.details;
				this.branchInfo = res.branchInfo;
				this.totals = this.buildTotals(res.totals);
				this.orderno = res.orderInfo.orderno;
				this.currentTrack = res.orderInfo.tracking.length ? res.orderInfo.tracking[0] : false;
				this.trackingTypeRef.setValue(this.currentTrack.type_id);
				if(this.currentTrack){
					this.trackingRef.setValue(this.currentTrack.trackingnumber);
				}

				if(this.forcedType){
					this.trackingTypeRef.setValue(this.getShipperId(this.forcedType))
					this.cd.detectChanges()
				}
				
				this.errorMessage = false;
			} else {
				this.errorMessage = res.message || 'No data found';
			}
		})
	}


	getShipperName(track){
		var name = '';
		if(this.shippers.length){
			name = this.shippers.find((via)=>via.shipper_id == track.type_id)['shippername'];
		}
		return name;
	}

	getShipperId(name){
		var id = '';
		if(this.shippers.length){
			id = this.shippers.filter((via)=>via.shippername.toUpperCase() == name.toUpperCase())[0]['shipper_id'];
		}
		return id;
	}
	

	buildTotals(input){ //totals only
		var out = [];
		const allowed = ['total'];
		Object.keys(input).forEach((k)=>{
			if(allowed.includes(k)){
				out.push({
					code: k,
					text: input[k]
				});
			}
		})
		return out;
	}


	resetData(){
		this.orderInfo = false
		this.items = [];
		this.branchInfo = false;
		this.totals = false;
		this.orderno = false;
	}

	openTrackingLink(url){
		window.open(url);
	}


	submitTracking(type){
		var update = false;
		var submit = true;
		var remove = false;
		var message = 'Tracking Number Submitted';
		
		if(type == 'update'){
			update = true;
			submit = false;
			message = 'Tracking Number Updated'
		}
		
		if(type == 'remove'){
			update = submit = false;
			remove = true;
			message = 'Tracking Number Removed'
			if(!confirm(`Remove tracking number from this order?`)){
				this.trackingRef.setValue('');
				return
			}
		}

		const data = {
			tracking: this.trackingRef.value.trim(),
			orderno: this.orderSearchRef.value.trim(),
			originaltrack: this.currentTrack,
			submit: submit,
			update: update,
			remove: remove,
			tracktype: this.trackingTypeRef.value
		}


		this.submitting = true;
		this.submitting = this.ordersService.trackingservice(data).subscribe((res)=>{
			if (res.success) {
				this.orderInfo = res.orderInfo;
				this.items = res.items.details;
				this.branchInfo = res.branchInfo;
				this.totals = this.buildTotals(res.totals);
				this.orderno = res.orderInfo.orderno;
				this.errorMessage = false;

				if(res.orderInfo.tracking?.length && type != 'remove'){
					const match = res.orderInfo.tracking.filter((i)=>
						i.trackingnumber == data.tracking
					)[0];
					this.setCurrentTracking(match)
				}

				if(type == 'remove'){
          if(res.orderInfo.tracking?.length){
            this.setCurrentTracking(res.orderInfo.tracking[res.orderInfo.tracking.length - 1]);
          }else{
            this.currentTrack = false;
          }
				}

				if(this.currentTrack){
					this.trackingRef.setValue(this.currentTrack.trackingnumber);
				}
				this.globalSearchService.showNotification(message, 'success', 'bottom', 'left');
				if(this.forced){
					this.updated.emit(null);
				}
			} else {
				this.globalSearchService.showNotification('Tacking Number already present', 'warning', 'bottom', 'left');
			}
			this.submitting = false;
		})
	}
}