import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, UntypedFormControl, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { InventoryService } from '../../services/inventory.service'
import { PrintService } from '../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-price-level-pricing',
	templateUrl: './price-level-pricing.component.html',
	styleUrls: ['./price-level-pricing.component.scss']
})
export class PriceLevelPricingComponent {
	color = 'blue';
	price_levels: any = [];
	results: any = [];
	paginatedResults = []
	filteredResults = [];

	pageSize = 10;
	categoryFilter = '';
	discountFilter = '';
	categories: any = [];
	discountLevels: any = []
	constructor(public printService: PrintService, private fb: FormBuilder, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private location: Location, public cdr: ChangeDetectorRef, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		const data = {};

		this.globalSearchService.aiDataAvailable$.next(null);

		this.inventoryService.getPriceLevels().subscribe((r: any) => {
			this.results = r;
			this.paginatedResults = r.active.slice(0, this.pageSize);
			this.categories = r.categories;
			this.discountLevels = r.pricelevel;
			this.filteredResults = r.active;
		})
	}

	back(): void {
		this.location.back()
	}

	onPageChange(event: PageEvent): void {
		const startIndex = event.pageIndex * event.pageSize;
		const endIndex = startIndex + event.pageSize;
		this.paginatedResults = this.results.slice(startIndex, endIndex);
	}

	savePriceLevelPricing(item: any): void {
		this.inventoryService.createPriceLevel(item).subscribe(response => {

		});
	  }

	applyFilters(): void {

		this.filteredResults = this.results.active.filter(item => {

			return (this.categoryFilter === '' || item.category_id === this.categoryFilter) &&
				(this.discountFilter === '' || item.price_type === this.discountFilter);
		});
	}

}
