<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Customer Data Export</h4>
						<p class="card-category text-right"></p>
					</div>
					<div class="card-body">
						<div class="m-auto main-width">
							<form [formGroup]="searchForm">
								<div class="row m-0 p-0">
									<div class="col-md-3 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Transaction Dates</mat-label>
											<mat-date-range-input [rangePicker]="picker">
												<input matStartDate formControlName="fromDateCtrl" placeholder="Start date">
												<input matEndDate formControlName="toDateCtrl" placeholder="End date">
											</mat-date-range-input>
											<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
											<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
											<mat-date-range-picker #picker></mat-date-range-picker>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Search</mat-label>
											<input matInput formControlName="keywords" (keydown.enter)="getCustExport()" />
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>City</mat-label>
											<mat-select formControlName="city">
												<mat-option value="">--ALL--</mat-option>
												<mat-option *ngFor="let k of cities" [value]="k.city">{{k.city}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-1 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>State</mat-label>
											<mat-select formControlName="states">
												<mat-option value="">--ALL--</mat-option>
												<mat-option *ngFor="let k of states" [value]="k.statecode">{{k.statecode}} - {{k.statename }}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Salesperson</mat-label>
											<mat-select formControlName="salesperson">
												<mat-option value="">--ALL--</mat-option>
												<mat-option *ngFor="let k of salespeople" [value]="k.salesmancode">{{k.salesmanname}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Product Line</mat-label>
											<mat-select formControlName="prodline">
												<mat-option value="">--ALL--</mat-option>
												<mat-option *ngFor="let k of productlines" [value]="k.line_field">{{k.line_field}} - {{k.line_description}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Payment Terms</mat-label>
											<mat-select formControlName="payterms">
												<mat-option value="">--ALL--</mat-option>
												<mat-option *ngFor="let k of paymentterms" [value]="k.termsindicator">{{k.terms}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Clubs</mat-label>
											<mat-select formControlName="clubs">
												<mat-option value="">--ALL--</mat-option>
												<mat-option *ngFor="let k of clubs" [value]="k.club">{{k.club}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Client Since</mat-label>
											<input matInput formControlName="clientsince" [matDatepicker]="since">
											<mat-datepicker-toggle matSuffix [for]="since"></mat-datepicker-toggle>
											<mat-datepicker #since></mat-datepicker>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Sales Floor</mat-label>
											<input matInput formControlName="salesfloor">
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Only Customers With Balance Due?</mat-label>
											<mat-select formControlName="balancedue">
												<mat-option value="0"> No </mat-option>
												<mat-option value="1"> Yes </mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-3">
										<mat-form-field appearance="outline">
											<mat-label>Only Customers with Email?</mat-label>
											<mat-select formControlName="emailflag">
												<mat-option value="0"> No </mat-option>
												<mat-option value="1"> Yes </mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-3 mt-3">
										<span *ngIf="sending">
											<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</span>
										<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults">
											<i class="fa fa-file-excel-o"></i> XLS
										</button>
										&nbsp;
										<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults">
											<i class="fa fa-file-pdf-o"></i> PDF
										</button>
										&nbsp;
										<button mat-raised-button color="accent" class="mt-2" (click)="getCustExport()" *ngIf="!sending">
											<mat-icon>refresh</mat-icon>
										</button>
									</div>
								</div>
							</form>
							<ng-container *ngIf="orderResults">
								<hr>
								<h3> Customer Listing {{searchForm.get('fromDateCtrl').value | date:'MM/d/yy'}} - {{searchForm.get('toDateCtrl').value | date: 'MM/d/yy'}}</h3>
								<h4>
									{{orderResults.title}}
								</h4>
								<div>
									<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault" *ngIf="orderResults"></mat-paginator>
									<div class="table" *ngIf="orderResults">
										<table class="table table-sm table-hover">
											<thead>
												<tr>
													<th>Number</th>
													<th>Type</th>
													<th>Customer</th>
													<th>Branch</th>
													<th>Address</th>
													<th>Address 2</th>
													<th>City</th>
													<th>State</th>
													<th>Zip</th>
													<th>Contact</th>
													<th>Phone</th>
													<th>Email</th>
													<th>Ship Method</th>
													<th>Due</th>
													<th>Club</th>
													<th>Date</th>
													<th>Total Sales</th>
											</thead>
											<tbody>
												<ng-container *ngFor="let o of dataObs | async;let i = index">
													<tr>
														<td>
															{{o.debtorno}}.{{o.branchcode }}
														</td>
														<td>{{o.typename}}</td>
														<td>
															{{o.name}}
														</td>
														<td>{{o.brname}}<br>
														</td>
														<td>{{o.billing_address1}}</td>
														<td>{{o.billing_address2}}</td>
														<td>{{o.billing_address3}}</td>
														<td>{{o.billing_address4}}</td>
														<td>{{o.billing_address5}}</td>
														<td>{{ o.contactname }}</td>
														<td>{{ o.phoneno }}</td>
														<td>{{ o.email}}</td>
														<td>{{o.shipping_method}}</td>
														<td>{{ o.due | currency }}</td>
														<td>{{ o.club }}</td>
														<td>{{ o.clientsince | date:'MM/d/yy' }}</td>
														<td>{{ o.total | currency }}</td>
													</tr>
												</ng-container>
											</tbody>
										</table>
									</div>
								</div>
								<div #print_table style="display: none">
									<div class="table">
										<table class="table table-sm table-hover" *ngIf="orderResults">
											<thead>
												<tr>
													<th>Number</th>
													<th>Type</th>
													<th>Customer</th>
													<th>Branch</th>
													<th>Contact</th>
													<th>Phone</th>
													<th>Email</th>
													<th>Ship Method</th>
													<th>Due</th>
													<th>Club</th>
													<th>Date</th>
													<th>Total Sales</th>
											</thead>
											<tbody>
												<ng-container *ngFor="let o of orderResults.values">
													<tr>
														<td>
															{{o.debtorno}}.{{o.branchcode }}
														</td>
														<td>{{o.typename}}</td>
														<td>
															{{o.name}}
														</td>
														<td>{{o.brname}}<br>
															{{o.billing_address1}}
															<span *ngIf="o.billing_address2 != ''">{{o.billing_address2}}</span><br>
															{{o.billing_address3}}, {{o.billing_address4}} {{o.billing_address5}}
														</td>
														<td>{{ o.contactname }}</td>
														<td>{{ o.phoneno }}</td>
														<td>{{ o.email}}</td>
														<td>{{o.shipping_method}}</td>
														<td>{{ o.due | currency }}</td>
														<td>{{ o.club }}</td>
														<td>{{ o.clientsince | date:'MM/d/yy' }}</td>
														<td>{{ o.total | currency }}</td>
													</tr>
												</ng-container>
											</tbody>
										</table>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>