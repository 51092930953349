<div class="main-content" *ngIf="!stockid">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-{{color}}-6">
                        <h4 class="card-title">Single Item Transfer</h4>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="transferForm">
                            <div class="row">
                                <div class="row col-md-12 mt-1">
                                    <div class="col-md-6 m-auto">
                                        <mat-card>
                                            <mat-card-header>
                                                <mat-card-title class="text-dark">From Location</mat-card-title>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <mat-form-field appearance="fill" class="full-width">
                                                    <mat-label>From Location</mat-label>
                                                    <mat-select formControlName="fromLocation" (selectionChange)="onFromLocationChange($event)">
                                                        <mat-option *ngFor="let loc of locations" [value]="loc.loccode">
                                                            {{loc.locationname}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div class="address-display" *ngIf="fromaddress">{{ fromaddress }}</div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-6 m-auto">
                                        <mat-card>
                                            <mat-card-header>
                                                <mat-card-title class="text-dark">To Location</mat-card-title>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <mat-form-field appearance="fill" class="full-width">
                                                    <mat-label>To Location</mat-label>
                                                    <mat-select formControlName="toLocation" (selectionChange)="onToLocationChange($event)">
                                                        <ng-container *ngFor="let loc of locations">
                                                            <mat-option *ngIf="loc.loccode != transferForm.get('fromLocation').value" [value]="loc.loccode">
                                                                {{loc.locationname}}
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div class="address-display" *ngIf="toaddress">{{ toaddress }}</div>
                                                <div class="address-display hide" *ngIf="!toaddress && fromaddress">{{ fromaddress }}</div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-12 mb-2 mt-2" *ngIf="!selectedItem">
                                        <mat-form-field appearance="outline" class="w-100">
                                          <mat-label>Search Item</mat-label>
                                          <input
                                            matInput
                                            #itemsearch
                                            placeholder="Search by item number or description"
                                            (keyup.enter)="searchItems($event)"
                                            [(ngModel)]="searchTerm"
                                            [ngModelOptions]="{ standalone: true }"
                                            [disabled]="!transferForm.get('fromLocation').value || !transferForm.get('toLocation').value">
                                          
                                          <button
                                            *ngIf="!searching"
                                            mat-icon-button
                                            matSuffix
                                            aria-label="Search"
                                            [disabled]="!transferForm.get('fromLocation').value || !transferForm.get('toLocation').value || !searchTerm.trim()"
                                            (click)="searchItems($event)">
                                            <mat-icon>search</mat-icon>
                                          </button>
                                        
                                          <mat-progress-spinner
                                            *ngIf="searching"
                                            matSuffix
                                            diameter="20"
                                            mode="indeterminate"
                                            color="accent">
                                          </mat-progress-spinner>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="items.data?.length > 0 && !selectedItem">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table mat-table [dataSource]="items" matSort class="w-100">
                                            <ng-container matColumnDef="stockid">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Item #</th>
                                                <td mat-cell *matCellDef="let row">{{row.stockid}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="description">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                                                <td mat-cell *matCellDef="let row">{{row.description}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="fromLocationQoh">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>From QOH</th>
                                                <td mat-cell *matCellDef="let row">{{row.fromLocationQoh || 0}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="toLocationQoh">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>To QOH</th>
                                                <td mat-cell *matCellDef="let row">{{row.toLocationQoh || 0}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let row">
                                                    <button mat-button color="primary" (click)="selectItem(row)">
                                                        Select
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        </table>

                                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of items"></mat-paginator>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="selectedItem">
                                <div class="col-md-12 m-0 p-0 mb-2">
                                    <div class="card-header border-2">
                                        <div class="row m-0 p-0">
                                            <h4>Transfering <b>{{selectedItem.stockid}}</b> - <span class="font-weight-lighter">{{selectedItem.description}}</span></h4>
                                            <button mat-flat-button class="pos-abs-r" color="accent" (click)="clearSelection()">
                                                Change Item
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="row col-md-12 m-0 p-0 mb-2 justify-content-center">
                                    <h5 class="col-md-4 m-0 text-center p-0 mb-1 circled"><span class="font-weight-lighter"> {{fromName}} QOH&nbsp;</span><b>{{fromLocationQoh}}</b>
                                        <ng-container *ngIf="getTotalTransferQuantity() > 0">
                                            (<span class="text-danger">-{{getTotalTransferQuantity()}}</span>)
                                        </ng-container>
                                    </h5>
                                    <h5 class="col-md-2 m-0 p-0 text-center"><mat-icon>trending_flat</mat-icon></h5>
                                    <h5 class="col-md-4 m-0 text-center p-0 mb-1 circled"><span class="font-weight-lighter"> {{toName}} QOH&nbsp;</span><b>{{toLocationQoh}}</b>
                                        <ng-container *ngIf="getTotalTransferQuantity() > 0">
                                            (<span class="text-success">+{{getTotalTransferQuantity()}}</span>)
                                        </ng-container>
                                    </h5>
                                </div>

                                <div class="col-md-12" *ngIf="availableBins.length > 0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Bin</th>
                                                    <th class="text-center">Available Quantity</th>
                                                    <th>Transfer Quantity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let bin of availableBins">
                                                    <td>{{bin.bin || 'NOBIN'}}</td>
                                                    <td class="text-center">{{bin.quantity}}</td>
                                                    <td>
                                                        <mat-form-field appearance="outline">
                                                            <input matInput type="number" [max]="bin.quantity" [min]="0" [(ngModel)]="bin.transferQty" [ngModelOptions]="{standalone: true}">
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="card m-0 p-0">
                                        <div class="card-header">
                                            <strong>Total Transfer Quantity: {{getTotalTransferQuantity()}}</strong>
                                        </div>
                                        <div *ngIf="isValidTransfer() && getTotalTransferQuantity() > 0" class="card-header">
                                            <strong>After transfer:</strong>
                                            <ul>
                                                <li>{{fromName}} QOH: {{ fromLocationQoh - getTotalTransferQuantity() }}</li>
                                                <li>{{toName}} QOH: {{ toLocationQoh + getTotalTransferQuantity() }}</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer row justify-content-end" *ngIf="selectedItem">
                        <button mat-raised-button color="primary" [disabled]="!isValidTransfer()" (click)="confirmTransfer()">
                            Confirm Transfer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="transferForm" *ngIf="stockid" class="col-md-12 m-0 p-0">
    <div class="row m-0 p-0">
        <div class="row col-md-12 mt-1">
            <div class="col-md-6 m-auto">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title class="text-dark">From Location</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>From Location</mat-label>
                            <mat-select formControlName="fromLocation" (selectionChange)="onFromLocationChange($event)">
                                <mat-option *ngFor="let loc of locations" [value]="loc.loccode">
                                    {{loc.locationname}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="address-display" *ngIf="fromaddress">{{ fromaddress }}</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6 m-auto">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title class="text-dark">To Location</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>To Location</mat-label>
                            <mat-select formControlName="toLocation" (selectionChange)="onToLocationChange($event)">
                                <ng-container *ngFor="let loc of locations">
                                    <mat-option *ngIf="loc.loccode != transferForm.get('fromLocation').value" [value]="loc.loccode">
                                        {{loc.locationname}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <div class="address-display" *ngIf="toaddress">{{ toaddress }}</div>
                        <div class="address-display hide" *ngIf="!toaddress && fromaddress">{{ fromaddress }}</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-12 mb-2 mt-2" *ngIf="!selectedItem">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search Item</mat-label>
                    <input matInput #itemsearch placeholder="Search by item number or description" (input)="searchItems($event)" (keyup.enter)="searchItems($event)" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}" [disabled]="!transferForm.get('fromLocation').value || !transferForm.get('toLocation').value ">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0" *ngIf="items.data?.length > 0 && !selectedItem">
        <div class="col-md-12 m-0 p-0">
            <div class="table-responsive">
                <table mat-table [dataSource]="items" matSort class="w-100">
                    <ng-container matColumnDef="stockid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item #</th>
                        <td mat-cell *matCellDef="let row">{{row.stockid}}</td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                        <td mat-cell *matCellDef="let row">{{row.description}}</td>
                    </ng-container>

                    <ng-container matColumnDef="fromLocationQoh">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>From QOH</th>
                        <td mat-cell *matCellDef="let row">{{row.fromLocationQoh || 0}}</td>
                    </ng-container>

                    <ng-container matColumnDef="toLocationQoh">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>To QOH</th>
                        <td mat-cell *matCellDef="let row">{{row.toLocationQoh || 0}}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-button color="primary" (click)="selectItem(row)">
                                Select
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of items"></mat-paginator>
            </div>
        </div>
    </div>
    <div class="row mt-3 m-0 p-0" *ngIf="selectedItem">
        <div class="col-md-12 m-0 p-0 mb-2">
            <div class="card-header border-2">
                <div class="row m-0 p-0">
                    <h4>Transfering <b>{{selectedItem.stockid}}</b> - <span class="font-weight-lighter">{{selectedItem.description}}</span></h4>
                </div>
            </div>
        </div>

        <div class="row col-md-12 m-0 p-0 mb-2 justify-content-center">
            <h5 class="col-md-4 m-0 text-center p-0 mb-1 circled"><span class="font-weight-lighter"> {{fromName}} QOH&nbsp;</span><b>{{fromLocationQoh}}</b>
                <ng-container *ngIf="getTotalTransferQuantity() > 0">
                    (<span class="text-danger">-{{getTotalTransferQuantity()}}</span>)
                </ng-container>
            </h5>
            <h5 class="col-md-2 m-0 p-0 text-center"><mat-icon>trending_flat</mat-icon></h5>
            <h5 class="col-md-4 m-0 text-center p-0 mb-1 circled"><span class="font-weight-lighter"> {{toName}} QOH&nbsp;</span><b>{{toLocationQoh}}</b>
                <ng-container *ngIf="getTotalTransferQuantity() > 0">
                    (<span class="text-success">+{{getTotalTransferQuantity()}}</span>)
                </ng-container>
            </h5>
        </div>

        <div class="col-md-12 m-0 p-0" *ngIf="availableBins.length > 0">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Bin</th>
                            <th class="text-center">Available Quantity</th>
                            <th>Transfer Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let bin of availableBins">
                            <td>{{bin.bin || 'NOBIN'}}</td>
                            <td class="text-center">{{bin.quantity}}</td>
                            <td>
                                <mat-form-field appearance="outline">
                                    <input matInput type="number" [max]="bin.quantity" [min]="0" [(ngModel)]="bin.transferQty" [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card m-0 p-0">
                <div class="card-header">
                    <strong>Total Transfer Quantity: {{getTotalTransferQuantity()}}</strong>
                </div>
                <div *ngIf="isValidTransfer() && getTotalTransferQuantity() > 0" class="card-header">
                    <strong>After transfer:</strong>
                    <ul>
                        <li>{{fromName}} QOH: {{ fromLocationQoh - getTotalTransferQuantity() }}</li>
                        <li>{{toName}} QOH: {{ toLocationQoh + getTotalTransferQuantity() }}</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <div class="row justify-content-end m-0 p-0 mt-3">
        <button mat-raised-button color="primary" [disabled]="!isValidTransfer()" (click)="confirmTransfer()">
            Confirm Transfer
        </button>
    </div>
</form>