<!-- Filters -->
<div class="row mb-3">
	<div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Search Accident Logs</mat-label>
			<input matInput (keyup)="applyFilter($event)" placeholder="Search by Location, Description, or Status" />
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
	</div>
	<div class="col-md-6 text-end">
		<button mat-raised-button color="accent" class="mt-2" (click)="toggleForm()">
			{{ isFormVisible ? 'Hide Form' : 'Add Accident Log' }}
		</button>
	</div>
</div>

<!-- Add/Edit Accident Log Form -->
<div *ngIf="isFormVisible" class="p-3 border rounded bg-light">
	<form [formGroup]="accidentLogForm" (ngSubmit)="saveAccidentLog()">
		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Vehicle</mat-label>
					<mat-select formControlName="vehicle_id" required>
						<mat-option *ngIf="vehicle" [value]="vehicle.id">
							{{ vehicle.license_plate }} - {{ vehicle.make }} {{ vehicle.model }}
						</mat-option>
						<mat-option *ngFor="let v of vehicles" [value]="v.id">
							{{ v.license_plate }} - {{ v.make }} {{ v.model }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="accidentLogForm.controls['vehicle_id'].invalid && accidentLogForm.controls['vehicle_id'].touched">
						Vehicle selection is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Accident Date</mat-label>
					<input matInput [matDatepicker]="picker" formControlName="accident_date" required />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-error *ngIf="accidentLogForm.controls['accident_date'].invalid && accidentLogForm.controls['accident_date'].touched">
						Accident date is required.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Location</mat-label>
					<input matInput formControlName="location" required />
					<mat-error *ngIf="accidentLogForm.controls['location'].invalid && accidentLogForm.controls['location'].touched">
						Location is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Claim Status</mat-label>
					<mat-select formControlName="claim_status" required>
						<mat-option value="Pending">Pending</mat-option>
						<mat-option value="Approved">Approved</mat-option>
						<mat-option value="Rejected">Rejected</mat-option>
						<mat-option value="Repaired">Repaired</mat-option>
						<mat-option value="Closed">Closed</mat-option>
					</mat-select>
					<mat-error *ngIf="accidentLogForm.controls['claim_status'].invalid && accidentLogForm.controls['claim_status'].touched">
						Claim status is required.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Description</mat-label>
					<textarea matInput formControlName="description" rows="3" required></textarea>
					<mat-error *ngIf="accidentLogForm.controls['description'].invalid && accidentLogForm.controls['description'].touched">
						Description is required.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Estimated Repair Cost ($)</mat-label>
					<input matInput formControlName="estimated_repair_cost" type="number" min="0" />
					<mat-error *ngIf="accidentLogForm.controls['estimated_repair_cost'].invalid && accidentLogForm.controls['estimated_repair_cost'].touched">
						Cost must be a positive value.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Actual Repair Cost ($)</mat-label>
					<input matInput formControlName="actual_repair_cost" type="number" min="0" />
					<mat-error *ngIf="accidentLogForm.controls['actual_repair_cost'].invalid && accidentLogForm.controls['actual_repair_cost'].touched">
						Cost must be a positive value.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="text-end">
			<button mat-raised-button color="accent" type="submit" [disabled]="accidentLogForm.invalid">
				Save Accident Log
			</button>
			<button mat-raised-button color="warn" type="button" (click)="toggleForm()">
				Cancel
			</button>
		</div>
	</form>
</div>

<!-- Accident Logs Table -->
<div class="table-responsive mt-4">
	<table class="table table-striped table-hover table-bordered">
		<thead class="thead-dark">
			<tr>
				<th class="text-center">Vehicle</th>
				<th class="text-center">Accident Date</th>
				<th class="text-center">Location</th>
				<th class="text-center">Description</th>
				<th class="text-center">Claim Status</th>
				<th class="text-center"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let log of filteredAccidentLogs">
				<td class="text-center">{{ log.vehicle_id }}</td>
				<td class="text-center">{{ log.accident_date }}</td>
				<td class="text-center">{{ log.location }}</td>
				<td class="text-center">{{ log.description }}</td>
				<td class="text-center">{{ log.claim_status }}</td>
				<td class="text-center">
					<button mat-icon-button color="primary" (click)="editAccidentLog(log)">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="warn" (click)="deleteAccidentLog(log.accident_id)">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>
