import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalsService } from 'app/services/globals.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';
import { ReportsService } from 'app/services/reports.service';
import { format } from 'date-fns';

@Component({
	selector: 'app-customer-item-prodline-sales',
	templateUrl: './customer-item-prodline-sales.component.html',
	styleUrls: ['./customer-item-prodline-sales.component.scss']
})


export class CustomerItemProdlineSalesComponent implements OnInit {
	currentResponse: any = false;
	
	
	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService, private inventoryService: InventoryService, private globalsService: GlobalsService, private reportsService: ReportsService, private location: Location) { }
		filename: any = 'CustomerItemSalesByProdline';
		subtitle: any = false;
		color: any = 'blue';
		config: any = false;
		running: boolean = false;
		error: any = false;
		allcustomers: boolean = false
	
		reportdata: { from: any; to: any; lines: any; salesman: any; branches: any; allcustomers: any; };
		@ViewChild('paginator') paginator: MatPaginator;
		datasource: any = false;
		columns_displayed: any = false;

		prodlines: any = [];
		allproductlines: any = [];
		salespeeps: any = [];
		allsalespeeps: any = [];
		branches: any = [];
		allbranches: any = [];

		
		date = new Date();
		todateCtrl =  new UntypedFormControl(new Date(this.date.getFullYear(), this.date.getMonth(), 0), [Validators.required]);
		fromdateCtrl = new UntypedFormControl(new Date(this.date.getFullYear(), this.date.getMonth() - 1, 1), [Validators.required]);
		linesCtrl = new UntypedFormControl([]);
		branchesCtrl = new UntypedFormControl([]);
		salesmanCtrl = new UntypedFormControl([]);

	ngOnInit(): void {
		this.color = this.globalsService.getColor();
		this.globalsService.getConfig().subscribe((c)=>{
			this.config = c;
		})

		this.inventoryService.getItemProductlines().subscribe((res: any)=>{
			res.forEach(line => {
				if(line.line_field != ''){
					this.prodlines.push({viewValue: line.line_description, value: line.line_field})
					this.allproductlines.push(line.line_field);
				}
			});
		})

		this.globalsService.getSalesPeople().subscribe((res : any)=>{
			res.forEach(person => {
				if(person.salesmancode != ''){
					this.salespeeps.push({viewValue: person.salesmanname, value: person.salesmancode})
					this.allsalespeeps.push(person.salesmancode)
				}
			});
		})

		this.globalsService.getAllLocations().subscribe((res: any) =>{
			res.forEach(loc => {
				if(loc.loccode != ''){
					this.branches.push({viewValue: loc.locationname, value: loc.loccode})
					this.allbranches.push(loc.loccode);
				}
			});
		})
		
	}

	formatRows(data: any){
		console.log(data);
		if(!data){return}
		// if( dynamic && data !== []){return}

		if(this.allcustomers){
			var temp = [];
			data['allcusts'].forEach(row => {
				temp[row['debtorno'] + '' +row['branchcode']] = row
			});
			data['dataonly'].forEach(row => {
				temp[row['debtorno'] + '' +row['branchcode']] = row
			});
			data = Object.values(temp);
		}else{
			data = data.dataonly;
		}

		this.datasource = false;
		this.columns_displayed = Object.keys(data[0]).filter((r)=>{return r !== 'empty'});
				
		const wanted_order = ["debtorno", "brname", "braddress1", "braddress2", "City", "State", "Zip", "Phone", "Email", "SALES ID", "SALES NAME"];
		const orderMap = new Map(wanted_order.map((item, index) => [item, index]));
		this.columns_displayed = this.columns_displayed.sort((a, b) => {
			const indexA = orderMap.get(a) ?? Number.MAX_VALUE;
			const indexB = orderMap.get(b) ?? Number.MAX_VALUE;
			return indexA - indexB;
		})
		this.subtitle = this.getReportSubtitle();
		this.running = false;
		this.datasource = new MatTableDataSource(data.sort((a, b) => { return a.debtorno.toString().localeCompare(b.debtorno.toString(), undefined, {numeric: true})}));
		this.datasource.paginator = this.paginator;
	}

	runReport(){

		this.fromdateCtrl.markAsTouched();
		this.todateCtrl.markAsTouched();
		if(!this.fromdateCtrl.value || !this.todateCtrl.value){
			return;
		}

		this.datasource = false;
		this.running = true;
		this.error = false;
		this.currentResponse = false;

		this.reportdata = {
			from: this.fromdateCtrl.value,
			to: this.todateCtrl.value,
			lines: this.linesCtrl.value?.length ? this.linesCtrl.value : this.allproductlines,
			salesman: this.salesmanCtrl.value?.length ? this.salesmanCtrl.value : this.allsalespeeps,
			branches: this.branchesCtrl.value?.length ? this.branchesCtrl.value : this.allbranches,
			allcustomers: this.allcustomers
		}

		
		this.reportsService.getCustomerItemProdlineSales(this.reportdata).subscribe((res)=>{
			if(res.success){
				this.formatRows(res.data);
				this.currentResponse = res.data;
			}else{
				this.running = false;
				this.error = "No data for selected filters"
			}
		})	
	}


	export(type){
		const now = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.buildhtml());
		const formatted = {
			from: format(new Date(this.reportdata.from), 'MM/dd/yyyy'),
			to: format(new Date(this.reportdata.to), 'MM/dd/yyyy')
		}
		const data = {
			content: encoded,
			filename: this.filename + (now.getMonth() + 1) + now.getDate() + now.getFullYear(),
			title: `Customer Item Sales by Product Line ${formatted.from} - ${formatted.to} Created @ ${now.toLocaleString()}`,
			subtitle: this.subtitle
		};
		if(type == 'pdf'){
			this.printService.pdf(data).subscribe((result: any) => {
				this.globalSearchService.downloadPdf(result.content, data.filename);
			});
		}else{
			this.printService.xls(data).subscribe((result: any) => {
				this.globalSearchService.downloadXls(result.content, data.filename);
			});
		}
		
	}

	getReportSubtitle(){
		const lines = this.reportdata.lines
		const salespeep = this.reportdata.salesman
		const locs = this.reportdata.branches

		var count;
		var title = 'For '
		if(locs.length == this.branches.length){
			title += 'Branches [ALL] '
		}else{
			title += 'Branches [' 
			count = locs.length -1;
			locs.forEach(br => {
				if(count == 0){
					title += `${br}`
				}else{
					title += `${br}, `
				}
				count--;
			});
			title += '] '
		}

		if(salespeep.length == this.salespeeps.length){
			title += 'Salesman [ALL] '
		}else{
			title += 'Salesman [' 
			count = salespeep.length -1;
			salespeep.forEach(per => {
				if(count == 0){
					title += `${per}`
				}else{
					title += `${per}, `
				}
				count--;
			});
			title += '] '
		}

		if(lines.length == this.prodlines.length){
			title += 'Lines [ALL] '
		}else{
			title += 'Lines [' 
			count = lines.length -1;
			lines.forEach(per => {
				if(count == 0){
					title += `${per}`
				}else{
					title += `${per}, `
				}
				count--;
			});
			title += '] '
		}

		return title;

	}

	buildhtml(){
		let html = '<table><thead>';
		html += '<tr>';
		(this.columns_displayed).forEach(k => {
			html += `<th>${k}</th>`
		});
		html += '</tr></thead><tbody>';
		(this.datasource.data).forEach(row => {
			html += '<tr>';
			(this.columns_displayed).forEach(k => {
				if(this.allproductlines.includes(k)){
					//product line columns $$
					html += `<td style="text-align: center">${this.financial(Number(row[k]))}</td>`
				}else{
					html += `<td>${row[k]}</td>`
				}
			});	
			html += '</tr>';
		});
		html += '</tbody></table>';
		return html;
	
	}

	financial(num: number): number {
		if (!num) {
			num = 0.00;
		}
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
		return parseFloat(rounded.toFixed(2));
	}

	back(): void {
		this.location.back()
	}
}
