import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  key = 'UET6DFMPAXW7BKCB';
  config: any;

  constructor (
    private globalSearchService: GlobalSearchService,
    private http: HttpClient,
    private _configSvc: RuntimeConfigLoaderService
  ) {
    this.globalSearchService.configsubscription.subscribe(r => {
      this.config = r;
    });
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  /** ================================
   *  VEHICLE API ENDPOINTS
   *  ================================ */

  // Get Vehicles with Filters
  public getVehicles (filters: any): Observable < any > {
    return this.http.post(this.setEndPoint('vehiclestracking/search'), filters, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Get a Single Vehicle
  public getVehicle (vehicleId: number): Observable < any > {
    return this.http.post(this.setEndPoint('vehiclestracking/show'), { vehicle_id: vehicleId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Add a New Vehicle
  public addVehicle (vehicleData: any): Observable < any > {
    return this.http.post(this.setEndPoint('vehiclestracking/store'), vehicleData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Update a Vehicle
  public updateVehicle (vehicleData: any): Observable < any > {
    return this.http.post(this.setEndPoint('vehiclestracking/update'), vehicleData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Delete a Vehicle
  public deleteVehicle (vehicleId: number): Observable < any > {
    return this.http.post(this.setEndPoint('vehiclestracking/delete'), { vehicle_id: vehicleId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  /** ================================
   *  FUEL LOG API ENDPOINTS
   *  ================================ */

  // Get Fuel Logs with Filters
  public getFuelLogs (filters: any): Observable < any > {
    return this.http.post(this.setEndPoint('fuel/search'), filters, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Add a New Fuel Log
  public addFuelLog (fuelData: any): Observable < any > {
    return this.http.post(this.setEndPoint('fuel/store'), fuelData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Update a Fuel Log
  public updateFuelLog (fuelData: any): Observable < any > {
    return this.http.post(this.setEndPoint('fuel/update'), fuelData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Delete a Fuel Log
  public deleteFuelLog (logId: number): Observable < any > {
    return this.http.post(this.setEndPoint('fuel/delete'), { log_id: logId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  /** ================================
   *  ACCIDENT LOG API ENDPOINTS
   *  ================================ */

  // Get Accident Logs with Filters
  public getAccidentLogs (filters: any): Observable < any > {
    return this.http.post(this.setEndPoint('accidents/search'), filters, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Add a New Accident Log
  public addAccidentLog (accidentData: any): Observable < any > {
    return this.http.post(this.setEndPoint('accidents/store'), accidentData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Update an Accident Log
  public updateAccidentLog (accidentData: any): Observable < any > {
    return this.http.post(this.setEndPoint('accidents/update'), accidentData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Delete an Accident Log
  public deleteAccidentLog (accidentId: number): Observable < any > {
    return this.http.post(this.setEndPoint('accidents/delete'), { accident_id: accidentId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
/** ================================
   *  MAINTENANCE LOG API ENDPOINTS
   *  ================================ */

  // Get Maintenance Logs with Filters
  public getMaintenanceLogs(filters: any): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance/search'), filters, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Get a Single Maintenance Log
  public getMaintenanceLog(logId: number): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance/show'), { log_id: logId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Add a New Maintenance Log
  public addMaintenanceLog(maintenanceData: any): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance/store'), maintenanceData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Update a Maintenance Log
  public updateMaintenanceLog(maintenanceData: any): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance/update'), maintenanceData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Delete a Maintenance Log
  public deleteMaintenanceLog(logId: number): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance/delete'), { log_id: logId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  /** ================================
   *  DRIVER LOGS API ENDPOINTS
   *  ================================ */

  // Get Driver Logs with Filters
  public getDriverLogs(filters: any): Observable<any> {
    return this.http.post(this.setEndPoint('driverlogs/search'), filters, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Add a New Driver Log
  public addDriverLog(driverData: any): Observable<any> {
    return this.http.post(this.setEndPoint('driverlogs/store'), driverData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Update a Driver Log
  public updateDriverLog(driverData: any): Observable<any> {
    return this.http.post(this.setEndPoint('driverlogs/update'), driverData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Delete a Driver Log
  public deleteDriverLog(logId: number): Observable<any> {
    return this.http.post(this.setEndPoint('driverlogs/delete'), { log_id: logId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  /** ================================
   *  MAINTENANCE PARTS API ENDPOINTS
   *  ================================ */

  public getPartsUsed(filters: any): Observable<any> {
	return this.http.post(this.setEndPoint('parts-used/search'), filters, this.httpOptions)
	  .pipe(catchError(this.handleError));
  }

  public addPartUsed(partData: any): Observable<any> {
	return this.http.post(this.setEndPoint('parts-used/add'), partData, this.httpOptions)
	  .pipe(catchError(this.handleError));
  }

  public updatePartUsed(partData: any): Observable<any> {
	return this.http.post(this.setEndPoint('parts-used/update'), partData, this.httpOptions)
	  .pipe(catchError(this.handleError));
  }

  public deletePartUsed(partId: number): Observable<any> {
	return this.http.delete(this.setEndPoint(`parts-used/delete?part_id=${partId}`), this.httpOptions)
	  .pipe(catchError(this.handleError));
  }


  // Add Parts to a Maintenance Log
  public addPartToMaintenance(partData: any): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance-parts/store'), partData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Update a Part in Maintenance
  public updatePartInMaintenance(partData: any): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance-parts/update'), partData, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Delete a Part from Maintenance
  public deletePartFromMaintenance(partId: number): Observable<any> {
    return this.http.post(this.setEndPoint('maintenance-parts/delete'), { part_id: partId }, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  /** ================================
   *  API UTILITIES
   *  ================================ */

  private setEndPoint (method: string): string {
    return this.getApi() + method + this.getApiKey();
  }

  private getApi (): string {
    return this.config.apiServer.endpoint;
  }

  private getApiKey (): string {
    return '&key=' + this.config.apiCredentials.clientId;
  }

  private handleError (error: HttpErrorResponse): Observable < never > {
    if (error.status === 401) {
      window.location.href = '/#/auth/logout';
    }
    return throwError(() => new Error(error.error || 'Server Error'));
  }
}