<ng-template #aIBottomSheet>
	<div class="ai-container">
		<div class="ai-header">
			<h5>🤖 AI Analysis</h5>
			<button class="btn-close" (click)="closeAi()" aria-label="Close"><i class="fa fa-close"></i></button>
		</div>

		<div *ngIf="loadingAi" class="ai-loading">
			<div class="spinner-border text-light" role="status"></div>
			<p>Analyzing report, please wait...</p>
		</div>
		<!-- Dynamic Chart Container -->
		<div *ngIf="containsChart(aiResult)" class="ai-chart">
			<div #chartContainer></div>
		</div>

		<div *ngIf="!loadingAi && cleanedAiText.length" class="ai-results">
			<div class="ai-output">
				<p *ngFor="let line of cleanedAiText.split('\n')" [innerHTML]="formatText(line)"></p>
			</div>
		</div>
		<div *ngIf="!loadingAi && !aiResult?.content?.length && aiResult" class="ai-no-data">
			{{ aiResult }}
		</div>
		<div *ngIf="!loadingAi && !aiResult?.content?.length" class="ai-no-data">
			No analysis available.
		</div>
	</div>
</ng-template>





<ng-template #truckalert let-modal>
	<div class="modal-header bgwarning">
		<h4 class="modal-title w-100" id="modal-title">
			<div class="w-100">
				{{returningTruck.truckname}}
				({{returningTruck.driver}})
				<b class="align-middle float-right d-flex font-weight-bold"><mat-icon matBadge="!">local_shipping</mat-icon></b>
			</div>
		</h4>
	</div>
	<div class="modal-body">
		<h3 class="text-center"><b>Completed run and is returning to the warehouse</b></h3>
		<div class="text-center overflow-hidden" *ngIf="mapLoading">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<div #mapDiv [ngClass]="mapLoading ? 'd-none' : 'w-100 map-height'">
			<div class="w-100" id="map"></div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="row w-100 justify-content-center">
			<div>
				<button mat-raised-button class="btn-primary" (click)="modalService.dismissAll()">Dismiss Alert</button>
			</div>
		</div>
	</div>
</ng-template>


<ng-template #sweeper>
	<div class="modal-header bg-primary text-white">
		<h4 class="modal-title w-100 text-center" id="minesweeper-title">
			Minesweeper
		</h4>
	</div>
	<div class="modal-body">
		<mat-tab-group>
			<!-- Minesweeper Tab -->
			<mat-tab label="Minesweeper">
				<ng-template matTabContent>
					<div class="game-container">
						<app-minesweeper class="w-100 h-100"></app-minesweeper>
					</div>
				</ng-template>
			</mat-tab>

			<!-- Space Invaders Tab -->
			<mat-tab label="Phil Invaders">
				<ng-template matTabContent>
					<div class="game-container">
						<app-space-invaders class="w-100 h-100"></app-space-invaders>
					</div>
				</ng-template>
			</mat-tab>
			<mat-tab label="PacMan">
				<ng-template matTabContent>
					<div class="game-container">
						<app-pacman class="w-100 h-100"></app-pacman>
					</div>
				</ng-template>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
		<div class="row w-100 justify-content-center">
			<button mat-raised-button class="btn-secondary" (click)="closeMineSweeper()">Close</button>
		</div>
	</div>
</ng-template>

<ng-template #newOrders>
	<div class="container-fluid" *ngIf="pendingorders.length">
		<div class="row align-items-center mb-2">
			<div class="col-md-10">
				<h4 class="text-warning font-weight-bold">Pending Orders</h4>
			</div>
			<div class="col-md-2 text-right">
				<button mat-raised-button color="warn" (click)="toggleNewOrders()">Close</button>
			</div>
		</div>
		<div class="row mb-2">

			<div class="col-12 text-right">
				<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator>
			</div>
			<div class="col-12">
				<mat-form-field class="full-width" appearance="fill" color="accent">
					<mat-label>Search Orders</mat-label>
					<input type="text" placeholder="Search" matInput [formControl]="searchPending" (input)="searchOpenOrders($event.target.value)">
				</mat-form-field>
			</div>
		</div>
		<div class="table-responsive" *ngIf="pendingorders.length">
			<table class="table table-sm table-bordered table-hover">
				<thead class="thead-dark">
					<tr>
						<th class="text-left">Edit Order#</th>
						<th class="text-left">Customer</th>
						<th class="text-left">Address</th>
						<th class="text-center"></th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let o of dataObs | async">
						<tr class="table-light">
							<td [ngClass]="'placedfrom-' + o.placedfrom">
								<button mat-stroked-button color="primary" (click)="loadOrder(o.orderno)" matTooltip="Edit Order">
									{{ o.orderno }}
								</button>
							</td>
							<td [ngClass]="'placedfrom-' + o.placedfrom">
								<a [routerLink]="'/customers/view/' + o.debtorno" class="text-dark">{{ o.deliverto }}</a>
							</td>
							<td [ngClass]="'placedfrom-' + o.placedfrom">
								{{ o.deladd1 }}, {{ o.deladd3 }}
							</td>
							<td class="text-center" [ngClass]="'placedfrom-' + o.placedfrom">
								<!--<button mat-raised-button color="warn" (click)="pickOrder(o.orderno)" matTooltip="Pick Order">PICK</button>-->
								<!--<button mat-raised-button color="warn" (click)="checkOrder(o.orderno)" matTooltip="Check Order">CHECK</button>-->
								<button mat-raised-button color="accent" (click)="invoiceOrder(o.orderno)" matTooltip="Invoice Order">INVOICE</button>
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>




<ng-template #pickingOrders>
	<div class="row">
		<div class="col-md-10">
			<h3>Picking Orders</h3>
		</div>
		<div class="col-md-2 text-right">
			<button mat-raised-button [color]="'accent'" (click)="togglePickingOrders()" class="mt-3">Close</button>
		</div>
	</div>
	<div class="table table-fixed" *ngIf="pickingorders.length">
		<table class="table table-sm table-fixed">
			<thead>
				<tr>
					<th class="text-left">Order#</th>
					<th class="text-left">Customer</th>
					<th class="text-left">Deliver To</th>
					<th class="text-left">Address</th>
					<th class="text-left">City</th>
					<th class="text-center"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let o of pickingorders">
					<td>
						<button mat-raised-button (click)="loadOrder(o.orderno)">
							{{ o.orderno }}
						</button>
					</td>
					<td>
						<a [routerLink]="'/customers/view/'+o.debtorno">{{ o.debtorno }}.{{ o.branchcode }}</a>
					</td>
					<td>{{ o.deliverto }}</td>
					<td>{{ o.deladd1 }}</td>
					<td>{{ o.deladd3 }}</td>
					<td><button mat-raised-button (click)="checkOrder(o.orderno)">CHECK</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</ng-template>
<ng-template #pickedOrders>
	<div class="row">
		<div class="col-md-10">
			<h3>Picked Orders</h3>
		</div>
		<div class="col-md-2 text-right">
			<button mat-raised-button [color]="'accent'" (click)="togglePickedOrders()" class="mt-3">Close</button>
		</div>
	</div>
	<div class="table table-fixed" *ngIf="pickedorders.length">
		<table class="table table-sm table-fixed">
			<thead>
				<tr>
					<th class="text-left">Order#</th>
					<th class="text-left">Customer</th>
					<th class="text-left">Deliver To</th>
					<th class="text-left">Address</th>
					<th class="text-left">City</th>
					<th class="text-center"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let o of pickedorders">
					<td>
						<button mat-raised-button (click)="loadOrder(o.orderno)">
							{{ o.orderno }}
						</button>
					</td>
					<td>
						<a [routerLink]="'/customers/view/'+o.debtorno">{{ o.debtorno }}.{{ o.branchcode }}</a>
					</td>
					<td>{{ o.deliverto }}</td>
					<td>{{ o.deladd1 }}</td>
					<td>{{ o.deladd3 }}</td>
					<td><button mat-raised-button (click)="checkOrder(o.orderno)">CHECK</button>

					</td>
				</tr>
			</tbody>
		</table>
	</div>
</ng-template>
<ng-template #checkedOrders>
	<div class="row">
		<div class="col-md-10">
			<h3>Checked Orders</h3>
		</div>
		<div class="col-md-2 text-right">
			<button mat-raised-button [color]="'accent'" (click)="toggleCheckedOrders()" class="mt-3">Close</button>
		</div>
	</div>
	<div class="table table-fixed" *ngIf="invoiceorders.length">
		<table class="table table-sm table-fixed">
			<thead>
				<tr>
					<th class="text-left">Order#</th>
					<th class="text-left">Customer</th>
					<th class="text-left">Deliver To</th>
					<th class="text-left">Address</th>
					<th class="text-left">City</th>
					<th class="text-center"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let o of invoiceorders">
					<td>
						<button mat-raised-button (click)="loadOrder(o.orderno)">
							{{ o.orderno }}
						</button>
					</td>
					<td>
						<a [routerLink]="'/customers/view/'+o.debtorno">{{ o.debtorno }}.{{ o.branchcode }}</a>
					</td>
					<td>{{ o.deliverto }}</td>
					<td> {{ o.deladd1 }}</td>
					<td>{{ o.deladd3 }}</td>
					<td><button mat-raised-button (click)="invoiceOrder(o.orderno)">Invoice</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</ng-template>



<app-chat-sidebar *ngIf="right_sidebar"></app-chat-sidebar>
<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-top" [attr.data-background-color]="color">
	<div class="container-fluid">
		<div class="navbar-wrapper">
			<div class="navbar-minimize" (click)="miniSideBar()">
				<button mat-flat-button="" class="btn btn-just-icon btn-white btn-fab btn-round"><i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i><i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i></button>
			</div>
			<!-- <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}} <span *ngIf="idleState == 'TIMED_OUT'"> </span></a> -->
			<h4 class="mt-2">&nbsp;&nbsp;{{ getTitle() }}</h4>
		</div>


		<!-- <ng-container *ngIf="aiDataAvailable">
			<button class="floating-ai-button" (click)="openAIBottomSheet()">
				<mat-icon class="ai-icon">psychology</mat-icon>
			</button>
		</ng-container> -->



		<ng-container *ngIf="config.env.package != 'tires' && !this.user.user.issalesman">
			&nbsp;&nbsp;&nbsp;&nbsp;
			<ng-container *ngIf="pendingorders.length">
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow">
					<button (click)="toggleNewOrders()" class="bg-transparent border-0 noshadow">
						<!-- matTooltip="View Pending Orders" -->
						<mat-icon matBadgePosition="after" [matBadge]="pendingorders.length" matBadgeColor="accent" class="mr-3">notifications_active</mat-icon>
					</button>
				</div>
			</ng-container>
			<!-- &nbsp;&nbsp;
			<ng-container *ngIf="pickingorders.length" >
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow" >
					<button (click)="togglePickingOrders()" class="bg-transparent border-0 noshadow" matTooltip="View Picking Orders">
						<mat-icon matBadgePosition="after" [matBadge]="pickingorders.length" matBadgeColor="accent" class="mr-3">inventory</mat-icon>
					</button>
				</div>
			</ng-container>
			&nbsp;&nbsp;
			<ng-container *ngIf="pickedorders.length" >
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow" >
					<button (click)="togglePickedOrders()" class="bg-transparent border-0 noshadow" matTooltip="View Picked Orders">
						<mat-icon matBadgePosition="after" [matBadge]="pickedorders.length" matBadgeColor="accent" class="mr-3">check_circle</mat-icon>
					</button>
				</div>
			</ng-container>
			&nbsp;&nbsp;
			<ng-container *ngIf="invoiceorders.length" >
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow" >
					<button (click)="toggleCheckedOrders()" class="bg-transparent border-0 noshadow" matTooltip="View Checking">
						<mat-icon matBadgePosition="after" [matBadge]="invoiceorders.length" matBadgeColor="accent" class="mr-3">playlist_add_check</mat-icon>
					</button>
				</div>
			</ng-container> -->
		</ng-container>

		<button mat-flat-button class="navbar-toggler text-white" type="button" (click)="sidebarToggle()">
			<span class="sr-only text-white">Toggle navigation</span>
			<span class="navbar-toggler-icon icon-bar text-white"></span>
			<span class="navbar-toggler-icon icon-bar text-white"></span>
			<span class="navbar-toggler-icon icon-bar text-white"></span>
		</button>

		<div class="collapse navbar-collapse justify-content-end" id="navigation">
			<search></search>
			<ul class="navbar-nav d-none">
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)">
						<i class="material-icons">dashboard</i>
						<p><span class="d-lg-none d-md-block">Stats</span></p>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)">
						<i class="material-icons">person</i>
						<p>
							<span class="d-lg-none d-md-block">Account</span>
						</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</nav>