import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup,FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OrdersService } from '../../services/orders.service';
import { PurchasingService } from '../../services/purchasing.service';

import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-purchase-order-inquiry',
	templateUrl: './purchase-order-inquiry.component.html',
	styleUrls: ['./purchase-order-inquiry.component.scss']
})
export class PurchaseOrderInquiryComponent implements OnInit {
@ViewChild('orderView') orderStatusViewRef: ElementRef;
itemSearchRef = new FormControl('');
orderSearchRef = new FormControl('');
filterresults: any = [];
filteredItems: any = [];
customerData: any = [];
openOrderData: any = [];
orders: any = [];
orderSearch: any = [];
orderResults: any = false;
ordersFound: any = [];

	@Input() name: string;
	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	invoicelink: any = '';
	orderdetails: any = false;

	ngOnInit(): void {}

	constructor(private purchasingService: PurchasingService,private globalSearchService: GlobalSearchService, public router: Router, private ordersService: OrdersService,private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
		this.globalSearchService.mesagesallowed.next(false);

		this.pickinglink = this.config.apiServer.baseUrl + this.config.apiServer.pickingLink;
		this.invoicelink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;
	}

	viewVendor(supplierno: string) {
		this.router.navigate(['/vendors/'+supplierno]);
	}

	viewOrder(orderno: any) {

		this.ordersService.getOrder(orderno).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}


	clearForm() {

	//	this.customersearch.setValue('')
	}


	public searchOrders() {
		const keyword = this.orderSearchRef.value
		this.purchasingService.getOrderInqSearch(keyword).subscribe(r => {
			this.orderResults = r;
		})

	}

	public onInput(event: any, keys = 'orderno') {

		// this pushes the input value into the service's Observable.

		this.globalSearchService.searchTerm.next(event.target.value);


		const term = event.target.value;
		const allresults = [];
		if (this.openOrderData) {
			this.setFilteredLists(term);
			this.globalSearchService.itemSearch.next(this.ordersFound);
		}


	}

	getOrderedSum(order: any): number {
		let sum = 0;
		for (let i = 0; i < order.details.length; i++) {
			sum += Number(order.details[i].quantityord) || 0;
		}
		return sum;
	}

	getReceivedSum(order: any): number {
		let sum = 0;
		for (let i = 0; i < order.details.length; i++) {
			sum += Number(order.details[i].quantityrecd) || 0;
		}
		return sum;
	}

	getNetTotal(order: any): number {
		if (!order || !order.details || !order.details.length) {
		  return 0;
		}

		return order.details.reduce((total, item) => {
		  return total + (item.quantityrecd * item.unitprice);
		}, 0);
	  }

	  getPoTotal(order: any): number {
		if (!order || !order.details || !order.details.length) {
		  return 0;
		}

		return order.details.reduce((total, item) => {
		  return total + (item.quantityord * item.unitprice);
		}, 0);
	  }

	filterOrders(orders) {

		const allresults = [];

		if (this.openOrderData && this.orderSearch) {
			const openorders = this.filterItem(this.openOrderData, '-', 'ORDER_NUMBER');
			if (openorders) {
				openorders.forEach(function(value) {
					if (orders.includes(value.ORDER_NUMBER)) {

						allresults.push({ content: 'Open Order', details: value, link: '/orders/lookup/' + value.ORDER_NUMBER })
					}
				});


			}
		}

		this.setFiltered(allresults)

	}

	setFilteredLists(term) {

		const allresults = [];
		const ordersFound = [];
		if (this.openOrderData) {
			const openorders = this.filterItem(this.openOrderData, term, 'ADDRESS_1,CUST_NAME,ORDER_NUMBER,CUST_ORDER_NO_,DATE_ORDERED,DATE_WANTED,CUST_NUMBER,ITEM_NUMBER');
			if (openorders) {
				openorders.forEach(function(value) {
					allresults.push({ content: 'Open Order', details: value, link: '/orders/lookup/' + value.ORDER_NUMBER })
					ordersFound.push(value.ORDER_NUMBER)
				});
			}
		}

		this.ordersFound = ordersFound;


		this.setFiltered(allresults)

	}
	getValueByType(values: any , value: any) {
		const filtered = false;
		if(typeof values == 'object' ) {
			if(values) {
				if(values.length) {
					const newfilter = values.filter((r:any) => {
						return r.code == value
					})[0]

					if(newfilter) {
						return newfilter.text
					}
				}
			}
		}
		return 'N/A';
	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results) {
		this.filteredItems = results;
	}

	assignCopy(input) {
		this.filteredItems = Object.assign([], input);
	}

	filterItem(input, term, keys) {
		if (!term) {
			this.assignCopy(input);
		}
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}

	navTo(link) {

		this.router.navigate([link]);
	}
	back(): void {
		this.location.back()
	}

}
