import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { CustomerService } from '../../services/customer.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-customers-groups',
	templateUrl: './customers-groups.component.html',
	styleUrls: ['./customers-groups.component.scss']
})
export class CustomersGroupsComponent implements OnInit {
	lineEditRef: any;
	color = 'blue';
	categories: any = [];
	productlines: any = [];
	rebates: any = [];
	hazards: any = [];
	manufacturers: any = [];
	groupForm: UntypedFormGroup;
	search = new UntypedFormControl('');
	searchResults: any = [];
	searching: any = false;
	grouped: any = [];
	customergroups: any = [];
	constructor(private customerService: CustomerService, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private location: Location, public cdr: ChangeDetectorRef, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.groupForm = this.fb.group({
			group_id: [''],
			group_name: [''],
			members: ['', Validators.required],
		});

		this.customerService.getGroups().subscribe(results => {
			this.customergroups = results;
		});

		this.globalSearchService.aiDataAvailable$.next(null);
	}

	customerSearch() {
		const value = this.search.value;
		if (this.searching) {
			this.searching.unsubscribe();
		}
		this.searching = this.customerService.getCustomerSearch(value).subscribe((results: any) => {
			this.searchResults = results
		});
	}
	removeGroup(group: any) {

		this.customerService.removeGroup(group.group_id).subscribe(results => {
			this.customergroups = results;
		})
	}
	removeMember(customer: any, group: any) {
		const gindex = this.customergroups.indexOf(group);
		const cindex = this.customergroups[gindex].members.indexOf(customer);
		this.customergroups[gindex].members.splice(cindex, 1)
		this.customerService.removeGroupMember(customer.member_id).subscribe(results => {
			this.customergroups = results;
		})
	}

	removeCustomer(c: any) {
		const index = this.grouped.indexOf(c);
		this.grouped.splice(index, 1);
	}

	addCustomer(customer: any) {

		const member = {
			debtorno: customer.debtorno,
			branch_code: customer.branchcode,
			is_primary: (this.grouped.length) ? 0 : 1,
			name: customer.name,
			member_id: '',
		};

		if (this.grouped.indexOf(member) < 0) {
			this.searchResults = false;
			this.grouped.push(member);
		}
		this.search.setValue('');
		this.groupForm.get('members').setValue(this.grouped);
	}

	addCategory() {

	}

	editGroup(g: any) {

		this.groupForm = this.fb.group({
			group_id: [g.group_id],
			group_name: [g.group_name],
			members: [g.members, Validators.required],
		});

		this.grouped = g.members;
		this.groupForm.get('members').setValue(g.members);

	}

	addGroup() {
		this.customerService.saveGroup(this.groupForm.value).subscribe(results => {
			this.customergroups = results;
			this.grouped = [];
		})
	}

	ngAfterViewInit() {

	}

	back(): void {
		this.location.back()
	}

}
