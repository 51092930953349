<div class="main-content">
    <div class="card">
        <div class="card-header card-header-{{color}}-6 row">
            <i class="material-icons cursor" (click)="back()">arrow_left</i>
			<h4>Item Detail Report</h4>
        </div>
        <div class="card-body">

            <ng-container *ngIf="filtersReady">
                <form [formGroup]="itemForm" *ngIf="itemForm" class="mt-4">
                    <div class="controls-wrapper">
                        <div class="row p-0 m-0">
                            <div class="col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Locations</mat-label>
                                    <mat-select formControlName="locs" multiple #locations>
                                        <div class="select-all" (click)="selectAllToggle(locations)">
                                            <button mat-icon-button>
                                                <span ml="1">Select All/None</span>
                                            </button>
                                        </div>
                                        <mat-option *ngFor="let loc of locationList"
                                            [value]="loc['loccode']">{{loc['locationname']}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 m-auto ">
                                <mat-form-field appearance="outline">
                                    <mat-label>Salesman</mat-label>
                                    <mat-select formControlName="sales" multiple #salesman>
                                        <div class="select-all" (click)="selectAllToggle(salesman)">
                                            <button mat-icon-button>
                                                <span ml="1">Select All/None</span>
                                            </button>
                                        </div>
                                        <mat-option *ngFor="let salesman of salesmanList"
                                            [value]="salesman['salesmancode']">{{salesman['salesmanname']}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>From</mat-label>
                                    <input matInput [matDatepicker]="fromdatepicker" formControlName="fromdate">
                                    <mat-datepicker-toggle matSuffix [for]="fromdatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #fromdatepicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>To</mat-label>
                                    <input matInput [matDatepicker]="todatepicker" formControlName="todate">
                                    <mat-datepicker-toggle matSuffix [for]="todatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #todatepicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <div class="button-wrapper">
                                    <div *ngIf="sending">
                                        <svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round"
                                                cx="33" cy="33" r="30">
                                            </circle>
                                        </svg>
                                    </div>
                                    <span>
                                        <h4></h4>
                                    </span>
                                    <ng-container *ngIf="!sending">
                                        <button mat-raised-button (click)="exportPdf(pdfTable)"
                                            class="btn-sm btn-danger" [disabled]="!results" style="margin-right: 5px;">
                                            <i class="fa fa-file-pdf-o"></i> PDF
                                        </button>
                                        <button mat-raised-button (click)="exportXls(xlsxTable)"
                                            class="btn-sm btn-success" [disabled]="!results" style="margin-right: 5px;">
                                            <i class="fa fa-file-excel-o"></i> XLS
                                        </button>
                                    </ng-container>
                                    <button mat-raised-button (click)="getIDET()" [disabled]="prods.length == 0"
                                        *ngIf="!sending">Run Report</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: -10px;">
                            <div class="col-md-2 m-auto d-flex justify-content-center ">
                                <mat-slide-toggle (change)="allowDiscontinue($event)">Include
                                    Discontinued?</mat-slide-toggle>
                            </div>
                            <div class="col-md-10">
                                <mat-form-field class="">
                                    <mat-chip-list #chipList aria-label="Product selection">
                                        <mat-chip *ngFor="let prod of prods" [selectable]="selectable"
                                            [removable]="removable" (removed)="remove(prod)">
                                            {{prod}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="Add a product..." #prodInput [formControl]="prodCtrl"
                                            [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                        <mat-option *ngFor="let prod of filteredProds | async" [value]="prod.stockid" >
                                            {{prod.stockid}} &nbsp;: {{prod.description}} &nbsp; 
                                            <ng-container *ngIf="prod.discontinued == 1">
                                                <div class="row align-middle pull-right float-right mr-2">
                                                    <p class="italics fs-small">Discontinued</p>
                                                </div>
                                            </ng-container>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                    <div style="margin-right: 30px; margin-left: 30px; border: 1px dotted gray; border-radius: 5px"
                        class='mat-elevation-z2' [hidden]="!results || sending">
                        <div #exportTable>
                            <ng-container *ngFor="let res of results | keyvalue">
                                <table class="table table-sm table-fixed mb-0 w-100">
                                    <thead>
                                        <tr class="header-template">
                                            <th colspan="11" class="text-left"><b>Stock ID</b></th>
                                        </tr>
                                        <tr>
                                            <td colspan="11"><b>{{res.key}}</b> &nbsp;- <span
                                                    style="font-weight: 400;">{{prodDescriptions[res.key]}}</span></td>
                                        </tr>
                                        <tr class="body-template d-contents tablebody">
                                            <th class="w-5"><b>Invoice</b></th>
                                            <th class=""><b>Date</b></th>
                                            <th class="w-5"><b>Cust#</b></th>
                                            <th class="w-20"><b>Name</b></th>
                                            <th class=""><b>Location</b></th>
                                            <th class=""><b>Salesperson</b></th>
                                            <th class=""><b>Salesperson#</b></th>
                                            <th class="text-center"><b>QTY</b></th>
                                            <th class=""><b>Price</b></th>
                                            <th class=""><b>Discount%</b></th>
                                            <th class=""><b>Extension</b></th>
                                        </tr>
                                    </thead>
                                    <ng-container *ngIf="res.value.length; else nohist" class="tablebody">
                                        <tr *ngFor="let r of res.value">
                                            <td class="text-left w-5">{{ r.transno }}</td>
                                            <td class="">{{r.trandate | date:'M/d/YYYY'}}</td>
                                            <td class="w-5">{{ r.customerno }}</td>
                                            <td class="w-20">{{ r.customername }}</td>
                                            <td class=""> {{ r.Location }} </td>
                                            <td class="">{{ r.Salesperson }}</td>
                                            <td class="">{{ r.Salespersoncode }}</td>
                                            <td class="text-center ">{{ r.QTY }}</td>
                                            <td class="">{{ r.Price | currency}}</td>
                                            <td class="">{{ r['Discount %'] | percent}}</td>
                                            <td class="">{{ r.Extension | currency }}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #nohist>
                                        <tr>
                                            <td class="w-5">-</td>
                                            <td class="">-</td>
                                            <td class="w-5">-</td>
                                            <td class="w-20">-</td>
                                            <td class="">-</td>
                                            <td class="">-</td>
                                            <td class="">-</td>
                                            <td class="text-center">-</td>
                                            <td class="">-</td>
                                            <td class="">-</td>
                                            <td class="text-center">-</td>
                                        </tr>
                                    </ng-template>
                                    <tr class="rollup-template">
                                        <th class="text-left w-5"></th>
                                        <th class=""></th>
                                        <th class="w-5"></th>
                                        <th class="w-20"></th>
                                        <th class=""></th>
                                        <th class=""></th>
                                        <th class=""></th>
                                        <th class="text-center"><b>Total QTY</b></th>
                                        <th class=""></th>
                                        <th class=""></th>
                                        <th class=""><b>Total Ext.</b></th>
                                    </tr>

                                    <tr *ngIf="res.value.length; else norollup">
                                        <td class="w-5"></td>
                                        <td class=""></td>
                                        <td class="w-5"></td>
                                        <td class="w-20"></td>
                                        <td class=""></td>
                                        <td class=""></td>
                                        <td class=""></td>
                                        <td class="text-center ">{{rollupQtys[res.key]}}</td>
                                        <td class=""></td>
                                        <td class=""></td>
                                        <td class="">{{ rollupExts[res.key] | currency }}</td>
                                    </tr>
                                    <ng-template #norollup>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style="text-align: center;">-</td>
                                            <td></td>
                                            <td></td>
                                            <td style="text-align: center;">-</td>
                                        </tr>
                                    </ng-template>
                                </table>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>



        <div #pdfTable style="display: none;">
            <table style="width: 100%; border-collapse: collapse;" *ngIf="itemForm">
                <tr>
                    <td style="background-color: rgb(255, 255, 255); font-size: 20px;">&nbsp;</td>
                </tr>
                <tr>
                    <td style="background-color: rgb(255, 255, 255); font-size: 20px;">
                        <B>{{exportHeaderGen('title')}}</B></td>
                </tr>
                <tr>
                    <td>
                        <h3>{{exportHeaderGen('locations')}}</h3>
                        <h3>{{exportHeaderGen('salesmen')}}</h3>
                    </td>
                </tr>
            </table>
            <ng-container *ngFor="let res of results | keyvalue">
                <table class="table table-sm table-fixed mb-0 w-100" style="border-collapse: collapse; width: 100%">
                    <thead>
                        <tr class="header-template" style="color: white;">
                            <th colspan="13" style="background-color: rgb(0, 53, 94); text-align: left;"><b>Stock ID</b>
                            </th>
                        </tr>
                        <tr>
                            <td colspan="13"><b>{{res.key}}</b> &nbsp;- <span
                                    style="font-weight: 400;">{{prodDescriptions[res.key]}}</span></td>
                        </tr>
                        <tr class="body-template d-contents tablebody" style="color: white;">
                            <th style="background-color: rgb(54, 75, 138);"><b>Invoice</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Date</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Stockid</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Desc.</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Cust#</b></th>
                            <th style="background-color: rgb(54, 75, 138); text-align: left;"><b>Name</b></th>
                            <th style="background-color: rgb(54, 75, 138); text-align: left;"><b>Location</b></th>
                            <th style="background-color: rgb(54, 75, 138); text-align: left;"><b>Salesperson</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Salesperson#</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>QTY</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Price</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Discount%</b></th>
                            <th style="background-color: rgb(54, 75, 138);"><b>Extension</b></th>
                        </tr>
                    </thead>
                    <ng-container *ngIf="res.value.length; else nohist" class="tablebody">
                        <tr *ngFor="let r of res.value">
                            <td style="text-align: center;">{{ r.transno }}</td>
                            <td style="text-align: center;">{{r.trandate | date:'M/d/YYYY'}}</td>
                            <td style="text-align: center;">{{res.key}}</td>
                            <td style="text-align: center;">{{prodDescriptions[res.key]}}</td>
                            <td style="text-align: center;">{{ r.customerno }}</td>
                            <td style="text-align: left;">{{ r.customername }}</td>
                            <td style="text-align: left"> {{ r.Location }} </td>
                            <td style="text-align: left">{{ r.Salesperson }}</td>
                            <td style="text-align: center">{{ r.Salespersoncode }}</td>
                            <td style="text-align: center;">{{ r.QTY }}</td>
                            <td class="">{{ r.Price | currency}}</td>
                            <td style="text-align: right;">{{ r['Discount %'] | percent}}</td>
                            <td style="text-align: right">{{ r.Extension | currency }}</td>
                        </tr>
                    </ng-container>
                    <ng-template #nohist>
                        <tr>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                            <td style="text-align: center;">-</td>
                        </tr>
                    </ng-template>
                    <tr class="rollup-template" style="border-collapse: collapse;">
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247); text-align: center;">
                            <b>Total QTY</b></th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247);">
                        </th>
                        <th
                            style="border-top: 1px dotted rgb(85, 83, 83); border-bottom: 1px dotted rgb(85, 83, 83); background-color: rgb(247, 247, 247); text-align: right;">
                            <b>Total Ext.</b></th>
                    </tr>

                    <tr *ngIf="res.value.length; else norollup">
                        <td style=""></td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style="text-align: center;">{{rollupQtys[res.key]}}</td>
                        <td style=""></td>
                        <td style=""></td>
                        <td style="text-align: right;">{{ rollupExts[res.key] | currency }}</td>
                    </tr>
                    <ng-template #norollup>
                        <tr>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;">-</td>
                            <td style=""></td>
                            <td style=""></td>
                            <td style="text-align: center;">-</td>
                        </tr>
                    </ng-template>
                </table>
            </ng-container>
        </div>

        <div #xlsxTable style="display: none;">
            <ng-container *ngFor="let res of results | keyvalue">
                <table class="table table-sm table-fixed mb-0 w-100">
                    <thead>
                        <tr class="header-template">
                            <th colspan="13"><b>Stock ID</b></th>
                        </tr>
                        <tr>
                            <td colspan="13"><b>{{res.key}}</b> &nbsp;- {{prodDescriptions[res.key]}}</td>
                        </tr>
                        <tr class="body-template d-contents tablebody">
                            <th class=""><b>Invoice</b></th>
                            <th class=""><b>Date</b></th>
                            <th class=""><b>Stockid</b></th>
                            <th class=""><b>Desc.</b></th>
                            <th class=""><b>Cust#</b></th>
                            <th class=""><b>Name</b></th>
                            <th class=""><b>Location</b></th>
                            <th class=""><b>Salesperson</b></th>
                            <th class=""><b>Salesperson#</b></th>
                            <th class=""><b>QTY</b></th>
                            <th class=""><b>Price</b></th>
                            <th class=""><b>Discount%</b></th>
                            <th class=""><b>Extension</b></th>
                        </tr>
                    </thead>
                    <ng-container *ngIf="res.value.length; else nohist" class="tablebody">
                        <tr *ngFor="let r of res.value">
                            <td class="text-left w-5">{{ r.transno }}</td>
                            <td class="">{{r.trandate | date:'M/d/YYYY'}}</td>
                            <td class="">{{res.key}}</td>
                            <td class="">{{prodDescriptions[res.key]}}</td>
                            <td class="w-5">{{ r.customerno }}</td>
                            <td class="w-20">{{ r.customername }}</td>
                            <td class=""> {{ r.Location }} </td>
                            <td class="">{{ r.Salesperson }}</td>
                            <td class=""><b>{{ r.Salespersoncode }}</b></td>
                            <td class=""><b>{{ r.QTY }}</b></td>
                            <td class="">{{ r.Price | currency}}</td>
                            <td class="">{{ r['Discount %']}}</td>
                            <td class="">{{ r.Extension | currency }}</td>
                        </tr>
                    </ng-container>
                    <ng-template #nohist>
                        <tr>
                            <td class="w-5">-</td>
                            <td class="">-</td>
                            <td class="w-5">-</td>
                            <td class="w-20">-</td>
                            <td class="">-</td>
                            <td class="text-center ">-</td>
                            <td class="">-</td>
                            <td class="">-</td>
                            <td class="">-</td>
                            <td class="">-</td>
                            <td class="">-</td>
                            <td class="">-</td>
                            <td class="">-</td>
                        </tr>
                    </ng-template>
                    <tr class="rollup-template">
                        <th class="text-left w-5"></th>
                        <td class=""></td>
                        <th class="w-5"></th>
                        <th class="w-20"></th>
                        <th class=""></th>
                        <th class=""></th>
                        <th class=""></th>
                        <th class=""></th>
                        <th class=""></th>
                        <th class="text-center "><b>Total QTY</b></th>
                        <th class=""></th>
                        <th class=""></th>
                        <th class=""><b>Total Ext.</b></th>
                    </tr>

                    <tr *ngIf="res.value.length; else norollup">
                        <td class="w-5"></td>
                        <td class=""></td>
                        <td class="w-5"></td>
                        <td class="w-20"></td>
                        <td class=""></td>
                        <td class=""></td>
                        <td class=""></td>
                        <td class=""></td>
                        <td class=""></td>
                        <td class="text-center ">{{rollupQtys[res.key]}}</td>
                        <td class=""></td>
                        <td class=""></td>
                        <td class="">{{ rollupExts[res.key] | currency }}</td>
                    </tr>
                    <ng-template #norollup>
                        <tr>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;"></td>
                            <td style="text-align: center;">-</td>
                            <td style=""></td>
                            <td style=""></td>
                            <td style="text-align: center;">-</td>
                        </tr>
                    </ng-template>
                    <!-- </thead> -->
                </table>
            </ng-container>
        </div>

    </div>
</div>