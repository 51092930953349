<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							Account Groups
						</h4>
					</div>
					<div class="card-body">
						<!-- Add New Group Button -->
						<div class="text-end mb-3">
							<button mat-raised-button color="accent" (click)="toggleForm()">
								{{ isFormVisible ? 'Hide Form' : 'Add New Group' }}
							</button>
						</div>
						<div class="row mb-3">
							<div class="col-md-12">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Search Groups</mat-label>
									<input matInput (keyup)="applySearch($event)" placeholder="Search by Group Name" />
									<mat-icon matSuffix>search</mat-icon>
								</mat-form-field>
							</div>
							<!-- <div class="col-md-6">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Filter by Account Section</mat-label>
									<mat-select (selectionChange)="applySectionFilter($event.value)">
										<mat-option value="">All Sections</mat-option>
										<mat-option *ngFor="let section of sections" [value]="section.sectionid">
											{{ section.sectionname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div> -->
						</div>
						<!-- Add New Group Form -->
						<tr *ngIf="!editingGroup && isFormVisible">
							<td colspan="6">
								<form [formGroup]="groupForm" (ngSubmit)="saveGroup()" class="p-4 border rounded bg-light">
									<div class="row g-4">
										<div class="col-md-6">
											<mat-form-field appearance="outline" class="w-100">
												<mat-label>Group Name</mat-label>
												<input matInput formControlName="groupname" placeholder="Group Name" required />
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline" class="w-100">
												<mat-label>Account Section</mat-label>
												<mat-select formControlName="sectioninaccounts">
													<mat-option *ngFor="let section of sections" [value]="section.sectionid">
														{{ section.sectionname }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline" class="w-100">
												<mat-label>Profit / Loss or Balance Sheet</mat-label>
												<mat-select formControlName="pandl">
													<mat-option value="0">Balance Sheet</mat-option>
													<mat-option value="1">Profit and Loss</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline" class="w-100">
												<mat-label>Trial Balance Sequence</mat-label>
												<input matInput formControlName="sequenceintb" placeholder="Trial Balance Sequence" required />
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline" class="w-100">
												<mat-label>Parent Group</mat-label>
												<mat-select formControlName="parentgroupname">
													<mat-option value=""></mat-option>
													<mat-option *ngFor="let group of groups" [value]="group.groupname">
														{{ group.groupname }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>
									<div class="text-end mt-3">
										<button mat-raised-button color="accent" type="submit" [disabled]="groupForm.invalid">
											Add Group
										</button>
										<button mat-raised-button color="warn" type="button" (click)="cancelEdit()">
											Cancel
										</button>
									</div>
								</form>
							</td>
						</tr>

						<!-- Groups Table -->
						<div class="table-responsive mt-4">
							<table class="table table-striped table-hover table-bordered">
								<thead class="thead-dark">
									<tr>
										<th class="text-center">Group Name</th>
										<th class="text-center">Account Section</th>
										<th class="text-center">P/L or Balance Sheet</th>
										<th class="text-center">Trial Balance Sequence</th>
										<th class="text-center">Parent Group</th>
										<th class="text-center"></th>
									</tr>
								</thead>
								<tbody>
									<!-- Groups Data -->
									<ng-container *ngFor="let group of filteredGroups">
										<tr>
											<td class="text-center">{{ group.groupname }}</td>
											<td class="text-center">{{ sectionName(group.sectioninaccounts) }}</td>
											<td class="text-center">{{ group.pandl }}</td>
											<td class="text-center">{{ group.sequenceintb }}</td>
											<td class="text-center">{{ group.parentgroupname }}</td>
											<td class="text-center">
												<button mat-icon-button color="primary" (click)="editGroup(group)" matTooltip="Edit">
													<mat-icon>edit</mat-icon>
												</button>
												<button mat-icon-button color="warn" (click)="deleteGroup(group.groupname)" matTooltip="Delete">
													<mat-icon>delete</mat-icon>
												</button>
											</td>
										</tr>
										<tr *ngIf="editingGroup?.groupname === group.groupname">
											<td colspan="6">
												<form [formGroup]="groupForm" (ngSubmit)="saveGroup()" class="p-4 border rounded bg-light">
													<div class="row g-4">
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Group Name</mat-label>
																<input matInput formControlName="groupname" placeholder="Group Name" required />
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Account Section</mat-label>
																<mat-select formControlName="sectioninaccounts">
																	<mat-option *ngFor="let section of sections" [value]="section.sectionid">
																		{{ section.sectionname }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Profit / Loss or Balance Sheet</mat-label>
																<mat-select formControlName="pandl">
																	<mat-option value="0">Balance Sheet</mat-option>
																	<mat-option value="1">Profit and Loss</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Trial Balance Sequence</mat-label>
																<input matInput formControlName="sequenceintb" placeholder="Trial Balance Sequence" required />
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Parent Group</mat-label>
																<mat-select formControlName="parentgroupname">
																	<mat-option value=""></mat-option>
																	<mat-option *ngFor="let group of groups" [value]="group.groupname">
																		{{ group.groupname }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
													<div class="text-end mt-3">
														<button mat-raised-button color="accent" type="submit" [disabled]="groupForm.invalid">
															Update Group
														</button>
														<button mat-raised-button color="warn" type="button" (click)="cancelEdit()">
															Cancel
														</button>
													</div>
												</form>
											</td>
										</tr>
									</ng-container>

									<!-- No Data Row -->
									<tr *ngIf="!filteredGroups || filteredGroups.length === 0">
										<td class="text-center" colspan="6">No groups found.</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
