import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit, Directive, HostListener } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable, Subscription, forkJoin } from 'rxjs';
import { take, debounceTime, finalize } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from 'file-saver';
import { UsersService } from '../../services/users.service';


@Component({
	selector: 'app-pos-order-invoice',
	templateUrl: './pos-order-invoice.component.html',
	styleUrls: ['./pos-order-invoice.component.scss'],

})
export class PosOrderInvoiceComponent {

	@Input() orderno: any = false;
	@Input() reload: any = false;
	@Input() ctlordirect: any = 'ctl';
	@Output() invoiced = new EventEmitter < any > ();
	@Output() forceReset = new EventEmitter < any > ();
	@Output() reloadCustomer = new EventEmitter < any > ();

	debounceClick$ = new Subject < void > ();
	paymentSubscription: Subscription;
	invoice_result: any = false;
	document_text = 'Invoice';
	document_type = '30';
	documentDetails: FormGroup;
	fupdate: any = false;

	order: any = {
		header: [],
		details: [],
		tax_data: {},
	};

	//orderno: any = '';
	debtorno: any = '';
	branchcode: any = '';
	reprintlink: any = '';
	dispatchlink: any = '';
	dispatching = false;
	addingpay = false;
	dispatch_details: any = [];
	//container for specifics about taxes.
	taxes: any = [];

	freight_tax_total = 0;
	freight_charge = 0.00;
	payment_total = 0.00
	freight_taxes: any = []
	display_total: any = '';

	payments_added: any = [];

	headercolumns = [
		'stockid',
		'quantity',
		'units'
	];

	totals = {
		subtotal: 0.00,
		freight: 0.00,
		tax: 0.00,
		discount: 0.00,
		total: 0.00,
	};

	taxdatatotal = 0;

	prefered_vendor: any = [];
	payments: any = [];
	color = 'blue'
	sending = false;
	config: any = false;
	baseUrl = '';
	invoiceLink = '';
	terms: any = false;
	salespeople: any = false;
	shipvias: any = false;
	document_types = [
		{ label: 'Back Order', value: 'backorder' },
		{ label: 'Cancel', value: 'cancel' },
	];

	doc_return_type = 'Item Balance';
	tax_details = [];
	isposuser = false;
	placedfrom = 2;

	constructor(private usersService: UsersService, private fb: FormBuilder, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private paymentsService: PaymentsService) {

		this.color = this.globalSearchService.getColor();
		this.baseUrl = this.globalSearchService.config.apiServer.baseUrl;
		this.invoiceLink = this.globalSearchService.config.apiServer.invoiceLink;
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	ngOnInit(): void {


		forkJoin({
			salespeople: this.globalSearchService.salespeople,
			terms: this.globalSearchService.terms,
			shipvia: this.globalSearchService.shipvia
		}).subscribe((results: any) => {
			// Now you have all results in parallel
			this.salespeople = results.salespeople;
			this.terms = results.terms;
			this.shipvias = results.shipvia;
		});
		this.debounceClick$
			.pipe(debounceTime(500))
			.subscribe(() => this.createInvoice());
	}
	onClick() {
		this.debounceClick$.next();
	}

	cancelOrder(orderno: any) {
		const confirm_cancel = confirm('Cancel this order?');
		if (confirm_cancel) {
			this.orderService.cancelOrder(orderno).subscribe(async (results: any) => {
				this.router.navigate(['/counter-pos']);
			});
		}
	}

	getTotalDispatching() {
		let value = 0;
		if (this.order.details) {
			value = this.order.details.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.dispatch);
			}, 0);
		}

		return value;
	}

	getTotalFromSavedTotals(saved_totals: any[]): number | null {
		if (saved_totals) {
			for (const item of saved_totals) {
				if (item) {
					if (item.code === "total") {
						return parseFloat(item.text);
					}
				}
			}
		}
		return null; // Return null if no "total" code is found
	}

	getBalance() {
		if (this.totals) {
			let total = this.totals.total;
			if (this.order) {
				total = this.getTotalFromSavedTotals(this.order.saved_totals);
			}
			return (this.totals) ? this.financial(total - this.payment_total) : 0.00;
		}
	}

	createInvoice() {
		if (this.sending) {
			return;
		}

		this.updateTotals();

		if (this.order && this.documentDetails.valid) {
			const data = {
				order: this.order,
				totals: this.totals,
				payments: this.payments_added,
				taxes: this.order.tax_data,
				document_form: this.documentDetails.value,
				placedfrom: this.placedfrom
			}

			this.sending = true;

			this.orderService.createInvoice(data)
				.pipe(
					take(1),
					finalize(() => {
						// Reset sending flag no matter what happens
						this.sending = false;
					})
				)
				.subscribe(
					(results: any) => {
						if (results.success) {
							this.invoiced.emit(results);
							this.reloadCustomer.emit(true);
							if (this.ctlordirect == 'direct') {
								this.invoice_result = false;
								this.forceReset.emit(true);
								this.clearCart();

								this.usersService.isPosUser()
									.pipe(take(1))
									.subscribe((isPOS) => {
										const route = isPOS ?
											`/pos/success/${results.transno}` :
											`/orders/success/${results.id}`;
										this.router.navigate([route]);
									});
							}
						} else {
							alert(results.message);
						}
					},
					(error) => {
						console.error('Error creating invoice:', error);
					}
				);
		} else {
			if (!this.documentDetails.valid) {
				this.globalSearchService.getFormValidationErrors(this.documentDetails);
			}
		}
	}




	clearCart() {
		this.orderService.clearOrder(this.order.header.debtorno, this.order.header.branchcode).subscribe((results: any) => {});
	}

	termRequiresPayment() {
		if (this.documentDetails?.get('terms')?.value && this.terms?.length) {
			const term = this.terms.find(r => r.termsindicator === this.documentDetails.get('terms').value);
			return term ? term.collect_payment === '1' : false;
		}

		return false;
	}


	updateDispatchQty(event: any, item: any) {

		// if (parseFloat(event.target.value) < 0) {
		// 	event.target.value = 0;
		// }

		if (parseFloat(item.ordered) >= parseFloat(event.target.value)) {
			item.dispatch = parseFloat(event.target.value);
		} else {

			event.target.value = parseFloat(item.ordered);
			item.dispatch = parseFloat(item.ordered)
		}

		this.updateTotals()
	}

	setQty(event: any, item: any) {
		if (parseFloat(item.ordered) < parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {
			item.dispatch = item.ordered;
		}

		this.updateTotals()
	}

	updatePayments(event: any) {

		if (event) {
			this.payments_added = event;
			this.payment_total = this.payments_added.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0);
		}
		this.getPay();
	}

	getPay() {

		const data = {
			orderno: this.order.header.orderno,
			debtorno: this.order.header.debtorno,
			branchcode: this.order.header.branchcode,
		}

		if (this.paymentSubscription) {
			this.paymentSubscription.unsubscribe();
		}

		// Assign the new subscription
		this.paymentSubscription = this.paymentsService.getOrderPayments(data)
			.pipe(take(1)) // Automatically completes after first emission
			.subscribe((results: any) => {

				this.payments = results;
				this.updateTotals();
				this.payment_total = this.financial(results.reduce((accumulator, item) => {
					return parseFloat(accumulator) + parseFloat(item.amount);
				}, 0));
			});
	}

	setFreightCharge(event: any) {

		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			if (!(event.target.value.trim() === '.')) {}
		} else {

			this.order.header.freightcost = parseFloat(event.target.value)
			this.freight_charge = this.order.header.freightcost;
			this.updateTotals();
		}
	}

	setFreightTax(event: any, tax: any) {
		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			// If it's NaN or less than 0, set it to 0 unless the last character is a period
			if (!(event.target.value.trim() === '.')) {
				event.target.value = 0;
			}
		}

		tax.taxrate = parseFloat(event.target.value);
		this.updateTotals();
	}

	setTax(event: any, item: any, taxindex: any) {
		// Parse event.target.value as a number
		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			// If it's NaN or less than 0, set it to 0 unless the last character is a period
			if (!(event.target.value.trim() === '.')) {
				event.target.value = 0;
			}
		}

		// Update tax rate with the sanitized value
		item.taxes[taxindex].taxrate = parseFloat(event.target.value);

		// Update totals
		this.updateTotals();
	}

	getFreightTax() {

		const total = 0
		this.freight_tax_total = 0;
		const freight = this.freight_charge

		let freighttax = 0;
		if (this.order) {
			const taxauths = [];
			this.freight_taxes = this.order.freight_tax;

			//by auth
			if (this.order.freight_tax) {
				this.order.freight_tax.forEach((tax, index) => {

					const taxrate = this.roundToDecimalPlaces(tax.taxrate / 100, 6);
					const taxvalue = this.roundToDecimalPlaces(taxrate * freight, 2);
					taxauths.push({ taxauthid: tax.taxauthid, value: taxvalue })

				});

				this.freight_taxes = taxauths;
				//total freight tax
				freighttax = this.order.freight_tax.reduce(function(accumulator, tax) {
					return parseFloat(accumulator) + ((tax.taxrate / 100) * freight);
				}, 0);
			}
		}

		this.freight_tax_total = freighttax;
		return freighttax;
	}

	updateTotals() {

		//todo clean this up. requires two containers..
		//parse items subtotals / taxes first.
		this.totals = {
			subtotal: 0.00,
			freight: 0.00,
			tax: 0.00,
			discount: 0.00,
			total: 0.00,
		};
		this.totals.total = 0;
		let totaltax = 0;
		//zero out tax containers
		const parent = this;
		if (this.order.tax_data && this.order.tax_data.authorities) {
			this.order.tax_data.authorities.forEach((item, index) => {
				this.order.tax_data.authorities[index].total = 0;
			});
		}

		const freighttax = this.getFreightTax()
		//add freight tax to tax total container
		if (this.freight_taxes) {
			this.freight_taxes.forEach((fghtax) => {
				this.order.tax_data.authorities.forEach((tax, index) => {
					if (tax.taxauthid == fghtax.taxauthid) {
						const value = parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
						if (value) {
							this.order.tax_data.authorities[index].total += parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
						}
					}
				})
			})
		}

		if (this.order.details && this.order.details.length) {
			this.order.details.forEach((item, index) => {
				//subtotals
				const discountprice = item.unitprice - parent.financial((item.discountpercent * item.unitprice));

				const subtotal = parent.financial(discountprice * item.dispatch);

				this.order.details[index].linesubtotal = subtotal;

				const parentindex = index;
				//this.order.details[index].taxtotal = 0;
				//item tax breakdown
				if (this.order.tax_data.authorities.length > 0) {
					item.taxes.forEach((tax, subindex) => {

						const thistax = this.order.tax_data.authorities.filter(all => all.taxauthid == tax.taxauthid)[0];
						if (thistax) {
							//!! LET PHP HANDLE THE ROUNDING
							const tindex = this.order.tax_data.authorities.indexOf(thistax);
							const authtax = (tax.taxrate / 100 * subtotal);
							if (authtax) {
								if (this.order.tax_data.authorities[tindex]) {
									this.order.tax_data.authorities[tindex].total += authtax;
									this.order.details[parentindex].taxes[subindex].total = authtax;
								}
							} else {
								if (this.order.tax_data.authorities[tindex]) {
									this.order.tax_data.authorities[tindex].total += 0.00;
									this.order.details[parentindex].taxes[subindex].total = 0.00;
								}
							}
						}
					});
				}
				//after
				const total_linetax = item.taxes.reduce(function(accumulator, line) {
					return accumulator + parent.financial(((line.taxrate * subtotal) /100));
				}, 0);

				this.order.details[index].taxtotal = (total_linetax);
				this.order.details[index].linetotal = (total_linetax + subtotal);

				totaltax += total_linetax;
			});

			this.totals.subtotal = this.order.details.reduce(function(accumulator, line) {
				//this financial not avail in reduce
				const discountpriced = parent.financial(line.discountpercent * line.unitprice)
				//incorect
				const discountprice = line.unitprice - discountpriced;
				const subtotal = parent.financial(discountprice * line.dispatch);

				return accumulator + subtotal;
			}, 0);

			this.taxdatatotal = this.order.tax_data.authorities.reduce(function(accumulator, line) {
				return accumulator + (line.total);
			}, 0);

			//total tax includes freight tax
			this.totals.tax = this.financial(totaltax) + this.financial(freighttax);
			this.totals.freight = this.freight_charge;
			this.totals.total = this.totals.subtotal + this.totals.freight + this.totals.tax + this.totals.discount;
		}
	}

	roundToDecimalPlaces(num: number, decimals: number): number {
		const multiplier = Math.pow(10, decimals);
		return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
	}


	financial(num: number): number {
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100; // Rounding to 2 decimal places again
		return parseFloat(rounded.toFixed(2));
	}


	santizeNumberValue(input: any) {
		return (Number.isNaN(input) || input == '') ? 0 : this.financial(input);
	}

	back(): void {
		this.location.back()
	}

	loadOrder() {

		this.order = {
			header: [],
			details: [],
			tax_data: {},
		};

		// Automatically complete after first emission
		this.orderService.getOrder(this.orderno)
			.pipe(take(1))
			.subscribe((details) => {

				this.order = details;

				// Handle freight charge logic
				if (this.order.header.orderstatusid == '51') {
					this.freight_charge = 0.00;
					this.order.header.freightcost = 0.00;
				} else {
					this.freight_charge = parseFloat(this.order.header.freightcost);
				}

				this.debtorno = this.order.header.debtorno;
				this.branchcode = this.order.header.branchcode;
				this.orderno = this.order.header.orderno;

				// Adjust tax rates
				this.order.tax_data.authorities.forEach(r => {
					r.taxrate = parseFloat(r.taxrate) * 100;
				});

				this.getPay();
				this.setForm();

			});
	}


	setForm() {

		const cancel_or_bo = (this.config && this.config.env.package == 'beauty') ? 'backorder' : 'cancel';

		this.documentDetails = this.fb.group({
			invoiceDate: [new Date().toISOString(), Validators.required],
			packages: [1, Validators.required],
			invoiceText: [''],
			email: [this.order.invoiceemailaddress],
			customerref: [this.order.header.customerref],
			terms: [this.order.header.termsindicator, Validators.required],
			salesperson: [this.order.header.salesmancode, Validators.required],
			shipvia: [this.order.header.shipvia, Validators.required],
			document_type: [cancel_or_bo, Validators.required],
		});

		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isposuser = true;
			}
		});
	}



	paymentRequired() {

	}

	ngOnChanges(changes: any) {
		if (changes.orderno) {
			this.invoice_result = false;
			this.loadOrder()
		}
	}

	toggleAddingPay() {
		this.addingpay = (this.addingpay) ? false : true;
	}

	addPoItems(supplier: any) {

		const item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item, supplier).subscribe((result) => {

		})
	}

	ngAfterViewInit(): void {

	}

}