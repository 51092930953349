import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common'
import { Router } from '@angular/router';
import * as jq from 'jquery';
declare let $: any;

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	user: any = [];
	userForm: UntypedFormGroup;
	userdetails: any = [];
	locations: any = [];
	favorites: any = [];
	formEditAllowed = [
		'userid',
		'password',
		'realname',
		'phone',
		'email',
		'defaultlocation',
		'fullaccess',
		'cancreatetender',
		'editinv',
		'dispatchadmin',
		'customeradmin',
		//'phoneno',
	]
	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];

	color = 'blue'

	company_devices: any = false;
	user_devices: any = false;
	dropevent: any = '';
	saveavailable: any = false;

	deviceTypes = ['INVOICE', 'CREDIT', 'PICK', 'RECEIPT', 'LABEL']
	selectedDeviceTypes: {
		[key: string]: string[]; // Key should be a combination of deviceID and machineName
	} = {};

	selectedPrintTypes: {
		[key: string]: string[]; // Key should be a combination of deviceID and machineName
	} = {};

	isPosUser = false;
	searchAvailableDevices: string = '';
	searchUserDevices: string = '';

	constructor(public usersService: UsersService, public globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public location: Location, public globalsService: GlobalsService,public router: Router) {

		this.color = this.globalSearchService.color


	}

	ngOnInit(): void {


		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isPosUser = true;
			}
		})
		this.globalSearchService.user.subscribe((result: any) => {

			this.user = result.user;

			this.usersService.getUser(this.user.userid).subscribe((r: any) => {
				this.userdetails = r
				const details = this.userdetails
				const controls = [];
				const formGroup: UntypedFormGroup = new UntypedFormGroup({});
				Object.keys(details).forEach(key => {
					if (this.formEditAllowed.includes(key)) {
						const value = (details[key]) ? details[key] : '';
						controls.push({ name: key, control: new UntypedFormControl(value, []) })
					}
				});
				controls.forEach(f => {
					formGroup.addControl(f.name, f.control, {})
				});
				this.userForm = formGroup;
				this.userForm.controls['userid'].disable();
				this.userForm.controls['password'].setValue('');
				const data = { userid: this.user.userid }
				this.usersService.getCompanyDevices(data).subscribe(cd => {
					this.company_devices = cd;
					// this.userService.getPrintSettings(this.user.userid).subscribe( ud => {
					// 	this.user_devices = ud;
					// })
					this.user_devices = this.usersService.getPrintSettings(this.user.userid);
				})

			})
		});

		const window_width = $(window).width();
		const $sidebar = $('.sidebar');
		const $sidebar_responsive = $('body > .navbar-collapse');
		const $sidebar_img_container = $sidebar.find('.sidebar-background');

		jq('.badge').click(function() {

			const $full_page_background = jq('.full-page-background');

			jq(this).siblings().removeClass('active');
			jq(this).addClass('active');

			const new_color = $(this).data('color');

			if ($sidebar.length !== 0) {
				$sidebar.attr('data-color', new_color);
			}

			if ($sidebar_responsive.length != 0) {
				$sidebar_responsive.attr('data-color', new_color);
			}
		});

		this.globalsService.getFavorites().subscribe((faves: any) => {
			this.favorites = faves;
		});
	}

	changColor(new_color: any) {

		//localStorage.setItem('color', JSON.stringify(new_color))
		this.globalSearchService.setColor(new_color)
		this.globalSearchService.color = new_color;
		this.color = new_color;

		const $sidebar = $('.sidebar');
		const $sidebar_responsive = $('body > .navbar-collapse');
		const $navbar_responsive = $('.navbar-top');
		const $sidebar_img_container = $sidebar.find('.sidebar-background');

		const $cards = $('body > .card-header');


		if ($sidebar.length !== 0) {
			$sidebar.attr('data-color', new_color);
			$sidebar.attr('data-background-color', new_color);
			$navbar_responsive.attr('data-background-color', new_color);
		}

		if ($cards.length !== 0) {
			$cards.attr('data-color', new_color);
			$cards.attr('color', new_color);
		}

		if ($sidebar_responsive.length != 0) {
			$sidebar_responsive.attr('data-color', new_color);
			$sidebar_responsive.attr('data-background-color', new_color);
			$navbar_responsive.attr('data-background-color', new_color);
		}

	}

	getSelectedDeviceTypes(deviceID: string, machineName: string) {
		return this.selectedDeviceTypes[deviceID + '_' + machineName] || [];
	}


	
		
	updateDeviceTypes(deviceID: string, machineName: string, deviceTypes: string[]) {
		const selectedDevice = this.user_devices.find(device =>
			device.deviceID === deviceID && device.machineName === machineName
		);

		if (selectedDevice) {
			// Use the correct method to update device types based on deviceID and machineName

		}
	}


	updatePrintSettings(deviceID: string, machineName: string, deviceTypes: string[]) {
		const selectedDevice = this.user_devices.find(device =>
			device.deviceID === deviceID && device.machineName === machineName
		);

		if (selectedDevice) {
			this.usersService.setPrintSetting(
				this.user.userid,
				selectedDevice.machineUser,
				selectedDevice.machineName,
				deviceID,
				deviceTypes
			);
		}
	}




	addUserDevice(device: any) {
		const payload = {
			userid: this.user.userid,
			deviceid: device.device_id,
			machinename: device.machine_name // Include both device_id and machine_name
		};

		this.usersService.setPrintSetting(
			this.user.userid,
			device.machine_user,
			device.machine_name,
			device.device_id,
			this.selectedDeviceTypes[device.device_id + '_' + device.machine_name] // Use combined key
		);

		this.user_devices = this.usersService.getPrintSettings(this.user.userid);
	}

	removeUserDevice(device: any) {
		const payload = {
			userid: this.user.userid,
			deviceid: device.deviceID,
			machinename: device.machineName // Include both device_id and machine_name
		};

		this.usersService.deletePrintSetting(this.user.userid, device.deviceID, device.machineName);
		this.user_devices = this.usersService.getPrintSettings(this.user.userid);
	}

	// Check if a device is already set up for a user
	isDeviceSetupForUser(deviceID: string) {
		const settings = JSON.parse(localStorage.getItem('printSettings')) || {};
		const userSettings = settings[this.user.userid] || [];
		return userSettings.some((device: any) => device.deviceID === deviceID);
	}


	back(): void {
		this.location.back()
	}

	updateUser() {

		this.usersService.updateUser(this.userdetails.userid, this.userForm.value).subscribe((results) => {
			this.user.user = results;
			//	this.globalSearchService.user.next(this.user);
			//	localStorage.setItem('user', JSON.stringify(this.user))

			this.showNotification('Updated', 'success', 'bottom', 'right');
		});

	}

	filteredAvailableDevices() {
		return (this.company_devices || []).filter(device =>
			(
				(device.machine_name?.toLowerCase().includes(this.searchAvailableDevices?.toLowerCase() || '') ||
				device.device_id?.toLowerCase().includes(this.searchAvailableDevices?.toLowerCase() || ''))
			) && !this.user_devices.some(userDevice =>
				userDevice.deviceID === device.device_id && userDevice.machineName === device.machine_name
			)
		);
	}


	filteredUserDevices() {
		return (this.user_devices || []).filter(device =>
			(
				device.machineName?.toLowerCase().includes(this.searchUserDevices?.toLowerCase() || '') ||
				device.deviceID?.toLowerCase().includes(this.searchUserDevices?.toLowerCase() || '')
			)
		);
	}

	ngAfterViewInit() {
		this.globalsService.getLocations().subscribe(details => {
			this.locations = details;
		});
	}
	drop(event: CdkDragDrop < any[] > ) {
		moveItemInArray(this.favorites, event.previousIndex, event.currentIndex);
		this.saveavailable = true;
	}
	
	removeFavorites(faveid){

	let data = {faveid: faveid};
	this.globalsService.removeFavorite(data).subscribe((faves: any) => {
			this.favorites = faves;
			this.saveavailable = true;
		});
		
	}
	reorderFavorites(){
		this.saveavailable = false;
		let data = {favorites: this.favorites};
		this.globalsService.reorderFavorites(data).subscribe((faves: any) => {
		this.router.navigate(['/']);
		});
	}
	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: 'notifications',
			message: message

		}, {
			type: type,
			timer: 2500,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'<a href="{3}" target="{4}" data-notify="url"></a>' +
				'</div>'
		});
	}
}