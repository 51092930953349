<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }} d-flex align-items-center">
						<h4 class="card-title" id="headof">
							<i class="material-icons cursor-pointer" (click)="back()">arrow_left</i> Balance Sheet
						</h4>
					</div>
					<div class="card-body">
						<form [formGroup]="formGroup" *ngIf="formGroup">
							<div class="row align-items-center">
								<div class="col-md-3 mr-2">
									<mat-checkbox formControlName="showAllAccounts">
										Show all accounts including zero balances
									</mat-checkbox>
								</div>
								<div class="col-md-5">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>As of Period</mat-label>
										<mat-select formControlName="from_period">
											<mat-option *ngFor="let period of from_period_options" [value]="period.periodno">
												{{ period.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4 d-flex align-items-center">
									<button mat-raised-button color="accent" (click)="getTrialBalance()" class="mr-2">Run</button>
									<ng-container *ngIf="trial_balance.length">
										<a href="{{ pdflink }}" class="btn btn-danger mx-1">
											<i class="fa fa-file-pdf-o"></i>
										</a>
										<a href="{{ excellink }}" class="btn btn-success mx-1">
											<i class="fa fa-file-excel-o"></i>
										</a>
									</ng-container>
								</div>
							</div>
						</form>

						<div class="row">
							<div class="col-md-12 text-center" *ngIf="loading">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
						</div>

						<div *ngIf="!loading" id="tbresults">
							<ng-container *ngIf="trial_balance">
								<div class="card">
									<div class="card-header">
										<h3 class="mt-2 bold mb-2">{{ title }}</h3>
									</div>
									<div class="card-body">
										<div class="table-responsive" *ngFor="let group of trial_balance; last as isLast">
											<h4 class="bold">{{ group.title }}</h4>
											<table class="table table-sm">
												<thead>
													<tr>
														<th width="15%">Account</th>
														<th width="25%">Account Name</th>
														<th width="15%" class="text-right">As of {{ repdate }}</th>
														<th width="15%" class="text-right"></th>
														<th width="15%" class="text-right">Prior Year</th>
														<th width="15%" class="text-right"></th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let account of group.accounts">
														<td>{{ account.accountcode }}</td>
														<td>{{ account.accountname }}</td>
														<td class="text-right">{{ account.ytdbalance | currency }}</td>
														<td class="text-right"></td>
														<td class="text-right">{{ account.lytdbalance | currency }}</td>
														<td class="text-right"></td>
													</tr>
													<tr>
														<td><h5 class="bold">{{ group.title }} Totals</h5></td>
														<td></td>
														<td class="text-right">{{ group.totals.ytdbalance | currency }}</td>
														<td class="text-right"></td>
														<td class="text-right">{{ group.totals.lytdbalance | currency }}</td>
														<td class="text-right"></td>
													</tr>
													<tr *ngIf="isLast">
														<td colspan="2" class="text-right"><h5 class="bold">Total Assets</h5></td>
														<td class="text-right">{{ trial_balance_totals.assets | currency }}</td>
														<td></td>
														<td class="text-right">{{ trial_balance_totals.lyassets | currency }}</td>
														<td></td>
													</tr>
													<tr *ngIf="isLast">
														<td colspan="2" class="text-right"><h5 class="bold">Total Liabilities + Equity</h5></td>
														<td class="text-right">{{ trial_balance_totals.liabilities | currency }}</td>
														<td></td>
														<td class="text-right">{{ trial_balance_totals.lyliabilities | currency }}</td>
														<td></td>
													</tr>
													<tr *ngIf="isLast">
														<td><h5 class="bold">Check Totals</h5></td>
														<td></td>
														<td class="text-right">{{ trial_balance_totals.ytdbalance | currency }}</td>
														<td class="text-right"></td>
														<td class="text-right">{{ trial_balance_totals.lytdbalance | currency }}</td>
														<td class="text-right"></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
