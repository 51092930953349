<ng-template #cardModal let-modal>
		<div class="modal-header">
		<h4 class="modal-title" id="modal-title" ><span *ngIf="newcard">Register Giftcard</span><span *ngIf="!newcard"> Reload Giftcard</span></h4>
		<button type="button" mat-icon-button aria-label="Close" (click)="modal.dismiss('Cross click')">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-body text-center">
				<form [formGroup]="gcForm" *ngIf="gcForm">
					<div class="row">
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Card Number</mat-label>
								<input matInput type="text" formControlName="cardnumber" />
							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Amount</mat-label>
								<input matInput type="text" formControlName="amount" />
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<mat-form-field appearance="outline">
								<mat-label>Comment</mat-label>
								<input matInput type="text" formControlName="comment" />
							</mat-form-field>
						</div>
					</div>
					<div class="row" *ngIf="!newcard">
						<div class="col-sm-6">
							<h3>New Card Balance:</h3>
						</div>
						<div class="col-sm-6">
							<h3 style="color:green">{{newBalance}}</h3>
						</div>
					</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-stroked-button color="primary" (click)="addGC();">
			<mat-icon>add</mat-icon>
			Add Giftcard
		</button>
	</div>
</ng-template>

<button mat-stroked-button color="white" (click)="gcModal()">Load Giftcard</button>
