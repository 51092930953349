<ng-template #addCardModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" >Register Giftcard</h4>
		<button type="button" mat-icon-button aria-label="Close" (click)="modal.dismiss('Cross click')">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-body text-center">
					<div class="row">
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Card Number</mat-label>
								<input matInput type="text" [formControl]="cardnumber" />
							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Amount</mat-label>
								<input matInput type="text" [formControl]="amount" />
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<mat-form-field appearance="outline">
								<mat-label>Comment</mat-label>
								<input matInput type="text" [formControl]="comment" />
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-stroked-button color="primary" (click)="addGC();">
			<mat-icon>add</mat-icon>
			Add Giftcard
		</button>
	</div>
</ng-template>
<ng-template #reloadModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" >Reload Giftcard </h4>
		<button type="button" mat-icon-button aria-label="Close" (click)="modal.dismiss('Cross click')">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-body text-center">
					<div class="row">
						<div class="col-sm-12">
							<mat-form-field appearance="outline">
								<mat-label>Amount</mat-label>
								<input matInput type="text" [formControl]="reloadamount" />
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<mat-form-field appearance="outline">
								<mat-label>Comment</mat-label>
								<input matInput type="text" [formControl]="reloadcomment" />
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-stroked-button color="primary" (click)="reloadCard(reloadgc);">
			<mat-icon>add</mat-icon>
			Reload Giftcard
		</button>
	</div>
</ng-template>
<ng-template #transModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" >Gift Card Transactions - {{ gctrans.giftcard_number }}</h4>
		<button type="button" mat-icon-button aria-label="Close" (click)="modal.dismiss('Cross click')">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body text-dark">
		<table class="table table-striped table-hover table-bordered">
											<thead class="thead-dark">
												<tr>
													<th scope="col">User ID</th>
													<th scope="col">Transaction Date</th>
													<th scope="col">Transaction Number</th>
													<th scope="col">Amount</th>
													<th scope="col">Comment</th>
													
												</tr>
											</thead>
											<tbody>
												<ng-container *ngFor="let s of gctrans.trans">
												<tr>
												    <td class="text-center">
														{{ s.userid }}
													</td>
													<td>{{ s.trandate | date: 'MM/dd/YYYY'}}</td>
													<td>{{ s.type }} - {{ s.transno }} </td>
													<td>{{ s.amount | currency}}</td>
													<td>{{ s.comment }}</td>
													
												</tr>
												</ng-container>
											</tbody>
										</table>
	</div>
</ng-template>
<div class="card m-0">
<div class="card-body">
<div class="row mb-2">
				<div class="col-10">
					<mat-form-field appearance="outline" class="search-field">
						<mat-label>Search Giftcards</mat-label>
						<input matInput placeholder="Search..." [(ngModel)]="searchText" (input)="filterGiftCards()">
						<button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchText" (click)="clearSearch()">
							<mat-icon>clear</mat-icon>
						</button>
					</mat-form-field>
				</div>
				<div class="col-2">
				<button mat-stroked-button color="primary" (click)="addCard()"> Add Giftcard </button>
				</div>
</div>
<div class="row mb-2">
<table class="table table-striped table-hover table-bordered">
											<thead class="thead-dark">
												<tr>
													<th scope="col"></th>
													<th scope="col">Gift Card Number</th>
													<th scope="col">Original Value</th>
													<th scope="col">Current Value</th>
													<th scope="col">Date Issued</th>
													<th scope="col">Comment</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<ng-container *ngFor="let s of filteredGiftCards">
												<tr>
												    <td class="text-center">
														
													</td>
													<td>{{ s.giftcard_number}}</td>
													<td>{{ s.value | currency}}</td>
													<td>{{ s.currentvalue | currency }} </td>
													<td>{{ s.record_time | date: 'MM/dd/YYYY' }}</td>
													<td>{{ s.comment }}</td>
													<td><button mat-icon-button (click)="viewTrans(s.giftcard_id)" title="View Transactions"><mat-icon>search</mat-icon></button>
													<button mat-icon-button (click)="addBalance(s.giftcard_id)" title="Reload Balance"><mat-icon>add</mat-icon></button></td>
												</tr>
												</ng-container>
											</tbody>
										</table>
										</div>
	</div>
</div>
										