<div class="card card-chart">
	<div class="card-header card-header-{{ color }} card-header-icon">
		<div class="card-icon">
		  <i class="material-icons"></i>
		</div>
		<h4 class="card-title text-dark"> {{ charttitle }}</h4>
	</div>
	<div class="card-body">
		<div class="row m-0 p-0">
			<div class="col-md-10  m-0 p-0 ">
				<div class="container-fluid tp-box h-100 w-100" [@flipState]="flip">
					<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? 'd-none' : ''">
						<div class="row text-right m-0 p-0">

							<button mat-raised-button class="btn btn-success export" [disabled]="sending" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
							&nbsp;&nbsp;
							<button mat-raised-button class="btn btn-danger export" [disabled]="sending" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
							&nbsp;

							<button mat-raised-button color="accent" (click)="toggleData()" [disabled]="sending" *ngIf="!showdata" class="ml-auto mt-3">
								SHOW CHART
							</button>
						</div>
						<div class="table table-fluid" #print_items>
							<table class="table table-fixed table-hover">
								<thead>
									<tr>
										<th>{{ brandorpline }}</th>
										<th class="text-center">UNITS</th>
										<th class="text-right">NET</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let d of data">
										<td>{{ d.label }}</td>
										<td class="text-center">{{ d.qty }}</td>
										<td class="text-right">{{ d.total | currency }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div id="frontside" class="tp-box__side tp-box__front h-100 w-100" [ngClass]="flip == 'active' ? 'd-none' : ''">
						<div class="chart-container bg-white not-expanded">
							<canvas id="brandChart" class="ml-auto mr-auto w-100 h-100">{{chart}}</canvas>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2 m-0 p-0 ">

				<span *ngIf="sending">
					<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</span>

				<button mat-raised-button color="accent" [disabled]="sending" (click)="loadData()" *ngIf="!sending" class="ml-auto mr-auto mt-2 mb-2 export">
					<mat-icon>refresh</mat-icon>
				</button>
				&nbsp;&nbsp;
				<button mat-raised-button color="accent" [disabled]="sending" (click)="reset()" *ngIf="!sending" class="ml-auto mr-auto mt-2 mb-2 export">
					RESET
				</button>
				<mat-form-field appearance="outline" class="text-right">
					<mat-label>Transaction Dates</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
						<input matEndDate [formControl]="todateCtrl" placeholder="End date">
					</mat-date-range-input>
					<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>

				<mat-form-field appearance="outline" class="">
					<mat-label>Brand</mat-label>
					<mat-select [formControl]="brands" #brandselect multiple appSelectAll>
						<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Product Line</mat-label>
					<mat-select [formControl]="lineid" multiple appSelectAll>
						<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="">
					<mat-label>Limit</mat-label>
					<mat-select [formControl]="limit">
						<mat-option *ngFor="let k of limits" [value]="k">
							{{ k }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<div *ngIf="(locations && locations.length > 1)">
					<mat-form-field appearance="outline">
						<mat-label>Warehouse</mat-label>
						<mat-select [formControl]="defaultlocation" multiple appSelectAll>
							<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
								{{ loc.locationname }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="">
					<button mat-raised-button class="btn btn-success btn-sm  export" [disabled]="sending" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
					&nbsp;&nbsp;
					<button mat-raised-button class="btn btn-danger btn-sm export" [disabled]="sending" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
					&nbsp;
					<button mat-raised-button color="accent" (click)="toggleData()" *ngIf="!showdata" class="btn-sm ml-auto mt-3">
						SHOW DATA
					</button>
				</div>



				<!-- <div class="col-md-2">
					<mat-form-field appearance="outline" class="">
						<mat-label>Category</mat-label>
						<mat-select [formControl]="categoryid" multiple appSelectAll>

							<mat-option *ngFor="let k of properties.categories" [value]="k.value">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div> -->
			</div>

		</div>

	</div>
</div>
