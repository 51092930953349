import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef ,Input,Output, EventEmitter,SimpleChanges,ChangeDetectionStrategy,OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup,UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { PaymentsService } from '../../services/payments.service';

@Component({
	selector: 'app-credit-card-form',
	templateUrl: './credit-card-form.component.html',
	styleUrls: ['./credit-card-form.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class CreditCardFormComponent implements OnInit {
	@Input() customer: any = [];
	@Input() branches: any = [];
	@Input() type: any = '';
	@Input() arpreauth: any = false;
	@Output() updatePayments: EventEmitter < any > = new EventEmitter();

	@ViewChild('thirdparty') thirdpartyRef: ElementRef;

	flip = 'inactive';
	payment_methods: any = [];
	bankaccounts: any = [];
	today = new Date();
	day_payments: any = [];
	cardForm: UntypedFormGroup;
	selectedCard = new UntypedFormControl('');
	searchByAmount = new UntypedFormControl('');
	searchByInvoice = new UntypedFormControl('');
	searchString = new UntypedFormControl('');
	customerSearchInline = new UntypedFormControl('');
	customerSearchResultsTwo: any = [];
	searchByCurrentPayments= new UntypedFormControl('');
	searchByCurrentInvoices= new UntypedFormControl('');

	selectAllPayments= new UntypedFormControl(false);
	selectAllInvoices= new UntypedFormControl(false);
	selectInvoiceToPay= new UntypedFormControl(false);

	searchByCurrentPaymentsDate= new UntypedFormControl('');
	searchByCurrentInvoicesDate = new UntypedFormControl('');
	holdReason = new UntypedFormControl('');

	alldebits: any = false;
	allcredits: any = false;

	//reversed
	singleusecard = new UntypedFormControl(true);
	currency: any = '';
	currencies: any = [];
	company: any = [];

	//paymentss
	datefrom = new Date()
	dateto = new Date()
	running = false;
	total_pay: any = '';
	payments: any = [];

	customersearching: any = false;
	customerSearchResults: any = [];
	amountResults: any = false;
	invoiceResults: any = false;
	customertransactions: any = [];

	hasprofile = false;
	payment_added: any = false;
	addingcard: any = false;

	allocations_from: any = [];
	allocations_to: any = [];

	allocation_from_total = 0.00;
	allocation_to_total = 0.00;
	profile_cards: any = [];

	payment_card: any = false;
	single_use: any = false;
	editingcard: any = false;
	sending: any = false;
	color = 'blue';
	charge_card = true;
	submitting = false;
	chargetype = 'existing_card';
	chargecardtype = 'no_charge';
	card_required = false;
	thirdpartylinks: any = false;
	showrefunds = false;
	urlSafe: SafeResourceUrl;
	creditsSelected: any = [];
	expandedElement: any = false;
	hidepay: any = false;
	debitsSelected: any = [];

	allpayments:any = [];
	alltransactions:any = [];
	holdreasons:any = [];
	token: any = false;
	config: any = false;

	constructor(private modalService: NgbModal,public sanitizer: DomSanitizer,private ordersService: OrdersService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private route: ActivatedRoute, public router: Router) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.holdreasons.subscribe( r => {
			this.holdreasons = r;
		})
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
		if(this.config.features.iframe) {
			const parent = this;
			addEventListener('message', function(event: any) {

				if(event.data) {
					const message = JSON.parse(event.data);
					parent.setToken(message.message);
				}
			}, false);
		}
	}

	setToken(token:any) {
		this.token = token;
	}

	ngOnInit(): void {
	}

	loadCustomer() {

		this.paymentsService.getCustomerAccountReceivables(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.customer = results;

			this.holdReason.setValue(results.holdreason);
			this.searchString.setValue(results.name);

			this.loadProfile(results.debtorno, results.branchcode);

			this.customertransactions = results.transactions;
			this.allcredits = results.payments
			this.alldebits = results.transactions

			this.alltransactions = results.transactions;
			this.allpayments = results.payments;

			this.setCardForm();

		});
	}

	toggleFlip() {
		this.flip = (this.flip == 'active') ? 'inactive': 'active';
	}

	loadProfile(debtorno: any, branchcode: any) {

		this.paymentsService.loadProfile(debtorno, branchcode).subscribe(async (result: any) => {
			if (result.cards) {
				this.hasprofile = true;
				this.profile_cards = result.cards
				this.payment_card = result.cards.filter((card: any) => {
					return card.default
				})[0];

				if(!this.payment_card.length) {
					this.payment_card = result.cards[0]
				}


				this.updatePaymentType(this.payment_card.payment_method);

			} else {
				this.hasprofile = false;
				this.profile_cards = [];
				this.payment_card = false;

			}
		})
	}

	setCardForm() {

		console.log(this.customer);

		this.cardForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [this.customer.name, Validators.required],
			ccnumber: ['', Validators.required],
			token: [this.token],
			expiry: ['', Validators.required],
			card_id: [true],
			cvv: [''],
			savetoprofile: [true, Validators.required]
		});
	}


	setCardRequired(method: any) {

	}

	updatePaymentType(method : any) {

		this.setCardRequired(method);
	}

	updateProfile(event: any) {
		this.customer.profile = event;
		this.addingcard = false;
		this.editingcard = false;
		this.sending = false;
		this.profile_cards = event.cards
		this.hasprofile = (event.cards.length) ? true :false;
		this.profile_cards = event.cards
	}

	toggleAddCard() {
		this.addingcard = (this.addingcard) ? false : true
	}


	doNotCharge(event: any) {
		event.preventDefault();
		this.payment_card = false;
		this.charge_card = false;

		this.profile_cards.every(card => card.default = false);
	}

	addPayment(event: any) {
		event.preventDefault();
	}

	hidePayments() {
		this.hidepay = (this.hidepay) ? false :true;
	}



	selectCustomer(customer: any) {
		//todo dont do this


		this.customersearching = false;
		this.customerSearchInline.setValue(customer.name);
		this.customer = customer;
		this.loadCustomer();
		//this.router.navigate(['/receivables/payment-entry/' + customer.debtorno + '/' + customer.branchcode]);
	}

	customerSearchtwo(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		const search = {
			keywords: event
		}

		this.customersearching = this.customerService.getCustomerSearch(search).subscribe(async (results: any) => {
			this.customerSearchResultsTwo = results;

			if(results.length == 1) {
				this.selectCustomer(results[0])
				this.customerSearchResults = false;
				this.customerSearchResultsTwo = false;
			}

		});
	}

	paymentTotals(): number {

		let balance = (this.allocation_to_total + this.allocation_from_total);
		if(balance >= 0 ) {
			balance = 0.00
		}
		return balance;
	}

	ngAfterViewInit(): void {

	}

	singleFormSet(event: any) {


	}

	editCard(event: any, card: any) {
		event.stopPropagation();
		event.preventDefault();

		this.editingcard = true;
		this.cardForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [card.name, Validators.required],
			ccnumber: [card.number, Validators.required],
			card_id: [card.card_id, Validators.required],
			expiry: [card.expiry_month+'/'+card.expiry_year, Validators.required],
			cvv: [''],
			savetoprofile: [true, Validators.required]
		});

	}

	updateCard(event:any) {
		event.stopPropagation();
		event.preventDefault();

		if(this.cardForm.valid) {
			this.sending = true;
			this.paymentsService.editCreditCard(this.cardForm.value).subscribe( (result: any) => {
				this.sending = false;
				if(!result.success) {
					alert(result.message)
				} else {

					if (result.cards) {
						this.hasprofile = true;
						this.profile_cards = result.cards
						this.payment_card = result.cards.filter((card: any) => {
							return card.default
						})[0];
					}

					this.editingcard = false;
				}
			});
		}

	}

	removeCard(event: any) {

		event.stopPropagation();
		event.preventDefault();
		this.sending = true;
		this.paymentsService.removeCreditCard(this.cardForm.value).subscribe( (result: any) => {
			this.sending = false;
			this.hasprofile = (result.cards.length) ? true :false;
			this.editingcard = false;
			this.profile_cards = result.cards
			this.payment_card = result.cards.filter((card: any) => {
				return card.default
			})[0];

		});
	}

	addPaymentMethod(event: any) {
		event.preventDefault();
		if(this.cardForm.valid) {
			this.sending = true;
			this.paymentsService.addCreditCard(this.cardForm.value).subscribe( (result: any) => {
				this.sending = false;
				if(!result.success) {
					alert(result.message)
				} else {
					//not sure if this is right
					this.editingcard = false;
					this.hasprofile = true;
					if (result.cards) {
						this.hasprofile = true;
						this.profile_cards = result.cards
						this.payment_card = result.cards.filter((card: any) => {
							return card.default
						})[0];
					}


					this.updatePayments.emit(result)
					this.setCardForm();
				}
			});
		}
	}
	setChargeCardType(event:any) {
		this.chargecardtype = event.value
	}
	setChargeType(event: any) {
		this.chargetype = event.value
		this.updatePaymentType(event.value)
	}

	back() {
		//this.location.back()
	}

	ngOnChanges(changes: SimpleChanges) {
		this.loadCustomer();
	}
}
