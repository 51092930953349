<div class="main-content bg-white">

    <div class="row m-0 p-0">
        <div class="col-6">
            <mat-form-field appearance='outline'>
                <mat-label>Order#</mat-label>
                <input matInput [formControl]="orderSearchRef" (keyup)="orderSearch()" placeholder="Enter transno" [readonly]="forced">
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field appearance='outline'>
                <mat-label>Enter your tracking number</mat-label>
                <input matInput [formControl]="trackingRef" placeholder="Enter tracking number" #trackingInput>
            </mat-form-field>
        </div>
    </div>

    <ng-container *ngIf="!orderInfo && searchingord">
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>    
    </ng-container>

    <div class="row bg-white p-2" *ngIf="orderInfo && orderInfo.tracking.length && currentTrack && shippers.length">
        <div class="col-auto m-0 p-2 italics font-weight-light">Tracking #(s):</div>
        <mat-chip-list class="col-10 m-0 p-0">
            <mat-chip *ngFor="let track of orderInfo.tracking" (click)="setCurrentTracking(track)" [selected]="track === currentTrack">#{{track.trackingnumber}} (<span class="text-decoration-underline">{{getShipperName(track)}}</span>)</mat-chip>
        </mat-chip-list>
    </div>
    
    <div class="row bg-white p-2" *ngIf="orderInfo && orderInfo.tracking.length && currentTrack && shippers.length && currentTrack.url">
        <div class="col-auto m-0 p-2 italics font-weight-light italics link pointer" (click)="openTrackingLink(currentTrack.url)" >
            <span class="borderedlink p-2 d-flex">
                <mat-icon>open_in_new</mat-icon>&nbsp;Open Tracking URL
            </span>
        </div>
    </div>


    <div class="card" *ngIf="orderInfo">
        <div class="card-header card-header-{{color}}-6">
            <h4 class="float-left">{{orderInfo.debtorno}}.{{orderInfo.branchcode}}&nbsp;{{orderInfo.brname}}</h4>
            <h4 class="float-right">#{{orderInfo.orderno}}</h4>
        </div>
        <div class="card-body">
            <div class="table" *ngIf="branchInfo">
                <h4>Delivery Address</h4>
                <table class="table table-sm">
                    <tr>
                        <th>Branch Name</th>
                        <th>Branch Address</th>
                        <th>Contact Info</th>
                        <th>Phone Number</th>
                    </tr>
                    <tr>
                        <td>{{branchInfo.brname}}</td>
                        <td>{{branchInfo.braddress1}} {{branchInfo.braddress2}} {{branchInfo.braddress3}}
                            {{branchInfo.braddress4}} {{branchInfo.braddress5}}</td>
                        <td>{{branchInfo.contactname}}</td>
                        <td>{{branchInfo.phoneno}}</td>
                    </tr>
                </table>
            </div>

            <div class="table scroll-y" *ngIf="items.length">
                <table class="table table-sm ">
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Invoiced</th>
                        <th class="text-center">Outstanding</th>
                        <th class="text-right">Unit Price</th>
                        <th class="text-right">Total</th>
                    </tr>
                    <tr *ngFor="let item of items">
                        <td>{{item.stkcode}}</td>
                        <td>{{item.itemdesc}}</td>
                        <td class="text-center">{{item.ordered}}</td>
                        <td class="text-center">{{item.qtyinvoiced}}</td>
                        <td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
                        <td class="text-right">{{item.unitprice | currency}}</td>
                        <td class="text-right">{{item.unitprice * item.ordered | currency}}</td>
                    </tr>
                    <tr *ngFor="let tot of totals">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-right"><b>{{tot.code}}</b></td>
                        <td class="text-right">{{tot.text | currency}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card-footer row justify-content-end">
            <ng-container *ngIf="currentTrack">
                <div class="col-6 justify-content-end p-0  mw-150">
                    <div class="row w-100 m-0 p-0">
                        <ng-container *ngIf="trackingTypeRef.value !== currentTrack.type_id">
                            <span class='text-danger font-weight-light'><mat-icon>warning</mat-icon>Type changed</span>
                        </ng-container>
                        <mat-form-field  appearance="outline" class="w-inherit" *ngIf="shippers.length">
                            <mat-label>Type</mat-label>
                            <mat-select  [formControl]="trackingTypeRef">
                                <ng-container *ngFor="let via of shippers">
                                    <mat-option value="{{via.shipper_id}}">
                                        {{via.shippername}}                                            
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="row w-100 m-0 p-0 justify-content-around">
                        
                        <mat-label class="text-danger mr-2 font-weight-light" *ngIf="currentTrack.trackingnumber !== trackingRef.value">Tracking # changed</mat-label>
                        <button color="primary" class="m-0 p-0"  mat-raised-button (click)="submitTracking('update')" [disabled]="trackingRef.value == ''">
                            Update
                        </button>
                        <button class="text-white bg-danger m-0 p-0"  mat-raised-button (click)="submitTracking('remove')" [disabled]="trackingRef.value == ''">
                            Remove
                        </button>
                    </div>
                </div>
                
                <mat-label class="text-success pl-5 font-weight-light pos-abs-left" *ngIf="currentTrack.trackingnumber !== trackingRef.value">Add new</mat-label>                
                <button color="accent" class="pos-abs-left" *ngIf="orderInfo.tracking.length"  mat-mini-fab  (click)="submitTracking('submit')" [disabled]="trackingRef.value == '' || currentTrack.trackingnumber == trackingRef.value">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-container>

            <ng-container *ngIf="!currentTrack">
                <div class="row justify-content-end w-100" *ngIf="trackingRef.value == '' || !trackingTypeRef.value">
                    <mat-label class="text-danger mr-2 italics">Enter 
                        <ng-container *ngIf="!trackingTypeRef.value || trackingTypeRef.value == ''">type</ng-container>
                        <ng-container *ngIf="!trackingTypeRef.value && trackingRef.value == ''"> and </ng-container>
                        <ng-container *ngIf="trackingRef.value == ''">tracking #</ng-container>
                    </mat-label>
                </div>
                <div class="col-6 justify-content-end p-0  mw-150">
                    <div class="w-100 justify-content-end p-0 m-0">
                        <mat-form-field  appearance="outline" class="w-inherit" *ngIf="shippers.length">
                            <mat-label>Type</mat-label>
                            <mat-select  [formControl]="trackingTypeRef">
                                <ng-container *ngFor="let via of shippers">
                                    <mat-option value="{{via.shipper_id}}" >{{via.shippername}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        
                        <button mat-raised-button color="primary" (click)="submitTracking('submit')" class="submit-btn float-right" [disabled]="trackingRef.value == '' || !trackingTypeRef.value">
                            Submit
                        </button>                        
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</div>