<ng-template #commUp let-modal>
	<div class="modal-header">
		<h3> Upload New Commissions </h3>
	</div>
	<div class="modal-body text-dark">
		<ng-container *ngIf="!uploaddata.rows">
			<div class="row text-center w-100 ml-auto mr-auto">
				<ngx-dropzone [multiple]="false" (change)="onSelect($event)">
					<ngx-dropzone-label class="m-2">
						DROP FILE HERE OR CLICK TO UPLOAD
					</ngx-dropzone-label>
					<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
						<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
					</ngx-dropzone-preview>
				</ngx-dropzone>
			</div>
		</ng-container>
		<ng-container *ngIf="uploaddata.rows">
			<div class="row text-center w-100 ml-auto mr-auto">
				<button mat-stroked-button color="success" aria-label="" (click)="sendComms()" class="mt-2 mb-2">
					<mat-icon>cloud_upload</mat-icon> Update
				</button>
				<table class="table table-sm table-responsive table-fluid">
					<tr>
						<th *ngFor="let col of uploaddata.header">
							{{col}}
						</th>
					</tr>
					<tr>
						<th *ngFor="let col of uploaddata.header">
							<mat-select [(ngModel)]="mapping[col]">
								<mat-option *ngFor="let map of mapcols" [value]="map.field">
									{{ map.label }}
								</mat-option>
							</mat-select>
						</th>
					</tr>
					<tr *ngFor="let row of uploaddata.rows">
						<td *ngFor="let col of uploaddata.header">{{row[col]}}</td>
					</tr>
				</table>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template  #overModal let-modal>
<div class="modal-header">
		<h3> Commission Overrides for {{selectedItem.stockid}} </h3>
	</div>
	<div class="modal-body text-dark">
		<ng-container>
			<div class="row text-center w-100 ml-auto mr-auto">
				<div class="table">
				
										<table class="table table-bordered table-fixed table-repsonsive table-fluid w-100">
											<thead>
												<tr>
													<th></th>
													<th class="max-td">Start Date</th>
													<th class="max-td">End Date</th>
													<th class="text-center">Commission Percent</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let p of selectedItem.overrides">
													<td class="text-center"><button mat-stroked-button class="btn-danger" aria-label="" (click)="$event.preventDefault();removeOverride(p.id)" class="mt-2 mb-2">
														<mat-icon>delete</mat-icon> Delete
													</button></td>
													<td class="max-td">{{ p.startdate | date: 'M/d/YYYY'}}</td>
													<td class="max-td">{{ p.enddate | date: 'M/d/YYYY'}}</td>
													<td class="text-center">{{p.commpct}} %</td>
												</tr>
												<tr>
													<td class="text-center"><button mat-stroked-button class="btn-danger" aria-label="" (click)="$event.preventDefault();addOverride()" class="mt-2 mb-2">
														<mat-icon>add</mat-icon> Add New Override
													</button></td>
													<td class="max-td"><mat-form-field appearance="outline">
													<mat-label>Start Date</mat-label>
														<input matInput [matDatepicker]="picker1" [formControl]="newStart"/>
																<mat-datepicker-toggle matSuffix [for]="picker1">
																</mat-datepicker-toggle>
																<mat-datepicker #picker1></mat-datepicker>
													</mat-form-field></td>
													<td class="max-td"><mat-form-field appearance="outline">
													<mat-label>Ending Date</mat-label>
														<input matInput [matDatepicker]="picker2" [formControl]="newEnd" />
																<mat-datepicker-toggle matSuffix [for]="picker2">
																</mat-datepicker-toggle>
																<mat-datepicker #picker2></mat-datepicker>
													</mat-form-field></td>
													<td class="text-center"><mat-form-field appearance="outline">
													<mat-label>Percent</mat-label>
														<input matInput type="text" [formControl]="newPct" />
													</mat-form-field></td>
												</tr>
											</tbody>
										</table>
									</div>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
	<button mat-stroked-button class="btn-danger" aria-label="" (click)="modal.dismiss('Cross click')" class="mt-2 mb-2">
														<mat-icon>cancel</mat-icon> Cancel
													</button>
	</div>
</ng-template>
<ng-template  #massOver let-modal>
<div class="modal-header">
		<h3> Add Override to All Selected Items </h3>
	</div>
	<div class="modal-body text-dark">
		<ng-container>
			<div class="row text-center w-100 ml-auto mr-auto">
			This will add a new override to {{commdetails.length}} items. Please be sure this is what you want to do.
			</div>
			<div class="row text-center w-100 ml-auto mr-auto">
				<div class="table">
				
										<table class="table table-bordered table-fixed table-repsonsive table-fluid w-100">
											<thead>
												<tr>
													<th class="max-td">Start Date</th>
													<th class="max-td">End Date</th>
													<th class="text-center">Commission Percent</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td class="max-td"><mat-form-field appearance="outline">
													<mat-label>Start Date</mat-label>
														<input matInput [matDatepicker]="pickera" [formControl]="massStart"/>
																<mat-datepicker-toggle matSuffix [for]="pickera">
																</mat-datepicker-toggle>
																<mat-datepicker #pickera></mat-datepicker>
													</mat-form-field></td>
													<td class="max-td"><mat-form-field appearance="outline">
													<mat-label>Ending Date</mat-label>
														<input matInput [matDatepicker]="pickerb" [formControl]="massEnd" />
																<mat-datepicker-toggle matSuffix [for]="pickerb">
																</mat-datepicker-toggle>
																<mat-datepicker #pickerb></mat-datepicker>
													</mat-form-field></td>
													<td class="text-center"><mat-form-field appearance="outline">
													<mat-label>Percent</mat-label>
														<input matInput type="text" [formControl]="massPct" />
													</mat-form-field></td>
												</tr>
											</tbody>
										</table>
									</div>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
	<button mat-stroked-button class="btn-danger" aria-label="" (click)="$event.preventDefault();addMassOverride()" class="mt-2 mb-2">
														<mat-icon>add</mat-icon> Add New Override
													</button>
													<button mat-stroked-button class="btn-danger" aria-label="" (click)="modal.dismiss('Cross click')" class="mt-2 mb-2">
														<mat-icon>cancel</mat-icon> Cancel
													</button>
	</div>
</ng-template>
<ng-template  #massModal let-modal>
<div class="modal-header">
		<h3>Change All Selected Items </h3>
	</div>
	<div class="modal-body text-dark">
		<ng-container>
			<div class="row text-center w-100 ml-auto mr-auto">
			This will change the commission percent and activate commissions on {{commdetails.length}} items. Please be sure this is what you want to do.
			</div>
			<div class="row text-center w-100 ml-auto mr-auto">
				<div class="table">
				
										<table class="table table-bordered table-fixed table-repsonsive table-fluid w-100">
											<thead>
												<tr>
													<th class="text-center">New Commission Percent</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td class="text-center"><mat-form-field appearance="outline">
													<mat-label>Percent</mat-label>
														<input matInput type="text" [formControl]="massPct2" />
													</mat-form-field></td>
												</tr>
											</tbody>
										</table>
									</div>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
	<button mat-stroked-button class="btn-danger" aria-label="" (click)="massUpdate()" class="mt-2 mb-2">
														<mat-icon>add</mat-icon> Update Commissions
													</button>
													<button mat-stroked-button class="btn-danger" aria-label="" (click)="modal.dismiss('Cross click')" class="mt-2 mb-2">
														<mat-icon>cancel</mat-icon> Cancel
													</button>
	</div>
</ng-template>
<ng-container>
	<div class="main-content">
		<div class="container-fluid">
			<div class="row ">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-3">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Item Commissions</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-body">
							<!--<button mat-stroked-button color="success" aria-label="" (click)="$event.preventDefault();uploadComms()" class="mt-2 mb-2">
								<mat-icon>cloud_upload</mat-icon> Upload Commissions
							</button>-->
							<button mat-stroked-button color="success" aria-label="" (click)="$event.preventDefault();massOverride()" class="mt-2 mb-2">
								<mat-icon>bolt</mat-icon> Add Override to all Filtered
							</button>
							<button mat-stroked-button color="success" aria-label="" (click)="$event.preventDefault();massChange()" class="mt-2 mb-2">
								<mat-icon>compare_arrows</mat-icon> Change all Filtered
							</button>


							<div class="row">
								<div class="col-6 text-right mt-3">
									<mat-form-field appearance="outline">
										<mat-label>Search Commissions</mat-label>
										<input type="text" class="text-left " matInput [formControl]="searchComms" (input)="itemSearch()" />
									</mat-form-field>
								</div>
								<div class="col-md-2 mt-3">
									<mat-form-field appearance="outline" >
									<mat-label>Product Line</mat-label>
									<mat-select [formControl]="prodlines" (selectionChange)="itemSearch()">
									    <mat-option value="">ALL</mat-option>
										<mat-option *ngFor="let k of plines" [value]="k.line_field">{{k.line_field }} - {{k.line_description}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>	
							</div>
									<div class="table">
									<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator>
										<table class="table table-bordered table-fixed table-repsonsive table-fluid w-100">
											<thead>
												<tr>
													<th></th>
													<th>Stock ID</th>
													<th class="max-td">Description</th>
													<th class="text-center">Commission Percent</th>
													<th class="text-center">Active</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let p of dataObs | async;let i = index">
													<td class="text-center"><button mat-stroked-button color="success" aria-label="" (click)="$event.preventDefault();showverrides(p)" class="mt-2 mb-2">
														<mat-icon>bolt</mat-icon> Overrides ({{p.overrides.length}})
													</button></td>
													<td>{{ p.stockid }}</td>
													<td class="max-td">{{ p.description }}</td>
													<td class="text-center"><mat-form-field appearance="outline">
													<mat-label>Percent</mat-label>
														<input matInput type="text" value="{{p.commpct}}" (change)="changePct(p,$event)" />
													</mat-form-field></td>
													<td class="text-center"><mat-slide-toggle [checked]="p.commissionable == 1" (change)="changeCommissionable(p,$event)">Commissionable</mat-slide-toggle></td>
												</tr>
											</tbody>
										</table>
									</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>