import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl, UntypedFormControl } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { PrintService } from '../../../services/print.service'
import { SystemService } from '../../../services/system.service'
import { GeneralLedgerService } from '../../../services/general-ledger.service'
import { InventoryService } from '../../../services/inventory.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import { Console } from 'console';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-giftcards',
  templateUrl: './giftcards.component.html',
  styleUrls: ['./giftcards.component.scss']
})
export class GiftcardsComponent {

	@ViewChild('addCardModal') addCardRef: ElementRef;
	@ViewChild('transModal') transRef: ElementRef;
	@ViewChild('reloadModal') reloadRef: ElementRef;
	searchText: any = '';
	filteredGiftCards: any = [];
	giftcards: any = '';
	cardnumber: any = new UntypedFormControl('');
	amount: any = new UntypedFormControl('');
	comment: any = new UntypedFormControl('');
	reloadamount: any = new UntypedFormControl('');
	reloadcomment: any = new UntypedFormControl('');
	user: any = false;
	gctrans: any = [];
	reloadgc: any = [];
	
	constructor(private systemService: SystemService, private modalService: NgbModal, private globalSearchService: GlobalSearchService) {
		this.systemService.getGiftCards().subscribe(async (results: any) => {
					if(results) {
						this.giftcards = results.cards;
						this.filterGiftCards();
					} 
				});
		this.globalSearchService.user.subscribe(results => {

			this.user = results
		});
	}
	filterGiftCards() {
		const search = this.searchText.toLowerCase();
		if (search) {
			this.filteredGiftCards = this.giftcards.filter(p => {
				 return p.giftcard_number.toLowerCase().includes(search) || p.comment.toLowerCase().includes(search);
				}
			);
		} else {
			this.filteredGiftCards = this.giftcards;
		}
		console.log(this.filteredGiftCards);
	}

	clearSearch() {
		this.searchText = '';
		this.filterGiftCards();
	}
	addCard() {
		this.cardnumber.value = '';
		this.amount.value = '';
		this.comment.value = '';
		this.modalService.open(this.addCardRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {

		}, (reason) => {
			
		});
	}
	addGC(){
		const data = {userid: this.user.user.userid, 'cardnumber': this.cardnumber.value, 'amount': this.amount.value, 'comment': this.comment.value};
		
		this.systemService.addGiftCard(data).subscribe(async (results: any) => {
					if(results.error == '') {
						this.giftcards = results.cards;
						this.filterGiftCards();
						this.modalService.dismissAll();
						this.globalSearchService.showNotification('Card added', 'success', 'bottom', 'right');
					} else {
					this.globalSearchService.showNotification(results.error, 'danger', 'bottom', 'right');
					}
				});
	}
	viewTrans(gcid: any){
		const data = {'cardid': gcid};
		this.systemService.viewCardTrans(data).subscribe(async (results: any) => {
					this.gctrans = results;
					this.modalService.open(this.transRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {

		}, (reason) => {
			
		});
				});
	
	}
	addBalance(gcid: any){
		this.reloadgc = gcid;
		this.modalService.open(this.reloadRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {
			
		}, (reason) => {
			
		});
			
	}
	
	reloadCard(gcid: any){
	
		if(this.reloadamount.value > 0){
		const data = {'userid': this.user.user.userid, 'cardid': gcid, 'amount': this.reloadamount.value, 'comment': this.reloadcomment.value};
		
		this.systemService.addGiftCardTrans(data).subscribe(async (results: any) => {
					if(results.error == '') {
						this.giftcards = results.cards;
						this.filterGiftCards();
						this.modalService.dismissAll();
						this.globalSearchService.showNotification('Card successfully reloaded', 'success', 'bottom', 'right');
					} else {
						this.globalSearchService.showNotification(results.error, 'danger', 'bottom', 'right');
					}
				});
		} else {
		
			this.globalSearchService.showNotification('Cannot reload with negative value or 0', 'danger', 'bottom', 'right');
		}
	
	}
}
