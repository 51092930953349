import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common'

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { VehicleService } from '../../services/vehicle.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
	selector: 'app-customer-vehicles',
	templateUrl: './customer-vehicles.component.html',
	styleUrls: ['./customer-vehicles.component.scss'],
})
export class CustomerVehiclesComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('locationsearch') customersearch: ElementRef;

	loccode: any = '';
	locselected = false;
	ld: any = [];
	term: any = '';

	taxgroup: any = '';
	taxes: any = [];
	currencies: any = [];
	bankaccounts: any = [];
	types: any = [];
	zones: any = [];
	vehForm: any = false;
	groups: any = [];
	years: any = [];
	makes: any = [];
	models: any = [];
	opts: any = [];
	pandl: any = [];
	vehicles: any = [];
	results: any = [];
	headercolumns: string[] = [
		'actions',
		'plate',
		'year',
		'make',
		'model',
		'model_type'
	];





	constructor(private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public vehicleService: VehicleService) {}

	ngOnInit(): void {
		this.globalSearchService.aiDataAvailable$.next(null);

		this.vehicleService.getVehYears().subscribe(details => {
			this.years = details;
		});

		this.route.params.subscribe(params => {
			const vehid = params['id'];
			const cid = params['cid'];

			if (vehid) {

				this.vehicleService.getVehicle(vehid).subscribe((results: any) => {
					this.ld = results;
					//this.defaults.region
					this.vehForm = this.fb.group({
						id: [results.id, []],
						plate: [results.plate, [Validators.required]],
						year: [results.year, [Validators.required]],
						make: [results.make, [Validators.required]],
						model: [results.model, [Validators.required]],
						model_type: [results.model_type, [Validators.required]],
					});

				});
			} else {

				this.vehicleService.getVehicles(cid).subscribe((results: any) => {
					this.vehicles = new MatTableDataSource(results);
					this.results = results
					this.vehicles.sort = this.sort;
					this.vehicles.paginator = this.paginator;

				});
			}

		});



	}
	getMakes() {

		if (this.ld.year != '') {
			this.vehicleService.getVehMakes(this.ld.year).subscribe(details => {
				this.makes = details;
			});
		}
	}
	getModels() {
		if (this.ld.year != '' && this.ld.make != '') {
			this.vehicleService.getVehModels(this.ld.year, this.ld.make).subscribe(details => {
				this.models = details;
			});
		}
	}
	getOpts() {
		if (this.ld.year != '' && this.ld.make != '' && this.ld.model != '') {
			this.vehicleService.getVehOpts(this.ld.year, this.ld.make, this.ld.model).subscribe(details => {
				this.opts = details;
			});
		}
	}
	updateVehicle() {
		if (this.vehForm.valid) {
			this.vehicleService.updateVehicle(this.vehForm.value).subscribe(details => {

				this.router.navigate(['customers/customer-vehicles']);
			});
		}

	}
	deleteVehicle(row: any) {

		this.vehicleService.deleteVehicle(row).subscribe(details => {

			if (details.error) {
				this.showNotification(details.error, 'danger', 'top', 'center');
			} else {
				this.showNotification('Account Deleted', 'success', 'top', 'center');
				this.route.params.subscribe(params => {
					const vehid = params['id'];
					const cid = params['cid'];
					this.vehicleService.getVehicles(cid).subscribe((results: any) => {
						this.vehicles = new MatTableDataSource(results);
						this.results = results
						this.vehicles.sort = this.sort;
						this.vehicles.paginator = this.paginator;
					});
				});
			}
		});
	}
	ngAfterViewInit() {
		this.route.params.subscribe(params => {
			const vehid = params['id'];
			const cid = params['cid'];
			this.vehicleService.getVehicles(cid).subscribe((results: any) => {
				this.vehicles = new MatTableDataSource(results);
				this.results = results
				this.vehicles.sort = this.sort;
				this.vehicles.paginator = this.paginator;
			});
		});
	}
	announceSortChange(sortState: Sort) {}
	back(): void {
		this.location.back()
	}
	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: 'notifications',
			message: message

		}, {
			type: type,
			timer: 1000,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'</div>'
		});
	}

}