import { Component, NgZone, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, ViewEncapsulation, Injector } from '@angular/core';
import { ViewportScroller, Location, DatePipe } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, FormControl } from '@angular/forms';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Buffer } from 'buffer';
import { scan } from 'rxjs/operators';

@Component({
	selector: 'app-warehouse-checking-fedex',
	templateUrl: './warehouse-checking-fedex.component.html',
	styleUrls: ['./warehouse-checking-fedex.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('300ms ease-out')),
			transition('inactive => active', animate('300ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	],
	// providers: [BluetoothService]
})
export class WarehouseCheckingFedexComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('assignModal') assignModalEle: ElementRef;
	@ViewChild('manualInputRef') manualInputEle: ElementRef;
	@ViewChild('trackingModal') trackingModalRef: ElementRef;
	
	device: any;
	batteryLevel: any = '';
	color: any = 'blue';
	user: any = false;
	config: any = false;
	sortcolumns: any = [];
	scantoadd1 = new UntypedFormControl('');
	manualInput = new UntypedFormControl('');
	orderSearch = new UntypedFormControl('');
	bay1input = '';
	bays: any = {};
	numOfBays: number = 3;
	alltrucks: any;
	selectedtruck: any;
	activetrucks: any;
	truckisactive: boolean;
	dispatches: any;
	allpoints: any;
	total_order_volume: any;
	total_items: any;
	loadingdata: any;
	loadingrun: any;
	active_dispatches: any;
	active_allpoints: any;
	bayTwoInactive: boolean = false;
	errorDuration: number = 8000;
	assignedBay: any = false;
	apploaded: boolean = false;
	socketActive: boolean = false;
	showScanInput: boolean = true;
	stopSocket: boolean = false;
	@ViewChild('editzipref') zipref: ElementRef;
	fedexOrders: any = true;
	selectedOrders: any = [];
	alldispatches: any;
	filteredorders: any;
	trackingorder: any = false;


	constructor(public _zone: NgZone, private printService: PrintService, private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.dispatchService.getFedexOrders().subscribe((res) => {
			this.fedexOrders = res;
		});
	}


	ngOnDestroy(): void {
		this.stopSocket = true;
		this.omsService.disconnectOnly();	
	}


	openOrderSelection() {
		this.modalService.open(this.zipref, { ariaLabelledBy: 'modal-title', size: 'ordersel' }).result.then((result) => { }, (reason) => {
		});
	}

	selectOrder(ord, bayobj) {
		const bay = bayobj.id;
		const selected = this.bays[bay]['selectedOrders'].includes(ord.orderno);
		//delivered
		if(ord.status_id == 31){
			return;
		}
		//warn for no tracking
		if((!ord.trackingnumber || ord.shipstatus == 1) && !selected){
			if(confirm(`Order #${ord.orderno} has no tracking number assigned to it. Assign tracking?`)){
				this.openTrackingAssign(ord.orderno);
				return;
			}else{
				if(!confirm(`Ship order without a tracking number?`)){
					return;
				}else{
				
				}
			}
		}

		if (this.bays[bay]['selectedOrders'].includes(ord.orderno)) {
			var hasloads = false;
			ord['orderdetails'].forEach(line => {
				if(!hasloads){
					hasloads = line.qtyloaded > 0 ? true : false
				}
			});
			hasloads ? alert(`#${ord.orderno} has been loaded to the truck, please unload before removing.`) : this.bays[bay]['selectedOrders'].splice(this.bays[bay]['selectedOrders'].indexOf(ord.orderno), 1);

		} else {
			this.bays[bay]['selectedOrders'].push(ord.orderno);
			this.orderSearch.setValue('');
			this.bays[bay]['filteredorders'] = this.bays[bay]['alldispatches'];
		}
	}

	openTrackingAssign(orderno: any){
		this.trackingorder = orderno;
		this.modalService.open(this.trackingModalRef, { ariaLabelledBy: 'modal-title', size: 'trackingsel' }).result.then((result) => { }, (reason) => {
			this.trackingorder = false;
			this.loadData();
		});
	}

	dismissTracking(){
		// this.loadData();
		// document.getElementById('trackingexit').click();
	}

	searchOrders(bay){
		const search = this.orderSearch.value;
		if(search.length){
			this.bays[bay]['filteredorders'] = this.bays[bay]['alldispatches'].filter((o)=>o.orderno.includes(search) || o.trackingnumber?.includes(search));
		}else{
			this.bays[bay]['filteredorders'] = this.bays[bay]['alldispatches'];
		}
	}


	selectedCount(bay) {
		var count = 0;
		this.bays[bay]['alldispatches'].map((i) => {
			if (this.bays[bay]['selectedOrders'].includes(i.orderno) && i.ordertype != 0) {
				i['orderdetails'].forEach(line => {
					count += Number(line.quantity);
				});
			}
		})
		return count;
	}

	saveOrderSelection(bayid) {
		const ords = this.bays[bayid]['alldispatches'].filter(o => this.bays[bayid]['selectedOrders'].includes(o.orderno)).map((o)=> {return o.ordertype != 0 ?  o.orderno : ''})
		const disp = this.bays[bayid].active;
		this.bays[bayid] = this.resetBay(bayid);

		this.dispatchService.updateBayOrders({
			disp: disp,
			ords: ords,
			bayid: bayid
		}).subscribe((res) => {
			const data = {}
			this.omsService.loadingBayAssigned({ data, roomName: 'OrderBoard' }, cb => { });
			this.loadData();
			this.modalService.dismissAll();
		})
	}


	ngAfterViewInit(): void {
		if (this.user.user.userid != 'TVGUIDE') {
			// this.manualDeviceAssign(4);
			this.openBaySelection();
		} else {
			this.setBays();
			this.loadData();
			this.startSocketLoop();
		}
	}


	manualDeviceAssign(bay) {
		this.assignedBay = bay == '' ? false : bay;
		this.modalService.dismissAll();
		this.startSocketLoop();
		this.setBays();
		this.loadData();
	}


	handleInput(event: any, bay: any) {
		const input = event.target.value
		this.manualInput.setValue('');

		const data = {
			barcode: input,
			device: {
				truckid: bay['truck']['id'],
				bay_id: bay['id'],
				type: 'wifi'
			},
			removing: bay.removing.status,
			user: this.user.user.userid
		}

		const CHAT_ROOM = 'scanners';
		this.omsService.sendloadscan({ data, roomName: CHAT_ROOM }, cb => { });
	}

	ngOnInit(): void {
	}


	startSocketLoop() {
		if (this.stopSocket) { return; }
		if (this.socketActive == false) {
			const token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
			if (token) {

				this.omsService.setupSocketConnection(token);
				this.omsService.subscribeToLoadingBays((err, data) => {	
					this.loadData();
				});
				this.omsService.subscribeToBayScanResults((err, data: any) => {
					if (data) {
						// data.data = JSON.parse(data.data);
						this.scanlogic(data);
					}
				})
				this.omsService.subscribeToBayScanUpdates((err, data: any) => {
					if (data) {
						data = JSON.parse(data);
						data = data.bay;
						if (this.assignedBay == data.id && !data.type) {
							this.manualDeviceAssign(data.id)
						}
						if (data.type && data.type == 'picking') {
							if (this.bays[data.bay_id].dispatch.length > 0) {
								(this.bays[data.bay_id].dispatch).forEach(order => {
									if (order.orderno == data.item.orderno) {
										(order.orderdetails).forEach(line => {
											if (line.orderlineno == data.item.linenumber) {
												line.picked = data.item.picked;
												line.claimed_user = data.item.claimed_user;
												line.userid = data.user
											}
										});
									}
								});

								(this.alldispatches).forEach(order => {
									if (order.orderno == data.item.orderno) {
										(order.orderdetails).forEach(line => {
											if (line.orderlineno == data.item.linenumber) {
												line.picked = data.item.picked;
												line.claimed_user = data.item.claimed_user;
												line.userid = data.user
											}
										});
									}
								});

							}
						}
					}
				})

				this.socketActive = true;
				setTimeout(() => {
					this.socketActive = false;
					this.omsService.disconnectOnly();
					console.log('Refreshing socket...')
					this.startSocketLoop();
				}, 1000 * 20);
			}
		}
	}


	loadData() {
		//get trucks
		this.getTruckData();
		//get highest priority disp per bay
		this.dispatchService.getBayDisps('fedex').subscribe((trucks: any) => {

			if (trucks['4'].length == 0) {
				this.bays['4'] = this.resetBay(4)
			}
			if (trucks['5'].length == 0) {
				this.bays['5'] = this.resetBay(5)
			}

			trucks['data'].forEach(disp => {
				this.dispatchService.getActivePicked(disp.truckid, 'fedex').subscribe((results: any) => {
					//catch for stuck bays due to cleared runs
					if (results == '') {
						this.checkIfRunCleared(disp.dispnumber);
					} else {
						switch (disp.bay_id) {
							case '4':
								this.bays['4']= this.resetBay('4');
								this.bays['4']['alldispatches'] = results;
								this.bays['4']['filteredorders'] = results;
								this.bays['4']['selectedOrders'] = disp['orders'].filter(Boolean);
								this.bays['4']['currentloads'] = this.bays['4']['alldispatches'].filter((o)=> this.bays['4']['selectedOrders'].includes(o.orderno));
								this.bays['4'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
								this.bays['4']['title'] = this.bays['4'].truck.name;
								this.bays['4'].active = disp.dispnumber;
								this.bays['4'].shippingtype = 'FEDEX';

								if (!this.bays['4']['currentloads'].length && this.assignedBay == disp.bay_id) {
									this.openOrderSelection();
								} else if(!this.bays['4']['currentloads'].length && this.assignedBay == ''){
									this.resetBay('4');
								}
								else {	
									this.bays['4'].dispatch = this.hasStartStop(this.bays['4']['currentloads']);
									this.bays['4'].total_items = this.calcExpectedScans(this.bays['4']['currentloads']);
									this.bays['4'].scans = this.calcCompletedScans(this.bays['4']['currentloads']);
									this.fulfilledOrders(this.bays['4']);
								}
								
								break;
								case '5':
									this.bays['5']= this.resetBay('5');
									this.bays['5']['alldispatches'] = results;
									this.bays['5']['filteredorders'] = results;
									this.bays['5']['selectedOrders'] = disp['orders'].filter(Boolean);
									this.bays['5']['currentloads'] = this.bays['5']['alldispatches'].filter((o)=> this.bays['5']['selectedOrders'].includes(o.orderno));
									this.bays['5'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
									this.bays['5']['title'] = this.bays['5'].truck.name;
									this.bays['5'].active = disp.dispnumber;
									this.bays['5'].shippingtype = 'UPS';
	
									if (!this.bays['5']['currentloads'].length && this.assignedBay == disp.bay_id) {
										this.openOrderSelection();
									} else if(!this.bays['5']['currentloads'].length && this.assignedBay == ''){
										this.resetBay('5');
									}
									else {	
										this.bays['5'].dispatch = this.hasStartStop(this.bays['5']['currentloads']);
										this.bays['5'].total_items = this.calcExpectedScans(this.bays['5']['currentloads']);
										this.bays['5'].scans = this.calcCompletedScans(this.bays['5']['currentloads']);
										this.fulfilledOrders(this.bays['5']);
									}
									break;
						}
					}
				});
			});
			// this.updateAllBayDevices();
			this.apploaded = true;
		})
	}

	checkIfRunCleared(disp: any) {
		this.dispatchService.dispIsCleared({ 'disp': disp }).subscribe((res) => {
			if (res.status) {
				this.dispatchService.completeBay({ 'disp': disp }).subscribe((result) => {
					this.globalSearchService.showNotification(`Dispatch ${disp} has been cleared. Marking loading bay as complete`, 'warning', 'bottom', 'center');
				});
			}
		})
	}

	getTruckData() {
		this.dispatchService.getTrucks().subscribe((result: any) => {
			if (result.length == 1) {
				const truck = result.filter(t => {
					return t.id == result[0].id
				})[0];
			}
			this.alltrucks = result
		});
	}

	setBays() {
		const fedex = 4;
		const ups = 5;
		this.bays[`${fedex}`] = this.resetBay(fedex);
		this.bays[`${ups}`] = this.resetBay(ups);
	}



	resetBay(id) {	
		return {
			'id': `${id}`,
			'title': '',
			'alldispatches': [],
			'selectedOrders':[],
			'filteredorders': [],
			'completionRate': 0,
			'scans': 0,
			'total_items': 0,
			'dispatch': [],
			'fulfilledOrds': [],
			'truck': [],
			'active': false,
			'removing': {
				'status': false,
				'last': false
			},
			'completing': false,
			'device': [],
			'error': {
				'status': false,
				'message': ''
			},
			'hasoverscan': false,
			'finalizing': false,
			'shippingtype': false
		}
	}


	scanlogic(input) {
		const res = input.response;
		const data = JSON.parse(input.data)

		//keeps remove logic in sync regardless of initiator
		if (data) {
			this.bays[res.bay_id].removing.status = data.removing
		}

		if (res.options) { return }
		var response = true;
		if (res.success == true) {

			//clear error
			this.removeBayError(this.bays[res.bay_id]);

			//if in remove mode
			if (this.bays[res.bay_id].removing.status) {
				var filled = (this.bays[res.bay_id].fulfilledOrds).find((dis) => {
					return res.order.order == dis.order
				})
				if (filled) {
					var da = filled;
					var item = (da.orderdetails).find((item) => { return item.stockid == res.item.stockid && item.orderlineno == res.item.orderlineno })
				} else {
					var da = (this.bays[res.bay_id].dispatch).find((dis) => { return res.order.order == dis.order })
					var item = (da.orderdetails).find((item) => { return item.stockid == res.item.stockid && item.orderlineno == res.item.orderlineno })
				}
				this.removePick(this.bays[res.bay_id], da, item);
			}
			// loading
			else {
				var error = false;
				var da = (this.bays[res.bay_id].dispatch).find((dis) => { return res.order.order == dis.order })
				if (da) {
					var item = (da.orderdetails).find((item) => { return item.stockid == res.item.stockid && item.orderlineno == res.item.orderlineno });
					var index = (this.bays[res.bay_id].dispatch).indexOf(da);

					//if item match is the current active item (index 1) (not index = 0 since thats a dummy stop);
					if (index == 1) {
						this.addPick(this.bays[res.bay_id], da, item);
					} else {
						//not active
						error = true;
					}
				} else {
					//not part of da
					error = true;
				}
				if (error) {
					const err = { error: 'Scan did not match the active sku' }
					this.handleError(err, this.bays[res.bay_id]);
				}
			}

			//scan couldn't be verified
		} else {
			if (res.function) {
				this.handleControl(res.function, this.bays[res.bay_id], data);
			}
			if (res.error) {
				this.handleError(res, this.bays[res.bay_id]);
			}
		}
		if (this.assignedBay != '') {
			setTimeout(() => {
				this.manualInputEle.nativeElement.focus();
			}, 1000);
		}
	}

	getActiveSku(bay) {
		const loads = bay.dispatch[1].orderdetails;
		var active = false;
		if (loads.length) {
			(loads).forEach(line => {
				if (line.qtyloaded != line.quantity) {
					if (!active) {
						active = line.stockid;
					}
				}
			});
		}
		return active;
	}

	handleControl(control, bay, data) {
		//data  = device/barcode input to sendscan
		switch (control) {
			case 'remove':
				bay.removing.status = bay.removing.status == false ? true : false;
				break;
			case 'complete':
				this.completeBay(bay);
				break;
			case 'assign_device_1':
				this.assignDevice(data, 1)
				break;
			case 'assign_device_2':
				this.assignDevice(data, 2)
				break;
			case 'assign_device_3':
				this.assignDevice(data, 3)
				break;
			case 'mst':
				data.barcode = 'MST';
				if (this.user.user.userid == data.user) {
					this.omsService.sendloadscan({ data, roomName: 'scanners' }, cb => { });
				} else {
					//have to go by user who scanned in data object otherwise doubles up calls
					// alert('user didnt initiate this request');
				}
				break;
		}
	}

	handleError(scanResponse, bay) {
		const error = scanResponse.error;
		bay.error.message = error;
		bay.error.status = true;

		//verify that loading bay browser has auotplay detection disabled
		this.audioPlayError(bay, '');

		setTimeout(() => this.removeBayError(bay), this.errorDuration);
	}

	removeBayError(bay) {
		bay.error.message = false;
		bay.error.status = false;
	}

	assignDevice(device, bay_id) {
		const data = {
			device: device.device,
			bay_id: bay_id
		}

		this.dispatchService.assignDevice(data).subscribe((res) => {
			var position = 'left';
			if (bay_id == 2) { position = 'center' }
			if (bay_id == 3) { position = 'right' }
			if (res.success) {
				this.globalSearchService.showNotification(res.message, 'success', 'bottom', position);
				// this.updateAllBayDevices();
			} else {
				this.globalSearchService.showNotification(res.message, 'danger', 'bottom', position);
			}
		})
	}

	updateAllBayDevices() {
		this.dispatchService.getBayDevice(1).subscribe((res: any) => {
			this.bays['1'].device = res;
		})
		this.dispatchService.getBayDevice(2).subscribe((res: any) => {
			this.bays['2'].device = res;
		})
		this.dispatchService.getBayDevice(3).subscribe((res: any) => {
			this.bays['3'].device = res;
		})
	}



	removePick(bay: any, da: any, item: any) {

		if (item.qtyloaded > 0) {
			item.qtyloaded -= 1

			const request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
			}
			if (this.assignedBay == bay.id) {
				this.dispatchService.unloadItemFromTruck(request).subscribe(results => {
					if (results.success) {
						this.updateBayProgress(bay);
						bay.removing.last = `-1 x ${item.stockid}`;
						this.removeFromFulfilled(bay);
					}
				})
			} else {
				//risky
				this.updateBayProgress(bay);
				bay.removing.last = `-1 x ${item.stockid} for ${da.orderno}`;
				this.removeFromFulfilled(bay);
			}
		}
	}


	addPick(bay: any, da: any, item: any) {

		if (item.qtyloaded < item.quantity) {
			item.qtyloaded += 1

			const request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
				orderlineno: item.orderlineno
			}

			this.dispatchService.loadItemToTruck(request).subscribe(results => {
				if (results.success) {
					if (da.orderdetails.length > 1) {
						let count = da.orderdetails.length
						da.orderdetails.forEach(entry => {
							if (entry.qtyloaded == entry.quantity) {
								count--;
								if (count == 0) {
									this.manualInput.setValue('');
									const ordIndex = bay.dispatch.indexOf(da);
									const ord = bay.dispatch.splice(ordIndex, 1)[0];
									bay.fulfilledOrds.push(ord);

								}
							}

						});
					} else {
						if (item.qtyloaded == item.quantity) {
							this.manualInput.setValue('');
							const ordIndex = bay.dispatch.indexOf(da);
							const ord = bay.dispatch.splice(ordIndex, 1)[0];
							bay.fulfilledOrds.push(ord);

						}
					}

					this.updateBayProgress(bay);
				}
			})
		}
	}

	//recalculate total scans for bay
	updateBayProgress(bay) {
		bay.scans = this.calcCompletedScans(bay.dispatch) + this.calcCompletedScans(bay.fulfilledOrds);
		if (this.assignedBay != '') {
			this.manualInputEle.nativeElement.focus();
		}
	}


	//remove from fulfilled if qtyloaded < quantity
	removeFromFulfilled(bay) {
		const filled = bay.fulfilledOrds;
		var hasoverscan = false;
		(filled).forEach(da => {
			if (da.ordertype != 0 && da.header.ordertype != 'PU') {
				var rows = da.orderdetails.length;
				(da.orderdetails).forEach(item => {
					if (item.quantity <= item.qtyloaded) {
						rows--;
					}
					if (item.qtyloaded > item.quantity) {
						hasoverscan = true;
					}
				});
				if (rows != 0) {
					var ordIndex = filled.indexOf(da);
					if (bay.dispatch.length > 0) {
						bay.dispatch.splice(1, 0, filled[ordIndex]);
					} else {
						bay.dispatch.push(filled[ordIndex]);
					}
					bay.fulfilledOrds.splice(ordIndex, 1);
				}
			}
		});

		this.bays[bay.id].hasoverscan = hasoverscan;
	}


	//calc expected total amount for dispatch
	calcExpectedScans(dispatch) {
		return dispatch.reduce((acc, row) => {
			let count = 0;
			if (row.orderdetails) {
				row.orderdetails.forEach(item => {
					if (row.ordertype != '11') {
						count += item.quantity;
					}
				});
				return Number(count) + acc;
			} else {
				return acc;
			}
		}, 0);
	}


	//calc completed scans of dispatch
	calcCompletedScans(dispatch) {
		return dispatch.reduce((acc, row) => {
			let scans = 0;
			if (Array.isArray(row.orderdetails) && row.orderdetails.length > 0 && row.ordertype != '11') {
				row.orderdetails.forEach((i) => {
					scans += Number(i.qtyloaded);
				});
			}
			return scans + acc;
		}, 0);
	}


	//completed scans of indvidual orders
	ordScanAmount(ord) {
		return (ord.orderdetails).reduce((acc, row) => {
			return acc += Number(row.qtyloaded);
		}, 0);
	}


	//called on loading of bay - splits completed scans from bay.dispatch and moves to bay.fulfulilled
	fulfilledOrders(bay) {
		if (bay.active) {
			bay.fulfilledOrds = [];

			const dispatch = bay.dispatch;
			var ordsFull = [];
			var creditonly = false;


			(dispatch).forEach(da => {
				if (da.ordertype != 0 && da.ordertype != 11) {
					var rows = da.orderdetails.length;
					(da.orderdetails).forEach(item => {
						if (item.qtyloaded >= item.quantity) {
							if (item.qtyloaded > item.quantity) {
								bay.hasoverscan = true;
							}
							rows--;
							if (rows == 0) {
								var ordIndex = dispatch.indexOf(da);
								let exist = bay.fulfilledOrds.find((entry) => {
									return entry.order == da.order
								})
								if (!exist) {
									bay.fulfilledOrds.push(dispatch[ordIndex]);
								}
								ordsFull.push(dispatch[ordIndex]);
							}
						}
					});
				}
				if (da.ordertype == 11) {
					if (dispatch.length == 3 && dispatch[0].orderno == "(01) BFDSC" && dispatch[2].orderno == "(01) BFDSC") {
						creditonly = true;
					}
					var ordIndex = dispatch.indexOf(da);
					if (bay.fulfilledOrds.indexOf(dispatch[ordIndex]) < 0) {
						bay.fulfilledOrds.push(dispatch[ordIndex]);
					}
					ordsFull.push(dispatch[ordIndex]);
				}
			});

			ordsFull.forEach((ord) => {
				let orig_da_index = bay.dispatch.indexOf(ord);
				bay.dispatch.splice(orig_da_index, 1);
				// ord['orig_da_index'] = orig_da_index;
			})

			if (creditonly) {
				this.completeBay(bay);
			}
		}
	}




	completeBay(bay, overide = false) {

		if (bay.scans < bay.total_items) {
			let error = { error: "Dispatch has not been loaded. Complete request ignored." }
			this.handleError(error, bay)
			return;
		}

		if (bay.removing.status) {
			this.bays[bay.id].removing.status = false;
		}

		if (bay.hasoverscan && !overide) {
			this.bays[bay.id].completing = true;
			return;
		} else if (bay.hasoverscan && overide) {
			this.bays[bay.id].finalizing = true;
		}

		const bay_const = bay;
		this.dispatchService.completeBay({ 'disp': bay_const.active, truck: bay_const['truck']['id'], 'fedex': true }).subscribe((result) => {
			if (result.success) {
				if (bay_const.hasoverscan) {
					const data = this.buildDiscrepancy(bay_const);
					this.dispatchService.mailLoadDiscrepancy(data).subscribe((res) => {
						if (res.success) {
							this.bays[bay_const.id] = this.resetBay(bay_const.id)
							this.loadData();
						}
					})
				} else {
					this.bays[bay_const.id] = this.resetBay(bay_const.id)
					this.dispatchService.getFedexOrders().subscribe((res) => {
						this.fedexOrders = res;
						if(result.completed){
							this.alldispatches = [];
						}
						this.loadData();
					});
				}
			}
		})
	}



	audioPlayError(bayi, additional) {
		const bay = bayi
		var audio: any = false;
		var audio_extra: any = false;

		if (bay.id == 1 || bay.id == '1') {
			audio = new Audio('/assets/bay1error.mp3');
		}
		if (bay.id == 2 || bay.id == '2') {
			audio = new Audio('/assets/bay2error.mp3');
		}
		if (bay.id == 3 || bay.id == '3') {
			audio = new Audio('/assets/bay3error.mp3');
		}


		for (let index = 0; index < 3; index++) {
			if (audio) {
				setTimeout(() => {
					if (this.assignedBay == '' || this.assignedBay == bay.id) {
						audio.play();
					}
				}, 1700 * index);
			}
		}


		switch (additional) {
			case 'noBarcode':
				audio_extra = new Audio('/assets/barcodemissing.mp3');
				break;
			case 'multBarcode':
				audio_extra = new Audio('/assets/barcodemissing.mp3');
				break;
		}

		if (audio_extra) {
			setTimeout(() => {
				audio_extra.play();
			}, 1700);
		}
	}



	//was running into case of start stop points disapperaing after messing with active dispatch - edits/adds/removes (one of those did it but couldn't find where)
	hasStartStop(dispatch) {
		if(!dispatch.length){
			return [];
		}
		var dispatchWithStartStop = [];
		const dummy_stop = { orderdetails: [], ordertype: 0 };
		const end = dispatch.length - 1;

		if (dispatch[0].ordertype != 0) {
			dispatchWithStartStop = [dummy_stop, ...dispatch];
		}

		if (dispatch[end].ordertype != 0) {
			dispatchWithStartStop = [...dispatchWithStartStop, dummy_stop];
		}
		return dispatchWithStartStop.length == 0 ? dispatch : dispatchWithStartStop;
	}


	buildDiscrepancy(bay) {
		//maybe need truck route
		let table = "<table>";
		let head = "<thead><tr style='background: black; color: white;'>";
		head += "<th>&nbsp;Order#&nbsp;</th>"
		head += "<th>&nbsp;Cust#&nbsp;</th>"
		head += "<th>&nbsp;Name&nbsp;</th>"
		head += "<th>&nbsp;Loaded&nbsp;</th>"
		head += "<th>&nbsp;Ordered&nbsp;</th>"
		head += "<th>&nbsp;Stock ID&nbsp;</th>"
		head += "</tr></thead>";

		let body = "<tbody>"
		let body_end = "</tbody>";
		let table_end = "</table>";

		var lines = []
		if (bay.dispatch) {
			(bay.dispatch).forEach((disp) => {
				if (disp.ordertype != 0) {
					let ordTotalQty = disp.totalqty;
					let ordScannedQty = this.ordScanAmount(disp);
					let row;

					if (ordScannedQty > ordTotalQty) {
						row = "<tr style='background:#FF4081'>";
					} else {
						row = "<tr>";
					}

					row += "<td>" + disp.header.orderno + "</td>"
					row += "<td>" + '<a href="https://clevehilltire.com/v8/#/customers/view/' + disp.header.debtorno + '">' + disp.header.debtorno + '</td>'
					row += "<td>" + disp.header.brname + "</td>"
					row += "<td style='text-align: center'>" + ordScannedQty + "</td>"
					row += "<td style='text-align: center'>" + ordTotalQty + "</td>"
					row += "<td>" + '<a href="https://clevehilltire.com/v8/#/inventory/view/' + disp.orderdetails[0].stockid + '">' + disp.orderdetails[0].stockid + '</a></td>'
					row += "</tr>";
					body += row;
				}
			})
		}

		if (bay.fulfilledOrds) {
			(bay.fulfilledOrds).forEach((disp) => {
				if (disp.ordertype != 0) {
					let ordTotalQty = disp.totalqty;
					let ordScannedQty = this.ordScanAmount(disp);
					let row;

					if (ordScannedQty > ordTotalQty) {
						row = "<tr style='background:#FF4081'>";
					} else {
						row = "<tr>";
					}

					row += "<td>" + disp.header.orderno + "</td>"
					row += "<td>" + '<a href="https://clevehilltire.com/v8/#/customers/view/' + disp.header.debtorno + '">' + disp.header.debtorno + '</td>'
					row += "<td>" + disp.header.brname + "</td>"
					row += "<td style='text-align: center'>" + ordScannedQty + "</td>"
					row += "<td style='text-align: center'>" + ordTotalQty + "</td>"
					row += "<td>" + '<a href="https://clevehilltire.com/v8/#/inventory/view/' + disp.orderdetails[0].stockid + '">' + disp.orderdetails[0].stockid + '</a></td>'

					row += "</tr>";
					body += row;
				}
			})
		}

		const loads = table + head + body + body_end + table_end + "<br>";

		table = "<table>";
		head = "<thead><tr style='background: black; color:white'>";
		head += "<th>&nbsp;Truck&nbsp;</th>"
		head += "<th>&nbsp;Driver&nbsp;</th>"
		head += "<th>&nbsp;Phone&nbsp;</th>"
		head += "</tr></thead>";

		body = "<tbody><tr>";
		body += "<td class='text-center'>&nbsp;&nbsp;" + bay.truck.name + "&nbsp;&nbsp;</td>";
		body += "<td class='text-center'>&nbsp;&nbsp;" + bay.truck.driver + "&nbsp;&nbsp;</td>";
		body += "<td class='text-center'>&nbsp;&nbsp;" + bay.truck.phone + "</td>&nbsp;&nbsp;</tr>";

		const truck = table + head + body + body_end + table_end + "<br>";

		const res = {
			'html': {
				truck: Buffer.from(truck.toString(), 'utf8').toString('base64'),
				loads: Buffer.from(loads.toString(), 'utf8').toString('base64')
			},
			'truck': bay.truck
		}
		return res;
	}



	getSuppPartno(opts) {
		return JSON.parse(opts).suppliers_partno
	}

	openBaySelection() {
		this.modalService.open(this.assignModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => { }, (reason) => { });
	}


	resetLoads(bay) {
		[...bay.dispatch, ...bay.fulfilledOrds].forEach((ord) => {
			if (ord.orderdetails) {
				ord.orderdetails.forEach(item => {
					while (item.qtyloaded > 0) {
						this.removePick(bay, ord, item);
					}
				});
			}
		})
		this.fulfilledOrders(bay);
	}

	complete_shortcut(bay) {
		if (bay.scans == bay.total_items) {
			if (confirm(`Complete bay ${bay.id}?`)) {
				this.completeBay(bay)
			}
		}
	}

	remove_shortcut(bay) {
		const data = {
			barcode: "REMOVE",
			device: {
				truckid: bay['truck']['id'],
				bay_id: bay['id']
			}
		}
		const CHAT_ROOM = 'scanners';
		this.omsService.sendloadscan({ data, roomName: CHAT_ROOM }, cb => { });
	}

	manual_pick(bay, da, item) {
		if (this.user.user.userid == 'TVGUIDE') {
			this.addPick(bay, da, item);
			const data = {
				in: bay
			}
			this.omsService.sendloadbayupdate({ data, roomName: 'scanners' }, cb => { });
			// 	// }
		}
	}
}

