import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { DatePipe , Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../services/vendor.service';
import { PaymentsService } from '../../services/payments.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-vendor-payment-entry',
	templateUrl: './vendor-payment-entry.component.html',
	styleUrls: ['./vendor-payment-entry.component.scss']
})

//TODO - ADD TO PAYMENT CART
export class VendorPaymentEntryComponent implements OnInit {
	@Input() vendor;
	@Output() newvendordata = new EventEmitter<boolean>();
	bankaccounts: any = [];
	payment_methods: any = [];
	paymentForm: UntypedFormGroup;
	payments: any = [];
	total_payments = 0.00;
	today = new Date();
	chargetype: any = 'Check';
	result: any = false;

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private paymentsService: PaymentsService) {}


	ngOnInit(): void {
		//vendor is required. Not on GL
		this.paymentForm = this.fb.group({
			payment_type: ['', Validators.required],
			bankaccount: ['', Validators.required],
			datepaid: [this.today, Validators.required],
			checkno: [''],
			reference: [''],
			description: ['', Validators.required],
			amount: [0.00, Validators.required],
			discount: [0, Validators.required],
		});

		this.globalSearchService.bankaccounts.subscribe((accounts: any) => {
			this.bankaccounts = accounts
			if (accounts) {
				this.paymentForm.get('bankaccount').setValue(accounts[0].accountcode);
			}
		});
		this.globalSearchService.payment_methods.subscribe((payment_methods: any) => {
			this.payment_methods = payment_methods
			if (payment_methods) {
				this.paymentForm.get('payment_type').setValue(payment_methods[0].paymentname);
			}
		});
	}

	addPayment() {
		if (this.paymentForm.valid) {

			this.payments.push(this.paymentForm.value);
			this.updateTotals();

			this.globalSearchService.showNotification('Payment added to batch', 'success', 'bottom', 'right');
			this.paymentForm.get('amount').setValue(0.00);
			this.paymentForm.get('checkno').setValue('');
			this.paymentForm.get('discount').setValue(0);
		} else {
			this.globalSearchService.getFormValidationErrors(this.paymentForm);
		}
	}

	setChargeType(event: any) {
		this.chargetype = event.value
		this.updatePaymentType(event.value)
	}

	updatePaymentType(method : any) {
		this.paymentForm.get('payment_type').setValue(method);
	}

	postPayments() {
		if (this.payments.length) {
			const data = { payments: this.payments, vendor: this.vendor }
			this.paymentsService.addVendorPayment(data).subscribe(result => {
				this.result = result
				this.globalSearchService.showNotification('Payment added', 'success', 'bottom', 'right');
				//this.paymentForm.reset();
				this.payments = [];
				this.paymentForm.get('description').setValue('');
				this.paymentForm.get('amount').setValue(0.00);
				this.paymentForm.get('discount').setValue(0);
				this.paymentForm.get('reference').setValue('');
				this.paymentForm.markAsUntouched();
				this.newvendordata.emit(this.vendor.supplierid);
			});
		}
	}

	removePayment(payment: any) {
		const index = this.payments.indexOf(payment);
		this.payments.splice(index, 1);
		this.updateTotals();
	}

	updatePaymentAmount(value: any, payment: any) {
		const index = this.payments.indexOf(payment);
		this.payments[index].amount = value;
	}
	getPaymentTotal(pay: any) {
		const discount = parseFloat(pay.amount) * (parseFloat(pay.discount) / 100);
		return (parseFloat(pay.amount) - discount);
	}

	updateTotals() {
		this.total_payments = this.payments.reduce(function(accumulator: number, payments: any) {
			const discount = parseFloat(payments.amount) * (parseFloat(payments.discount) / 100);
			return accumulator + (parseFloat(payments.amount) - discount);
		}, 0);
	}

	ngAfterViewInit() {
		//defaults
	}

}
