<ng-template #creditModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Apply Credit(s) <span class="float-right">Credits Used: {{appliedCredits.length}}</span> </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" >
		<div class="mb-3">
			<table class="p-2 m-auto" *ngIf="openCredits">
				<tr>
					<th class="col-2">Credit</th>
					<th class="col-2 text-center">Total</th>
					<th class="col-2 text-center">Remaining</th>
					<th class="col-2 text-center">Applied?</th>
					<th class=""></th>
				</tr>
				<tr *ngFor="let credit of openCredits" [ngClass]="credit.applied > 0 ? 'credit-row-hl' : ''">
					<td class="col-2">{{credit.orderno}}</td>
					<td class="col-2 text-center">{{credit.total | currency}}</td>
					<td class="col-2 text-center">{{credit.leftto * -1 | currency}}</td>
					<td class="col-2 text-center">
						<ng-container *ngIf="credit.applied > 0"> {{credit.applied | currency}} </ng-container>
						<ng-container *ngIf="!credit.applied > 0">-</ng-container>
					</td>
					<td class="float-right mr-3">
						<button mat-flat-button color="primary" (click)="creditSelected(credit)" *ngIf="!appliedCredits.includes(credit)">
							<span>Apply</span>
						</button>
						<button mat-flat-button color="accent" (click)="creditRemove(credit)" *ngIf="appliedCredits.includes(credit)">
							<span>Remove</span>
						</button>
					</td>
				</tr>
			</table>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="row m-0 p-0">
<div [ngClass]="pos ? 'col-7' : 'col-12'">

<ng-container *ngIf="paymentForm">
	<h5 class="text-right mt-2 mb-2">ADD PAYMENT</h5>
	<form [formGroup]="paymentForm" class="payment-form" (ngSubmit)="addPayment()" class="text-dark">
		<div class="row text-right text-dark m-0 p-0 border ">
			<div class="text-right d-none" [ngClass]="pos ? 'col-md-4' : 'col-md-12'" *ngIf="bankaccounts">
				<mat-form-field appearance="outline" >
					<mat-label>Bank Account</mat-label>
					<mat-select formControlName="bankaccount">
						<mat-option *ngFor="let method of bankaccounts" [value]="method.accountcode">
							{{method.bankaccountname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="mt-2 text-right" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<mat-form-field appearance="outline">
					<mat-label>Payment Type</mat-label>
					<mat-select formControlName="type" (selectionChange)="updatePaymentType($event, false)" >
						<ng-container *ngFor="let method of paymentmethods">
						<mat-option [value]="method.paymentname">
							{{method.paymentname }}
						</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<ng-container *ngIf="preselected_payment && loadingcards">
				<div class="text-center" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<h5 class="text-danger">Pre Selected Payment Loading Profile</h5>
				<span>
					<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</span>
				</div>

			</ng-container>
			<ng-container *ngIf="card_required && !pos">
				<div class="text-center"  [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
					<span *ngIf="loadingcards">
						<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>

					<mat-form-field appearance="outline" *ngIf="profile_cards.length">
						<mat-label>Charge Credit Card</mat-label>
						<mat-select formControlName="card_selected" (selectionChange)="changeCard($event.value)">
							<mat-option [value]="false">
								DO NOT CHARGE
							</mat-option>
							<mat-option *ngFor="let method of profile_cards" [value]="method" [ngClass]="preselected_payment.card_id == method.card_id ? 'text-danger': ''">
							{{ method.number }} {{ method.expiry_month }}/{{ method.expiry_year }}
							<span *ngIf="preselected_payment.card_id == method.card_id">Requested</span>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</ng-container>
			<div class="text-right" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<mat-form-field appearance="outline">
					<mat-label>Reference</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" formControlName="reference" matInput appSelectOnFocus/>
				</mat-form-field>
			</div>
			<div class="text-right" [ngClass]="pos ? 'col-md-4' : 'col-md-12'">
				<mat-form-field appearance="outline">
					<mat-label>Amount</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Amount" aria-label="Amount" formControlName="amount" matInput appSelectOnFocus/>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 text-right">
				<button class="btn btn-sm float-left btn-light" type="button" (click)="loadCredits($event)" *ngIf="config.env.package == 'beauty' && openCredits.length">Apply Credit</button>
				<button
				  class="btn btn-sm"
				  [ngClass]="paymentForm.valid ? 'btn-success' : 'btn-danger'"
				  [disabled]="addingPayment"
				  type="submit"
				  >
				  Add Payment
				</button>

			</div>
		</div>
	</form>
</ng-container>
</div>
<div [ngClass]="pos ? 'col-5' : 'col-12'" class="payment-list-container">
  <h5 class="form-title mt-3 mb-1 text-right">
	{{ payments && payments.length ? 'Payments Added (' + payments.length + ')' : 'Add Payments' }}
  </h5>

  <ul class="list-group list-group-flush mt-1">
	<!-- Due Amount with Overpayment Highlight -->
	<ng-container *ngIf="payments && payments.length; else noPayments">
	  <li
		class="list-group-item d-flex justify-content-between align-items-center"
		[ngClass]="{ 'overpayment': balance() < 0 }"
	  >
		<span class="due-amount">
		  DUE: {{ totals.total + totals.adjustment | currency }}
		</span>
		<span *ngIf="balance() < 0" class="text-danger font-weight-bold ml-2">
		  Overpayment: {{ balance() | currency }}
		</span>
		<button mat-icon-button color="accent" *ngIf="showRemoveAllButton()" (click)="removeAllPayments()">
		  <mat-icon>delete_forever</mat-icon>
		</button>
	  </li>

	  <!-- List of Payments -->
	  <ng-container *ngIf="payments && payments.length">
	  <li class="list-group-item" *ngFor="let pay of payments">
		  <ng-container *ngIf="pay">
				<div class="payment-details d-flex align-items-center">
				<button mat-icon-button color="accent" class="mr-2" (click)="removePayment(pay.payment_id)">
					<mat-icon color="accent">remove</mat-icon>
				</button>
				<div class="payment-info">
					<span class="payment-type">{{ pay?.payment_type }}&nbsp;&nbsp;</span>
					<span class="payment-last-four" *ngIf="pay.options">ending {{ lastFour(pay?.options?.number) }}</span>
					<span class="payment-amount font-weight-bold">{{ pay?.amount | currency }}</span>
				</div>

				</div>
		  </ng-container>
	  </li>
	  </ng-container>
	</ng-container>

	<!-- No Payments Placeholder -->
	<ng-template #noPayments>
	  <li class="list-group-item text-center text-muted">
		No payments added.
	  </li>
	</ng-template>
  </ul>
</div>

