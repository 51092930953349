import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
  selector: 'app-single-item-transfer',
  templateUrl: './single-item-transfer.component.html',
  styleUrls: ['./single-item-transfer.component.scss']
})
export class SingleItemTransferComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('itemsearch') itemsearch: ElementRef;
  @Input() stockid: any = false;
  @Input() defaultlocation: any = false;

  transferForm: FormGroup;
  locations: any[] = [];
  availableBins: any[] = [];
  selectedItem: any = null;
  items: MatTableDataSource < any > = new MatTableDataSource();
  inventorysearching: any;
  searchTerm: string = '';
  fromLocationQoh: number;
  toLocationQoh: number;
  toBins: any[] = [];
  defaultBin: string = '';
  user: any = false;
  fromaddress: any = false;
  toaddress: any = false;
  searching: boolean = false;
  color: string;

  displayedColumns: string[] = ['stockid', 'description', 'fromLocationQoh', 'toLocationQoh', 'action'];
  fromName: any;
  toName: any;
  canChangeItem: boolean = true;

  constructor(
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    private globalSearchService: GlobalSearchService
  ) {
    this.transferForm = this.fb.group({
      fromLocation: ['', Validators.required],
      toLocation: ['', Validators.required]
    });
    this.color = this.globalSearchService.getColor();
    this.globalSearchService.user.subscribe(results => { this.user = results })
  }



  ngOnInit() {
    this.transferForm.get('fromLocation').setValue(this.user.user.defaultlocation.loccode);
    this.globalSearchService.locations.subscribe((locations: any) => {
      this.locations = locations;
      if (this.locations) {
        this.transferForm.get('fromLocation').setValue(this.user.user.defaultlocation.loccode);
        this.fromaddress = this.getAddress(this.user.user.defaultlocation.loccode)['address'];
        this.transferForm.markAllAsTouched();
        this.transferForm.updateValueAndValidity();
      }

    });

    if (this.stockid) {
      this.canChangeItem = false;
      this.searchTerm = this.stockid;
      this.searchItems('');
    }

  }

  ngAfterViewInit() {
    this.items.sort = this.sort;
    this.items.paginator = this.paginator;
  }


  searchItems(event: any) {
    this.searching = true;
    this.selectedItem = null;
    if (this.inventorysearching) {
      this.inventorysearching.unsubscribe();
    }

    const exactStockIdSearch = this.searchTerm.trim();

    this.inventorysearching = this.inventoryService.lookup({
      stockid: this.searchTerm
    }).subscribe((items: any) => {
      const exactMatch = items.find(item => item.stockid === exactStockIdSearch);
      if (exactMatch) {
        this.selectItem(exactMatch);
      } else {
        this.items = new MatTableDataSource(items);
        this.items.sort = this.sort;
        this.items.paginator = this.paginator;
        this.searching = false;

        const fromLocation = this.transferForm.get('fromLocation').value;
        const toLocation = this.transferForm.get('toLocation').value;
        if (fromLocation || toLocation) {
          this.updateItemsQoh(fromLocation, toLocation);
        }
      }
    });
  }

  selectItem(item: any) {
    this.selectedItem = item;
    this.searching = false;
    this.items = new MatTableDataSource();

    const fromLocation = this.transferForm.get('fromLocation').value;
    if (fromLocation) {
      this.onFromLocationChange({ value: fromLocation });
    }

    const toLocation = this.transferForm.get('toLocation').value;
    if (toLocation) {
      this.onToLocationChange({ value: toLocation });
    }
  }

  updateItemsQoh(fromLocation: string, toLocation: string) {
    const currentItems = this.items.data;

    currentItems.forEach(item => {
      const frombins = this.getLocBins(item, fromLocation);
      if (frombins) {
        const fromQoh = frombins.reduce((total, bin) =>
          total + (Number(bin.quantity) || 0), 0
        )
        item.fromLocationQoh = fromQoh;
      } else {
        item.fromLocationQoh = 0;
      }

      const tobins = this.getLocBins(item, toLocation);
      if (tobins) {
        const toQoh = tobins.reduce((total, bin) =>
          total + (Number(bin.quantity) || 0), 0
        )
        item.toLocationQoh = toQoh;
      } else {
        item.toLocationQoh = 0;
      }


      this.updateItemsDataSource();
    })
  }

  getLocBins(item: any, loccode: any) {
    const bins = item.qoh.filter((loc) => {
      if (loc.loccode == loccode) {
        return loc;
      }
    })
    return bins;
  }

  updateItemsDataSource() {
    this.items = new MatTableDataSource(this.items.data);
    this.items.sort = this.sort;
    this.items.paginator = this.paginator;
  }

  clearSelection() {
    this.selectedItem = null;
    this.availableBins = [];
    this.toBins = [];
    this.defaultBin = '';

    if (this.searchTerm) {
      this.selectedItem = false;
      this.searchTerm = '';
      this.searchItems(null);

    }
  }

  getAddress(loc: any) {
    const location = this.locations.find((l) =>
      l.loccode == loc
    )

    return {
      'address': `${location.deladd1} ${location.deladd2} \n ${location.deladd3} ${location.deladd4} \n ${location.deladd5} ${location.deladd6}`,
      'name': location.locationname
    }
  }

  onFromLocationChange(event: any) {
    const fromLocation = event.value;
    const toLocation = this.transferForm.get('toLocation').value;
    this.fromaddress = this.getAddress(fromLocation)['address'];
    this.fromName = this.getAddress(fromLocation)['name'];

    if (this.selectedItem) {
      const data = {
        stockid: this.selectedItem.stockid,
        loccode: fromLocation
      };

      this.inventoryService.getItemBins(data).subscribe((bins: any) => {
        this.availableBins = bins.map(bin => ({
          ...bin,
          transferQty: 0
        }));

        this.fromLocationQoh = this.availableBins.reduce((total, bin) =>
          total + (Number(bin.quantity) || 0), 0
        );
      });
    }

    if (toLocation) {
      this.updateItemsQoh(fromLocation, toLocation);
    }
  }

  onToLocationChange(event: any) {
    const toLocation = event.value;
    const fromLocation = this.transferForm.get('fromLocation').value;
    this.toaddress = this.getAddress(toLocation)['address'];
    this.toName = this.getAddress(toLocation)['name'];

    this.toLocationQoh = 0;
    this.toBins = [];
    this.defaultBin = '';

    if (this.selectedItem) {
      this.inventoryService.getItemBins({
        stockid: this.selectedItem.stockid,
        loccode: toLocation
      }).subscribe((bins: any) => {
        this.toBins = bins;

        // Find the default bin, if any
        const defaultBinObj = bins.find(bin => bin.defaultbin === 1);
        if (defaultBinObj) {
          this.defaultBin = defaultBinObj.bin;
        }

        this.toLocationQoh = bins.reduce((total, bin) =>
          total + (Number(bin.quantity) || 0), 0
        );
      });
    }

    if (fromLocation) {
      this.updateItemsQoh(fromLocation, toLocation);
    }
  }

  getTotalTransferQuantity(): number {
    return this.availableBins.reduce((total, bin) =>
      total + (Number(bin.transferQty) || 0), 0
    );
  }

  isValidTransfer(): boolean {
    if (!this.selectedItem) return false;

    const isFormValid = this.transferForm.valid;
    const differentLocation = this.transferForm.get('fromLocation').value !== this.transferForm.get('toLocation').value;
    const hasTransferQuantity = this.getTotalTransferQuantity() > 0;

    return isFormValid && differentLocation && hasTransferQuantity;
  }

  confirmTransfer() {
    if (!this.isValidTransfer()) return;

    const transferData = {
      stockid: this.selectedItem.stockid,
      description: this.selectedItem.description || '',
      fromLocation: this.transferForm.get('fromLocation').value,
      toLocation: this.transferForm.get('toLocation').value,
      userid: this.user,
      toBins: this.toBins,
      defaultBin: this.defaultBin,
      bins: this.availableBins
        .filter(bin => bin.transferQty > 0)
        .map(bin => ({
          bin: bin.bin,
          quantity: bin.transferQty,
          maxqty: bin.quantity
        }))
    };

    this.inventoryService.transferSingleItem(transferData).subscribe(
      response => {
        let message = 'Transfer completed successfully';

        if (response.data) {
          this.fromLocationQoh = response.data.fromQoh || 0;
          this.toLocationQoh = response.data.toQoh || 0;
        }
        if (response.warnings && response.warnings.length > 0) {
          message += ' with warnings';
          this.globalSearchService.showNotification(
            response.warnings.join('. '),
            'warning',
            'bottom',
            'left'
          );
        }

        this.globalSearchService.showNotification(
          message,
          'success',
          'bottom',
          'left'
        );

        // Reset the form
        this.resetForm();
      },
      error => {
        this.globalSearchService.showNotification(
          'Transfer failed: ' + (error.message || 'Unknown error'),
          'danger',
          'bottom',
          'left'
        );
      }
    );
  }

  resetForm() {
    if (this.stockid) {
      this.availableBins = this.availableBins.map(bin => ({
        ...bin,
        transferQty: 0
      }));

      const fromLocation = this.transferForm.get('fromLocation').value;
      const toLocation = this.transferForm.get('toLocation').value;

      this.onFromLocationChange({ value: fromLocation });
      this.onToLocationChange({ value: toLocation });
    } else {
      const fromLocation = this.transferForm.get('fromLocation').value;

      this.selectedItem = null;
      this.transferForm.reset();
      this.availableBins = [];
      this.toBins = [];
      this.defaultBin = '';
      this.items = new MatTableDataSource();
      this.searchTerm = '';
      this.fromLocationQoh = 0;
      this.toLocationQoh = 0;

      this.transferForm.get('fromLocation').setValue(fromLocation);
      this.onFromLocationChange({ value: fromLocation });
      this.toaddress = false;
      this.transferForm.updateValueAndValidity();
    }
  }
}