import { ChangeDetectorRef, Component, OnInit, ElementRef, Input, Output, EventEmitter, HostListener, ViewChild, TemplateRef, Renderer2 } from '@angular/core';
import { ROUTES } from '../../classes/routes';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UsersService } from '../../services/users.service';
import { Message } from '../../classes/message';
import { Chart, registerables } from 'chart.js/auto';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { interval, Subscription, Observable } from 'rxjs';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import * as moment from 'moment';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { getPrinters } from 'pdf-to-printer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DispatchService } from 'app/services/dispatch.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ClaudeService } from '../../services/claude-service.service';

declare const google: any;

Chart.register(...registerables);

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	@Output() rightSidebarEvent = new EventEmitter < boolean > ();
	@ViewChild('checkedOrders') checkedOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('pickingOrders') pickingOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('pickedOrders') pickedOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('sweeper') sweeperRef = {} as TemplateRef < any > ;
	@ViewChild('newOrders') newOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('aIBottomSheet') AIBottomSheet = {} as TemplateRef < any > ;
	@ViewChild('chartContainer', { static: false }) chartContainer!: ElementRef;

	chartInstances: Chart[] = [];
	loadingAi: boolean = false;
	cleanedAiText: string = '';

	private sort: MatSort;
	returningTruck: any;
	mapLoading: boolean = false;
	subbedToAlerts: boolean = false;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;

	navSubscription: Subscription
	idleState = 'NOT_STARTED';
	countdown ? : number = null;
	lastPing ? : Date = null;
	neworders: any = [];

	opened = false
	ordersopened = false

	listTitles: any[];
	location: Location;
	right_sidebar = true;
	mobile_menu_visible: any = 0;
	toggleButton: any;
	sidebarVisible: boolean;
	searchTerm = '';
	userData;
	orderData;
	searchResults;
	SENDER;
	messages = [];
	message;
	CHAT_ROOM = 'DashBoard';
	messageForm;
	messagecount = 0;
	public itcount = 0;
	sub;
	notificationcount: number;
	public collapseMini = false;
	color: any = 'blue';
	config: any = false;
	original_url: any = false;
	user: any = false;
	timer: any = false;

	pendingorders: any = [];
	allpendingorders: any = [];
	pendingopen: any = [];
	pickedorders: any = [];
	invoiceorders: any = [];
	pickingorders: any = [];
	pickopen: any = false;
	invoiceopen: any = false;
	checkopen: any = false;

	items_per_page = [10, 25, 50, 100, 500];
	pagesizedefault = 10;

	searchPending = new UntypedFormControl('');

	//truck alert & map
	@ViewChild('mapDiv') mapDivEle: ElementRef;
	@ViewChild('truckalert') truckalertRef: ElementRef;


	map: any = false;
	updated_map: any = false;
	markers: any = [false];
	pathlines: any = false;
	isMerging: boolean = false;
	map_animation: any = google.maps.Animation.DROP;


	durationInSeconds = 5;
	fetching: any = false;
	showsweeper: boolean = false;
	private ctrlPressed = false;
	private aPressed = false;

	@HostListener('document:keydown', ['$event'])
	handleKeyDown(event: KeyboardEvent): void {
		if (event.key === 'Control') {
			this.ctrlPressed = true;
		}
		if (event.key.toLowerCase() === 'h' && this.ctrlPressed) {
			this.aPressed = true;
		}
		if (event.key.toLowerCase() === 'j' && this.ctrlPressed && this.aPressed) {
			event.preventDefault(); // Optional: prevent browser shortcuts like 'Ctrl + A'
			if (!this.showsweeper) {
				this.showsweeper = true;
				this.openMinesweeper();
				console.log('Ctrl + h + j pressed. Sweeper activated!');
			} else {
				this.showsweeper = false;
			}
		}
	}

	@HostListener('document:keyup', ['$event'])
	handleKeyUp(event: KeyboardEvent): void {
		if (event.key === 'Control') {
			this.ctrlPressed = false;
		}
		if (event.key.toLowerCase() === 'h') {
			this.aPressed = false;
		}
	}

	aiDataAvailable: any = null;
	aiResult: any = null

	constructor(private renderer: Renderer2, private claudeService: ClaudeService, private _snackBar: MatSnackBar, private dispatchService: DispatchService, private bottomSheet: MatBottomSheet, private omsService: OmsService, private modalService: NgbModal, private ordersService: OrdersService, location: Location, private element: ElementRef, private router: Router, private globalSearchService: GlobalSearchService, private usersService: UsersService, public formBuilder: UntypedFormBuilder, private idle: Idle, keepalive: Keepalive, public cd: ChangeDetectorRef) {

		this.color = this.globalSearchService.getColor();
		this.location = location;


		this.globalSearchService.aiDataAvailable$.subscribe((status) => {
			this.aiDataAvailable = status;
		});

		this.globalSearchService.aiDataResults$.subscribe((response) => {
			this.aiResult = response;
		});



		this.globalSearchService.configsubscription.subscribe(conf => {
			this.config = conf;
		});

		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			if (this.config.package === 'beauty') {
				const data = [{ user: this.user.user.userid }];

				this.omsService.subscribeToOrderUpdates((err, data) => {
					this.loadCheckedOrders();
				});
			}

			//groups && users - phil is the user logged in on the order dashbaord tv in the office @ cornwall
			const allowed = ['11', 'PHIL', '22']
			if (this.config.env.package === 'tires' && !this.subbedToAlerts && (allowed.includes(this.user.user.user_group) || allowed.includes(this.user.user.userid))) {
				this.subbedToAlerts = true;
				this.omsService.subscribeToTruckClearAlerts(result => {
					this.showTruckReturnAlert(result.truck)
				})
			}
			//everyone else besides drivers
			if (this.config.env.package === 'tires' && (this.user.user.user_group !== '11') || this.user.user.userid === 'PHIL') {
				this.subbedToAlerts = true;
				this.omsService.subscribeToTruckClearAlerts(result => {
					const dateTime = new Date().toLocaleString();
					this.openSnackBar(`${result?.truck?.truckname} Returning | ${dateTime}`, 'Close');
				})
			}
		});

		idle.setIdle(600); // how long can they be inactive before considered idle, in seconds
		idle.setTimeout(600); // how long can they be idle before considered timed out, in seconds
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

		// do something when the user becomes idle
		idle.onIdleStart.subscribe(() => {
			this.idleState = 'IDLE';
			this.original_url = this.router.url
			this.autonav();
		});
		// do something when the user is no longer idle
		idle.onIdleEnd.subscribe(() => {
			this.idleState = 'NOT_IDLE';
			this.countdown = null;
			cd.detectChanges(); // how do i avoid this kludge?
			this.reset();
			this.router.navigate([this.original_url]);
		});

		// do something when the user has timed out
		idle.onTimeout.subscribe(() => this.idleState = 'TIMED_OUT');
		// do something as the timeout countdown does its thing
		idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 15000
		});
	}

	orderInNewOrder(data: any) {
		const thisorderexits = this.neworders.filter((a: any) => {
			return a.orderno == data.orderno;
		});
		return thisorderexits[0];
	}

	togglePickedOrders() {
		if (!this.pickopen) {
			this.pickopen = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.pickedOrdersTemplate, config);
		} else {
			this.pickopen = false;
			this.bottomSheet.dismiss(this.pickedOrdersTemplate);
		}
	}

	togglePickingOrders() {
		if (!this.pickopen) {
			this.pickopen = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.pickingOrdersTemplate, config);
		} else {
			this.pickopen = false;
			this.bottomSheet.dismiss(this.pickingOrdersTemplate);
		}
	}

	toggleCheckedOrders() {
		if (!this.checkopen) {
			this.checkopen = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.checkedOrdersTemplate, config);
		} else {
			this.checkopen = false;
			this.bottomSheet.dismiss(this.checkedOrdersTemplate);
		}
	}

	toggleNewOrders() {
		if (!this.ordersopened) {
			this.ordersopened = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.newOrdersTemplate, config);
		} else {
			this.ordersopened = false;
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		}
	}

	toggleBottomSheet() {
		if (!this.opened) {
			this.opened = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.checkedOrdersTemplate, config);
		} else {
			this.opened = false;
			this.bottomSheet.dismiss(this.checkedOrdersTemplate);
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	getColor() {
		return this.color;
	}

	resetNewOrders() {
		this.neworders = [];
		this.bottomSheet.dismiss(this.newOrdersTemplate);
	}

	closeBottomSheet() {
		this.bottomSheet.dismiss(this.checkedOrdersTemplate);
	}

	autonav() {

		if (this.idleState == 'IDLE') {
			if (this.user && this.user.user.user_group == '21') {
				this.navSubscription = interval(15000).subscribe((x => {
					this.rotateNav();
				}));
			} else {
				const d = new Date();
				const hour = d.getHours();
				if (hour > 20) {
					this.reset();
					//this.router.navigate(['auth/logout']);
					location.reload()
				}
			}
		}
	}

	rotateNav() {

		const navoptions = [
			'warehouse/warehouse-reports',
			'dashboard',
		]

		if (this.itcount == navoptions.length - 1) {
			this.itcount = 0;
		}

		const newroute = navoptions[this.itcount];
		if (this.user) {
			this.router.navigate([newroute]);
			this.itcount = this.itcount + 1
		}

		if (
			this.router.url != '/orders/entry' &&
			this.router.url != '/inventory/lookup' &&
			this.router.url != '/dispatches/route' &&
			this.router.url != '/orders/pick'

		) {

			if (this.sidebarVisible) {
				//	this.sidebarToggle();
				//	this.collapseMini = false;
				//	this.miniSideBar()
			}

			//this.router.navigate([newroute]);
		} else {
			//not allowed here.
		}

	}

	keyPress(event: KeyboardEvent): void {
		this.reset();
	}

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e) {
		this.reset();
	}

	reset() {
		// we'll call this method when we want to start/reset the idle process
		// reset any component state and be sure to call idle.watch()
		this.idle.watch();
		this.idleState = 'NOT_IDLE';
		this.countdown = null;
		this.lastPing = null;
		if (this.navSubscription) {
			this.navSubscription.unsubscribe();
		}



	}

	ngOnInit() {

		this.reset();
		this.setPagination([]);

		this.userData = this.usersService.getLocalUser();

		this.globalSearchService.messages.subscribe((results: any) => {
			this.messagecount = results;
		});

		this.globalSearchService.searchTerm.subscribe((newValue: any) => {
			this.searchTerm = newValue;
		});

		this.globalSearchService.userData.subscribe((newValue: any) => {
			this.userData = newValue;
			this.user = newValue;
			//this.loadCheckedOrders();
		});

		this.globalSearchService.searchResults.subscribe((newValue: any) => {
			this.searchResults = newValue;
		});

		this.listTitles = ROUTES.filter(listTitle => listTitle);

		this.listTitles.forEach(a => {
			if (a.children) {
				a.children.forEach(b => {
					if (this.listTitles.indexOf(b) < 1) {
						this.listTitles.push(b);
					}
				})
			}
		});

		const navbar: HTMLElement = this.element.nativeElement;
		this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
		this.router.events.subscribe((event) => {
			this.sidebarClose();
			const $layer: any = document.getElementsByClassName('close-layer')[0];
			if ($layer) {
				$layer.remove();
				this.mobile_menu_visible = 0;
			}
		});

		const navstate = this.usersService.getNavState();
		if (navstate) {
			this.collapseMini = false;
			this.miniSideBar();
		}


		this.loadCheckedOrders();

	}

	audioPlayNewOrder() {

		//var audio = new Audio("/assets/out-of-nowhere-message-tone.mp3");
		//let play = audio.play();
		// if (play !== undefined) {
		// 	play.catch(error => {
		// 		// Auto-play was prevented
		// 		// Show a UI element to let the user manually start playback
		// 	}).then(() => {
		// 		// Auto-play started
		// 	});
		// }
	}



	loadCheckedOrders() {
		// Setup search parameters with direct default location assignment
		const search = {
			loccode: this.user?.user.defaultlocation.loccode || '',
			debtorno: false,
			newonly: true,
		};

		// Cancel any active subscription
		if (this.fetching) {
			this.fetching?.unsubscribe();
		}

		// Process orders in a single-pass reduce function
		this.fetching = this.ordersService.getCustomerOpenOrdersFiltered(search).subscribe((results: any) => {
			if (results && results.length) {
				const categorizedOrders = results.reduce(
					(acc, order) => {
						const orderStatusId = parseInt(order.orderstatusid, 10);

						// Only process orders with placedfrom values of '3' or '4'
						if (order.placedfrom === '3' || order.placedfrom === '4') {
							switch (true) {
								case orderStatusId === 1:
									acc.pendingorders.push(order);
									break;
								case orderStatusId >= 5 && orderStatusId < 21:
									acc.pickingorders.push(order);
									break;
								case orderStatusId === 21:
									acc.pickedorders.push(order);
									break;
								case orderStatusId === 24:
									acc.invoiceorders.push(order);
									break;
							}
						}
						return acc;
					}, { pendingorders: [], pickingorders: [], pickedorders: [], invoiceorders: [] }
				);

				this.pendingorders = categorizedOrders.pendingorders;
				this.allpendingorders = categorizedOrders.pendingorders;
				this.setPagination(this.pendingorders);

				this.pickingorders = categorizedOrders.pickingorders;
				this.pickedorders = categorizedOrders.pickedorders;
				this.invoiceorders = categorizedOrders.invoiceorders;
			}


		});
	}



	loadOrder(orderno: any) {
		this.globalSearchService.reloadingOrder(true);
		this.ordersService.loadSalesOrder(orderno).subscribe((result: any) => {
			this.router.navigate(['./orders/entry/' + result.header.debtorno + '/' + result.header.branchcode]);
			this.globalSearchService.reloadingOrder(false);
			this.spliceandCloseNewOrder(orderno);
		});
	}

	spliceandCloseNewOrder(orderno: any) {
		//REMOVED New orders - made more sense for pending
		if (!this.neworders.length) {
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		} else {
			//not sure if this is wanted. place holder
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		}
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./customers/view/' + event.debtorno]);
	}

	pickOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['./orders/pick/' + event]);
	}

	checkOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['./orders/check/' + event]);
	}

	invoiceOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['orders/invoice/' + event + '/dispatch']);
	}

	sidebarOpen() {
		const toggleButton = this.toggleButton;
		const body = document.getElementsByTagName('body')[0];
		setTimeout(function() {
			toggleButton.classList.add('toggled');
		}, 500);

		body.classList.add('nav-open');

		this.sidebarVisible = true;
	};

	sidebarClose() {
		const body = document.getElementsByTagName('body')[0];
		this.toggleButton.classList.remove('toggled');
		this.sidebarVisible = false;
		body.classList.remove('nav-open');
	};

	sidebarToggle() {
		// const toggleButton = this.toggleButton;
		// const body = document.getElementsByTagName('body')[0];
		const $toggle = document.getElementsByClassName('navbar-toggler')[0];
		//this.usersService.setNavState(this.sidebarVisible)

		if (this.sidebarVisible === false) {
			this.sidebarOpen();
		} else {
			this.sidebarClose();
		}
		const body = document.getElementsByTagName('body')[0];

		if (this.mobile_menu_visible == 1) {
			// $('html').removeClass('nav-open');
			body.classList.remove('nav-open');
			if ($layer) {
				$layer.remove();
			}
			setTimeout(function() {
				$toggle.classList.remove('toggled');
			}, 400);

			this.mobile_menu_visible = 0;
		} else {
			setTimeout(function() {
				$toggle.classList.add('toggled');
			}, 430);

			var $layer = document.createElement('div');
			$layer.setAttribute('class', 'close-layer');

			if (body.querySelectorAll('.main-panel')) {
				document.getElementsByClassName('main-panel')[0].appendChild($layer);
			} else if (body.classList.contains('off-canvas-sidebar')) {
				document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
			}

			setTimeout(function() {
				$layer.classList.add('visible');
			}, 100);

			$layer.onclick = function() { //asign a function
				body.classList.remove('nav-open');
				this.mobile_menu_visible = 0;
				$layer.classList.remove('visible');
				setTimeout(function() {
					$layer.remove();
					$toggle.classList.remove('toggled');
				}, 400);
			}.bind(this);

			body.classList.add('nav-open');
			this.mobile_menu_visible = 1;
		}
	};

	getTitle() {

		let titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee.charAt(0) === '#') {
			titlee = titlee.slice(1);
		}
		if (this.listTitles) {
			for (let item = 0; item < this.listTitles.length; item++) {
				if (this.listTitles[item].path === this.router.url) {
					return this.listTitles[item].title;
				}
			}
		}

		const subpath = titlee.split('/', 3);

		return this.capitalize(subpath)

		return 'Dashboard';
	}

	public capitalize(s) {

		let fullstring = '';
		s.forEach(function(value) {
			fullstring += value && value[0].toUpperCase() + value.slice(1) + ' ';
		});

		return fullstring;
	}

	public miniSideBar() {
		const body = document.getElementsByTagName('body')[0];

		if (this.collapseMini) {
			this.collapseMini = false;
			body.classList.remove('sidebar-mini');
		} else {
			this.collapseMini = true;
			body.classList.add('sidebar-mini');
		}

		this.usersService.setNavState(this.collapseMini);
	}

	public onInput(event: any) {
		// this pushes the input value into the service's Observable.
		this.globalSearchService.searchTerm.next(event.target.value);
	}

	right_side_bar() {
		this.right_sidebar = !this.right_sidebar
		this.rightSidebarEvent.emit(this.right_sidebar)
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this.cd.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}

	searchOpenOrders(event: any) {
		if (event != '') {
			this.pendingorders = this.globalSearchService.filterItem(this.allpendingorders, event, 'orderno,deliverto,debtorno,deladdress1,deladdress3');
			this.setPagination(this.pendingorders);
		} else {
			this.pendingorders = this.allpendingorders;
			this.setPagination(this.pendingorders);
		}
	}

	resetCount() {
		this.messagecount = 0;
	}

	closeMineSweeper(): void {
		this.bottomSheet.dismiss(this.sweeperRef);
	}

	openMinesweeper(): void {
		const config = {
			hasBackdrop: true
		}

		this.bottomSheet.open(this.sweeperRef, config);
	}

	showTruckReturnAlert(data) {
		this.returningTruck = data;
		this.dispatchService.getActiveDispatches().subscribe((res: any) => {
			// if(res.in this.user.user.userid)

			if (!res['drivers'].includes(this.user.user.userid)) {
				this.modalService.open(this.truckalertRef, { ariaLabelledBy: 'modal-title', size: 'xl', backdropClass: 'interrupt', backdrop: 'static', keyboard: false, centered: true, windowClass: 'interrupt' }).result.then((result) => {}, (reason) => {});
				this.mapLoading = true;

				setTimeout(() => {
					this.drawMap(data);
				}, 2500)
			}
		})

	}

	drawMap(truck) {

		this.globalSearchService.hideSideBar();
		const coordinates = [];
		const lat = truck.lat;
		const lng = truck.lng;
		const truckname = truck.truckname;
		//bfdsc
		const home = { lat: 42.928455044944165, lng: -78.82142723259965 }
		const truckpos = { lat: Number(lat), lng: Number(lng) }
		const trucklat = new google.maps.LatLng(truckpos);
		const lines = [trucklat];
		lines.push(home)

		const m = new google.maps.Marker({
			position: home,
			title: 'BFDSC',
			optimized: true,
			animation: google.maps.Animation.DROP,
			label: {
				text: 'BFDSC',
				color: '#000',
				fontSize: '14px',
				fontWeight: 'bold'
			}
		}, );

		coordinates.push(m);

		const c = new google.maps.Marker({
			position: trucklat,
			title: truckname,
			optimized: true,
			animation: google.maps.Animation.DROP,
			label: {
				text: truckname,
				color: '#000',
				fontSize: '14px',
				fontWeight: 'bold'
			}
		});

		coordinates.push(c);

		const mapOptions = {
			zoom: 11,
			center: truckpos,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				'featureType': 'water',
				'stylers': [{
					'saturation': 43
				}, {
					'lightness': -11
				}, {
					'hue': '#0088ff'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'geometry.fill',
				'stylers': [{
					'hue': '#ff0000'
				}, {
					'saturation': -100
				}, {
					'lightness': 99
				}]
			}, {
				'featureType': 'road',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'color': '#808080'
				}, {
					'lightness': 54
				}]
			}, {
				'featureType': 'landscape.man_made',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ece2d9'
				}]
			}, {
				'featureType': 'poi.park',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ccdca1'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'labels.text.fill',
				'stylers': [{
					'color': '#767676'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'labels.text.stroke',
				'stylers': [{
					'color': '#ffffff'
				}]
			}, {
				'featureType': 'poi',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'landscape.natural',
				'elementType': 'geometry.fill',
				'stylers': [{
					'visibility': 'on'
				}, {
					'color': '#b8cb93'
				}]
			}, {
				'featureType': 'poi.park',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.sports_complex',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.medical',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.business',
				'stylers': [{
					'visibility': 'simplified'
				}]
			}]

		};

		this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

		coordinates.forEach((item: any) => {
			item.setMap(this.map);
		});

		const lineSymbol = {
			path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		};

		const pathTo = new google.maps.Polyline({
			path: lines,
			geodesic: true,
			strokeColor: '#F1575A',
			strokeOpacity: 1.0,
			strokeWeight: 2,
			icons: [{
				icon: lineSymbol,
				offset: '100%'
			}],
		});

		pathTo.setMap(this.map);
		this.mapLoading = false;
	}

	formatText(text: string): string {
		// 🔥 Remove [CHART_START]...[CHART_END] blocks
		text = text.replace(/\[CHART_START\][\s\S]*?\[CHART_END\]/g, '');

		// Apply formatting rules
		text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>'); // Bold text
		text = text.replace(/\n/g, '<br>'); // Line breaks
		text = text.replace(/•/g, '🔹'); // Bullet points
		text = text.replace(/(\d+)\.\s/g, '<span class="numbered-point">$1.</span> '); // Numbered lists
		text = text.replace(/```([\s\S]*?)```/, '<pre class="ascii-chart">$1</pre>'); // ASCII charts
		text = text.replace(/(📱\s*Contact.*?:\s*)(\d{3}-\d{3}-\d{4})/g, '$1<a href="tel:$2">$2</a>'); // Phone links

		return text.trim();
	}



	closeAi() {
		this.bottomSheet.dismiss(this.AIBottomSheet);
	}



	processAiResponse(): void {
		if (!this.aiResult || !this.aiResult.content) {
			this.cleanedAiText = '';
			return;
		}

		const fullText = this.aiResult.content
			.filter((item: any) => item.type === 'text')
			.map((item: any) => item.text)
			.join("\n\n");

		this.cleanedAiText = this.cleanText(fullText);
	}

	cleanText(text: string): string {
		return text
			.replace(/\[CHART_DATA_FOLLOWS\]/g, '')
			.replace(/\[CHART_START\][\s\S]*?\[CHART_END\]/g, '')
			.trim();
	}

	updateAiResult(newResult: any): void {
		this.aiResult = newResult;
		this.processAiResponse(); // Clean text
		setTimeout(() => this.extractAndRenderCharts(), 100);
	}

	openAIBottomSheet(): void {

		this.loadingAi = true;

		const config = {
			hasBackdrop: true
		}
		this.bottomSheet.open(this.AIBottomSheet, config);

		this.globalSearchService.aiDataAvailable$.subscribe(data => {
			this.loadingAi = true; // Show loading spinner
			// this.loadingAi = false; // Show loading spinner
			// const result =  {
			// 	"id": "msg_0142TZz8ZskLP4mLaHW2hZD4",
			// 	"type": "message",
			// 	"role": "assistant",
			// 	"model": "claude-3-5-sonnet-20241022",
			// 	"content": [
			// 		{
			// 			"type": "text",
			// 			"text": "Business Insights Report - Inventory Transaction Analysis\n\nREPORT TYPE: Inventory Movement Analysis for Product SUM-ATH87\n\nKEY METRICS:\n1. Total Transactions: 17\n2. Average Selling Price: $124.80\n3. Average Purchase Price: $109.49\n4. Gross Margin: ~12.2%\n5. Total Units Sold: 26\n6. Total Units Purchased: 29\n\nTRENDS & PATTERNS:\n1. Price Volatility\n- Selling price ranges from $116.10 to $130.71\n- Purchase price ranges from $100.00 to $112.21\n- Gradual price increase trend over the analyzed period\n\n2. Order Patterns\n- Average order quantity for sales: 4 units\n- Purchase orders typically range from 6-8 units\n- Most frequent customers: Rear End Specialist, Snyder Automotive\n\n3. Inventory Management\n- Maintaining low inventory levels (0-8 units)\n- Regular restocking pattern when inventory reaches 0-3 units\n- Average inventory turn time: ~45 days\n\nRECOMMENDATIONS:\n1. Optimize Order Quantities\n   - Consider implementing min/max inventory levels based on demand patterns\n   - Standardize purchase order quantities to optimize shipping costs\n\n2. Price Standardization\n   - Implement consistent pricing strategy to maintain margins\n   - Consider volume-based pricing tiers for frequent customers\n\n3. Stock Level Management\n   - Set up automated reorder points to prevent stockouts\n   - Monitor lead times to optimize inventory levels\n\n4. Customer Relationship Management\n   - Develop specific programs for high-volume customers\n   - Track customer purchase patterns for better forecasting\n\n5. Margin Management\n   - Review pricing strategy to maintain target margins\n   - Consider bulk purchase discounts from suppliers to reduce costs\n\n[CHART_DATA_FOLLOWS]\n\n[CHART_START]\nid: inventory_price_trends\ntype: line\nlabels: 02/24, 03/24, 05/24, 06/24, 09/24, 10/24, 12/24, 01/25, 02/25\nvalues: 112.21, 130.71, 130.71, 130.71, 126.60, 126.60, 116.10, 118.50, 118.50\nseries: Average Monthly Selling Price\n[CHART_END]"
			// 		}
			// 	],
			// 	"stop_reason": "end_turn",
			// 	"stop_sequence": null,
			// 	"usage": {
			// 		"input_tokens": 3658,
			// 		"cache_creation_input_tokens": 0,
			// 		"cache_read_input_tokens": 0,
			// 		"output_tokens": 548
			// 	}
			// };

			//this.updateAiResult(result);

//
// 			if(data) {
//
// 				this.claudeService.analyzeReport(data).subscribe({
// 					next: (response) => {
// 						this.loadingAi = false;
// 						// Handle API errors
// 						if (response.type === 'error') {
// 							console.log(response.error.message)
// 							if (response.error.type === 'overloaded_error') {
// 								this.aiResult = "🚦 Claude is currently overloaded. Please try again in a few moments.";
// 							} else {
// 								this.aiResult = `⚠️ Error: ${response.error.message}`;
// 							}
// 						} else {
// 							// Normal response
// 							this.updateAiResult(response);
//
// 						}
// 					},
// 					error: (err) => {
// 						this.loadingAi = false;
// 						console.log(err);
// 						this.aiResult = "❌ An error occurred while processing the report. Please try again.";
// 					}
// 				});
// 			}

			if (data) {
// 				this.claudeService.analyzeReport(data).subscribe({
// 					next: (response) => {
// 						this.loadingAi = false;
//
// 						// Handle API errors
// 						if (response.type === 'error') {
// 							console.log(response.error.message);
// 							if (response.error.type === 'overloaded_error') {
// 								this.aiResult = "🚦 Claude is currently overloaded. Please try again in a few moments.";
// 							} else {
// 								this.aiResult = `⚠️ Error: ${response.error.message}`;
// 							}
// 							return;
// 						}
//
// 						try {
// 							// Ensure the response is treated as a string
// 							const responseStr = typeof response === 'string' ? response : JSON.stringify(response);
//
// 							// Match and extract all JSON-like segments containing `"content": "..."` data
// 							const contentArray = responseStr.match(/data:\s*({.*?})/gs) // Use `gs` to match across lines
// 								?.map(match => {
// 									try {
// 										return JSON.parse(match.replace(/^data:\s*/, '')).content; // Parse and extract content
// 									} catch (err) {
// 										console.warn("Skipping invalid JSON segment:", match);
// 										return ''; // Skip invalid segments
// 									}
// 								})
// 								.join('') || ''; // Join extracted content into a full string
//
// 							// Wrap the extracted text in the expected structure
// 							this.aiResult = {
// 								id: `msg_${Date.now()}`,
// 								type: "message",
// 								role: "assistant",
// 								model: "claude-3-5-sonnet-20241022",
// 								content: [
// 									{
// 										type: "text",
// 										text: contentArray.trim() // Ensure no extra spaces
// 									}
// 								],
// 								stop_reason: "end_turn",
// 								stop_sequence: null,
// 								usage: {
// 									input_tokens: 0, // Can be dynamically set if needed
// 									cache_creation_input_tokens: 0,
// 									cache_read_input_tokens: 0,
// 									output_tokens: contentArray.length // Rough estimation
// 								}
// 							};
//
// 							this.updateAiResult(this.aiResult);
// 						} catch (error) {
// 							console.error("Error processing AI response:", error);
// 							this.aiResult = "❌ Failed to process AI response. Please try again.";
// 						}
//
// 					},
// 					error: (err) => {
// 						this.loadingAi = false;
// 						console.log(err);
// 						this.aiResult = "❌ An error occurred while processing the report. Please try again.";
// 					}
// 				});
			}


		});
	}

	containsChart(result: any): boolean {
		if (!result || !result.content) return false;

		return result.content.some((item: any) => /\[CHART_START\]/.test(item.text));
	}


	extractAndRenderCharts(): void {
		this.destroyCharts(); // Clear old charts

		if (!this.chartContainer || !this.chartContainer.nativeElement) return;

		const chartMatches = this.aiResult.content
			.map((item: any) => item.text.match(/\[CHART_START\]([\s\S]*?)\[CHART_END\]/g))
			.flat()
			.filter(Boolean);

		chartMatches.forEach(chartText => {
			const chartInfo = this.parseChartMetadata(chartText);
			if (!chartInfo) return;

			const newCanvas = this.renderer.createElement('canvas');
			newCanvas.id = chartInfo.id;

			// Create a wrapper div to control chart size
			const chartWrapper = this.renderer.createElement('div');
			this.renderer.setStyle(chartWrapper, 'width', '100%');
			this.renderer.setStyle(chartWrapper, 'height', '400px'); // Set a fixed height to prevent collapsing
			this.renderer.setStyle(chartWrapper, 'max-height', '500px'); // Adjust max height as needed
			this.renderer.setStyle(chartWrapper, 'position', 'relative'); // Ensures proper scaling

			this.renderer.appendChild(chartWrapper, newCanvas);
			this.renderer.appendChild(this.chartContainer.nativeElement, chartWrapper);

			const ctx = newCanvas.getContext('2d');

			// Ensure the canvas container has a proper height before initializing Chart.js
			newCanvas.style.height = '100%';
			newCanvas.style.width = '100%';

			const newChart = new Chart(ctx, {
				type: chartInfo.type,
				data: {
					labels: chartInfo.labels,
					datasets: [{
						label: chartInfo.series,
						data: chartInfo.values,
						backgroundColor: this.getRandomColors(chartInfo.values.length),
						borderColor: '#666',
						borderWidth: 1
					}]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						y: { beginAtZero: true } // Ensures proper Y-axis scaling
					},
					layout: {
						padding: { top: 10, bottom: 10 } // Adds spacing around the chart
					},
					plugins: { legend: { position: 'bottom' } }
				}
			});

			// 🔥 Store chart instance for later destruction
			this.chartInstances.push(newChart);
		});

	}


	parseChartMetadata(chartText: string): any {
		const idMatch = chartText.match(/id:\s*(.+)/);
		const typeMatch = chartText.match(/type:\s*(.+)/);
		const labelsMatch = chartText.match(/labels:\s*(.+)/);
		const valuesMatch = chartText.match(/values:\s*(.+)/);
		const seriesMatch = chartText.match(/series:\s*(.+)/);

		if (!idMatch || !typeMatch || !labelsMatch || !valuesMatch || !seriesMatch) return null;

		return {
			id: idMatch[1].trim(),
			type: typeMatch[1].trim(),
			labels: labelsMatch[1].split(',').map(label => label.trim()),
			values: valuesMatch[1].split(',').map(value => parseFloat(value)), // Ensure numeric values
			series: seriesMatch[1].trim()
		};
	}

	getChartType(chartId: string): string {
		if (chartId.includes('Sales') || chartId.includes('Performance')) return 'line';
		if (chartId.includes('brandSales')) return 'bar';
		if (chartId.includes('brandChart')) return 'pie'; // Detect pie charts
		return 'bar'; // Default to bar chart
	}

	getRandomColors(count: number): string[] {
		const colors = ['#ff5f6d', '#ff9966', '#4caf50', '#3f51b5', '#ffeb3b', '#9c27b0', '#ff5722'];
		return Array.from({ length: count }, (_, i) => colors[i % colors.length]);
	}


	destroyCharts(): void {
		this.chartInstances.forEach(chart => chart.destroy());
		this.chartInstances = [];
	}

	ngOnDestroy() {

	}
}