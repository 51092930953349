import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, take } from 'rxjs/operators';

import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
	ClassicEditor,
	AccessibilityHelp,
	Autoformat,
	Autosave,
	BlockQuote,
	Bold,
	Code,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	Highlight,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	ListProperties,
	Paragraph,
	PasteFromOffice,
	SelectAll,
	Strikethrough,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo,
	type EditorConfig
} from 'ckeditor5';

@Component({
	selector: 'app-customer-statements',
	templateUrl: './customer-statements.component.html',
	styleUrls: ['./customer-statements.component.scss', './customer-statements.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CustomerStatementsComponent implements OnInit, AfterViewInit {
	@Input() customerdata;
	statements: any;
	config: any = false;
	statementLink: any = '';
	today = new Date();
	todate = new UntypedFormControl(new Date());
	email = new UntypedFormControl('');
	emailMessage = new UntypedFormControl('');
	sending: any = false;
	searchsubscripiton: any = false;
	salespeople: any = [];
	private statementSubscription: Subscription;
	filteredSalesmen: any = {};
	constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private globalsService: GlobalsService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.statementLink = r.apiServer.statementLink;
		});
		this.globalSearchService.salespeople.subscribe(r => {
			this.salespeople = r;
		});
		this.globalSearchService.aiDataAvailable$.next(null);
	}

	public isLayoutReady = false;
	public loading = false;
	public Editor = ClassicEditor;
	public ckconfig: EditorConfig = {};


	filterBySalesmanCode(salesmanCode: string) {
		return this.salespeople.filter(salesman => salesman.salesmancode === salesmanCode)[0];
	}

	base64Decode(content: string) {
		try {
			return atob(content);
		} catch (error) {
			return '';
		}
	}

	ngOnInit(): void {
		this.getStatement();
		const cutoffDate = new Date(this.todate.value).toISOString().split('T')[0];

		this.statementLink = this.config.apiServer.baseUrl
							+ this.config.apiServer.statementLink
							+ '&debtorno=' + this.customerdata.debtorno
							+ '&cutoff=' + cutoffDate;


		this.todate.valueChanges.subscribe(newValue => {

			const cutoffDate = new Date(this.todate.value).toISOString().split('T')[0];

			this.statementLink = this.config.apiServer.baseUrl
								+ this.config.apiServer.statementLink
								+ '&debtorno=' + this.customerdata.debtorno
								+ '&cutoff=' + cutoffDate;

			this.getStatement();
		});
	}

	getStatement() {
		const request = {
			debtorno: this.customerdata.debtorno,
			branchcode: this.customerdata.branchcode,
			todate: this.todate.value
		}


		// Unsubscribe from the previous subscription if it's still running
		if (this.statementSubscription) {
			this.statementSubscription.unsubscribe();
		}
		this.loading = true;
		// Make the new request and subscribe with take(1)
		this.statementSubscription = this.customerService.getStatements(request).pipe(take(1)).subscribe((results: any) => {
			const salesman = this.filterBySalesmanCode(this.customerdata.customer.salesman);
			let takon = '';
			if (salesman && salesman.smaneamil) {
				takon = (this.customerdata.customer.statementemailaddress) ? ',' + salesman.smaneamil : '' + salesman.smaneamil;
			}
			if (this.customerdata && this.customerdata.customer.statementemailaddress) {
				this.email.setValue(this.customerdata.customer.statementemailaddress + takon);
			}

			if (!this.customerdata.customer.statementemailaddress && takon !== '') {
				this.email.setValue(takon);
			}
			this.loading = false;
			// Set the email message and statement data
			this.emailMessage.setValue(results.message);
			this.statements = results;
		});
	}

	emailStatement() {
		const data = {
			debtorno: this.customerdata.debtorno,
			branchcode: this.customerdata.branchcode,
			type: 'email',
			email: this.email.value,
			message: this.emailMessage.value
		}
		this.sending = true;
		if (this.searchsubscripiton) {
			this.searchsubscripiton.unsubscribe();
		}
		this.searchsubscripiton = this.customerService.sendStatement(data).subscribe((results: any) => {
			this.globalSearchService.showNotification('Email Sent', 'success', 'bottom', 'right');
			this.sending = false;
		});
	}

	ngAfterViewInit(): void {
		this.ckconfig = {
			toolbar: {
				items: [
					'undo',
					'redo',
					'|',
					'heading',
					'|',
					'fontSize',
					'fontFamily',
					'fontColor',
					'fontBackgroundColor',
					'|',
					'bold',
					'italic',
					'underline',
					'strikethrough',
					'code',
					'|',
					'link',
					'insertTable',
					'highlight',
					'blockQuote',
					'|',
					'bulletedList',
					'numberedList',
					'todoList',
					'outdent',
					'indent'
				],
				shouldNotGroupWhenFull: true
			},
			plugins: [
				AccessibilityHelp,
				Autoformat,
				Autosave,
				BlockQuote,
				Bold,
				Code,
				Essentials,
				FontBackgroundColor,
				FontColor,
				FontFamily,
				FontSize,
				Heading,
				Highlight,
				Indent,
				IndentBlock,
				Italic,
				Link,
				List,
				ListProperties,
				Paragraph,
				PasteFromOffice,
				SelectAll,
				Strikethrough,
				Table,
				TableCaption,
				TableCellProperties,
				TableColumnResize,
				TableProperties,
				TableToolbar,
				TextTransformation,
				TodoList,
				Underline,
				Undo
			],
			fontFamily: {
				supportAllValues: true
			},
			fontSize: {
				options: [10, 12, 14, 'default', 18, 20, 22],
				supportAllValues: true
			},
			heading: {
				options: [
				{
					model: 'paragraph',
					title: 'Paragraph',
					class: 'ck-heading_paragraph'
				},
				{
					model: 'heading1',
					view: 'h1',
					title: 'Heading 1',
					class: 'ck-heading_heading1'
				},
				{
					model: 'heading2',
					view: 'h2',
					title: 'Heading 2',
					class: 'ck-heading_heading2'
				},
				{
					model: 'heading3',
					view: 'h3',
					title: 'Heading 3',
					class: 'ck-heading_heading3'
				},
				{
					model: 'heading4',
					view: 'h4',
					title: 'Heading 4',
					class: 'ck-heading_heading4'
				},
				{
					model: 'heading5',
					view: 'h5',
					title: 'Heading 5',
					class: 'ck-heading_heading5'
				},
				{
					model: 'heading6',
					view: 'h6',
					title: 'Heading 6',
					class: 'ck-heading_heading6'
				}]
			},
			initialData: '',
			link: {
				addTargetToExternalLinks: true,
				defaultProtocol: 'https://',
				decorators: {
					toggleDownloadable: {
						mode: 'manual',
						label: 'Downloadable',
						attributes: {
							download: 'file'
						}
					}
				}
			},
			list: {
				properties: {
					styles: true,
					startIndex: true,
					reversed: true
				}
			},
			placeholder: '',
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
			}
		};

		this.isLayoutReady = true;
		this.cdr.detectChanges();
	}

}