<!-- Filters -->
<div class="row mb-3">
	<div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Search Maintenance Logs</mat-label>
			<input matInput (keyup)="applyFilter($event)" placeholder="Search by Service Type or Details" />
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
	</div>
	<div class="col-md-6 text-end">
		<button mat-raised-button class="mt-2" color="accent" (click)="toggleForm()">
			{{ isFormVisible ? 'Hide Form' : 'Add Maintenance Log' }}
		</button>
	</div>
</div>

<!-- Add/Edit Maintenance Log Form -->
<div *ngIf="isFormVisible" class="p-3 border rounded bg-light">
	<form [formGroup]="maintenanceLogForm" (ngSubmit)="saveMaintenanceLog()">
		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Vehicle</mat-label>
					<mat-select formControlName="vehicle_id" required>
						<mat-option *ngIf="vehicle" [value]="vehicle.id">
							{{ vehicle.license_plate }} - {{ vehicle.make }} {{ vehicle.model }}
						</mat-option>
						<mat-option *ngFor="let v of vehicles" [value]="v.id">
							{{ v.license_plate }} - {{ v.make }} {{ v.model }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="maintenanceLogForm.controls['vehicle_id'].invalid && maintenanceLogForm.controls['vehicle_id'].touched">
						Vehicle selection is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Service Date</mat-label>
					<input matInput [matDatepicker]="picker" formControlName="service_date" required />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-error *ngIf="maintenanceLogForm.controls['service_date'].invalid && maintenanceLogForm.controls['service_date'].touched">
						Service date is required.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Service Type</mat-label>
					<mat-select formControlName="service_type" required>
						<mat-option value="Oil Change">Oil Change</mat-option>
						<mat-option value="Brake Check">Brake Check</mat-option>
						<mat-option value="Tire Rotation">Tire Rotation</mat-option>
						<mat-option value="Engine Repair">Engine Repair</mat-option>
						<mat-option value="Other">Other</mat-option>
					</mat-select>
					<mat-error *ngIf="maintenanceLogForm.controls['service_type'].invalid && maintenanceLogForm.controls['service_type'].touched">
						Service type selection is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Service Provider</mat-label>
					<input matInput formControlName="service_provider" />
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Details</mat-label>
					<textarea matInput formControlName="details" rows="3"></textarea>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Mileage at Service</mat-label>
					<input matInput formControlName="mileage_at_service" type="number" min="0" required />
					<mat-error *ngIf="maintenanceLogForm.controls['mileage_at_service'].invalid && maintenanceLogForm.controls['mileage_at_service'].touched">
						Mileage must be a positive value.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Cost ($)</mat-label>
					<input matInput formControlName="cost" type="number" min="0" required />
					<mat-error *ngIf="maintenanceLogForm.controls['cost'].invalid && maintenanceLogForm.controls['cost'].touched">
						Cost must be a positive value.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="text-end">
			<button mat-raised-button color="accent" type="submit" [disabled]="maintenanceLogForm.invalid">
				Save Maintenance Log
			</button>
			<button mat-raised-button color="warn" type="button" (click)="toggleForm()">
				Cancel
			</button>
		</div>
	</form>
</div>

<!-- Maintenance Logs Table -->
<div class="table-responsive mt-4">
	<table class="table table-striped table-hover table-bordered">
		<thead class="thead-dark">
			<tr>
				<th class="text-center">Vehicle</th>
				<th class="text-center">Service Date</th>
				<th class="text-center">Service Type</th>
				<th class="text-center">Details</th>
				<th class="text-center">Cost ($)</th>
				<th class="text-center"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let log of filteredMaintenanceLogs">
				<td class="text-center">{{ log.vehicle_id }}</td>
				<td class="text-center">{{ log.service_date }}</td>
				<td class="text-center">{{ log.service_type }}</td>
				<td class="text-center">{{ log.notes }}</td>
				<td class="text-center">{{ log.cost }}</td>
				<td class="text-center">
					<button mat-icon-button color="primary" (click)="editMaintenanceLog(log)">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="warn" (click)="deleteMaintenanceLog(log.log_id)">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>
