import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common'
import { interval, Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder,UntypedFormControl, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { InventoryService } from '../../services/inventory.service'
import { PrintService } from '../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss']
})
export class CommissionsComponent implements OnInit{
	@ViewChild('commUp') commUpload: ElementRef;
	@ViewChild('overModal') overrideList: ElementRef;
	@ViewChild('massOver') overSetup: ElementRef;
	@ViewChild('massModal') updateSetup: ElementRef;
	
	searchComms = new UntypedFormControl('');
	newStart = new UntypedFormControl('');
	newEnd = new UntypedFormControl('');
	newPct = new UntypedFormControl('');
	massStart = new UntypedFormControl('');
	massEnd = new UntypedFormControl('');
	massPct = new UntypedFormControl('');
	massPct2 = new UntypedFormControl('');
	prodlines = new UntypedFormControl('');
	
	color = 'blue';
	allcomms: any = [];
	commdetails: any = [];
	files: any = [];
	uploaddata: any = [];
	plines: any = [];
	mapcols: any = [{ 'field': 'stockid', label: 'Item Number' },
		{ 'field': 'startdate', label: 'Start Date' },
		{ 'field': 'enddate', 'label': 'End Date' },
		{ 'field': 'commpct', 'label': 'Commission %' },
	];
	mapping: any = {};
	selectedItem: any = [];
	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}
	
	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [100, 500, 1000];
	pagesizedefault = 100;
	
	constructor(private _changeDetectorRef: ChangeDetectorRef, public printService: PrintService, private fb: FormBuilder, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private location: Location, public cdr: ChangeDetectorRef, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}
	
	ngOnInit(): void {
	
		this.inventoryService.getItemProductlines().subscribe(p => {
			this.plines = p
		});
		
		this.setPagination([]);
	}
	
	
	itemSearch() {
		if(this.searchComms.value != '' || this.prodlines.value != '') {
			this.commdetails = this.globalSearchService.filterItem(this.allcomms,this.searchComms.value,'stockid,description');
			if(this.prodlines.value != ''){
			this.commdetails = this.globalSearchService.filterItem(this.commdetails,this.prodlines.value,'lineid');
			}
		} else {
			this.commdetails = this.allcomms;
		}
		this.setPagination(this.commdetails);
	}
	
	ngAfterViewInit() {
		this.inventoryService.getCommissions().subscribe((results: any) => {
			this.allcomms = results;
			this.commdetails = results;
			this.setPagination(this.commdetails);
		});
	}
	showverrides(item: any){
	this.selectedItem = item;
	this.newStart.setValue('');
	this.newEnd.setValue('');
	this.newPct.setValue('0');
	this.modalService.open(this.overrideList, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	removeOverride(id: any){
	
		let data = {'id': id};
		this.inventoryService.clearCommission(data).subscribe((results: any) => {
			this.allcomms = results;
			this.commdetails = results;
			this.itemSearch();
			this.modalService.dismissAll();
		});
	}
	changePct(item: any, e: any){
		let newVal = e.target.value;
		item.commpct = newVal;
		let parts = new Array();
		parts.push(item);
		let data = { 'updates': parts };
		this.inventoryService.updateCommission(data).subscribe((results: any) => {
		});
		
	}
	
	changeCommissionable(item: any, e: any){
		let newVal = e.checked;
		item.commissionable = newVal;
		let parts = new Array();
		parts.push(item);
		let data = { 'updates': parts };
		this.inventoryService.updateCommission(data).subscribe((results: any) => {
		});
	}
	uploadComms() {
		this.modalService.open(this.commUpload, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	massOverride(){
	this.massStart.setValue('');
	this.massEnd.setValue('');
	this.massPct.setValue('0');
	this.modalService.open(this.overSetup, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	addMassOverride(){
		const commpct = this.massPct.value;
		if(commpct == '' || commpct < 0 || isNaN(commpct)){
		alert('Invalid commission percent submitted');
		} else if (this.massStart.value == ''){
		alert('Start Date Required');
		} else if (this.massEnd.value == ''){
		alert('End Date Required');
		} else {
		let data = {'override':{'startdate': this.massStart.value,'enddate':this.massEnd.value,'commpct':this.massPct.value},'parts':this.commdetails};
		this.inventoryService.overrideCommission(data).subscribe((results: any) => {
			this.allcomms = results;
			this.commdetails = results;
			this.itemSearch();
			this.modalService.dismissAll();
		});
		}
	}
	addOverride(){
		const commpct = this.newPct.value;
		if(commpct == '' || commpct < 0 || isNaN(commpct)){
		alert('Invalid commission percent submitted');
		} else if (this.newStart.value == ''){
		alert('Start Date Required');
		} else if (this.newEnd.value == ''){
		alert('End Date Required');
		} else {
		let parts = new Array();
		parts.push(this.selectedItem);
		let data = {'override':{'startdate': this.newStart.value,'enddate':this.newEnd.value,'commpct':this.newPct.value},'parts':parts};
		this.inventoryService.overrideCommission(data).subscribe((results: any) => {
			this.allcomms = results;
			this.commdetails = results;
			this.itemSearch();
			this.modalService.dismissAll();
		});
		}
	}
	massChange(){

	this.massPct2.setValue('0');
	this.modalService.open(this.updateSetup, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	massUpdate(){
		
		const commpct = this.massPct2.value;
		let results = new Array();
		if(commpct == '' || commpct < 0 || isNaN(commpct)){
		alert('Invalid commission percent submitted');
		} else{
		for(let i = 0; i < this.commdetails.length; i++){
		
		this.commdetails[i].commpct = commpct;
		this.commdetails[i].commissionable = 1;
		results.push(this.commdetails[i]);
		}
		let data = { 'updates': results };
		this.inventoryService.updateCommission(data).subscribe((results: any) => {
			this.allcomms = results;
			this.commdetails = results;
			this.itemSearch();
			this.modalService.dismissAll();
		});
		}
	}
	sendComms() {
		const data = { tabledata: this.uploaddata, mapping: this.mapping };
		this.inventoryService.updateCommission(data).subscribe((results: any) => {
			this.allcomms = results;
			this.commdetails = results;
			this.itemSearch();
			this.modalService.dismissAll();
		});
	}
	onSelect(event: any) {
		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff

				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.inventoryService.uploadComms(upload).subscribe((results: any) => {
					this.uploaddata = results;

					this.files = [];
				});

			});
		}
	}
	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}
	
	back(): void {
		this.location.back()
	}
	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}
	
	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}
}
