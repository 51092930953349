<div class="container-fluid tp-box" [@flipState]="flip">
	<div class="row tp-box__side tp-box__back" *ngIf="flip == 'active'">
		<div class="card">
			<div class="card-header card-header-danger card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(false, '')">
					<i class="material-icons">file_copy</i>
				</div>
				<h3 class="card-title">{{ title }} </h3>
			</div>
			<div class="card-body" *ngIf="viewing">

				<div class="card-body" *ngIf="viewing">
					<!-- Spinner -->
					<div *ngIf="isLoading" class="text-center">
						<mat-spinner></mat-spinner>
					</div>

					<!-- Data Table -->
					<div *ngIf="!isLoading">
						<ng-container *ngIf="!sending">
							<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
							&nbsp;&nbsp;
							<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
						</ng-container>
						<ng-container *ngIf="sending">
							<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</ng-container>

						<mat-form-field class="full-width text-right" appearance="fill" color="accent">
							<mat-label>Terms Code</mat-label>
							<mat-select [formControl]="termscodes" (selectionChange)="changeTerm();">
								<mat-option [value]="'all'">All Terms</mat-option>
								<mat-option *ngFor="let method of terms" [value]="method.termsindicator">
									{{method.terms }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<div class="table" #print_table>
							<table class="table table-fixed table-striped">
								<thead class="thead-dark">
									<tr>
										<th (click)="sortData('typename')">Type
											<mat-icon *ngIf="currentSortColumn === 'typename'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('trandate')">Date
											<mat-icon *ngIf="currentSortColumn === 'trandate'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('debtorno')">Customer#
											<mat-icon *ngIf="currentSortColumn === 'debtorno'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('name')">Customer
											<mat-icon *ngIf="currentSortColumn === 'name'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('transno')">Document No
											<mat-icon *ngIf="currentSortColumn === 'transno'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('orderno')">Order No
											<mat-icon *ngIf="currentSortColumn === 'orderno'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('total')">Total
											<mat-icon *ngIf="currentSortColumn === 'total'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('payterms')">Term
											<mat-icon *ngIf="currentSortColumn === 'payterms'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('email')">Email
											<mat-icon *ngIf="currentSortColumn === 'email'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
										<th (click)="sortData('phoneno')">Phone#
											<mat-icon *ngIf="currentSortColumn === 'phoneno'">
												{{ sortDirection === 1 ? 'arrow_upward' : 'arrow_downward' }}
											</mat-icon>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let transaction of data">
										<td>{{ transaction.typename }}</td>
										<td>{{ transaction.trandate | date:'MM/dd/yyyy' }}</td>
										<td>{{ transaction.debtorno }}</td>
										<td>{{ transaction.name }}</td>
										<td>{{ transaction.transno }}</td>
										<td>{{ transaction.orderno }}</td>
										<td>{{ transaction.total | currency }}</td>
										<td>{{ transaction.payterms }}</td>
										<td>{{ transaction.email }}</td>
										<td>{{ transaction.phoneno }}</td>
									</tr>
									<tr *ngIf="!data.length">
										<td colspan="10" class="text-center">No transactions available</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

			</div>

		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-danger card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(data, title)">
					<i class="material-icons">file_copy</i>
				</div>
				<p class="card-category">{{ title }}</p>
				<h3 class="card-title">
					<span *ngIf="data">
						{{ data.length }}
					</span>
					<span *ngIf="!data">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
				<small>
					<p class="card-category text-info text-justify mt-3 text-right">
						&nbsp;
					</p>
				</small>
			</div>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(data, title)">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>