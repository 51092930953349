<ng-container>
	<div class="row text-center">
		<h4>{{title}}</h4>
		<form [formGroup]="tireGuideForm" class="w-100">
			<div class="row">
				<div class="col-md-3">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Year</mat-label>
						<input type="text" #yearInput matInput formControlName="year"  [matAutocomplete]="years" appSelectOnFocus>
						<mat-autocomplete #years="matAutocomplete" (optionSelected)="getMakes()" >
							<mat-option *ngFor="let year of all_years" [value]="year.cyear" >
								{{ year.cyear}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Make</mat-label>
						<input type="text" #makeInput matInput formControlName="make"  [matAutocomplete]="makes" appSelectOnFocus>
						<mat-autocomplete #makes="matAutocomplete" (optionSelected)="getModels()" >
							<mat-option *ngFor="let make of all_makes" [value]="make.make1" >
								{{ make.make1 }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Model</mat-label>
						<mat-select formControlName="model" (selectionChange)="getOpts()">

							<mat-option *ngFor="let model of models" [value]="model.model">
								{{ model.model }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Option</mat-label>
						<mat-select formControlName="model_type" (selectionChange)="getFitment()">
							<mat-option *ngFor="let opt of opts" [value]="opt.option1">
								{{ opt.option1 }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</form>
	</div>
</ng-container>
<div *ngIf="sending && !vehicles">
	<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
	</svg>
</div>
<ng-container *ngIf="tguidelist.length && !vehicles" >
	<h4>Results ({{ tguidelist.length }})</h4>
	<mat-expansion-panel *ngFor="let tguide of tguidelist">
		<mat-expansion-panel-header>
			<mat-panel-title> <div class="pointer" (click)="sendResult(tguide.rawsize)">{{tguide.rawsize}} </div>

				&nbsp;&nbsp;
				<button mat-icon-button (click)="sendResult(tguide.rawsize)"><mat-icon>search</mat-icon>
				</button>

		</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="content-container" [class.opened]="opened">
			<div class="row">
				<div class="col-md-12 ml-auto mr-0 text-md-right ml-md-0 mr-md-0 pl-md-0 pr-md-0 ">
					<ul class="list-group list-group-horizontal entry-list pt-0 mt-0 pb-0 mb-0">
						<li class="list-group-item flex-fill"><span class="spec-label-small">Tire Size:</span> <span class="spec-value-small"><button mat-button color="accent" (click)="sendResult(tguide.rawsize)">{{ tguide.rawsize }}</button></span></li>
						<li class="list-group-item flex-fill"><span class="spec-label-small">Base:</span> <span class="spec-value-small">{{ tguide.base1 }}</span></li>
						<li class="list-group-item flex-fill"><span class="spec-label-small">Run Flat:</span> <span class="spec-value-small">{{ tguide.runflat }}</span></li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 ml-auto mr-0 text-md-right ml-md-0 mr-md-0 pl-md-0 pr-md-0 ">
					<ul class="list-group list-group-horizontal entry-list pt-0 mt-0 pb-0 mb-0">
						<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">TPMS:</span> <span class="spec-value-small">{{ tguide.tpms }}</span></li>
						<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">Front Inf:</span> <span class="spec-value-small">{{ tguide.frontinf }}</span></li>
						<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">Rear Inf:</span> <span class="spec-value-small">{{ tguide.rearinf }}</span></li>
					</ul>
				</div>
			</div>
			<div class="row bold">
				<div class="col-md-12 text-center"> Rim Information</div>
			</div>
			<div class="row">
				<ul class="list-group list-group-horizontal entry-list pt-0 mt-0 pb-0 mb-0">
					<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill">
						<span class="spec-label-small">Rim Size:</span>
						<span class="spec-value-small">{{ tguide.rimwidth }}</span>
					</li>
					<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill">
						<span class="spec-label-small">BCD Display:</span>
						<span class="spec-value-small">{{ tguide.bcd_display }}</span>
					</li>
					<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">CBD Display:</span> <span class="spec-value-small">{{ tguide.cbd_display }}</span></li>
					<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">Offset:</span> <span class="spec-value-small">{{ tguide.offset_display }}</span></li>
					<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">Torque:</span> <span class="spec-value-small">{{ tguide.lug_nut_torque }}</span></li>
					<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">OE Hex:</span> <span class="spec-value-small">{{ tguide.oehex }}</span></li>
					<li class="list-group-item pt-0 mt-0 pb-0 mb-0 flex-fill"><span class="spec-label-small">Thread:</span> <span class="spec-value-small">{{ tguide.thread }}</span></li>
				</ul>
			</div>
		</div>
	</mat-expansion-panel>
</ng-container>
