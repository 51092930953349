<div class="main-content">
	<div class="card">
		<div class="card-header card-header-{{color}}-6 row">
			<i class="material-icons" (click)="back()">arrow_left</i>
			<h4>Customer Item Sales by Product Line</h4>
		</div>

		<div class="card-body">
			<div class="row justify-content-between">

				<mat-form-field appearance="outline">
					<mat-label>Transaction Dates</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
						<input matEndDate [formControl]="todateCtrl" placeholder="End date">
					</mat-date-range-input>
					<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>

				<mat-form-field appearance="outline" *ngIf="prodlines.length">
					<mat-label>Product Lines</mat-label>
					<mat-select multiple [formControl]="linesCtrl" appSelectAll>
						<mat-option *ngFor="let line of prodlines" [value]="line.value">
							(&nbsp;<span class="italic font-w-light">{{line.value}}</span>&nbsp;)&nbsp;{{line.viewValue}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Salesman</mat-label>
					<mat-select [formControl]="salesmanCtrl" multiple appSelectAll>
						<mat-option *ngFor="let person of salespeeps" [value]="person.value">
							{{person.viewValue}}
						</mat-option>
					</mat-select>
				</mat-form-field>
				
				<mat-form-field appearance="outline">
					<mat-label>Branches</mat-label>
					<mat-select multiple [formControl]="branchesCtrl" appSelectAll>
						<mat-option *ngFor="let br of branches" [value]="br.value">
							{{br.viewValue}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<div class="row p-2">
					<button class="mr-1 filterbtn btn-success" mat-raised-button (click)="export('xls')" [disabled]="running || !datasource">XSLX</button>
					<button class="ml-1 filterbtn btn-danger" mat-raised-button (click)="export('pdf')" [disabled]="running || !datasource">PDF</button>
				</div>

				<div class="row p-2 m-0">
					<mat-slide-toggle [checked]="allcustomers" (change)="allcustomers = allcustomers ? false : true; formatRows(currentResponse)" class="mr-1">All Customers?</mat-slide-toggle>
					<button class="filterbtn" color="accent" mat-raised-button (click)="runReport()">Run Report</button>	
				</div>
			</div>
			
			<div class="row m-0 p-2 text-center justify-content-center" *ngIf="running">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
			</div>
			<div *ngIf="error" class="m-0 p-0 text-center">
				<h3 class="italic font-w-light">{{error}}</h3>
			</div>

			<div class="row m-0 p-2 overflow-scroll" *ngIf="datasource && columns_displayed && !running">
				<h4>{{subtitle}}</h4>
				<table mat-table [dataSource]="datasource" class="mat-elevation-z8">
					<ng-container *ngFor="let col of columns_displayed">
						<ng-container matColumnDef="{{col}}">
							<th mat-header-cell *matHeaderCellDef> {{col}} </th>
							<ng-container *ngIf="!allproductlines.includes(col)">
								<td mat-cell *matCellDef="let element"> {{element[col]}} </td>
							</ng-container>
							<ng-container *ngIf="allproductlines.includes(col)">
								<td mat-cell *matCellDef="let element" [ngClass]="element[col] > 0 ? 'pl-2 pr-2 text-center bg-success-light' : element[col] < 0 ? 'pl-2 pr-2 text-center bg-danger-light' : 'pl-2 pr-2 text-center'"> {{element[col] | currency}} </td>
							</ng-container>	
						</ng-container>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="columns_displayed"></tr>
					<tr mat-row *matRowDef="let row; columns: columns_displayed;"></tr>
				</table>
			</div>
			<mat-paginator #paginator [pageSizeOptions]="[25,50,100]" showFirstLastButtons></mat-paginator>
		</div>

	</div>

</div>