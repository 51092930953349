import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { VehiclesService } from '../../services/vehicles.service';


@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  vehicleForm: FormGroup;
  vehicles: any = [];
  filteredVehicles: any = [];
  selectedVehicle: any | null = null;
  isFormVisible = false;
  isLoading = false;
  searchTerm = '';
  statusFilter = '';
  config: any = [];
  color: any = 'blue';
  constructor(
    private fb: FormBuilder,
    private globalsService: GlobalsService,
    private vehiclesService: VehiclesService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private snackBar: MatSnackBar,
    private globalSearchService: GlobalSearchService

  ) {
    this.color = this.globalsService.getColor();
    this.globalSearchService.configsubscription.subscribe(r => {
      this.config = r;
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadVehicles();
  }

  initializeForm(): void {
    this.vehicleForm = this.fb.group({
      debtorno: ['', Validators.required], // Customer ID
      branch: ['', Validators.required], // Branch
      vin: ['', [Validators.required, Validators.minLength(17), Validators.maxLength(17)]],
      plate: ['', Validators.required],
      serial: [''],
      make: ['', Validators.required],
      model: ['', Validators.required],
      model_type: [''],
      year: ['', [Validators.required, Validators.min(1900), Validators.max(new Date().getFullYear())]],
      engine_size: [''],
      transmission: [''],
      last_mileage: [0, Validators.required],
      annual_mileage: [0],
      next_inspection_date: [''],
      next_maint_date: [''],
      next_oil_change: [''],
      notes: [''],
      status: ['Active', Validators.required] // Active, Inactive, Maintenance, Retired
    });
  }

  loadVehicles(): void {
    this.isLoading = true;
    const filters = [];
    this.vehiclesService.getVehicles(filters).subscribe(
      (vehicles) => {
        this.vehicles = vehicles.vehicles;
        this.filteredVehicles = vehicles.vehicles;
        this.isLoading = false;
      },
      () => {
        this.snackBar.open('Error loading vehicles.', 'Close', { duration: 3000 });
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: any): void {
    this.searchTerm = event.target.value.toLowerCase();
    this.filterVehicles();
  }

  applyStatusFilter(status: string): void {
    this.statusFilter = status;
    this.filterVehicles();
  }

  filterVehicles(): void {
    this.filteredVehicles = this.vehicles.filter(vehicle => {
      const matchesSearch = !this.searchTerm ||
        vehicle.vin.toLowerCase().includes(this.searchTerm) ||
        vehicle.license_plate.toLowerCase().includes(this.searchTerm) ||
        vehicle.make.toLowerCase().includes(this.searchTerm) ||
        vehicle.model.toLowerCase().includes(this.searchTerm);
      const matchesStatus = !this.statusFilter || vehicle.status === this.statusFilter;
      return matchesSearch && matchesStatus;
    });
  }

  toggleForm(): void {
    this.isFormVisible = !this.isFormVisible;
    if (this.isFormVisible) this.resetForm();
  }
  selectVehicle(v) {
    this.selectedVehicle = v;
  }

  editVehicle(vehicle: any): void {
    this.selectedVehicle = vehicle;
    this.vehicleForm.patchValue(vehicle);
    this.isFormVisible = true;
  }

 saveVehicle(): void {
   if (this.vehicleForm.valid) {
     const vehicleData = this.vehicleForm.value;
 
     if (this.selectedVehicle) {
       // Update existing vehicle
       this.vehiclesService.updateVehicle(vehicleData).subscribe(() => {
         this.snackBar.open('Vehicle updated successfully.', 'Close', { duration: 3000 });
         this.loadVehicles();
         this.toggleForm();
       });
     } else {
       // Add new vehicle
       this.vehiclesService.addVehicle(vehicleData).subscribe(() => {
         this.snackBar.open('Vehicle added successfully.', 'Close', { duration: 3000 });
         this.loadVehicles();
         this.toggleForm();
       });
     }
   }
 }
 
  deleteVehicle(vehicleId: number): void {
    if (!confirm('Are you sure you want to delete this vehicle?')) return;

    this.vehiclesService.deleteVehicle(vehicleId).subscribe(
      (result) => {
        if (result.success) {
          this.snackBar.open('Vehicle deleted successfully.', 'Close', { duration: 3000 });
          this.loadVehicles();
        } else {
          this.snackBar.open(result.error, 'Close', { duration: 3000 });
        }
      },
      () => {
        this.snackBar.open('Error deleting vehicle.', 'Close', { duration: 3000 });
      }
    );
  }

  resetForm(): void {
    this.vehicleForm.reset();
    this.vehicleForm.patchValue({ status: 'Active' });
    this.selectedVehicle = null;
  }

  back(): void {
    this.location.back();
  }
}