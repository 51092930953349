import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalsService } from 'app/services/globals.service';
import { OrdersService } from 'app/services/orders.service';
import { CustomerService } from 'app/services/customer.service';
import { PurchasingService } from 'app/services/purchasing.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl } from '@angular/forms';
import { single } from 'rxjs/operators';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { PrintService } from 'app/services/print.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { OmsService } from 'app/services/oms.service';
import { UsersService } from 'app/services/users.service';

@Component({
	selector: 'app-simpletire-order-processing',
	templateUrl: './simpletire-order-processing.component.html',
	styleUrls: ['./simpletire-order-processing.component.scss'],
	animations: [
		trigger('fadeInOut', [
		  state('void', style({ opacity: 0 })),
		  transition('void <=> *', animate('250ms ease-in-out')),
		]),
	  ],
})
export class SimpletireOrderProcessingComponent implements OnInit {
	color: any = '';
	config: any;
	fetchingOrds: boolean = false;
	shippers: any;
	shipvia: any = false;
	processedOrders: any = [];
	processedOrders_filtered: any = [];
	scheduledOrders: any = [];
	scheduledOrders_filtered: any = [];
	all_selection: boolean = false;
	podetails: any = false;
	ordsearch = new UntypedFormControl('');
	cancelReasonsRef = new UntypedFormControl('');
	@ViewChild('purchaseOrderDetails') purchaseOrderDetailsRef: ElementRef;
	@ViewChild('detailsModal') detailsModalEle: ElementRef;
	@ViewChild('trackingModal') trackingModalRef: ElementRef;
	previewOrd: any = false;
	dragging:any = false;
	stopLoading: any =false;
	tabindex: number = 0;
	showorder: boolean = false;
	orderdetails: any = false;
	trackingorder: any = false;
	hidetracking: boolean = true;
	searchByItems: boolean = true;
	html: string;
	cancelReasons: any;
	cannotShipReason: any;
	showCancel: boolean = false;
	labels: any;
	overide_order: any;
	user: any = false;
	altering: boolean =false;
	alreadyscheduled: any = [];
	showAlreadyScheduled: boolean = false;
	scheduledOrdersOriginal: any = [];
	
	constructor(private usersService: UsersService, private omsService: OmsService, private printService: PrintService,  private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private ordersService: OrdersService, private location: Location, private customerService: CustomerService, private purchasingService: PurchasingService, private modalService: NgbModal) { }

	ngOnInit(): void {
		this.globalSearchService.user.subscribe((res)=>{
			this.user = res.user;
		})
		this.color = this.globalsService.getColor();
		this.globalsService.getConfig().subscribe((res) => {
			this.config = res;
		});
		this.shippers = this.globalsService.getShipVia().subscribe((res)=>{
			this.shippers = res;
			this.shipvia = res.find((i)=> i.shippername == 'DELIVERY');
		});
		this.getOrders();
		this.getCancelReasons();
	}


	getOrders() {
		this.fetchingOrds = true;
		this.scheduledOrders = [];
		this.processedOrders = [];
		this.ordersService.getSimpleTireOrders().subscribe((res) => {
			this.fetchingOrds = false;
			this.sortOrders(res);
		})
	}

	sortOrders(ordObj){
		this.scheduledOrders = [];
		this.processedOrders = [];
		this.alreadyscheduled = []; //processed simple side and not in our system - hidden by default to prevent dupes
		Object.keys(ordObj).forEach((status) =>{
			if(ordObj[status] && ordObj[status]['open'].length){
				if(status == 'SCHEDULED'){
					this.alreadyscheduled = [...this.alreadyscheduled, ...ordObj[status]['open']];
				}else{
					this.scheduledOrders = [...this.scheduledOrders, ...ordObj[status]['open']];
				}
			}
			if(ordObj[status] && ordObj[status]['processed'].length){
				this.processedOrders = [...this.processedOrders, ...ordObj[status]['processed']];
			}
		})

		this.scheduledOrdersOriginal = this.scheduledOrders;
		
		this.mergeOpenOrders();
		this.processedOrders_filtered = this.processedOrders;
	}

	mergeOpenOrders(toggle = false){
		if(toggle){
			this.showAlreadyScheduled = this.showAlreadyScheduled ? false : true;
		}
		if(this.showAlreadyScheduled){
			this.scheduledOrders = [...this.scheduledOrders, ...this.alreadyscheduled];
			this.scheduledOrders_filtered = this.scheduledOrders;
		}else{
			this.scheduledOrders = this.scheduledOrdersOriginal;
			this.scheduledOrders_filtered = this.scheduledOrders;
		}
	}

	process(order: any, forcesalesorder: any = false, forcepo: any = false){
		order.processing = true;
		var order_labels = order['headers']['labels'];
		
		var shipvia:any = false;
		if(order_labels.length){
			order_labels.forEach(lab => {
				shipvia = this.shippers.find((i)=> i.shippername.toUpperCase() == lab.shipping_method.toUpperCase());
				lab['shipper_id'] = shipvia.shipper_id
			});
		}


		order['headers']['shipvia'] = shipvia;
		order['headers']['required_ship_date'] = order['required_ship_date'];
		order['headers']['simple_order_id'] = order['simple_order_id'];		
		order['headers']['warehouse_order_id'] = order['warehouse_order_id'];


		const order_products = order['products'];
		const order_headers = order['headers'];

		const ord = {
			orders: [{
				header: order_headers,
				items: order_products,
				labels: order_labels,
				'soOnly': forcesalesorder,
				'poOnly': forcepo
			}]
		};

		this.ordersService.processSimpleTireOrders(ord).subscribe((res)=>{
			if(res.length){
				this.sortOrders(res[0]['orders']);
			}
		})
	}

	updatePrice($event, item){
		item.overideprice = $event.target.value;
	}

	updateFet($event, item){
		item.overidefet = $event.target.value;
	}

	alterPrice(order: any){
		this.altering = true;
		this.viewSimpleOrder(order);
	}



	//if non check marked row is clicked, only that order is processed
	processMult(order: any){
		var orders = [];
		var processing;
		var singleorder = false;
		if(!order.checked){
			processing = [order];
			singleorder = true;
		}else{
			processing = this.scheduledOrders_filtered;
		}


		processing.forEach(order => {
			if(!order.checked && !singleorder){return}

			order.processing = true;
			var order_labels = order['headers']['labels'];
			
			var shipvia:any = false;
			if(order_labels.length){
				order_labels.forEach(lab => {
					shipvia = this.shippers.find((i)=> i.shippername.toUpperCase() == lab.shipping_method.toUpperCase());
					lab['shipper_id'] = shipvia.shipper_id
				});
			}
			order['headers']['shipvia'] = shipvia;
			order['headers']['required_ship_date'] = order['required_ship_date'];
			order['headers']['simple_order_id'] = order['simple_order_id'];
			order['headers']['warehouse_order_id'] = order['warehouse_order_id'];

			const order_products = order['products'];
			const order_headers = order['headers'];

			const ord = {
				header: order_headers,
				items: order_products,
				labels: order_labels
			}

			orders.push(ord);
		});

		const data = {
			orders: orders
		}

		this.ordersService.processSimpleTireOrders(data).subscribe((res)=>{
			if(res.length){
				this.sortOrders(res[res.length - 1]['orders']);
			}
		})
	}

	printMult(order: any){
		var orders = [];
		var printing;
		var singleorder = false;
		if(!order.checked){
			printing = [order];
			singleorder = true;
		}else{
			printing = this.processedOrders_filtered;
		}
		
		printing.forEach(order => {
			if(!order.labelchecked && !order.orderchecked && !singleorder){return}
			if(order.labelchecked){
				this.getLabel(order, 'print');
			}
			if(order.orderchecked){
				this.getPickingList(order, 'print');
			}
		});
	}

	getPickingList(order, type ='pdf'){
		const input = {
			orderno: order.salesorder.orderno,
			type: type
		}
		this.ordersService.printPackingList(input).subscribe((res)=>{
		})
	}

	selectAllOrders(processed = false){
		if(!processed){
			this.all_selection = this.all_selection ? false : true;
			this.scheduledOrders_filtered.map((i)=>{
				const discrep = i.total < i.internal_total ? true : false;
				if(i.canbeordered && !discrep && i.status != 'Scheduled'){
					i.checked = this.all_selection	
				}
			});
		}else{
			this.all_selection = this.all_selection ? false : true;
			this.processedOrders_filtered.map((i)=>{
				i.orderchecked = this.all_selection	
				i.labelchecked = this.all_selection	
			});
		}

	}

	getLabel(ord, type = 'url'){
		//TYPE url = download from browser
		//TYPE print = print to zebra printer and don't download
		this.ordersService.getSimpleTireLabels(ord.warehouse_order_id, type).subscribe((res:any)=>{
			this.labels = res;
			if(res.success && res.labels){
				window.open(res.labels)
			}else if(res.message){
				this.globalSearchService.showNotification(res.message, 'danger', 'bottom','right');
			}else if(res.base64){
				//print connect logic here
				this.sendPrint(res.base64);
			}
		})
	}

	sendPrint(base64: any) {
		this.omsService.setNewOrder();
		this.globalSearchService.user.subscribe(user => {
			const printers = this.usersService.getDevicesByType(user.user.userid, 'LABEL');
			const data = { pdf: base64, user: user, devices: printers }
			if (printers && printers.length) {
				this.omsService.sendPrintCommand({ data, roomName: 'Printers' }, cb => {
					this.omsService.setPrintedOrder();
				})
			}
		});
	}

	viewSimpleOrder(order, cancels = false){
		if(cancels){this.showCancel = true}
		this.hidetracking = true;
		this.orderdetails = this.buildOrderDetails(order)

		this.showorder = true;
		this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.hidetracking = false;
			this.showCancel = false;
			this.altering = false;
			
			if(reason == 'overidePrice'){
				this.orderdetails['details'].forEach(prod => {
					order['products'].map((line)=> {
						if(line['lineno'] == prod['lineno'] && line['warehouse_part_number'] == prod['stkcode']){
							line['overideprice'] = prod['overideprice'];
							if(prod['overidefet']){
								line['overidefet'] = prod['overidefet'];
							}
						}
					})
				});

				this.process(order);
			}
		});
	}

	buildOrderDetails(order){
		return  {
			header: {
				orderno: order.simple_order_id,
				whorderno: order.warehouse_order_id,
				debtorno: 'SIMPLETIRE: 1958',
				name: order.headers.customer_first_name + ' ' + order.headers.customer_last_name,
				simpletot: order.total,
				internaltot: order.internal_total

			},
			details: [...order.products.map(line=>{
				return {
					stkcode: line.warehouse_part_number,
					description: line.product,
					ordered: line.qty,
					simpleprice: line.price,
					internalprice: line.internal_price,
					porequired: line.porequired,
					canbeordered: line.canbeordered,
					error_reason: line.error_reason,
					remotesupplier: line.remotesupplier,
					overideprice: line.internal_price,
					fet: line.fet, //simple
					internal_fet: line.internal_fet,
					lineno: line.lineno
				}
			})]
		}
	}

	getExtension(item, type){
		var ext;
		if(type == 'internal'){
			ext = (Number (item.internalprice) + Number (item.internal_fet)) * item.ordered;
		}else if(type == 'simple'){
			ext = (Number(item.simpleprice) + Number(item.fet)) * item.ordered;
		}else{
			ext = (Number(item.unitprice)) * item.ordered;
		}
		return ext;
	}

	getSoTotal(items){
		return items.reduce(function(accumulator, i) {
			return accumulator + parseFloat(this.getExtension(i, 'so'));
		}.bind(this), 0);
	}

	financial(num: number): number {
		if (!num) {
			num = 0.00;
		}
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
		return parseFloat(rounded.toFixed(2));
	}


	buildExport(){

		var html = '<table><thead><tr>';
			html += `<th>Warehouse ID</th>`;
			html += `<th>Simple ID</th>`;
			html += `<th>Status</th>`;
			html += `<th>Processed</th>`
			html += `<th>Sales Order</th>`;
			html += `<th>SO Status</th>`;
			html += `<th>Purchase Orders</th>`;
			html += `<th>Required Ship</th>`;
			html += `<th>Customer Name</th>`;
			html += `<th>Address</th>`;
			html += `<th>City</th>`;
			html += `<th>State</th>`;
			html += `<th>Zip</th>`;
			html += `<th>Sub-Total</th>`
			html += `</tr></thead><tbody>`;
		
		([...this.scheduledOrders , ...this.processedOrders]).forEach((o)=>{
			html += '<tr>';
			var pos = [];
			var processed = false;
			if(o.salesorder){
				pos = (o.salesorder?.haspo).map((o)=>{return o.purchase_orderno}).join(", ");
				processed = true;
			}
			html += `<td>${o.warehouse_order_id}</td>`;
			html += `<td>${o.simple_order_id}</td>`;
			html += `<td>${o.status}</td>`;
			html += `<td>${processed ? 'Yes' : 'No'}</td>`
			html += `<td>${o.salesorder ? o.salesorder?.orderno : '-'}</td>`;
			html += `<td>${o.salesorder ? o.salesorder?.status:'-'}</td>`;
			html += `<td>${pos.length ? pos: ''}</td>`;
			html += `<td>${o.required_ship_date}</td>`;
			html += `<td>${o.headers.customer_first_name} ${o.headers.customer_last_name}</td>`;
			html += `<td>${o.headers.shipping_address1} , ${o.shipping_address2 ? (", " + o.shipping_address2) : '' }</td>`;
			html += `<td>${o.headers.shipping_city}</td>`;
			html += `<td>${o.headers.shipping_state}</td>`;
			html += `<td>${o.headers.shipping_zip}</td>`;
			html += `<td>$${o.total ? o.total : o.salesorder.total}</td>`;
			html += '</tr>';
		})
			html += `</tbody></table>`;

			return html;
	}

	exportPdf() {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.buildExport());
		const data = {
			content: encoded,
			filename: 'SimpleTireOrders',
			title: 'Simple Tire Orders',
			subtitle: 'Created ' + today.toLocaleString(),
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.buildExport());
		const data = {
			content: encoded,
			filename: 'SimpleTireOrders',
			title: 'Simple Tire Orders',
			subtitle: 'Created ' + today.toLocaleString(),
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	

	
	cancelSimpleOrder(order){
		if(confirm(`Cancel Simpletire order # ${order.header.orderno}`)){
			this.updateOrderStatus(order.header.orderno, 'cancel', this.cancelReasonsRef.value)
		}
	}


	updateOrderStatus(orderno, type, reason){
		var data = {
			status: '',
			orderno: orderno
		}
		switch(type){
			case 'cancel':
				data.status = 'CANCELLED'
				data['reason_id'] = reason;
				break;
			case 'shipped':
				data.status = 'SCHEDULED'
				break;
			case 'confirmed': //local delivery only - maybe dont need right away
				data.status = 'DELIVERED'
				break;
			default:
				break;
		}

		this.ordersService.updateSimpleTireOrder(data).subscribe((res: any)=>{
			const message = res.message ? res.message : 'ERROR: ' + JSON.stringify(res);

			if(res.status.toLowerCase() == 'success'){
				this.globalSearchService.showNotification( message, 'success', 'bottom','right');
				this.modalService.dismissAll();
			}else{
				this.globalSearchService.showNotification( message, 'danger', 'bottom','right');
			}
		})
	}
	
	getCancelReasons(){
		this.ordersService.getSimpleTireCancelReasons().subscribe((res: any) => {
			this.cancelReasons = res;
			if(res.status == 'success'){
				this.cancelReasons = res.items;
			}else{
				setTimeout(()=>{
					this.getCancelReasons()
				}, 5000);
			}
		})	
	}


	itemSearchToggle(){
		this.searchByItems = this.searchByItems ? false : true;
		this.filterOrders();
	}


	filterOrders(){
		const search = this.ordsearch.getRawValue().toUpperCase();

		 function getSearch(ord){
			var data = []
			data['header'] = ord.headers;
			data['name'] = (data['header'].customer_first_name + ' ' + data['header'].customer_last_name).toUpperCase();
			data['address'] = (data['header'].shipping_address1 + ' ' + data['header'].shipping_address2 + ' ' + data['header'].shipping_city + ' ' + data['header'].shipping_phone + ' ' + data['header'].shipping_state + ' ' + data['header'].shipping_zip).toUpperCase() 
			data['orderids'] = (ord.warehouse_order_id + ' ' + ord.simple_order_id).toUpperCase();
			data['prods'] = (ord.products.map((prod)=>{return prod.warehouse_part_number})).join(' ').toUpperCase();
			return data;
		 }

		this.scheduledOrders_filtered = this.scheduledOrders.filter((ord)=>{
			ord = getSearch(ord);
			if(ord.name.includes(search) || ord.address.includes(search) || ord.orderids.includes(search) || (this.searchByItems && ord.prods.includes(search))){
				return ord;
			}
		})

		this.processedOrders_filtered = this.processedOrders.filter((ord)=>{
			ord = getSearch(ord);
			if(ord.name.includes(search) || ord.address.includes(search) || ord.orderids.includes(search) || (this.searchByItems && ord.prods.includes(search))){
				return ord;
			}
		})

	}

	loadOrder(order) {
		
		this.ordersService.getOrder(order.salesorder.orderno).subscribe((results: any) => {
			const simple_data = this.buildOrderDetails(order)
			var temp = results;
			temp['header'] = simple_data['header'];
			temp['details'] = simple_data['details'];
			temp['salesorder'] = order['salesorder'];

			this.orderdetails = temp;

			this.showorder = true;

			this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {

			});

		});
	}

	openTrackingAssign(orderno: any){
		this.trackingorder = orderno;
		this.modalService.open(this.trackingModalRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => {
			this.trackingorder = false;
		});
	}

	openOverride(order: any){
		this.overide_order = true;
		this.viewSimpleOrder(order);
	}

	viewPurchaseOrder(orderno){
		this.purchasingService.getPurchaseOrder(orderno).subscribe((order: any) => {
			this.podetails = order
			this.modalService.open(this.purchaseOrderDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {

			});
		});
	}

	getTotalQuantity(): number {
		if (!this.podetails || !this.podetails.polines) return 0;
		return this.podetails.polines.reduce((total, item) => parseFloat(total) + parseFloat(item.quantityord), 0);
	}

	getTotalValue(): number {
		if (!this.podetails || !this.podetails.polines) return 0;
		return this.podetails.polines.reduce((total, item) => parseFloat(total) + (parseFloat(item.unitprice) * parseFloat(item.quantityord)), 0);
	}



	//hiding for now, cancels the SO & PO and updates the simpletireorder table row to be processed 0.
	//Not sure if needed since requires remote po's to be cancelled manually.
	unProcessOrder(ord){
		ord.processing = true;

		const data = {
			simple_order_id: ord.simple_order_id,
			warehouse_order_id: ord.warehouse_order_id,
			headers: ord.headers,
			salesorder: ord.salesorder,
		}

		this.ordersService.unProcessSimpleTireOrder(data).subscribe((res)=>{
			if(res){
				this.sortOrders(res)
			}
		})
	}


	back(): void {
		this.location.back()
	}
}
