import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { VendorsComponent } from './vendors.component';
import { VendorEditComponent } from './edit/vendor-edit/vendor-edit.component';
import { VendorCreateComponent } from './vendor-create/vendor-create.component';
import { VendorPaymentEntryComponent } from './vendor-payment-entry/vendor-payment-entry.component';
import { VendorViewComponent } from './vendor-view/vendor-view.component';
import { VendorEditaccountComponent } from './edit/vendor-editaccount/vendor-editaccount.component';
import { VendorNotesComponent } from './edit/vendor-notes/vendor-notes.component';
import { VendorContactsComponent } from './edit/vendor-contacts/vendor-contacts.component';
import { VendorSettingsComponent } from './edit/vendor-settings/vendor-settings.component';
import { VendorAddonComponent } from './edit/vendor-addon/vendor-addon.component';
import { VendorTransactionsComponent } from './vendor-transactions/vendor-transactions.component';
import { VendorItemlookupComponent } from './vendor-itemlookup/vendor-itemlookup.component';
import { VendorInvoiceComponent } from './vendor-invoice/vendor-invoice.component';
import { VendorInvoiceCompleteComponent } from './vendor-invoice/vendor-invoice-complete/vendor-invoice-complete.component';
import { VendorsDashboardComponent } from './vendors-dashboard/vendors-dashboard.component';
import { VendorCreditComponent } from './vendor-credit/vendor-credit.component';
import { VendorPhonePipePipe } from './vendor-phone-pipe.pipe';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { VendorAllocationsComponent } from './vendor-allocations/vendor-allocations.component';
import { VendordashPaymentsComponent } from './vendors-dashboard/vendordash-payments/vendordash-payments.component';
import { VendordashInvoiceComponent } from './vendors-dashboard/vendordash-invoice/vendordash-invoice.component';
import { CustomersModule } from '../customers/customers.module';
import { VendordashOverdueComponent } from './vendors-dashboard/vendordash-overdue/vendordash-overdue.component';
import { SharedModule } from '../shared/shared.module';
import { VrptComponent } from './vrpt/vrpt.component';
import { VendorReportsComponent } from './vendor-reports/vendor-reports.component'
import { ClipboardModule } from '@angular/cdk/clipboard';
import { VrptContentComponent } from './vrpt/vrpt-content/vrpt-content.component';
import { CheckPrintingComponent } from './check-printing/check-printing.component';
import { PaymentRunReportComponent } from './payment-run-report/payment-run-report.component';

const maskConfigFunction: () => Partial<IConfig> = () => {
	return {
		validation: false,
	};
};

@NgModule({
	declarations: [
		VendorsComponent,
		VendorEditComponent,
		VendorCreateComponent,
		VendorPaymentEntryComponent,
		VendorViewComponent,
		VendorEditaccountComponent,
		VendorNotesComponent,
		VendorContactsComponent,
		VendorSettingsComponent,
		VendorAddonComponent,
		VendorTransactionsComponent,
		VendorItemlookupComponent,
		VendorInvoiceComponent,
		VendorInvoiceCompleteComponent,
		VendorsDashboardComponent,
		VendorCreditComponent,
		VendorPhonePipePipe,
		VendorAllocationsComponent,
		VendordashPaymentsComponent,
		VendordashInvoiceComponent,
		VendordashOverdueComponent,
		VrptComponent,
		VendorReportsComponent,
		VrptContentComponent,
  CheckPrintingComponent,
  PaymentRunReportComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		NgxExtendedPdfViewerModule,
		FormsModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatSelectModule,
		MatMenuModule,
		MatIconModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatChipsModule,
		MatListModule,
		MatTabsModule,
		SharedModule,
		MatButtonToggleModule,
		ClipboardModule,
		CustomersModule,
		NgxMaskModule.forRoot(maskConfigFunction),
	],
	providers: [
		GlobalSearchService
	],
})
export class VendorsModule {}
