import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
  selector: 'app-vehicles-main',
  templateUrl: './vehicles-main.component.html',
  styleUrls: ['./vehicles-main.component.scss']
})
export class VehiclesMainComponent implements OnInit {

	config: any = [];
	  color: any = 'blue';

  constructor( private globalsService: GlobalsService,private globalSearchService: GlobalSearchService) {
	  this.color = this.globalsService.getColor();
	  this.globalSearchService.configsubscription.subscribe(r => {
		this.config = r;
	  });
  }

  ngOnInit(): void {}
}
