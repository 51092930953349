import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-bin-history',
	templateUrl: './bin-history.component.html',
	styleUrls: ['./bin-history.component.scss']
})
export class BinHistoryComponent implements OnInit, OnChanges {

	@Input() bins;
	@Input() stockid = false;
	@ViewChild('paginator') paginator: MatPaginator;
	history : any = [];
	bindetails: any = [];
	fromdateCtrl = new UntypedFormControl('')
	todateCtrl = new UntypedFormControl('')
	stockIdCtrl = new UntypedFormControl('')
	todate = new Date();
	fromdate = new Date();
	today = new Date();
	forcedid: any = false;
	gettingHistory: boolean = false;
	datasource: any = false;
	displayedColumns: any =  ['View', 'Date', 'Description', 'To', 'Type', 'User'];

	constructor(private inventoryService : InventoryService,private modalService: NgbModal) {
		const today = new Date();
		const priorDate = new Date(new Date().setDate(today.getDate() - 90));
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)
	 }


	ngOnInit(): void {
		if(!this.stockid){
			this.getHistory();
		}
	}
	
	getHistory(){
		function getYMD(datestring){
			const d = new Date(datestring);
			const year = d.getFullYear();
			const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
			const day = String(d.getDate()).padStart(2, "0");
			return `${year}-${month}-${day}`;
		}	
		
		const daterange = {
			fromdate : getYMD(this.fromdateCtrl.value),
			todate : getYMD(this.todateCtrl.value),
			stockid: this.forcedid ? this.forcedid : this.stockIdCtrl.value,
		};

		this.history = false;
		this.gettingHistory = true;
		this.inventoryService.getBinMovements(daterange).subscribe((results: any) => {
			this.gettingHistory = false;
			this.history = results.length ? results : false;
			this.datasource = new MatTableDataSource(results)
			this.datasource.paginator = this.paginator;	
		})		
	}

	reverseMovements(id: any) {
		this.inventoryService.reverseMovements(id).subscribe((results: any) => {
			this.gettingHistory = false;
			this.history = results;
		})
	}

	getToBin(jsonencoded: any) {
		const bao = JSON.parse(jsonencoded);
		return bao.to
	}

	openModal(content: any , id: any) {

		this.inventoryService.getBinMovementDetails(id).subscribe((results:any) => {
			console.log(results);
			this.bindetails = results
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
				//this.editing_shipping = false;
			}, (reason) => {
				//close with reason..
			});
		});
	  }


	  ngOnChanges(changes: SimpleChanges): void {
		if(changes.stockid){
			this.history = false;
			this.forcedid = changes.stockid.currentValue;
			this.getHistory();
		}
	}

}
