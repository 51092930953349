import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { VehiclesService } from '../../services/vehicles.service';

@Component({
	selector: 'app-maintenance-logs',
	templateUrl: './maintenance-logs.component.html',
	styleUrls: ['./maintenance-logs.component.scss']
})
export class MaintenanceLogsComponent implements OnInit {
	maintenanceLogForm: FormGroup;
	maintenanceLogs: any = [];
	filteredMaintenanceLogs: any = [];
	vehicles: any = [];
	selectedMaintenanceLog: any | null = null;
	isFormVisible = false;
	isLoading = false;
	searchTerm = '';
	vehicleFilter = '';
	config: any = [];
	color: any = 'blue';

	constructor(
		private fb: FormBuilder,
		private globalsService: GlobalsService,
		private vehiclesService: VehiclesService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private snackBar: MatSnackBar,
		private globalSearchService: GlobalSearchService
	) {
		this.color = this.globalsService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	ngOnInit(): void {
		this.initializeForm();
		this.loadMaintenanceLogs();
		this.loadVehicles();
	}

	initializeForm(): void {
		this.maintenanceLogForm = this.fb.group({
			vehicle_id: [''],
			service_date: ['', Validators.required],
			service_type: [''],
			service_provider: [],
			details: ['', Validators.required],
			mileage_at_service: [0, [Validators.required, Validators.min(0)]],  // Correct field name
			cost: [0, [Validators.required, Validators.min(0)]],
		});
	}


	loadMaintenanceLogs(): void {
		this.isLoading = true;
		const filters = {};
		this.vehiclesService.getMaintenanceLogs(filters).subscribe(
			(maintenanceLogs) => {
				this.maintenanceLogs = maintenanceLogs.maintenanceRecords;
				this.filteredMaintenanceLogs = maintenanceLogs.maintenanceRecords;
				this.isLoading = false;
			},
			() => {
				this.snackBar.open('Error loading maintenance logs.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	loadVehicles(): void {
		const filters = {};
		this.vehiclesService.getVehicles(filters).subscribe((vehicles) => {
			this.vehicles = vehicles.vehicles;
		});
	}

	applyFilter(event: any): void {
		this.searchTerm = event.target.value.toLowerCase();
		this.filterMaintenanceLogs();
	}

	applyVehicleFilter(vehicleId: string): void {
		this.vehicleFilter = vehicleId;
		this.filterMaintenanceLogs();
	}

	filterMaintenanceLogs(): void {
		this.filteredMaintenanceLogs = this.maintenanceLogs.filter(log => {
			const matchesSearch = !this.searchTerm ||
				log.service_type.toLowerCase().includes(this.searchTerm) ||
				log.details.toLowerCase().includes(this.searchTerm);
			const matchesVehicle = !this.vehicleFilter || log.vehicle_id.toString() === this.vehicleFilter;
			return matchesSearch && matchesVehicle;
		});
	}

	toggleForm(): void {
		this.isFormVisible = !this.isFormVisible;
		if (this.isFormVisible) this.resetForm();
	}

	editMaintenanceLog(log: any): void {
		this.selectedMaintenanceLog = log;
		this.maintenanceLogForm.patchValue(log);
		this.isFormVisible = true;
	}

	saveMaintenanceLog(): void {
		if (this.maintenanceLogForm.valid) {
			const maintenanceLogData = this.maintenanceLogForm.value;
			if (this.selectedMaintenanceLog) {
				this.vehiclesService.updateMaintenanceLog(maintenanceLogData).subscribe(() => {
					this.snackBar.open('Maintenance log updated successfully.', 'Close', { duration: 3000 });
					this.loadMaintenanceLogs();
					this.toggleForm();
				});
			} else {
				this.vehiclesService.addMaintenanceLog(maintenanceLogData).subscribe(() => {
					this.snackBar.open('Maintenance log added successfully.', 'Close', { duration: 3000 });
					this.loadMaintenanceLogs();
					this.toggleForm();
				});
			}
		}
	}

	deleteMaintenanceLog(logId: number): void {
		if (!confirm('Are you sure you want to delete this maintenance log?')) return;

		this.vehiclesService.deleteMaintenanceLog(logId).subscribe(
			(result) => {
				if (result.success) {
					this.snackBar.open('Maintenance log deleted successfully.', 'Close', { duration: 3000 });
					this.loadMaintenanceLogs();
				} else {
					this.snackBar.open(result.error, 'Close', { duration: 3000 });
				}
			},
			() => {
				this.snackBar.open('Error deleting maintenance log.', 'Close', { duration: 3000 });
			}
		);
	}

	resetForm(): void {
		this.maintenanceLogForm.reset();
		this.selectedMaintenanceLog = null;
	}

	back(): void {
		this.location.back();
	}
}