import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehiclesService } from '../../services/vehicles.service';

@Component({
	selector: 'app-fuel-logs',
	templateUrl: './fuel-logs.component.html',
	styleUrls: ['./fuel-logs.component.scss']
})
export class FuelLogsComponent implements OnInit {
	@Input() vehicle: any | null = null;

	fuelLogForm: FormGroup;
	fuelLogs: any[] = [];
	filteredFuelLogs: any[] = [];
	vehicles: any[] = [];
	selectedFuelLog: any | null = null;
	isFormVisible = false;
	isLoading = false;
	searchTerm = '';
	vehicleFilter = '';

	constructor(
		private fb: FormBuilder,
		private vehiclesService: VehiclesService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.initializeForm();
		this.loadVehicles();
		this.getFuelLogs();
	}

	initializeForm(): void {
		this.fuelLogForm = this.fb.group({
			vehicle_id: [this.vehicle ? this.vehicle.id : '', Validators.required],
			fuel_date: ['', Validators.required],
			fuel_type: ['', Validators.required],
			amount: [0, [Validators.required, Validators.min(0.1)]],
			cost: [0, [Validators.required, Validators.min(0)]],
			odometer: [0, [Validators.required, Validators.min(0)]]
		});
	}


	getFuelLogs(): void {
		let filters = {
			vehicle_id: this.vehicle?.id
		}
		this.vehiclesService.getFuelLogs(filters).subscribe((fuel: any) => {
			this.fuelLogs = fuel.fuel_logs;
			this.filteredFuelLogs = fuel.fuel_logs;
		});
	}

	loadVehicles(): void {
		this.vehiclesService.getVehicles({}).subscribe((vehicles) => {
			this.vehicles = vehicles.vehicles;
		});
	}

	applyFilter(event: any): void {
		this.searchTerm = event.target.value.toLowerCase();
		this.filterFuelLogs();
	}

	applyVehicleFilter(vehicleId: string): void {
		this.vehicleFilter = vehicleId;
		this.filterFuelLogs();
	}

	filterFuelLogs(): void {
		this.filteredFuelLogs = this.fuelLogs.filter(log =>
			(!this.searchTerm || log.fuel_type.toLowerCase().includes(this.searchTerm)) &&
			(!this.vehicleFilter || log.vehicle_id.toString() === this.vehicleFilter)
		);
	}

	saveFuelLog(): void {
	  if (this.fuelLogForm.valid) {
		const fuelLogData = this.fuelLogForm.value;

		if (this.selectedFuelLog) {
		  // Update existing fuel log
		  fuelLogData.log_id = this.selectedFuelLog.log_id;
		  this.vehiclesService.updateFuelLog(fuelLogData).subscribe(() => {
			this.snackBar.open('Fuel log updated successfully.', 'Close', { duration: 3000 });
			this.getFuelLogs();
			this.toggleForm();
		  });
		} else {
		  // Add new fuel log
		  this.vehiclesService.addFuelLog(fuelLogData).subscribe(() => {
			this.snackBar.open('Fuel log added successfully.', 'Close', { duration: 3000 });
			this.getFuelLogs();
			this.toggleForm();
		  });
		}
	  } else {
		this.showValidationErrors();
	  }
	}


	editFuelLog(log: any): void {
	  this.selectedFuelLog = log;
	  this.fuelLogForm.patchValue(log);
	  this.isFormVisible = true;
	}

	deleteFuelLog(logId: number): void {
	  if (!confirm('Are you sure you want to delete this fuel log?')) return;

	  this.vehiclesService.deleteFuelLog(logId).subscribe(
		(result) => {
		  if (result.success) {
			this.snackBar.open('Fuel log deleted successfully.', 'Close', { duration: 3000 });
			this.getFuelLogs(); // Refresh the logs
		  } else {
			this.snackBar.open(result.error, 'Close', { duration: 3000 });
		  }
		},
		() => {
		  this.snackBar.open('Error deleting fuel log.', 'Close', { duration: 3000 });
		}
	  );
	}


	showValidationErrors(): void {
		for (const control in this.fuelLogForm.controls) {
			if (this.fuelLogForm.controls.hasOwnProperty(control)) {
				this.fuelLogForm.controls[control].markAsTouched();
			}
		}
	}

	toggleForm(): void {
		this.isFormVisible = !this.isFormVisible;
		if (this.isFormVisible) this.fuelLogForm.reset({ vehicle_id: this.vehicle ? this.vehicle.vehicle_id : '' });

	}
}
