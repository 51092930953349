import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CardLists, Card } from '../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-order-inquiry',
	templateUrl: './order-inquiry.component.html',
	styleUrls: ['./order-inquiry.component.scss']
})

export class OrderInquiryComponent implements OnInit {
	@Input() name: string;
	@ViewChild('orderView') orderStatusViewRef: ElementRef;
	@ViewChild('invoiceDetails') invoiceDetailsRef: ElementRef;
	@ViewChild('orderDetails') orderDetailsRef: ElementRef;
	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	orderSearchTypeRef = new FormControl('B');
	filterresults: any = [];
	filteredItems: any = [];
	orderData: CardLists;
	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];
	orderResults: any = false;
	ordersFound: any = [];
	showOrderDetails = false;
	showInvoiceDetails = false;
	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	invoicelink: any = '';
	invoicelink2: any = '';
	orderdetails: any = false;
	user: any = false;
	sending = false;
	searchsub: any = false;
	displaydetails: any = false;
	creditlink: any = '';
	creditpickinglink: any = '';
	ngOnInit(): void {}

	constructor(private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, public router: Router, private ordersService: OrdersService, private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
		this.globalSearchService.mesagesallowed.next(false);

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});


		this.pickinglink = this.config.apiServer.baseUrl + this.config.apiServer.pickingLink;
		this.invoicelink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;
		this.invoicelink2 = this.config.apiServer.baseUrl + this.config.apiServer.invoiceLink;

		this.creditlink = this.config.apiServer.baseUrl + this.config.apiServer.creditLink;
		this.creditpickinglink = this.config.apiServer.baseUrl + this.config.apiServer.creditPickingLink;
	}

	viewCustomer(debtorno: string) {
		this.router.navigate(['/customers/view/' + debtorno]);
	}

	viewInvoice(orderno: any) {
		this.ordersService.getInvoicedOrder(orderno).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	viewOrder(orderno: any) {
		this.showOrderDetails = true;
		this.ordersService.getOrder(orderno).subscribe(order => {
			this.orderdetails = order;
			this.showModal(this.orderDetailsRef, 'orderDetails', order);
		});
	}

	openDocument(transaction: any, display: string) {
		this.showInvoiceDetails = true;
		const fetchDetails = transaction.type === '11' ? this.globalsService.getCreditNote(transaction.id, display) : this.globalsService.getInvoice(transaction.id, display);
		fetchDetails.subscribe(result => {
			this.showModal(this.invoiceDetailsRef, 'invoiceDetails', result);
		});
	}

	showModal(ref: ElementRef, modalType: string, data: any) {
		if (modalType === 'orderDetails') {
			this.orderdetails = data;
		} else if (modalType === 'invoiceDetails') {
			this.displaydetails = data;
		}
		this.modalService.open(ref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then(() => {
			this.resetModalState(modalType);
		}, () => {
			this.resetModalState(modalType);
		});
	}


	clearForm() {

		//	this.customersearch.setValue('')
	}
	resetModalState(modalType: string) {
		if (modalType === 'orderDetails') {
			this.showOrderDetails = false;
			this.orderdetails = null;
		} else if (modalType === 'invoiceDetails') {
			this.showInvoiceDetails = false;
			this.displaydetails = null;
		}
	}

	public searchOrders() {

		const payload = {
			keywords: this.orderSearchRef.value,
			orderType: this.orderSearchTypeRef.value,
			issalesman: this.user.user.issalesman,
			salesman: this.user.user.salesman,
		}

		this.sending = true;
		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}

		this.searchsub = this.ordersService.getOrderInqSearch(payload).subscribe(r => {
			this.sending = false;
			this.orderResults = r;
		})

	}

	public onInput(event: any, keys = 'orderno') {

		// this pushes the input value into the service's Observable.

		this.globalSearchService.searchTerm.next(event.target.value);
		const term = event.target.value;
		const allresults = [];
		if (this.openOrderData) {
			this.setFilteredLists(term);
			this.globalSearchService.itemSearch.next(this.ordersFound);
		}
	}

	filterOrders(orders) {

		const allresults = [];

		if (this.openOrderData && this.orderSearch) {
			const openorders = this.filterItem(this.openOrderData, '-', 'ORDER_NUMBER');
			if (openorders) {
				openorders.forEach(function(value) {
					if (orders.includes(value.ORDER_NUMBER)) {

						allresults.push({ content: 'Open Order', details: value, link: '/orders/lookup/' + value.ORDER_NUMBER })
					}
				});


			}
		}

		this.setFiltered(allresults)

	}

	setFilteredLists(term) {

		const allresults = [];
		const ordersFound = [];
		if (this.openOrderData) {
			const openorders = this.filterItem(this.openOrderData, term, 'ADDRESS_1,CUST_NAME,ORDER_NUMBER,CUST_ORDER_NO_,DATE_ORDERED,DATE_WANTED,CUST_NUMBER,ITEM_NUMBER');
			if (openorders) {
				openorders.forEach(function(value) {
					allresults.push({ content: 'Open Order', details: value, link: '/orders/lookup/' + value.ORDER_NUMBER })
					ordersFound.push(value.ORDER_NUMBER)
				});
			}
		}

		this.ordersFound = ordersFound;


		this.setFiltered(allresults)

	}
	getValueByType(values: any, value: any) {
		const filtered = false;
		if (typeof values == 'object') {
			if (values) {
				if (values.length) {
					const newfilter = values.filter((r: any) => {
						return r.code == value
					})[0]

					if (newfilter) {
						return newfilter.text
					}
				}
			}
		}
		return 'N/A';
	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results) {
		this.filteredItems = results;
	}

	assignCopy(input) {
		this.filteredItems = Object.assign([], input);
	}

	filterItem(input, term, keys) {
		if (!term) {
			this.assignCopy(input);
		}
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}

	getDiscountTotal(item: any) {
		const discounted = this.financial(parseFloat(item.unitprice) * (parseFloat(item.discountpercent) / 100));
		const discountprice = parseFloat(item.unitprice) - discounted;
		const total = item.quantity * discountprice;

		return total;
	}
	financial(num: number): number {

		const rounded = Math.round((num + Number.EPSILON) * 100) / 100; // Rounding to 2 decimal places again
		return parseFloat(rounded.toFixed(2));
	}

	navTo(link) {

		this.router.navigate([link]);
	}
	back(): void {
		this.location.back()
	}

}
