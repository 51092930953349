<div class="main-content">

    <div class="card">
        <div class="card-header card-header-{{color}}-6"> Payment Run Report</div>
        <div class="card-body" *ngIf="dataReady">
            <form class="row m-0 p-0 justify-content-between" [formGroup]="reportForm">

                <mat-form-field appearance='fill' class="col-2">
                    <mat-label>From Vendor Code</mat-label>
                    <input matInput formControlName="fromvend">
                </mat-form-field>
                <mat-form-field appearance='fill' class="col-2">
                    <mat-label>To Vendor Code</mat-label>
                    <input matInput formControlName="tovend">
                </mat-form-field>
                <mat-form-field appearance='fill' class="col-2">
                    <mat-label>For Suppliers Trading in </mat-label>
                    <mat-select formControlName="currency">
                        <mat-option *ngFor="let type of currencies"
                            value="{{type.currabrev}}">{{type.currabrev}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance='fill' class="col-2">
                    <mat-label>Exchange Rate</mat-label>
                    <input matInput formControlName="exchange">
                </mat-form-field>
                <mat-form-field appearance='fill' class="col-2">
                    <mat-label>Pay From Account </mat-label>
                    <mat-select formControlName="fromaccount">
                        <mat-option *ngFor="let type of bankaccounts"
                            value="{{type.accountcode}}">({{type.accountcode}}){{type.bankaccountname}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance='fill' class="col-2">
                    <mat-label>Payment Type </mat-label>
                    <mat-select formControlName="paytype">
                        <mat-option *ngFor="let type of paymentmethods"
                            value="{{type.paymentid}}">{{type.paymentname}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </form>
            <form [formGroup]="reportForm" class="row m-0 p-0 w-100">
                <mat-form-field appearance='fill' class="col-2">
                    <mat-label>Payments Due To</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="due">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div class="col-10">
                    <button class="float-right m-1" mat-raised-button color="primary" (click)="runReport()">Run</button>
                    <mat-label *ngIf="reportdata && !(viewingForm === reportForm.value)" class="text-danger m-1">Form has changed, re-run before processing.</mat-label>
                    <button *ngIf="reportdata" class="float-right m-1" mat-raised-button color="accent" (click)="process()" [disabled]="!(viewingForm === reportForm.value)">Process</button>
                    <button *ngIf="reportdata" class="float-right m-1" mat-raised-button  (click)="print()">Print</button>
                </div>
            </form>
        </div>
        <hr style="height: 1px;">
        <div class="col-12 m-auto p-2">
            <table *ngFor="let supplier of reportdata" class="m-auto">
                <tr>
                    <th>Supplier</th>
                    <th>$ Due</th>
                    <th>Exchange DIff</th>
                </tr>
                <tr>
                    <th>{{supplier['supplierid']}}&nbsp;-&nbsp;{{supplier['name']}}&nbsp;-&nbsp;{{supplier['terms']}}</th>
                </tr>
                <tbody class="table-striped">
                    <tr *ngFor="let r of supplier['details']">
                        <td>{{r.trandate}}&nbsp;-&nbsp;{{r.typename}}&nbsp;-&nbsp;{{r.transno}}</td>
                        <td>{{r.balance | currency}}</td>
                        <td>{{r.diffonexch | currency}}</td>
                    </tr>
                </tbody>
                <tr class="total-row">
                    <th>Total due for {{supplier['name']}}</th>
                    <th>{{supplier['balance'] | currency}}</th>
                    <th>{{supplier['exchTotal'] | currency}}</th>
                </tr>
            </table>
        </div>
        <div class="card-footer"></div>
    </div>

</div>