<!-- Filters -->
<div class="row mb-3">
	<div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Search Parts Used</mat-label>
			<input matInput (keyup)="applyFilter($event)" placeholder="Search by Part Name or Number" />
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
	</div>
	<div class="col-md-6 text-end">
		<button mat-raised-button class="mt-2" color="accent" (click)="toggleForm()">
			{{ isFormVisible ? 'Hide Form' : 'Add Part Used' }}
		</button>
	</div>
</div>

<!-- Add/Edit Part Form -->
<div *ngIf="isFormVisible" class="p-3 border rounded bg-light">
	<form [formGroup]="partsUsedForm" (ngSubmit)="savePartUsed()">
		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Vehicle</mat-label>
					<mat-select formControlName="vehicle_id" required>
						<mat-option *ngIf="vehicle" [value]="vehicle.id">
							{{ vehicle.plate }} - {{ vehicle.make }} {{ vehicle.model }}
						</mat-option>
						<mat-option *ngFor="let v of vehicles" [value]="v.id">
							{{ v.plate }} - {{ v.make }} {{ v.model }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="partsUsedForm.controls['vehicle_id'].invalid && partsUsedForm.controls['vehicle_id'].touched">
						Vehicle selection is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Part Name</mat-label>
					<input matInput formControlName="part_name" required />
					<mat-error *ngIf="partsUsedForm.controls['part_name'].invalid && partsUsedForm.controls['part_name'].touched">
						Part Name is required.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Part Number</mat-label>
					<input matInput formControlName="part_number" required />
					<mat-error *ngIf="partsUsedForm.controls['part_number'].invalid && partsUsedForm.controls['part_number'].touched">
						Part Number is required.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-3">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Quantity</mat-label>
					<input matInput formControlName="quantity" type="number" required />
					<mat-error *ngIf="partsUsedForm.controls['quantity'].invalid && partsUsedForm.controls['quantity'].touched">
						Must be at least 1.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-3">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Cost ($)</mat-label>
					<input matInput formControlName="cost" type="number" required />
					<mat-error *ngIf="partsUsedForm.controls['cost'].invalid && partsUsedForm.controls['cost'].touched">
						Must be a positive value.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="text-end">
			<button mat-raised-button color="accent" type="submit" [disabled]="partsUsedForm.invalid">
				Save Part
			</button>
			<button mat-raised-button color="warn" type="button" (click)="toggleForm()">
				Cancel
			</button>
		</div>
	</form>
</div>

<!-- Table -->
<div class="table-responsive mt-4">
	<table class="table table-striped table-hover table-bordered">
		<thead class="thead-dark">
			<tr>
				<th class="text-center">Part Name</th>
				<th class="text-center">Part Number</th>
				<th class="text-center">Quantity</th>
				<th class="text-center">Cost ($)</th>
				<th class="text-center"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let part of filteredPartsUsed">
				<td class="text-center">{{ part.part_name }}</td>
				<td class="text-center">{{ part.part_number }}</td>
				<td class="text-center">{{ part.quantity }}</td>
				<td class="text-center">{{ part.cost }}</td>
				<td class="text-center">
					<button mat-icon-button color="warn" (click)="deletePartUsed(part.part_id)">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>