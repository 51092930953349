<ng-container *ngIf="!menuapp">
	<form [formGroup]="searchForm" class="m-0 p-0">
		<div class="main_card m-0 p-0">
			<div class="row m-0 p-0">
				<div class="col-12">
					<h3> Top {{ this.searchForm.get('limit').value }} Items </h3>
				</div>
			</div>
			<div class="row m-0 p-0">
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline" class="amount-text">
						<mat-label>Limit</mat-label>
						<input matInput type="text" formControlName="limit" class="text-right " />
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Brands</mat-label>
						<mat-select formControlName="brand" multiple appSelectAll>
							<mat-option *ngFor="let v of brands" [value]="v.manufacturer_id">
								{{v.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Categories</mat-label>
						<mat-select formControlName="category" multiple appSelectAll>
							<mat-option *ngFor="let v of categories" [value]="v.categoryid">
								{{v.categoryid }} - {{v.categorydescription}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Product Lines</mat-label>
						<mat-select formControlName="prodline" multiple appSelectAll>
							<mat-option *ngFor="let v of productlines" [value]="v.line_field">
								{{v.line_description}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<span *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
					&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="!sending">
						<i class="fa fa-file-excel-o"></i> XLS
					</button>
					&nbsp;&nbsp;
					<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="!sending">
						<i class="fa fa-file-pdf-o"></i> PDF
					</button>
					&nbsp;&nbsp;
					<button mat-raised-button color="accent" class="mt-2" (click)="tophundred()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</form>
	<div #print_table>
		<div class="table table-responsive text-center" *ngIf="loading">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<table class="table table-striped table-bordered table-hover table-fixed"  *ngIf="!loading">
			<thead>
				<tr>
					<th (click)="sortBy('stockid')">Item Number</th>
					<th (click)="sortBy('description')">Description </th>
					<th (click)="sortBy('salestotal')">Sales Total ($) </th>
					<th (click)="sortBy('qtytotal')">Sales Total (Qty)</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of sortedReportResults">
					<td>{{ r.stockid }}</td>
					<td>{{ r.description }}</td>
					<td>{{ r.salestotal | currency }}</td>
					<td>{{ r.qtytotal | number }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</ng-container>

<ng-container *ngIf="menuapp">
<div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Top {{ this.searchForm.get('limit').value }} Items</h4>
					<p class="card-category text-right"></p>
				</div>
				<div class="card-body">
	<form [formGroup]="searchForm" class="m-0 p-0">
		<div class="m-auto main-width">
			<div class="row m-0 p-0">
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline" class="amount-text">
						<mat-label>Limit</mat-label>
						<input matInput type="text" formControlName="limit" class="text-right " />
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Brands</mat-label>
						<mat-select formControlName="brand" multiple appSelectAll>
							<mat-option *ngFor="let v of brands" [value]="v.manufacturer_id">
								{{v.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Categories</mat-label>
						<mat-select formControlName="category" multiple appSelectAll>
							<mat-option *ngFor="let v of categories" [value]="v.categoryid">
								{{v.categoryid }} - {{v.categorydescription}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Product Lines</mat-label>
						<mat-select formControlName="prodline" multiple appSelectAll>
							<mat-option *ngFor="let v of productlines" [value]="v.line_field">
								{{v.line_description}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Salespeople</mat-label>
						<mat-select formControlName="salesperson" multiple appSelectAll>
							<mat-option *ngFor="let v of salespeople" [value]="v.salesmancode">
								{{v.salesmancode}} - {{v.salesmanname}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="col-md-3 mt-3">
					<span *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
					<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="reportResults.length != 0">
						<i class="fa fa-file-excel-o"></i> XLS
					</button>
					&nbsp;
					<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="reportResults.length != 0">
						<i class="fa fa-file-pdf-o"></i> PDF
					</button>
					&nbsp;
					<button mat-raised-button color="accent" class="mt-2" (click)="tophundred()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</form>
	<div #print_table>
		<table class="table table-striped table-bordered table-hover table-fixed">
			<thead>
				<tr>
					<th>Item Number</th>
					<th>Description</th>
					<th>Sales Total ($)</th>
					<th>Sales Total (Qty)</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of sortedReportResults">
					<td>{{ r.stockid }}</td>
					<td>{{ r.description }}</td>
					<td>{{ r.salestotal | currency }}</td>
					<td>{{ r.qtytotal | number }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	</div>
			</div>
		</div>
	</div>
</div>
</div>
</ng-container>
