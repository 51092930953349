import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PrintService } from '../../services/print.service';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-customer-consignment',
	templateUrl: './customer-consignment.component.html',
	styleUrls: ['./customer-consignment.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerConsignmentComponent implements OnInit {
	@Input() customerdata;
	@Output() updateCustomerEmit: EventEmitter < any > = new EventEmitter();

	flip = 'inactive';
	printing = false;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('reorderBox') reorderRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('print_basic') tablecontentRef: ElementRef;
	headercolumns: string[] = [
		'stockid',
		'brname',
		'description',
		'cost',
		'quantity',
		'date_stocked',
		'ext',
		'actions',
	];
	transactions: any = [];
	consignitems: any = [];
	items: any = [];
	debtorno: any = [];
	color: any = 'blue';
	displaydetails: any = [];
	transid: any = '';
	trandata: any = [];
	total_qty = 0;
	orderquantity = 0;
	reorderstockid: any = '';
	reorderitem: any = [];
	cart_totals: any = [];
	dspcost = true;
	config: any = [];

	//cart
	price: any = [];
	quantity: any = [];
	discount: any = [];
	notes: any = [];

	editsallowed: any = {
		discounts: false,
		editar: false,
		editbilling: false,
		editshipping: false,
		editcontact: false,
		editprofile: false,
		editnotes: false,
		takepayment: false,
	}

	constructor(private printService: PrintService, private route: ActivatedRoute, public customerService: CustomerService, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private globalsService: GlobalsService) {

	this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;

		});
	}

	ngOnInit(): void {

		this.globalSearchService.aiDataAvailable$.next(null);
	}
	updateCustomerEvent(event:any) {
		this.updateCustomerEmit.emit(event);

	}



	addToOrder(stockid) {

		//const search = { stockid: stockid, single: true, stock: true, salestype: this.customerdata.customer.salestype };
		//this.ordersService.getProducts(search).subscribe((results: any) => {

			const item = this.reorderitem;
			item.quantity = this.orderquantity;
			item.price = item.price.replace('$', '');
			this.modalService.dismissAll();
			this.ordersService.addToOrder(item, this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any) => {

				this.globalSearchService.showNotification(item.stockid + ' Added ', 'success', 'bottom', 'right');

				this.total_qty = 0;
				this.total_qty = results.reduce(function(accumulator, items) {
					return accumulator + items.quantity;
				}, 0);

				this.items = results;
				this.ordersService.getTotals(this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any[]) => {
				this.cart_totals = results;
			});
			});



		//});
	}

	updateCartItem(item: any) {

		const index = this.items.indexOf(item);

		const data = {
			customer: this.customerdata.debtorno,
			branch: this.customerdata.branchcode,
			cartid: item.cart_id,
			price: item.price,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
		};


		this.ordersService.updateOrder(data).subscribe((results: any) => {
			this.total_qty = 0;
			this.total_qty = results.reduce(function(accumulator, items) {
				return accumulator + items.quantity;
			}, 0);

			//this.items = results;
			//this.globalSearchService.cartData.subscribe((results: any) => {
			//	this.picking = results;
			//});
			this.ordersService.getTotals(this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any[]) => {
			this.cart_totals = results;
			});
		});


	}

	removeFromOrder(cart_id) {

		this.ordersService.removeFromOrder(cart_id, this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any) => {

			this.globalSearchService.showNotification('Item Removed ', 'danger', 'bottom', 'right');

			this.total_qty = 0;
			this.total_qty = results.reduce(function(accumulator, items) {
				return accumulator + items.quantity;
			}, 0);


			this.items = results;
			this.ordersService.getTotals(this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any[]) => {
			this.cart_totals = results;
		});
		});


	}

	orderConsignItem(item: any) {
		this.reorderstockid = item.stockid;
		this.orderquantity = item.qty;
		this.reorderitem = item;
		this.modalService.open(this.reorderRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {

		});
	}

	updateCustomer() {

	}

	getTotal() {

		const total = this.cart_totals.filter(t => {
			return t.code == 'total';
		})[0]

		let value = 0.00;
		if (total) {
			value = parseFloat(parseFloat(total.text).toFixed(2))
		}

		return value;

	}

	toggleFlip() {
		this.flip = (this.flip == 'active') ? 'inactive' : 'active';
	}

	pdf() {
		this.printing = true;
		this.exportPdf();
	}

	totalItems() {

	}

	totalValue() {

	}

	excel() {
		this.globalSearchService.exportJsonToExcel(this.consignitems, this.customerdata.debtorno+'.xlsx');
	}

	//work around for table button
	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			landscape: true,
			filename: this.customerdata.debtorno+'-Consignment.pdf',
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.printing = false;
		});

	}

	ngAfterViewInit() {

		this.ordersService.getCart(this.customerdata.debtorno, this.customerdata.branchcode).subscribe((items: any) => {
			this.total_qty = 0;
			this.total_qty = items.reduce(function(accumulator, items) {
				return accumulator + items.quantity;
			}, 0);

			this.items = items;
		});

		this.ordersService.getTotals(this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any[]) => {
			this.cart_totals = results;
		});

		this.customerService.getConsignment({ debtorno: this.customerdata.debtorno }).subscribe((results: any[]) => {
			this.consignitems = results;
		});
	}

}
