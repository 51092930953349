<h4>Cards On File</h4>

<button class="btn btn-sm" (click)="toggleAddCard()">
	<i class="fa fa-plus"></i>&nbsp;&nbsp;New Card
</button>

<span *ngIf="loadingcards">
	<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
	</svg>
</span>
<ng-container *ngIf="addingcard">

	<app-credit-card-form [customer]="customer" [branches]="customer.branches" [type]="'orderentry'" (updatePayments)="loadProfile()"></app-credit-card-form>
</ng-container>
<ng-container *ngIf="!addingcard">
	<mat-card>
		<mat-card-content>
			<ul class="list-group">
				<li class="list-group-item text-center" *ngIf="profile_cards && !profile_cards.length && !loadingcards">
					<mat-icon color="primary">credit_card_off</mat-icon>
					<span>No Cards On File</span>
				</li>
				<div class="list-group-item card-item d-flex align-items-center justify-content-between" *ngFor="let c of profile_cards; let i = index;">
				  <div class="card-info">
					<div class="card-name">
					  <mat-icon *ngIf="c.default; else notDefaultIcon" class="default-icon" (click)="setAsDefault(c)">star</mat-icon>
					  <ng-template #notDefaultIcon>
						<mat-icon class="not-default-icon" (click)="setAsDefault(c)">star_border</mat-icon>
					  </ng-template>
					  <b>{{ c.branchcode }} | {{ c.name }}</b>
					</div>
					<div class="card-details">
					  <strong>{{ c.card_type }}</strong>
					  <span class="text-muted">**** {{ c.number.slice(-4) }}</span>
					  <span class="expiry">Exp: {{ c.expiry_month }}/{{ c.expiry_year }}</span>
					</div>
				  </div>
				  <button mat-icon-button color="warn" class="remove-button" (click)="removeCard(c)">
					<mat-icon>delete</mat-icon>
				  </button>
				</div>

			</ul>
		</mat-card-content>
	</mat-card>
</ng-container>