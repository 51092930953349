import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { PaymentsService } from 'app/services/payments.service';
import { PrintService } from 'app/services/print.service';

@Component({
	selector: 'app-payment-run-report',
	templateUrl: './payment-run-report.component.html',
	styleUrls: ['./payment-run-report.component.scss']
})

export class PaymentRunReportComponent implements OnInit {
	color: any = false;
	currencies: any = false;
	paymentmethods: any = false;
	bankaccounts: any = false;
	reportForm: any = new UntypedFormGroup({});
	dataReady: boolean = false;
	reportdata: any = false;
	viewingForm: any;



	constructor(private printService: PrintService, private globalSearchService: GlobalSearchService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder) { }

	ngOnInit(): void {

		this.globalSearchService.payment_methods.subscribe((results: any) => {
			this.paymentmethods = results;
		})

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.bankaccounts.subscribe((results: any) => {
			this.bankaccounts = results;
		});

		this.globalSearchService.currencies.subscribe((results: any) => {
			this.currencies = results;
		})

		this.buildForm();
	}


	runReport() {
		if (this.reportForm.valid) {
			const data = this.reportForm.value;
			this.viewingForm = this.reportForm.value
			this.paymentsService.runPaymentReport(data).subscribe((res) => {
				this.reportdata = res['data'];
			})
		} else {
			this.reportForm.updateValueAndValidity()
		}
	}


	buildForm() {
		this.reportForm = this.fb.group({
			fromvend: ['a', Validators.required],
			tovend: ['z', Validators.required],
			currency: ['USD', Validators.required],
			exchange: ['1', Validators.required],
			due: ['01/31/2025', Validators.required],
			fromaccount: ['', Validators.required],
			paytype: ['', Validators.required],
		});

		this.dataReady = true;
	}


	print() {
		const date = new Date();
		const today = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
		const encoded: string = this.globalSearchService.base64encode(this.buildHtml());
		const data = {
			content: encoded,
			filename: `paymentrunreport`,
			title: `Payment Run Report`,
			subtitle: `Payment Run Report for ${this.viewingForm['due']}`
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}




	buildHtml(){
		let html = '<table style="width: 100%">'
		this.reportdata.forEach(sup => {
			//table head - reapat on page breaks
			html += '<thead>'
				html += '<tr style="background-color: black !important; color:white !important">'
					html += '<th style="text-align: left">Supplier</th>' 
					html += '<th style="text-align: right">$ Due</th>' 
					html += '<th style="text-align: right">Exchange Diff</th>' 
				html += '</tr>'

				//sup head
				html += '<tr>'
					html+= `<th style="text-align: left">${sup['supplierid']} - ${sup['name']} - ${sup['terms']}</th>`
				html += '</tr>'
			html += '</thead>'

			//details rows
			html += '<tbody>'
				sup['details'].forEach(det => {
					html += '<tr>';
						html += `<td>${det.trandate} - ${det.typename} - ${det.transno}</td>`
						html += `<td style="text-align: right">${det.balance}</td>`;
						html += `<td style="text-align: right">${det.diffonexch}</td>`;
					html += '</tr>';
				});
			html += '</tbody>'

			//sup total row
			html += '<tr style="border-top:1px solid black; border-bottom: 1px solid black">';
				html+= `<th style="text-align: left">Total due for ${sup['name']}</th>`
				html+= `<th style="text-align: right">${sup['balance']}</th>`
				html+= `<th style="text-align: right">${sup['exchTotal']}</th>`
			html+= '</tr>'
		});
		return html;
	}

	process() {
		var data = this.viewingForm;
		data['process'] = true;


		this.paymentsService.runPaymentReport(data).subscribe((res) => {
			if (res.success) {
				this.globalSearchService.showNotification('Run Processed', 'success', 'bottom', 'left');
			}
		})
	}


}
