<ng-template #uploadpo let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>Purchase Order Uploader</b></h4>
		<h6 *ngIf="poReviewReady">File count : ({{files.length}})</h6>
		<button mat-stroked-button class="pomodalbtn position-absolute r-70" (click)="$event.preventDefault();toggleUploadHelp()" (keydown.enter)="$event.preventDefault();">Help</button>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>

	</div>
	<div class="modal-body pomodalbody">
		<ng-container *ngIf="!poHelp">
			<ngx-dropzone (change)="onSelect($event)" class="dropzone" *ngIf="!processing_recs && !poReviewReady">
				<ngx-dropzone-label class="dropzone-label">Drop file, or click to upload!</ngx-dropzone-label>
				<ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemove(f)">
					<ngx-dropzone-label class="overflow-auto ">{{ f.name }}</ngx-dropzone-label>
				</ngx-dropzone-preview>
			</ngx-dropzone>
			<div class="table table-responsive text-center" *ngIf="processing_recs">
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
			<mat-tab-group *ngIf="poReviewReady">
				<mat-tab label="Recognized" *ngIf="poDataSource['good']">
					<table mat-table [dataSource]="poDataSource['good']" class="mat-elevation-z8 demo-table" *ngIf="poReviewReady" class="po-table">
						<ng-container matColumnDef="stockid">
							<th mat-header-cell *matHeaderCellDef> Stock ID </th>
							<td mat-cell *matCellDef="let element"> {{element.stockid}} </td>
						</ng-container>

						<ng-container matColumnDef="vendor">
							<th mat-header-cell *matHeaderCellDef> Recommendation Source </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.vendor}} </td>
						</ng-container>

						<ng-container matColumnDef="description">
							<th mat-header-cell *matHeaderCellDef> Description </th>
							<td mat-cell *matCellDef="let element"> {{element.description}} </td>
						</ng-container>

						<ng-container matColumnDef="manufacturer">
							<th mat-header-cell *matHeaderCellDef> MFG </th>
							<td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
						</ng-container>

						<ng-container matColumnDef="line">
							<th mat-header-cell *matHeaderCellDef> Line </th>
							<td mat-cell *matCellDef="let element"> {{element.line}} </td>
						</ng-container>

						<ng-container matColumnDef="min">
							<th mat-header-cell *matHeaderCellDef> Min </th>
							<td mat-cell *matCellDef="let element"> {{element.min}} </td>
						</ng-container>

						<ng-container matColumnDef="max">
							<th mat-header-cell *matHeaderCellDef> Max </th>
							<td mat-cell *matCellDef="let element"> {{element.max}} </td>
						</ng-container>

						<ng-container matColumnDef="qty">
							<th mat-header-cell *matHeaderCellDef> Order Quantity </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.quantity}} </td>
						</ng-container>

						<ng-container matColumnDef="price">
							<th mat-header-cell *matHeaderCellDef> Price </th>
							<td mat-cell *matCellDef="let element"> {{element.price | currency}} </td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="poUploadColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: poUploadColumns;"></tr>
					</table>
				</mat-tab>
				<mat-tab label="Unrecognized" *ngIf="poDataSource['bad']">
					<table mat-table [dataSource]="poDataSource['bad']" class="mat-elevation-z8 demo-table" *ngIf="poReviewReady" class="po-table">
						<ng-container matColumnDef="stockid">
							<th mat-header-cell *matHeaderCellDef> Stock ID </th>
							<td mat-cell *matCellDef="let element"> {{element.stockid}} </td>
						</ng-container>

						<ng-container matColumnDef="vendor">
							<th mat-header-cell *matHeaderCellDef> Reccomendation Source </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.vendor}} </td>
						</ng-container>

						<ng-container matColumnDef="description">
							<th mat-header-cell *matHeaderCellDef> Description </th>
							<td mat-cell *matCellDef="let element"> {{element.description}} </td>
						</ng-container>

						<ng-container matColumnDef="manufacturer">
							<th mat-header-cell *matHeaderCellDef> MFG </th>
							<td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
						</ng-container>

						<ng-container matColumnDef="line">
							<th mat-header-cell *matHeaderCellDef> Line </th>
							<td mat-cell *matCellDef="let element"> {{element.line}} </td>
						</ng-container>

						<ng-container matColumnDef="min">
							<th mat-header-cell *matHeaderCellDef> Min </th>
							<td mat-cell *matCellDef="let element"> {{element.min}} </td>
						</ng-container>

						<ng-container matColumnDef="max">
							<th mat-header-cell *matHeaderCellDef> Max </th>
							<td mat-cell *matCellDef="let element"> {{element.max}} </td>
						</ng-container>

						<ng-container matColumnDef="qty">
							<th mat-header-cell *matHeaderCellDef> Order Quantity </th>
							<td mat-cell *matCellDef="let element" class="text-center "> {{element.quantity}} </td>
						</ng-container>

						<ng-container matColumnDef="price">
							<th mat-header-cell *matHeaderCellDef> Price </th>
							<td mat-cell *matCellDef="let element"> {{element.price | currency}} </td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="poUploadColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: poUploadColumns;"></tr>
					</table>
				</mat-tab>
			</mat-tab-group>
		</ng-container>
		<ng-container *ngIf="poHelp">
			<div class="row">
				<div class="col">
					<h4><b>Headers</b></h4>
					<h6 class="warning ">(These are required to be exact matches, order of columns isn't important)</h6>
					<ol>
						<li>stockid</li>
						<li>qty_purchase</li>
						<li>price</li>
					</ol>
					<hr>
					<h4><b>Notes</b></h4>
					<ul style="max-height: 150px;" class="overflow-auto ">
						<li>
							<p>Each stockid is validated within the system. If a match is found it will be shown under the recognized tab, otherwise it will be under the unrecognized tab.</p>
						</li>
						<li>
							<p>Only recognized skus will be pushed through to PO review.</p>
						</li>
						<li>
							<p>Multiple files can be uploaded at once and will be merged into a single purchase order.</p>
						</li>
						<li>
							<p>When price is left blank, the system will use last PO price and if that's blank it will use primary supplier price.</p>
						</li>
						<li>
							<p>Lines with a qty_purchase of 0 are ignored.</p>
						</li>
						<li>
							<p>Files can be removed during the upload step by hovering over the title of the file and clicking the <mat-icon>cancel</mat-icon> button.</p>
						</li>
					</ul>
				</div>
				<mat-divider vertical="true"></mat-divider>
				<div class="col">
					<h4><b>Row Data</b></h4>
					<h6>(Stockids are validated within in the system)</h6>
					<ol>
						<li>
							Stock ID you are trying to purchase as it appears in the system.
							<ul>
								<li>TOY-243450 <mat-icon>done</mat-icon>
								</li>
								<li>"TOY-243450" <span style="color: red;">(quotes)</span></li>
							</ul>
						</li>
						<li>
							Amount to Order.
							<ul>
								<li>4 <mat-icon>done</mat-icon>
								</li>
								<li>four <span style="color: red;">(non-numeric value)</span></li>
							</ul>
						</li>
						<li>
							Price of a single tire with no commas.
							<ul>
								<li>78.92<mat-icon>done</mat-icon>
								</li>
								<li>1,274.22 <span style="color: red;">(comma)</span></li>
							</ul>
						</li>
					</ol>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer pomodalfooter" [ngClass]="upload_error ? 'p-5' : ''">
		<div class="pomodalvendors row">
			<div>
				<span>Selected Vendor</span>
				<hr class="p-0 m-0">
				<h4><b><span [ngClass]="vendors_detected != false && customer.supplierid != vendors_detected ? 'warning' : ''">{{customer.supplierid}}</span> : {{customer.suppname}}</b></h4>
			</div>
			<div *ngIf="vendors_detected">
				<span>Detected</span>
				<hr class="p-0 m-0">
				<h4><span [ngClass]="customer.supplierid != vendors_detected ? 'warning' : ''">{{vendors_detected}}</span></h4>
			</div>
			<div *ngIf="poReviewReady">
				<span>Recognized</span>
				<hr class="p-0 m-0">
				<h4>Units: {{uploadPodata['units']['good']}} Lines: {{uploadPodata['lines']['good']}} <br></h4>
			</div>
			<div *ngIf="poReviewReady && poDataSource['bad']">
				<span>Unrecognized</span>
				<hr class="p-0 m-0">
				<h4>Units: {{uploadPodata['units']['bad']}} Lines: {{uploadPodata['lines']['bad']}} <br></h4>
			</div>
			<div *ngIf="upload_error" class="error_container">
				<span>Processing Error</span>
				<hr class="p-0 m-0 w-100 ">
				<h4 class='d-flex '>
					<ng-container *ngFor="let file of upload_error">
						<div *ngFor="let err of file | keyvalue" class="mr-5">
							{{err.key}} : <span class="warning" style="font-size: 16px;">{{err.value.message}} &nbsp;</span>
							<br style="height: 1px;">
							<span style="font-size: 12px;">{{err.value.file}}</span>
						</div>
					</ng-container>
				</h4>
			</div>
		</div>

		<button mat-stroked-button *ngIf="!poReviewReady" class="pomodalbtn" (click)="$event.preventDefault();uploadPo()" (keydown.enter)="$event.preventDefault();" [disabled]="files.length == 0">Process Recommendations</button>
		<button mat-stroked-button *ngIf="poReviewReady" class="pomodalbtn float-left " (click)="$event.preventDefault();resetUploadPo()" (keydown.enter)="$event.preventDefault();">Back</button>
		<button mat-stroked-button *ngIf="poReviewReady" class="pomodalbtn" (click)="$event.preventDefault();buildPO()" (keydown.enter)="$event.preventDefault();" [disabled]="!poDataSource['good']">Build PO</button>
	</div>
</ng-template>
<ng-template #mmmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ promo.itemdesc.stockid }} <span>{{promo.itemdesc.description }}</span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-12">
				<div class="promo-item-description">
					<p class="stock-id">{{ promo.itemdesc.stockid }}</p>
					<p class="description">{{ promo.itemdesc.description }}</p>
				</div>
				<div class="promo-list-container">
					<ul class="promo-list">
						<ng-container *ngFor="let intro of promo.intros">
							<ng-container *ngIf="intro.type === '1' || intro.type === 1">
								<li class="promo-item">
									<div class="item-description">
										<p class="item-title">{{ intro.stockid }} - {{ intro.description }}</p>
										<p class="item-qty-price">{{ intro.qty }} @ {{ intro.price | currency }}</p>
									</div>
								</li>
							</ng-container>
						</ng-container>
					</ul>
				</div>

			</div>
			<div class="col-md-12 text-right">
				<mat-form-field appearance="outline">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput type="number" [(ngModel)]="promo.qty" (input)="setParent($event, promo)" class="text-right">
				</mat-form-field>
			</div>
		</div>

		<!-- {{ promo.descriptions | json }} <h4>PROMO {{ promo.description }} {{ ispromo.promo.price | currency }} </h4> -->
		<div class="promo-container" *ngIf="promo">
			<div class="col-12 sticky-col">
				<button class="btn btn-primary float-right" [disabled]="isCompleted(promo)" (click)="addMM(promo)">Add To Order </button>
			</div>
			<h5>Promo Items</h5>
			<div class="row m-0 p-0 w-100">

				<ng-container *ngFor="let intro of promo.intros">
					<div class="col-md-4" *ngIf="intro.type != '1'">
						<div class="promos-intro">
							<h4>{{ intro.name }} {{ intro.description }}</h4>
							<ng-container *ngIf="intro.type === '1'; else typeDefault">
								<div class="promos-intro-details">
									<div class="promos-quantity-info">
										{{ intro.item }} - {{ intro.description }} ({{ intro.qty }} @ {{ intro.price | currency }})
									</div>
								</div>
							</ng-container>
							<ng-template #typeDefault>
								<div class="promos-intro-details" [ngClass]="{'promos-error': intro.error}">
									<div class="promos-quantity-info">
										{{ intro.price }}
										<span [ngClass]="{'promos-zero-quantity': (updateMMQty(intro) == 0), 'text-success': (updateMMQty(intro) < 0) }">
											<ng-container *ngIf="intro.dsptype != '$'">{{ intro.dsptype }} {{ updateMMQty(intro) }} / {{ intro.floor }}</ng-container>
											<ng-container *ngIf="intro.dsptype == '$'">{{ intro.dsptype }} {{ updateMMQty(intro) | number : '1.2-2'}} / {{ intro.floor | number: '1.2-2'}}</ng-container>
										</span>
										{{ intro.price }}
									</div>
								</div>
							</ng-template>
						</div>
						<div>
							<mat-form-field appearance="fill">
								<mat-label>Search Mix & Match...</mat-label>
								<input matInput [(ngModel)]="intro.searchQuery" (input)="filterItems(intro)" appCollapseKeyboard />
								<span matSuffix>
									<button mat-icon-button color="white" (click)="filterItems(intro)">
										<mat-icon>search</mat-icon>
									</button>
								</span>
							</mat-form-field>
						</div>
						<ul class="promos-three-column-list">
							<ng-container *ngFor="let item of (intro.filteredItems ? intro.filteredItems : intro.items)">
								<li class="promos-list-group-item promos-item-details">
									<div class="promos-item-details-row">
										<div class="promos-item-description">
											<p>{{ item.stockid }}</p>
											<p>{{ item.description }}</p>
										</div>
										<div class="promos-item-quantity">
											<button mat-icon-button (click)="decrementmm(item, intro)">
												<mat-icon>remove</mat-icon>
											</button>
											<input matInput type="text" class="promos-quantity-input" [value]="item.quantity | number" (input)="qtyMM($event, item, intro)" />
											<button mat-icon-button (click)="incrementmm(item, intro)">
												<mat-icon>add</mat-icon>
											</button>
										</div>
									</div>
								</li>
							</ng-container>
						</ul>
					</div>
				</ng-container>

			</div>
		</div>
	</div>
</ng-template>
<ng-template #edumodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ event.name }} Registration - {{ event.location_name }} - {{ event.date }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-12">
				{{ event.short_description }}
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-3">
				<b>Host</b>
			</div>
			<div class="col-3">
				{{ event.host }}
			</div>
			<div class="col-3">
				<b>Presenter</b>
			</div>
			<div class="col-3">
				{{ event.artist_name }}
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-3">
				<b>Cost Per Seat</b>
			</div>
			<div class="col-3">
				{{ event.discount | currency }}
			</div>
			<div class="col-3">
				<b>Capacity Remaining</b>
			</div>
			<div class="col-3">
				{{ event.remaining }} / {{ event.uses_total }}
			</div>
		</div>
		<hr>
		<form [formGroup]="regForm">
			<div class="row">

				<div class="col-2">
					<mat-form-field>
						<mat-label>Name</mat-label>
						<input matInput type="text" formControlName="eduname">
					</mat-form-field>
				</div>
				<div class="col-2">
					<mat-form-field>
						<mat-label>Cell Phone</mat-label>
						<input matInput type="text" formControlName="educell">
					</mat-form-field>
				</div>
				<div class="col-2">
					<mat-form-field>
						<mat-label>Email</mat-label>
						<input matInput type="text" formControlName="eduemail">
					</mat-form-field>
				</div>
				<div class="col-2">
					<mat-form-field>
						<mat-label>Instagram</mat-label>
						<input matInput type="text" formControlName="eduinsta">
					</mat-form-field>
				</div>
				<div class="col-2">
					<button type="button" mat-stroked-button aria-label="Add" (click)="addSeat(event)" [disabled]="!regForm.valid"><mat-icon>add</mat-icon> Add Seat</button>
				</div>

			</div>
		</form>
		<hr>
		<div class="row" *ngFor="let seat of event.seats">
			<div class="col-2">
				{{ seat.name }}
			</div>
			<div class="col-2">
				{{ seat.cell }}
			</div>
			<div class="col-2">
				{{ seat.email }}
			</div>
			<div class="col-2">
				{{ seat.insta }}
			</div>
			<div class="col-2">
				<button type="button" mat-stroked-button aria-label="Add" (click)="remSeat(seat)"><mat-icon>remove</mat-icon></button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<h4 class="modal-title">Total: {{ event.total | currency}}</h4>
		<button type="button" mat-stroked-button aria-label="Add" (click)="addedureg(event)" [disabled]="!event.seats.length"><mat-icon>add</mat-icon> Add To Order</button>
	</div>
</ng-template>
<ng-template #serialmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{selecteditem.stockid }} Select Serial / Lot Numbers</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">	
	<div class="row" *ngFor="let s of selecteditem.serialdata; let i = index;">
	<div class="col-6 mt-2">{{ s.serialno }} </div>
	<div class="col-6 mt-2"><mat-form-field>
	<input matInput type="text" class="promos-quantity-input" [attr.data-serialno]="s.serialno" [value]="s.qtypicked" (input)="qtySerial($event, s, selectedItem)" />
	</mat-form-field></div>
	</div>
	</div>
	<div class="modal-footer">
		{{ serialqtypicked }} / {{serialqtyneeded}} Selected <button type="button" mat-stroked-button aria-label="Add" (click)="addserialitem(event)" [disabled]="serialqtypicked != serialqtyneeded"><mat-icon>add</mat-icon> Add To Order</button>
	</div>
</ng-template>
<ng-template #itemHistoryRef let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ selecteditem.stockid }} <span [innerHTML]="cleanAndFormatHTML(selecteditem.description)"></span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<mat-tab-group>
			<mat-tab label="{{ customer.name }} Sales">
				<div class="row mt-3">
					<div class="col-12">

						<div class="row" *ngIf="!itemhistory.length">
							<div class="col-12 text-center">
								<h5>No Sales History</h5>
							</div>
						</div>
						<table class="table table-sm smaller-font" *ngIf="itemhistory.length">
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Order#</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Total</th>
							</tr>
							<tr *ngFor="let i of itemhistory">
								<td>{{ i.trandate }}</td>
								<td>{{ i.typename }}</td>
								<td>{{ i.order_ }}</td>
								<td>{{ i.quantity }}</td>
								<td>{{ i.price  | currency}}</td>
								<td>{{ i.price * i.quantity | currency }}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Purchases">
				<!-- (orderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)" -->
				<h4>Open Purchase Orders</h4>
				<app-open-purchase-orders [item]="selecteditem.stockid"></app-open-purchase-orders>
				<mat-divider></mat-divider>
				<h4>Purchase History</h4>
				<table class="table table-sm" *ngIf="itemhistory.purchase">
					<thead>
						<tr>
							<th class="text-left w-20">SUPP</th>
							<th class="text-left w-20">PO</th>
							<th class="text-left w-20">DATE</th>
							<th class="text-center w-20">QTY</th>
							<th class="text-right w-20">COST</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let h of itemhistory.purchase">
							<td class="text-left"> {{h.suppname}}</td>
							<td class="text-left"> {{h.orderno}}</td>
							<td class="text-left"> {{h.orddate}}</td>
							<td class="text-center"> {{h.quantityord}}</td>
							<td class="text-right"> {{h.unitprice | currency}}</td>
						</tr>
					</tbody>
				</table>
			</mat-tab>
			<mat-tab label="Purchase Data">
				<div class="row m-0 p-0 mt-3">
					<ng-container *ngFor="let pdata of selecteditem.purchdata">
						<mat-card class="ml-auto mr-auto col-md-4 cpdata" [ngClass]="selecteditem.selected_po_option == pdata ? 'cpdata-selected': '' " (click)="setItemSelectedPoOption(selecteditem,pdata)">
							<mat-card-header>
								<mat-card-subtitle><span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="small" matBadgeColor="accent"></span></mat-card-subtitle>
								<mat-card-title>{{ pdata.supplierid }} - {{ pdata.suppname }} </mat-card-title>
								<mat-card-title> {{ pdata.price | currency }}</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<h4>Inventory</h4>
								<ng-container *ngIf="pdata.remote">
									<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
										<li>
											<div class="spec-label">{{ remote.feedlabel }}:</div>
											<div class="spec-value">{{ remote.qty }}</div>
										</li>
									</ul>
								</ng-container>
								<ng-container *ngIf="!pdata.remote">
									<ul class="entry-list p-0 m-0">
										<li>
											<div class="spec-label">N/A:</div>
											<div class="spec-value">N/A</div>
										</li>
									</ul>
								</ng-container>
							</mat-card-content>

						</mat-card>
					</ng-container>
				</div>
				<app-purchase-data [item]="selecteditem" (newitemdata)="updateItem($event)"></app-purchase-data>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #purchasedetails let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngFor="let item of purchase_items">
			<div class="col-12">
				<app-purchase-data [item]="item" (newitemdata)="updateItem($event)"></app-purchase-data>
			</div>
			<div [ngClass]="item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(item.cart_id, pdata)">
					<span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="large" matBadgeColor="accent"></span>
					<div class="card-header text-center">
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} {{ pdata.price | currency }}</h4>
					</div>
					<div class="card-body text-center">
						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #itemdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.stockid }}</b><span [innerHTML]="item_details.name"></span> &nbsp;&nbsp;&nbsp;
			<a [routerLink]="'/inventory/view/'+item_details.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="cleanAndFormatHTML(item_details.descriptions.name)"></h3>
						<section class="section-small" [innerHTML]="cleanAndFormatHTML(item_details.descriptions.description)"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.features)"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.benefits)"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="standard" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #orderdetailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.deliverto }} ( {{ orderdetails.debtorno }}.{{orderdetails.branchcode}} ) </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<h4>INVOICE: <b>{{ orderdetails.orderno }}</b><br>
				</h4>
			</div>
		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">PRICE</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of orderdetails.details">
						<td class="text-left"><b>{{ item.stkcode }}</b></td>
						<td>{{ item.itemdesc }}</td>
						<td class="text-center">{{ item.quantity }} </td>

						<td class="text-right">{{ item.unitprice * item.quantity | currency }} </td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-container *ngFor="let item of filtered_items; let i = index;trackBy: trackByStockId">
	<!-- BEAUTY HEADER -->
	<div class="row mt-1 mb-1 pt-0 pb-0 border-top text-center text-md-left">

		<div class="col-12 text-center col-md-2 col-lg-auto mt-0 ml-auto mr-auto ml-md-0 mr-md-auto">
			<div class="ml-auto mr-auto" [ngClass]="( isExpanded(i) ) ? 'pholder_expanded': 'pholder'" (click)="expand_row(i)">
				<img src="{{item.image_large}}" class="ml-auto mr-auto img img-responsive img-fluid p-0 m-0 attribute-image" lazy>
				<span *ngFor="let image of item.attribute_images">
					<img src="{{image}}" class="ml-auto mr-auto img img-responsive img-fluid p-0 m-0 attribute-image" lazy>
				</span>
			</div>
		</div>
		<div class="col-md-4 col-lg-3 mt-1 ml-auto mr-auto ml-md-0 mr-md-0 p-0" [ngClass]="showfilters? 'col-xl-2' : ''">
			<button tabindex="-1" mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewPurchOrderOptions(item)" *ngIf="config.pofromorder && config.env.package == 'tires'">
				<mat-icon class="smaller-font">edit</mat-icon>
			</button>
			<span class="bold pointer" (click)="openModal(itemdetails, item)">
				{{ item.stockid }} | <span class="lightbrand">{{item.brand}}</span>
				<hr class="m0">
				<span class="lightdesc">{{ item.description }}</span>
			</span>
		</div>
		<div class="col-12 col-md-5 col-lg-2 col-xl-3 mt-2 ml-auto mr-auto ml-md-auro mr-md-0">
			<div class="row m-0 ml-auto mr-0 p-0">
				<div class="col-2 col-md-6 col-lg-5 m-0 p-0 ml-auto mr-auto">
					<div class="form-field">
						<label>
							<span>Discount %</span>
							<input type="text" tabindex="-1" inputmode="numeric" pattern="[0-9]*" (input)="updateSearchDiscount($event, item)" [value]="item.discount" class="text-right outlined-input">
							<span class="underline"></span>
						</label>
					</div>
				</div>
				<div class="col-2 col-md-6 col-lg-6 m-0 p-0 ml-auto mr-auto mr-lg-0">
					<div class="form-field">
						<label>
							<span *ngIf="type == '21'">Cost $</span>
							<span *ngIf="type != '21'">
								{{ item.specialflag ? 'Special $': 'Sell Price $' }}
							</span>

							<input type="text" tabindex="-1" inputmode="numeric" pattern="[0-9]*" (input)="updateSearchPrice($event, item)" [value]="item.price" class="text-right outlined-input" [ngClass]="item.specialflag ? 'specialbox': ''">
							<span class="underline"></span>
						</label>
					</div>
					<span *ngIf="config.pofromorder">
						<button tabindex="-1" aria-label="Change Vendor" color="accent" onclick="viewitemOrderHistory(item)">
							<span class="material-icons smaller-font">visibility</span>
						</button>
					</span>
					<mat-hint *ngIf="item.hasdiscount && item.discount" class="text-danger bolder text-right">
						{{ (item.price - (getItemDiscountMultiplier(item) * item.price)) | currency }} @ {{ getItemDiscountMultiplier(item) | percent}}
					</mat-hint>
					<mat-hint *ngIf="item.fetvalue && item.fetvalue != '0.00'" class="text-danger bolder text-right">
						FET: {{ item.fetvalue | currency }}
					</mat-hint>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-6 col-lg-3  ml-auto mr-auto">
			<div class="row p-0 m-0 align-items-center mt-1">
				<div class="col-auto mt-2 ml-auto mr-0 p-0 shrink-md-2 mr-md-3">
					<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-lg-1" (click)="decrement(item)">
						<small><i class="material-icons">remove</i></small>
					</button>
				</div>
				<div class="m-0 p-0 mt-2 col-auto" [ngClass]="showfilters ? 'col-4' : 'col-6 mw100'">
					<div class="quantity-input-container" #inputElement>
						<label for="quantity" class="ml-1" [ngClass]="(isInputFocused) ? 'shrink':''">{{ isInputFocused || item.quantity ? '' : '&nbsp;Qty' }}</label>

						<ng-container *ngIf="i === 0">
							<input type="text" #firstItem id="quantity" tabindex="{{ i + 2 }}" inputmode="numeric" pattern="[0-9]*" [(ngModel)]="item.quantity" class="text-right" (keydown.enter)="addToOrder(item);handleEnterKey($event, i);" (keydown.tab)="handleTabKey($event, i)" (focus)="selectText($event)">
						</ng-container>
						<ng-container *ngIf="i !== 0">
							<input type="text" id="quantity" tabindex="{{ i + 2 }}" inputmode="numeric" pattern="[0-9]*" [(ngModel)]="item.quantity" class="text-right" (keydown.enter)="addToOrder(item);handleEnterKey($event, i);" (keydown.tab)="handleTabKey($event, i)" (focus)="selectText($event)">
						</ng-container>



					</div>
				</div>
				<div class="col-auto mt-2 ml-3 mr-0 p-0 mr-auto shrink-md-2">
					<ng-container>
						<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(item)">
							<small><i class="material-icons">add</i></small>
						</button>
					</ng-container>
				</div>
			</div>
		</div>

		<!-- total box with add qty -->
		<div class="col-6 col-md-6 col-lg-1 ml-auto mb-auto mt-auto ml-auto mr-auto">
			<div [ngClass]="(item.quantity > 0) ? 'linetotal_row_picked' : 'linetotal_row'" class="row">
				<span class="linetotal_price text-dark">
					&nbsp;{{ getLineTotal(item) | currency }} &nbsp;
				</span>

				<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="addToOrder(item)" *ngIf="!item.ispromo && !item.isevent && item.controlled != 1">
					<small><i class="material-icons">add_box</i></small>
				</button>
				<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="triggerEdu(item)" *ngIf="item.isevent">
					<small><i class="material-icons">settings</i></small>
				</button>
				<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="triggerSerial(item)" *ngIf="item.controlled == 1">
					<small><i class="material-icons">settings</i></small>
				</button>

				<ng-container *ngIf="item.ispromo">
					<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
						<small><i class="material-icons">settings</i></small>
					</button>
					<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
						<small><i class="material-icons">add_box</i></small>
					</button>
				</ng-container>
			</div>
		</div>

		<!-- end whole container -->
		<!-- END HEAD -->
	</div>
	<!-- END BEAUTY HEAD -->
</ng-container>