import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { VehiclesService } from '../../services/vehicles.service';

@Component({
  selector: 'app-accident-logs',
  templateUrl: './accident-logs.component.html',
  styleUrls: ['./accident-logs.component.scss']
})
export class AccidentLogsComponent implements OnInit {
  accidentLogForm: FormGroup;
  accidentLogs: any = [];
  filteredAccidentLogs: any = [];
  vehicles: any = [];
  selectedAccidentLog: any | null = null;
  isFormVisible = false;
  isLoading = false;
  searchTerm = '';
  vehicleFilter = '';
  config: any = [];
  color: any = 'string';

  constructor(
    private fb: FormBuilder,
    private globalsService: GlobalsService,
    private vehiclesService: VehiclesService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private snackBar: MatSnackBar,
    private globalSearchService: GlobalSearchService
  ) {
    this.color = this.globalsService.getColor();
    this.globalSearchService.configsubscription.subscribe(r => {
      this.config = r;
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadAccidentLogs();
    this.loadVehicles();
  }

  initializeForm(): void {
    this.accidentLogForm = this.fb.group({
      vehicle_id: ['', Validators.required],
      accident_date: ['', Validators.required],
      location: ['', Validators.required],
      description: ['', Validators.required],
      damage_description: [''],
      estimated_repair_cost: [0, [Validators.required, Validators.min(0)]],
      actual_repair_cost: [0, [Validators.min(0)]],
      reported_to_insurance: [false],
      claim_status: ['Pending', Validators.required]
    });
  }

  loadAccidentLogs(): void {
    this.isLoading = true;
    const filters = {};
    this.vehiclesService.getAccidentLogs(filters).subscribe(
      (accidentLogs) => {
        this.accidentLogs = accidentLogs.accidents;
        this.filteredAccidentLogs = accidentLogs.accidents;
        this.isLoading = false;
      },
      () => {
        this.snackBar.open('Error loading accident logs.', 'Close', { duration: 3000 });
        this.isLoading = false;
      }
    );
  }

  loadVehicles(): void {
    const filters = {};
    this.vehiclesService.getVehicles(filters).subscribe((vehicles) => {
      this.vehicles = vehicles.vehicles;
    });
  }

  applyFilter(event: any): void {
    this.searchTerm = event.target.value.toLowerCase();
    this.filterAccidentLogs();
  }

  applyVehicleFilter(vehicleId: string): void {
    this.vehicleFilter = vehicleId;
    this.filterAccidentLogs();
  }

  filterAccidentLogs(): void {
    this.filteredAccidentLogs = this.accidentLogs.filter(log => {
      const matchesSearch = !this.searchTerm ||
        log.location.toLowerCase().includes(this.searchTerm) ||
        log.description.toLowerCase().includes(this.searchTerm) ||
        log.claim_status.toLowerCase().includes(this.searchTerm);
      const matchesVehicle = !this.vehicleFilter || log.vehicle_id.toString() === this.vehicleFilter;
      return matchesSearch && matchesVehicle;
    });
  }

  toggleForm(): void {
    this.isFormVisible = !this.isFormVisible;
    if (this.isFormVisible) this.resetForm();
  }

  editAccidentLog(log: any): void {
    this.selectedAccidentLog = log;
    this.accidentLogForm.patchValue(log);
    this.isFormVisible = true;
  }

  saveAccidentLog(): void {
    if (this.accidentLogForm.valid) {
      const accidentLogData = this.accidentLogForm.value;
      if (this.selectedAccidentLog) {
        this.vehiclesService.updateAccidentLog(accidentLogData).subscribe(() => {
          this.snackBar.open('Accident log updated successfully.', 'Close', { duration: 3000 });
          this.loadAccidentLogs();
          this.toggleForm();
        });
      } else {
        this.vehiclesService.addAccidentLog(accidentLogData).subscribe(() => {
          this.snackBar.open('Accident log added successfully.', 'Close', { duration: 3000 });
          this.loadAccidentLogs();
          this.toggleForm();
        });
      }
    }
  }

  deleteAccidentLog(accidentId: number): void {
    if (!confirm('Are you sure you want to delete this accident log?')) return;

    this.vehiclesService.deleteAccidentLog(accidentId).subscribe(
      (result) => {
        if (result.success) {
          this.snackBar.open('Accident log deleted successfully.', 'Close', { duration: 3000 });
          this.loadAccidentLogs();
        } else {
          this.snackBar.open(result.error, 'Close', { duration: 3000 });
        }
      },
      () => {
        this.snackBar.open('Error deleting accident log.', 'Close', { duration: 3000 });
      }
    );
  }

  resetForm(): void {
    this.accidentLogForm.reset();
    this.selectedAccidentLog = null;
  }

  back(): void {
    this.location.back();
  }
}