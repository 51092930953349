import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl, UntypedFormControl } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'
import { TaxesService } from '../../services/taxes.service'
import { GeneralLedgerService } from '../../services/general-ledger.service'
import { InventoryService } from '../../services/inventory.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import { Console } from 'console';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss'],
  animations: [
		trigger('slide', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: 0, width: 0, overflow: 'hidden' }),
				animate(100, style({ height: '*', width: '*', transform: 'translateX(2000px)', overflow: 'hidden' })),
				animate('100ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(150, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('150ms', style({ height: '0', width: '0', opacity: 0, transform: 'translateX(2000px)' }))
			])
		]),
	]
})
export class MarketingComponent {

	color = 'blue';
	
	constructor(private location: Location){
	
	
	}
	back(): void {
		this.location.back()
	}

}
