<ng-template #monthDetails let-modal>
	<div class="modal-header">
		<h4> {{ monthtitle }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="table mt-3" *ngIf="results">
			<div class="table">
				<table class="table table-striped table-hover" *ngIf="monthdetails">
					<tr>
						<th>DOCUMENT#</th>
						<th>SOURCE</th>
						<th>TYPE</th>
						<th>DATE</th>
						<th>ITEM#</th>
						<th>DESC</th>
						<th class="text-center">DISCOUNT</th>
						<th class="text-center">QTY</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of monthdetails">
						<td>
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>
								<button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10'">
									<mat-icon>history</mat-icon>
									<span>Credit</span>
								</button>
							</mat-menu>
							{{ item.transno }}
						</td>
						<td>
							<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'beauty'">
								<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
								<ng-container *ngSwitchCase="2">POS</ng-container>
								<ng-container *ngSwitchCase="3">SALES PERSON</ng-container>
								<ng-container *ngSwitchCase="4">WEB</ng-container>
								<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
							</ng-container>
							<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'tires'">
								<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
								<ng-container *ngSwitchCase="2">AMI</ng-container>
								<ng-container *ngSwitchCase="4">WEB</ng-container>
								<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
							</ng-container>
						</td>
						<td>{{ item.typename }}</td>
						<td>{{ item.trandate }}</td>
						<td><a [routerLink]="'/inventory/view/'+item.stockid">{{ item.stockid }}</a></td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.discountpercent | percent }}</td>
						<td class="text-center">{{ item.qty }}</td>
						<td class="text-right">{{item.price | currency }}</td>
						<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
					</tr>
				</table>
				<h4 class="text-center" *ngIf="!results.items">
					No Results
				</h4>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="podetails">
			<a [routerLink]="'/vendors/'+podetails.poheader.supplierno">{{ podetails.poheader.supplierno }}</a> Purchase Order
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<!-- Purchase Order Tab -->
			<mat-tab label="Purchase Order">
				<div class="po-details" *ngIf="podetails">
					<!-- PO Header -->
					<h4 class="mt-4 mb-0"><strong>Purchase Order #{{ podetails.poheader.orderno }}</strong></h4>

					<!-- Contact and Delivery Information Cards -->
					<div class="row m-0 p-0">
						<!-- Contact Information -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-primary text-white">
									<strong>Contact Information</strong>
								</div>
								<div class="card-body">
									<p>
										<strong>Contact:</strong> {{ podetails.poheader.contact }}<br>
										<strong>Address:</strong>
										<ng-container *ngIf="podetails.address">
											<div [innerHTML]="podetails.address"></div>
										</ng-container>
									</p>
								</div>
							</div>
						</div>

						<!-- Delivery Information -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-primary text-white">
									<strong>Delivery Information</strong>
								</div>
								<div class="card-body">
									<p>
										<strong>Delivery Address:</strong><br>
										<ng-container *ngFor="let field of ['deladd1', 'deladd2', 'deladd3', 'deladd4', 'deladd5']">
											<span *ngIf="podetails.poheader[field]">{{ podetails.poheader[field] }}<br></span>
										</ng-container>
									</p>
								</div>
							</div>
						</div>
					</div>

					<!-- Notes and Totals -->
					<div class="row m-0 p-0">
						<!-- Notes Section -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-info text-white">
									<strong>Notes</strong>
								</div>
								<div class="card-body">
									<div [innerHTML]="podetails.poheader.stat_comment"></div>
								</div>
							</div>
						</div>

						<!-- Totals Section -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-info text-white">
									<strong>Totals</strong>
								</div>
								<div class="card-body">
									<p>
										<strong>Total Quantity:</strong> {{ getTotalQuantity() }}<br>
										<strong>Total Value:</strong> {{ getTotalValue() | currency }}
									</p>
								</div>
							</div>
						</div>
					</div>

					<!-- Purchase Order Lines Table -->
					<div class="table-responsive mt-3">
						<table class="table table-sm table-striped table-hover table-bordered">
							<thead class="thead-dark">
								<tr>
									<th>ITEM</th>
									<th>DESCRIPTION</th>
									<th class="text-center">QTY</th>
									<th class="text-center">RCVD</th>
									<th class="text-center">PENDING</th>
									<th class="text-right">PRICE</th>
									<th class="text-right">TOTAL</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of podetails.polines" [ngClass]="item.itemcode == stockidMatch ? 'background-primary' : ''">
									<td>
										<button mat-icon-button (click)="copyToClipboard(item.itemcode)" title="Copy to clipboard" class="mr-2">
											<mat-icon>content_copy</mat-icon>
										</button>
										<a [routerLink]="'/inventory/view/' + item.itemcode" (click)="modal.dismiss('route change')" class="font-weight-bold">
											{{ item.itemcode }}
										</a>
									</td>
									<td>{{ item.itemdescription }}</td>
									<td class="text-center">{{ item.quantityord }}</td>
									<td class="text-center">{{ item.quantityrecd }}</td>
									<td class="text-center">{{ item.quantityord - item.quantityrecd }}</td>
									<td class="text-right">{{ item.unitprice | currency }}</td>
									<td class="text-right">{{ item.unitprice * item.quantityord | currency }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2"><strong>Total</strong></td>
									<td class="text-center"><strong>{{ getTotalQuantity() }}</strong></td>
									<td colspan="3"></td>
									<td class="text-right"><strong>{{ getTotalValue() | currency }}</strong></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</mat-tab>

			<!-- Receiving Details Tab -->
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>GRN</th>
								<th>Order#</th>
								<th>Item#</th>
								<th>Description</th>
								<th>Received</th>
								<th>Invoiced</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.receiving" [ngClass]="r.itemcode == stockidMatch ? 'background-primary' : ''">
								<td>{{ r.grnno }}</td>
								<td>{{ r.poorderno }}</td>
								<td>{{ r.itemcode }}</td>
								<td>{{ r.itemdescription }}</td>
								<td>{{ r.qtyrecd }}</td>
								<td>{{ r.quantityinv }}</td>
								<td>{{ r.update_date }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					<p>No Receiving Details</p>
				</ng-container>
			</mat-tab>
			<!-- Receiving Movements Tab -->
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>Item#</th>
								<th>Location</th>
								<th>User</th>
								<th>Quantity</th>
								<th>QOH</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.movements" [ngClass]="r.stockid == stockidMatch ? 'background-primary' : ''">
								<td>{{ r.stockid }}</td>
								<td>{{ r.loccode }}</td>
								<td>{{ r.userid }}</td>
								<td>{{ r.qty }}</td>
								<td>{{ r.newqoh }}</td>
								<td>{{ r.trandate }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					<p>No Movements</p>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Close</button>
	</div>
</ng-template>
<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<mat-tab-group (selectedTabChange)="loadData()" >
	<mat-tab label="Item History">
		<ng-template matTabContent>
		
		<h3> Item History </h3>
		
		<div class="row m-0 p-0">
			<div class="col-md-2">
				<mat-form-field appearance="outline">
					<mat-label>Warehouse</mat-label>
					<mat-select [formControl]="defaultlocation" multiple appSelectAll>
						<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
							{{ loc.locationname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline" class="">
					<mat-label>Brand</mat-label>
					<mat-select [formControl]="brands" multiple appSelectAll>
						<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline" class="">
					<mat-label>Category</mat-label>
					<mat-select [formControl]="categoryid" multiple appSelectAll>
						<mat-option *ngFor="let k of properties.categories" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline" class="">
					<mat-label>Product Line</mat-label>
					<mat-select [formControl]="lineid" multiple appSelectAll>
						<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2 mr-0">
				<mat-form-field appearance="outline" class="text-right">
					<mat-label>Transaction Dates</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
						<input matEndDate [formControl]="todateCtrl" placeholder="End date">
					</mat-date-range-input>
					<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<div class="pos-right">
					<button class="btn btn-success btn-sm" [disabled]="sending || (!results)" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
					&nbsp;&nbsp;
					<button class="btn btn-danger btn-sm" [disabled]="sending || (!results && !results?.length)" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
					<span *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
					<button mat-raised-button color="accent" class="btn-sm ml-2 mt-2" (click)="loadData()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
				</div>
			</div>
			<div class="col-md-4 ">
				<mat-form-field appearance="outline">
					<input type="text" class="text-right p-0 m-0" placeholder="Search Items " aria-label="Notes" #itemsearch [formControl]="search" (keyup.enter)="loadData()" matInput />
					<span matSuffix>
						<button tabindex="-1" mat-icon-button color="white">
							<mat-icon (click)="loadData()">search</mat-icon>
						</button>
					</span>
				</mat-form-field>
			</div>
			<div class="col-md-8">
				<mat-paginator
		  [pageSizeOptions]="items_per_page"
		  [pageSize]="100"
		></mat-paginator>
			</div>

		</div>
		

		<div class="table mt-2" #print_items>
			<table class="table table-striped table-fixed table-hover" *ngIf="results">
				<thead>
				<tr>
					<th>DOCUMENT#</th>
					<th>TYPE</th>
					<th>LOCATION#</th>
					<th>DATE</th>
					<th>ITEM#</th>
					<th>DESC</th>
					<th class="text-center">DISCOUNT</th>
					<th class="text-center">QTY ORDERED</th>
					<th class="text-center">QTY REC</th>
					<th class="text-right">PRICE</th>
					<th class="text-right">TOTAL</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let item of dataObs | async">
					<td>
						<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item (click)="viewOrder(item.orderno, orderDetails, item.itemcode)">
								<mat-icon>visibility</mat-icon>
								<span>View</span>
							</button>
							<a href="{{config.apiServer.baseUrl}}{{baselink}}{{item.orderno}}" mat-menu-item>
								<mat-icon>print</mat-icon>
								<span>PDF</span>
							</a>
							<a href="{{config.apiServer.baseUrl}}{{explink}}{{item.orderno}}" mat-menu-item>
								<mat-icon>save</mat-icon>
								<span>Excel</span>
							</a>
							<!-- <button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10'">
								<mat-icon>history</mat-icon>
								<span>RMA/Credit</span>
							</button> -->
						</mat-menu>
						{{ item.transno }}
					</td>
					<td>{{ item.typename }}</td>
					<td>{{ item.loccode }}</td>
					<td>{{ item.trandate }}</td>
					<td>{{ item.stockid }}</td>
					<td>{{item.description}}</td>
					<td class="text-center">{{item.discountpercent | percent }}</td>
					<td class="text-center">{{ item.quantityord }}</td>
					<td class="text-center">{{ item.quantityrecd }}</td>
					<td class="text-right">{{item.price | currency }}</td>
					<td class="text-right">{{ getLineTotal(item) | currency }}</td>
				</tr>
				<tr>
					<td>TOTAL</td>
					<td></td>
					<td></td>

					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td ></td>
					<td class="text-center">{{ getItemTotals() }} </td>
					<td></td>
					<td class="text-right">{{ getDollarTotals() | currency }}</td>
				</tr>
				</tbody>
			</table>
			<h4 class="text-center" *ngIf="!results">
				No Results
			</h4>
		</div>
	</ng-template></mat-tab>
	<mat-tab label="Brands">
		<ng-template matTabContent>
			<app-brand-report [vendor]="vendordata" [menuapp]="false"></app-brand-report>
		</ng-template>
	</mat-tab>
	<mat-tab label="CVSLS">
		<ng-template matTabContent>
			<app-cvsls-content [vendor]="vendordata"></app-cvsls-content>
		</ng-template>
	</mat-tab>
	<mat-tab label="VRTP">
		<ng-template matTabContent>
			<app-vrpt-content [vendor]="vendordata"></app-vrpt-content>
		</ng-template>
	</mat-tab>

</mat-tab-group>
